import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer1.module.css";

const Footer1 = () => {
  return (
    <>
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.groupChild} />
      </div>

      <a href="https://www.facebook.com/Techrowth/" target="_blank"  className="text-white">
    <img className={styles.footerChild} alt=""  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/fb.svg" />
    </a>
    <a href="https://www.instagram.com/tech_rowth/" target="_blank"  className="text-white">
    <img className={styles.footerChil} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/insta.svg" />
    </a>
    <a href="https://www.linkedin.com/company/techrowth/" target="_blank"  className="text-white">
    <img className={styles.footerChil3} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/linkedin.svg" />
    </a>
    <a href="https://twitter.com/tech_rowth" target="_blank"  className="text-white">
    <img className={styles.footerChil4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/twitter.svg" />
    </a>
    <a href="https://www.youtube.com/channel/UCCx5CMYxWfs52awPd94vBCg" target="_blank"  className="text-white">
    <img className={styles.footerChil5} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/youtube.svg" />
    </a>

      <div className={styles.resourcesAboutTechrowthContainer}>
      <p className={styles.resources}> <Link to="/digital-marketing" style={{textDecoration:"none"}} className="text-white">Digital Marketing</Link></p>
        <p className={styles.aboutTechrowth}>
          <Link to="/digital-marketing/intent-we-serve" className="text-white" style={{textDecoration:"none"}}>
          {" "}  Intents We Serve{" "}
          </Link>
          |
          <Link to="/digital-marketing" className="text-white" style={{textDecoration:"none"}}>
          {" "}  Our Services{" "}
          </Link>
          |
          {/* <Link to="/digital-marketing/graphic-design" className="text-white" style={{textDecoration:"none"}}>
          {" "} Graphic Design{" "}
          </Link>
          | */}
          <Link to="/digital-marketing/ecommerce-marketing" className="text-white" style={{textDecoration:"none"}}>
          {" "}  E-commerce{" "}
          </Link>
          |
          <Link to="/digital-marketing/content-marketing" className="text-white" style={{textDecoration:"none"}}>
          {" "}  Content{" "}
          </Link>
        </p>
        <p className={styles.aboutTechrowth}>&nbsp;</p>
        <p className={styles.resources}><Link to="/it-services" style={{textDecoration:"none"}} className="text-white">IT Services</Link></p>
        <p className={styles.aboutTechrowth}>
          <Link to="/it-services/it-development" className="text-white" style={{textDecoration:"none"}}>
          {" "}   Development{" "}
          </Link>
          |
          <Link to="/it-services/uiux-services" className="text-white" style={{textDecoration:"none"}}>
          {" "} UI/UX Interactive{" "}
          </Link>
          |
          <Link to="/it-services/it-management-service" className="text-white" style={{textDecoration:"none"}} >
          {" "}   IT Management{" "}
          </Link>
          |
          <Link to="/it-services/integration-services" className="text-white" style={{textDecoration:"none"}}>
          {" "}    Integrations{" "}
          </Link>
          |
          <Link to="/it-services/quality-testing-service" className="text-white" style={{textDecoration:"none"}}>
          {" "}    Quality Testing{" "}
          </Link>
        </p>
        <p className={styles.blankLine3}>&nbsp;</p>

    
      
        {/* <p className={styles.aboutTechrowth}>&nbsp;</p> */}
        <p className={styles.resources}>Analytics</p>
        <p className={styles.aboutTechrowth}>
          
          {" "}   Data Analytics{" "}
          
          |
          
          {" "} Data Visualization{" "}
          
          |
          
          {" "}   Business Analytics{" "}
          
          |
          
          {" "}    Data Mining{" "}
          
          |
          
          {" "}    Predictive Analytics{" "}
          
      
        </p>
        <p className={styles.blankLine3}>&nbsp;</p>
        <p className={styles.resources}>Who We Are</p>
        <p className={styles.aboutTechrowth}>
        <Link to="/about-us" className="text-white" style={{textDecoration:"none"}}>
          {" "}    About Us{" "}
          </Link>
          |
        <Link to="/contact-us" className="text-white" style={{textDecoration:"none"}}>
          {" "}    Contact Us{" "}
          </Link>
           
    
        </p>
        <p className={styles.aboutTechrowth}>&nbsp;</p>
        <p className={styles.resources}>Resources</p>
        <p className={styles.aboutTechrowth}>
        <Link to="/techrowth-knowledge" className="text-white" style={{textDecoration:"none"}}>
          {" "}    Techrowth Knowledge{" "}
          </Link>
          
          |
          <Link to="/techrowth-portfolio" className="text-white" style={{textDecoration:"none"}}>
          {" "}    Portfolio{" "}
          </Link>      
        </p>
        <p className={styles.aboutTechrowth}>&nbsp;</p>
      </div>
      <div className={styles.groupParent}>
        <div className={styles.rectangleWrapper}>
          <div className={styles.groupItem} />
        </div>
        <div className={styles.coIzyaneInovsolutionsLtdMParent}>
          <div className={styles.coIzyaneInovsolutionsContainer}>
          <p
              className={styles.aboutTechrowth}
            >{`Zambia `}</p>
            <p
              className={styles.aboutTechrowth}
            >{`C/O iZyane InovSolutions Ltd `}</p>
            <p
              className={styles.aboutTechrowth}
            >{`Mukuba Pensions House, 2nd Floor,  `}</p>
            <p className={styles.aboutTechrowth}>
              Dedan Kimathi Road, Lusaka 10100
            </p>
            <p className={styles.aboutTechrowth}>&nbsp;</p>
            <p className={styles.aboutTechrowth}>
              info@techrowth.com | <br />
              (+260) 767527931
            </p>
          </div>
          <div className={styles.groupInner} />
          <div className={styles.no3731stContainer}>
          <p
              className={styles.aboutTechrowth}
            >{`India (HQ) `}</p>
            <p
              className={styles.aboutTechrowth}
            >{`No.373, Garudadri Arcade, 2nd floor, 2nd Phase,`}</p>
            <p className={styles.aboutTechrowth}>
              WOC Road, Stage 2,<br/> Rajajinagar Bengaluru, Karnataka
              560086
            </p>
            <p className={styles.aboutTechrowth}>&nbsp;</p>
            <p className={styles.aboutTechrowth}>
              info@techrowth.com |<br /> +91 80731 62943
            </p>
          </div>
          <div className={styles.lineDiv} />
          <div className={styles.no3731stContainer1}>
          <p
              className={styles.aboutTechrowth}
            >{`Australia `}</p>
            <p
              className={styles.aboutTechrowth}
            >{` 10, Willesden Avenue, Kingsley,`}</p>
            <p className={styles.aboutTechrowth}>
              Western Australia 6026
            </p><br/>
            <p className={styles.aboutTechrowth}>&nbsp;</p>
            <p className={styles.aboutTechrowth}>
              info@techrowth.com |<br /> +61 04020 49350
            </p>
          </div>
          <div className={styles.lineDiv1} />
          <div className={styles.termsConditionsPrivacyPParent}>
            <div className={styles.termsConditionsContainer}>
            {/* <a href="/terms-and-conditions" className="text-white" style={{textDecoration:"none"}}><p>Terms & Conditions</p></a> */}
            <a href="/terms-and-conditions" className="text-white" style={{textDecoration:"none"}}> <p className={styles.aboutTechrowth}>{`Terms & Conditions	   `}</p></a>
            <a href="/privacy-policy" className="text-white" style={{textDecoration:"none"}}><p className={styles.aboutTechrowth}>{`Privacy Policy   `}</p></a>
              <p className={styles.aboutTechrowth}>Sitemap  </p>
            </div>
            <div className={styles.techrowthPvtLtd}>
              {" "}
              © 2023 Techrowth Pvt. Ltd.
            </div>
          </div>
        </div>
      </div>






    </div>

    <div className={styles.footermobile}>
    <Link to="/" className="text-white">
    <img className={styles.footerChild1} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/footer.png" />
    </Link>
    <div className={styles.footerChild2}>
    <div className={styles.footerChild3 }>
        <strong><a href="/digital-marketing" style={{textDecoration:"none"}} className="text-white"><h6>Digital Marketing</h6></a></strong>
        <a href="/digital-marketing/intent-we-serve" className="text-white" style={{textDecoration:"none"}}><p>Intents We Serve</p></a>
        <a href="/digital-marketing" className="text-white" style={{textDecoration:"none"}}><p>Our Services</p></a>
        {/* <a href="/digital-marketing/graphic-design" className="text-white" style={{textDecoration:"none"}}><p>Graphic Design</p></a> */}
        <a href="/digital-marketing/ecommerce-marketing" className="text-white" style={{textDecoration:"none"}}><p>E-Commerce</p></a>
        <a href="/digital-marketing/content-marketing" className="text-white" style={{textDecoration:"none"}}><p>Content</p></a>
        <br/>
        <strong><h6 style={{textDecoration:"none"}}>Who We Are</h6></strong>
        <a href="/about-us" className="text-white" style={{textDecoration:"none"}}><p>About Us</p></a>
  
        <a href="/contact-us" className="text-white" style={{textDecoration:"none"}}><p>Contact Us</p></a>

        <br/>
        <strong><h6>Resources</h6></strong>
        <a href="/techrowth-knowledge" style={{textDecoration:"none"}} className="text-white"><p>Techrowth Knowledge</p></a>
     
        <a href="/techrowth-portfolio" style={{textDecoration:"none"}} className="text-white"><p>Portfolio</p></a>
    
        <br/>

    </div>
    <div className={styles.footerChild4}>
    <strong><a href="/it-services" className="text-white" style={{textDecoration:"none"}}><h6>IT Services</h6></a></strong>
        <a href="/it-services/it-development" className="text-white" style={{textDecoration:"none"}}><p>Development</p></a>
        <a href="/it-services/uiux-services" className="text-white" style={{textDecoration:"none"}}><p>UI/UX Interactive</p></a>
        <a href="/it-services/it-management-service" className="text-white" style={{textDecoration:"none"}}><p>IT Management</p></a>
        <a href="/it-services/integration-services" className="text-white" style={{textDecoration:"none"}}><p>Integrations</p></a>
        <a href="/it-services/quality-testing-service" className="text-white" style={{textDecoration:"none"}}><p>Quality Testing</p></a>

        <br/>
    <strong><h6>Analytics</h6></strong>
        <p>Data Analytics</p>
        <p>Data Visualization</p>
        <p>Business Analytics</p>
        <p>Data Mining</p>
        <p>Predictive Analytics</p>


        <br/>
    </div>
    </div>
    <div className={styles.footeradd}>
    <a href="https://www.facebook.com/Techrowth/" target="_blank"  className="text-white">
    <img className={styles.footerchildimg} alt=""  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/fb.svg" />
    </a>
    <a href="https://www.instagram.com/tech_rowth/" target="_blank"  className="text-white">
    <img className={styles.footerchildimg} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/insta.svg" />
    </a>
    <a href="https://www.linkedin.com/company/techrowth/" target="_blank"  className="text-white">
    <img className={styles.footerchildimg} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/linkedin.svg" />
    </a>
    <a href="https://twitter.com/tech_rowth" target="_blank"  className="text-white">
    <img className={styles.footerchildimg} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/twitter.svg" />
    </a>
    <a href="https://www.youtube.com/channel/UCCx5CMYxWfs52awPd94vBCg" target="_blank"  className="text-white">
    <img className={styles.footerchildimg} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/youtube.svg" />
    </a>

</div>
    <div className={styles.footeraddress}>
      <p>Zambia</p>
      <p>C/O iZyane InovSolutions Ltd <br/>
Mukuba Pensions House, 2nd Floor,  <br/>
Dedan Kimathi Road, Lusaka 10100</p>
<p>info@techrowth.com | (+260) 767527931</p>
<hr className={styles.footeraddress1}/>
<p>India (HQ)</p>

<p>No.373, Garudadri Arcade, 2nd floor, 2nd Phase,<br/>
WOC Road, Stage 2, Rajajinagar<br/> Bengaluru, Karnataka 560086</p>
<p>info@techrowth.com | +91 80731 62943</p>
<hr className={styles.footeraddress1}/>
<p>Australia</p>

<p>10, Willesden Avenue, Kingsley,<br/>
Western Australia 6026</p>
<p>info@techrowth.com | +61 04020 49350</p>
<hr className={styles.footeraddress1}/>

<a href="/terms-and-conditions" className="text-white" style={{textDecoration:"none"}}><p>Terms & Conditions</p></a>
<a href="/privacy-policy" className="text-white" style={{textDecoration:"none"}}><p>Privacy Policy </p></a>
<a href="#" className="text-white" style={{textDecoration:"none"}}><p>Sitemap</p></a>
<p> © 2023 Techrowth Pvt. Ltd.</p>
    </div>
      
    </div>
    </>
  );
};

export default Footer1;
