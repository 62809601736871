import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth from "../components/InterestedInTechrowth";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider from "../components/ClientsSlider";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions2 from "../components/SocialMediaMarketingSolutions2";
import Services2 from "../components/Services2";
import ProblemsWeSolved from "../components/ProblemsWeSolved";
import OtherServices03 from "../components/OtherServices03";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial6 from "../components/NewTestimonial6";
import { Link } from "react-router-dom";
import TechnologiesWeUseAndServiSocia6 from "../components/TechnologiesWeUseAndServiSocia6";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth116";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth16";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";
const EmailMarketing = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Hero+Section-2.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M8.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Email Marketing", path: "/digital-marketing/email-marketing" },

    ]);
  }, []);

  const HeroTitel = "Email Marketing";
  const HeroContent =
    "Techrowth provides the best Email Marketing services in Bangalore, delivering expert strategies to optimize your email campaigns and engage your audience effectively.";
  const subject = "Email Marketing ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/email-marketing" />
<title>
Best Email Marketing Company In Bangalore | Email Marketing Services in Bengaluru
</title>
<meta name="description" content="Reach your target audience with the help of the best email marketing company in Bangalore with our result-driven email marketing strategy.  Contact our email marketing experts
" />
<meta name="keywords" content="Email Marketing Services In Bangalore, Email Marketing Company in Bangalore, email marketing service providers,Email Marketing Services In Bangalore
" />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider/>
      <NewTestimonial6/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Reach out to your target audience with the best email marketing company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions2 />
      <Services2/>
      <ProblemsWeSolved/>
      <TechnologiesWeUseAndServiSocia6/>

      <OtherServices03/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default EmailMarketing;
