import styles from "./AdvantageOfTechrowth.module.css";
import NumberCounter from 'number-counter';

const InterestedInTechrowth1 = ({ title}) => {
  return (
    <div className={styles.secondPageIntent}>
      <div className="container py-5">
      <b className={styles.advantageOfTechrowth} style={{fontWeight:"600"}}>Interested in {title}</b>
      <div className={styles.weExploreDifferentContainer +" my-4"}>
        <p className={styles.weExploreDifferent}>
        Expert Web Application Development Services for a Streamlined Journey
        </p>
      </div>
      <div className={styles.secondPageIntentInner +" my-4"}>
        <div className={styles.frameParent }>
          <div className={styles.parent}>
          {/* <NumberCounter end={34} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>60%</div>
            <div className={styles.incrementInGross}>
            Increase in User Engagement
            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={21} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>40%</div>
            <div className={styles.incrementInSocial}>
            Reduction in Process Cycle Time
            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={9} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>63%</div>
            <div className={styles.incrementInSocial}>
            Increase in Organic Traffic Growth

            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={36} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>500+</div>
            <div className={styles.incrementInSocial}>
            Feedback Submission

            </div>
          </div>
        </div>
      </div>
      <div className={styles.inTheRealmContainer}>
        <p className={styles.weExploreDifferent}>
        Here's a glimpse of our outstanding achievements and the impact we've made with our Web Application Development Service in Bangalore

        </p>
      </div>
    </div>
    </div>
  );
};

export default InterestedInTechrowth1;
