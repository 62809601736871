import React, { useState, useEffect } from "react";
import FullScreenButton from "./example21";
import TrustedByBrands from "../components/TrustedByBrands";
import "./PhoneDetector.css";
import 'react-phone-number-input/style.css'
import styles from '../components/ImageRow.module.css';
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Link } from "react-router-dom";




const PhoneDetector = () => {
  return (
    <>
    <div className={styles1.newHomepage}>
    <div className="portfolio-headings pt-4 pb-2" style={{width:"100%" }}>
      <div className="container">
        <div className="product-heading-display">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="product-header">
                <div className="row">
                  <div className="col-lg-1 col-2" style={{margin:"auto 0"}}>
                    <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/16.png" alt="" className="img-fluid" style={{width:"5rem"}}/>
                  </div>
                  <div className="col-lg-10 col-10">
                    <h2 className="mb-0" style={{ color: "#0076a2" }}>Citizen Car</h2>
                    <h5 style={{ color: "#0076a2" }}>#Ecommerce Sector <br/>
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}>Hire Us</span>
                    </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    
    </div>
1
    <div className="App">
      <FullScreenButton />
    </div>
    <div className="App1 container">
    <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture108.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
              <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture109.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture110.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture111.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture112.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
 

    </div>
        <div className="container my-5">
    <div className="row" style={{marginTop:"10rem"}}>
        <div className="col-md-8 col-12">
        <h1 style={{ color: "#0076a2" }}>Citizen Car</h1>
      <h5>We've created user-friendly platforms to drive your automotive business forward. Dive into our portfolio to see how we've helped resellers connect with customers effortlessly. Our websites are designed to simplify car browsing, showcase inventory, and ensure a smooth and informed buying experience, making every ride a reliable adventure.</h5>
 <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Sector : </span>Ecommerce Sector</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Problem Statement : </span>Citizen Cars, a premium second-hand car reseller, demanded a website that reflected the quality of their offerings. They sought a responsive and user-friendly platform enriched with advanced features.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Solution : </span>We accepted the challenge with enthusiasm and created a website that matches the elegance of their premium cars. Our design is not just responsive and user-friendly but also incorporates advanced features that elevate the browsing experience. The outcome is an online showroom that perfectly complements their premium offerings, delivering a top-notch user experience.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Tools and Techniques : </span>ReactJS, NodeJS, Figma</h5>





      <h5 style={{ color: "#0076a2",fontWeight:"600" }}>Need a Website ? 
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}> Hire Us</span>
                    </Link>
                    </h5>
        </div>
        <div className="col-md-4 col-12" style={{height:"33rem", overflowY:"scroll",border:"2px solid #e9eef4", padding:"10px"}}>
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Fullscreen/23.jpg" alt="Image 2" className="img-fluid"/>

        </div>
      </div>

                    <hr className="my-5" style={{ color: "#0076a2" }} />
                    <h4>You might also like</h4>
                    <div className={styles.imageContainer}>

                    <Link to="/techrowth-portfolio/business-listing" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/22.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/25.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Business Listing</p>
          </div>
        </div>

      </Link>
      <Link to="/techrowth-portfolio/arvaa-academy" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/23.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/17.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Arvaa Academy
</p>
          </div>
        </div>
  
    </Link>
    <Link to="/techrowth-portfolio/zambia-medical-assoc" style={{textDecoration:"none", color:"black"}}>
  
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/24.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/21.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Zambia Medical Assoc</p>
          </div>
        </div>
        
      </Link>
      </div>
    </div>
    <div className={styles1.newHomepage}>

    <ContactForm1/></div>
      <TrustedByBrands />
      </>
  );

};





export default PhoneDetector;
