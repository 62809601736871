import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>Intents We Serve</b>
          <p className={styles.ourCoreValuessss}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc14.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Enquiry</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    An enquiry is a request for information or clarification about something. In the context of a business, an enquiry might be a question or request for more information about a product or service, a request for a quote or proposal, or a request to place an order. Business transactions frequently involve enquiries, which can be made by a range of parties, including clients, prospective clients, suppliers, and partners. 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc15.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Branding</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Branding is the process of creating a unique name, design, and image for a product or service in consumers' minds. It is a way to differentiate your offering from that of your competitors and establish a presence in the market. Some of the elements that can be included in a brand strategy include the brand name, logo, color scheme, messaging, and packaging.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc16.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Engagement</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Increase visibility, improve the rankings, and bring in more visitors`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Engagement refers to the level of interaction and participation that a website or social media profile is able to generate from its users. It is a measure of how well the content on a website or social media profile is able to attract and retain the attention of its visitors, and how well it is able to encourage them to take some action, such as commenting, liking, sharing, or subscribing. 

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc17.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Downloading</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI
                        </div>
                      </div>
                    </div>
                     
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                         The main benefits of app downloads for businesses are improved customer engagement, increased customer convenience, customer loyalty, and brand visibility. Overall, having an app can help businesses reach and serve their customers in a more effective and efficient way, which can lead to increased sales and profitability.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc18.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-Commerce</b>
                        <div className={styles.consistentInGetting}>
                        Turn social media into a shopping paradise
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    E-Commerce services refer to the range of online platforms and tools that facilitate the buying and selling of products or services over the Internet. These services include a wide range of areas, including the development of online storefronts, safe payment processing, order administration, inventory tracking, and customer assistance, allowing firms to set up and manage their online retail presence efficiently.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc19.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Upselling</b>
                        <div className={styles.consistentInGetting}>
                        Connect with millions of shoppers
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Upselling in digital marketing is the practice of encouraging customers to purchase a more expensive product or upgrade their current purchase, through the use of digital channels, such as email, social media, and online advertising. This can include targeted ads, email campaigns, and personalized product recommendations. Upselling is often used to increase the average order value and generate more revenue for a business. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc20.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Cross-selling</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Seamless transactions, secure payments`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                        Cross-selling is a sales technique that involves promoting related or complementary products or services to a customer who has already expressed an interest in purchasing something from you. The goal of cross-selling is to increase the size of the sale and generate more revenue from each customer. Cross-selling can be an effective way to increase customer satisfaction and loyalty, as it allows businesses to offer customers a more complete solution to their needs. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc21.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Remarketing</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
               
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                          Engagement refers to the level of interaction and participation that a website or social media profile is able to generate from its users. It is a measure of how well the content on a website or social media profile is able to attract and retain the attention of its visitors, and how well it is able to encourage them to take some action, such as commenting, liking, sharing, or subscribing. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc22.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Redownloading</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Seamless transactions, secure payments`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                       Redownloading refers to making inactive customers download the app again. Reinstalling an app can positively impact businesses with benefits like enhanced customer retention, increased loyalty, improved convenience, and additional revenue prospects. Overall, redownloading an app can help businesses keep their existing customers engaged and generate additional revenue from them.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc23.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Reactivation</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
               
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                          Reactivation is the process of bringing back former customers or users who have stopped engaging with a product or service. Reactivation efforts can take many forms, including email campaigns, special offers, or personalized recommendations. The goal of reactivation is to convince former customers or users that the product or service is still relevant and valuable to them and to remind them of the benefits that they enjoyed when they were previously using it. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>Intents We Serve</b>
          <p className={styles.ourOrigin123}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc14.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Enquiry</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    An enquiry is a request for information or clarification about something. In the context of a business, an enquiry might be a question or request for more information about a product or service, a request for a quote or proposal, or a request to place an order. Business transactions frequently involve enquiries, which can be made by a range of parties, including clients, prospective clients, suppliers, and partners. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc15.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Branding</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Branding is the process of creating a unique name, design, and image for a product or service in consumers' minds. It is a way to differentiate your offering from that of your competitors and establish a presence in the market. Some of the elements that can be included in a brand strategy include the brand name, logo, color scheme, messaging, and packaging.
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc16.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Engagement</b>
                        <div className={styles.consistentInGetting}>
                        Increase visibility and improve the rankings
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Engagement refers to the level of interaction and participation that a website or social media profile is able to generate from its users. It is a measure of how well the content on a website or social media profile is able to attract and retain the attention of its visitors, and how well it is able to encourage them to take some action, such as commenting, liking, sharing, or subscribing. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc17.svg"

                  />
                  <div className={styles.groupContainer}>
                    
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Downloading</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    The main benefits of app downloads for businesses are improved customer engagement, increased customer convenience, customer loyalty, and brand visibility. Overall, having an app can help businesses reach and serve their customers in a more effective and efficient way, which can lead to increased sales and profitability.
                    </div>
              </div>
          
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc18.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-Commerce</b>
                        <div className={styles.consistentInGetting}>
                        Turn social media into a shopping paradise
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    E-Commerce services refer to the range of online platforms and tools that facilitate the buying and selling of products or services over the Internet. These services include a wide range of areas, including the development of online storefronts, safe payment processing, order administration, inventory tracking, and customer assistance, allowing firms to set up and manage their online retail presence efficiently.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc19.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Upselling</b>
                        <div className={styles.consistentInGetting}>
                        Connect with millions of shoppers
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Upselling in digital marketing is the practice of encouraging customers to purchase a more expensive product or upgrade their current purchase, through the use of digital channels, such as email, social media, and online advertising. This can include targeted ads, email campaigns, and personalized product recommendations. Upselling is often used to increase the average order value and generate more revenue for a business. 
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc20.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Cross-selling</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Cross-selling is a sales technique that involves promoting related or complementary products or services to a customer who has already expressed an interest in purchasing something from you. The goal of cross-selling is to increase the size of the sale and generate more revenue from each customer. Cross-selling can be an effective way to increase customer satisfaction and loyalty, as it allows businesses to offer customers a more complete solution to their needs. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc21.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Remarketing</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Engagement refers to the level of interaction and participation that a website or social media profile is able to generate from its users. It is a measure of how well the content on a website or social media profile is able to attract and retain the attention of its visitors, and how well it is able to encourage them to take some action, such as commenting, liking, sharing, or subscribing. 
                    </div>
              </div>
            
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc22.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Redownloading</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
              
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Redownloading refers to making inactive customers download the app again. Reinstalling an app can positively impact businesses with benefits like enhanced customer retention, increased loyalty, improved convenience, and additional revenue prospects. Overall, redownloading an app can help businesses keep their existing customers engaged and generate additional revenue from them.

                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc23.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Reactivation</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Reactivation is the process of bringing back former customers or users who have stopped engaging with a product or service. Reactivation efforts can take many forms, including email campaigns, special offers, or personalized recommendations. The goal of reactivation is to convince former customers or users that the product or service is still relevant and valuable to them and to remind them of the benefits that they enjoyed when they were previously using it. 
                    </div>
              </div>
          
            </div>
           
          </div>
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
