import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth1 from "../components/InterestedInTechrowth1";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider1 from "../components/ClientsSlider1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions11 from "../components/SocialMediaMarketingSolutions11";
import Services11 from "../components/Services11";
import ProblemsWeSolved1 from "../components/ProblemsWeSolved1";
import OtherServices6 from "../components/OtherServices6";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial12 from "../components/NewTestimonial12";
import { Link } from "react-router-dom";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthsub225";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthsub205";
import style from "./NewHomepage1.module.css";
const WebsiteAccessibility = () => {
  const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/banner6.png";
  const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M20.png";


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT", path: "/it-services" },
      { name: "Website Accessibility", path: "/it-services/it-development/website-accessibility-service" },

    ]);
  }, []);

  const HeroTitel = "Website Accessibility Service";
  const HeroContent =
    "Empower inclusivity with our Website Accessibility Service in Bangalore. We make your website accessible to all users, regardless of disabilities.";
  const subject = "Website Accessibility Service ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/it-services/it-development/website-accessibility-service" />
<title>From Compliance to Inclusivity with the best website accessibility service in Bangalore</title>
<meta name="description" content="Techrowth offers the best web accessibility service in Bangalore to ensure your website is accessible to all users. Improve user experience and comply with accessibility standards." />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider1/>
      <NewTestimonial12/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your website's potential with our Web Accessibility Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions11 />
      <Services11/>
      <ProblemsWeSolved1/>
      <OtherServices6/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default WebsiteAccessibility;
