import styles from "./InterestedDevelopment3.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Web Application Development Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`We offer the following Web Application Development Solutions `}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w41.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Increased Demand</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                     Techrowth has witnessed a substantial increase in the demand for web application development services.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`projects undertaken increased by `}</span>
                    <b className={styles.eCommercePortals}>
                      65%
                    </b>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w44.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Security Enhancements

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Techrowth has focused on strengthening the security of web applications they develop.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`achieved `}</span>
                      <b className={styles.websites}>
                      50%
                      </b>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` decrease in security incidents and vulnerabilities  `}</span>
                    </p>
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
            <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w42.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                   Enhanced Performance
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Techrowth has consistently focused on optimizing the performance of web applications. 
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  <b className={styles.eCommercePortals}>
                   30%
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` reduction in page load times`}</span>
                  
                  </div>
                </div>
              </div>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w43.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  User Engagement

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Through effective web application development strategies, Techrowth has achieved great increase which includes metrics such as time spent on the application.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                    
                      <b className={styles.websites}>40% </b>
                      <span className={styles.weHaveDeveloped}> increase in user engagement metrics for their client’s applications</span>
 

                    </p>
                  </div>
                </div>
              </div>
             
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
