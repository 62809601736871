import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth1 from "../components/InterestedInTechrowth1";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider1 from "../components/ClientsSlider1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions9 from "../components/SocialMediaMarketingSolutions9";
import Services9 from "../components/Services9";
import ProblemsWeSolved1 from "../components/ProblemsWeSolved1";
import OtherServices4 from "../components/OtherServices4";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial10 from "../components/NewTestimonial10";
import { Link } from "react-router-dom";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthsub223";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthsub203";
import style from "./NewHomepage1.module.css";
const WebApplication = () => {
  const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/banner2.png";
  const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M18.png";


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT", path: "/it-services" },
      { name: "Web Application", path: "/it-services/it-development/web-application-development-service" },

    ]);
  }, []);

  const HeroTitel = "Web Application Development Service";
  const HeroContent =
    "Step into the future with Techrowth's Web Application Development Service in Bangalore. From concept to execution, we craft seamless, user-centric experiences.";
  const subject = "Web Application Development Service ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://techrowth.com/it-services/it-development/web-application-development-service" />
<meta charSet="utf-8" />
<title>Empowering Your Business with Expert Web Application Development Services in Bangalore</title>
<meta name="description" content="Web application development services offered by Techrowth navigates the digital landscape with custom web application development in Bangalore. Get in touch with us now for a personalized solution." />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider1/>
      <NewTestimonial10/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your website's potential with our Web Application Development Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions9 />
      <Services9/>
      <ProblemsWeSolved1/>
      <OtherServices4/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default WebApplication;
