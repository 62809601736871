import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth202";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues9 from "../components/OurCoreValues9";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain9 from "../components/OtherServicesmain9";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth222";
import SatisfiedClients from "../components/SatisfiedClients";

import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const QualityTestingPage = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/Qualitybg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M24.png";

const automaticTesting = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/testing.png";
const ManualTesting = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/testing+(1).png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
      { name: "Quality Testing", path: "/it-services/quality-testing-service" },
    ]);
  }, []);
  const HeroTitel =
    "Quality Testing Service";
  const HeroContent =
    "Ensure perfection with our IT Quality Testing Service in Bangalore. Rigorously test your products for flawless performance. Enhance reliability, build trust with customers, and achieve excellence with our cutting-edge solutions.";
  const RecentText =
    "Let us take your business to the next level with our expert IT services";
  const subject = "IT Quality Testing Service ?" 

  return (
    <>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize efficiency with our IT Quality Testing Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
           <AccelerateYourGrowth />
           <OurCoreValues9/>
           <OtherServicesmain9/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default QualityTestingPage;
