import React from "react";
import styles from "../Stories4.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div4}>
        <h3 className={styles.divup}>Neha Nagesh</h3>
        <p className={styles.divup1}>Shegan Gastropub - Managing Director </p>
        <video controls controlsList="nodownload" disablePictureInPicture  className={styles.customVideoControls} >
  <source
    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Shegan_12-07-2023_Video01_SheganTestimonialVideo_v1_1.mp4" width={"100%"}
    type="video/mp4"
  />
  Your browser does not support the video tag.
</video>
      <div className={styles.weHaveBeenUsingTechrowthFParent}>
        <div className={styles.weHaveBeenContainer}>
          <h6
            className={styles.revolutionizingTraditionalBa}
          >{`“Working with Techrowth has been a wonderful journey. Their team is friendly, always available for last-minute queries, and delivers excellent results. For anyone seeking top-notch digital marketing, Techrowth is the best choice”.`}</h6>
        </div>
     
      </div>
 
    </div>
  );
};

export default TSlide1;
