import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth from "../components/InterestedInTechrowth";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider from "../components/ClientsSlider";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions4 from "../components/SocialMediaMarketingSolutions4";
import Services4 from "../components/Services4";
import ProblemsWeSolved from "../components/ProblemsWeSolved";
import OtherServices05 from "../components/OtherServices05";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial2 from "../components/NewTestimonial2";
import { Link } from "react-router-dom";
import TechnologiesWeUseAndServiSocia2 from "../components/TechnologiesWeUseAndServiSocia2";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth115";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth15";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";
const SocialMediaOptimization = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Hero+Section-4.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M4.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Social Media Optimization", path: "/digital-marketing/social-media-optimization" },

    ]);
  }, []);

  const HeroTitel = "Social Media Optimization";
  const HeroContent =
    "Enhance your brand's full potential on social media with our expert social media optimization service in Bangalore";
  const subject = "Social Media Optimization ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with the best social media optimization company in Bangalore";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/social-media-optimization" />
<title>SMO Agency - Top Social Media Optimization company in Bangalore
</title>
<meta name="description" content="Social Media Optimization Company, offering result-driven SMO services that help you to build strong business identity on all of the social media platforms.
" />
<meta name="keywords" content="social media agency in bangalore, social media services in bangalore, social media optimization, services in bangalore, social media optimization, social media consultant, social marketing agency, top social media optimization companies, social media expert, best social media optimization companies, smo company in bangalore, best smo packages, smo packages
" />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider/>
      <NewTestimonial2/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the potential of your online reach with the best social media optimization company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions4 />
      <Services4/>
      <ProblemsWeSolved/>
      <TechnologiesWeUseAndServiSocia2/>

      <OtherServices05/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default SocialMediaOptimization;
