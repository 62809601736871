import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import NewHomepage from "./pages/NewHomepage";
import DigitalMarketing from "./pages/DigitalMarketing";
import GraphicDesign from "./pages/GraphicDesign";
import DigitalMarketingServices from "./pages/SocialMediaServices";
import Ecommerce from "./pages/Ecommerce";
import Header from "./components/Header";
import Footer from "./components/Footer1";
import CaseStudy from "./pages/CaseStudy";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import IntentPrototype from "./pages/IntentPrototype";
import Content from "./pages/Content";
import DevelopmentPage from "./pages/DevelopmentPage";
import UiUxInterface from "./pages/UiUxInterface";
import IntegrationPage from "./pages/IntegrationPage";
import WhoWeAre from "./pages/WhoWeAre";
import ITMain from "./pages/ITMain";
import QualityTestingPage from "./pages/QualityTestingPage";
import ITManagement from "./pages/ITManagement";
import CaseStudy1 from "./pages/CaseStudy1";
import CaseStudy11 from "./pages/CaseStudy11";
import CaseStudy12 from "./pages/CaseStudy12";
import CaseStudy13 from "./pages/CaseStudy13";
import CaseStudy14 from "./pages/CaseStudy14";
import CaseStudy17 from "./pages/CaseStudy17";
import CaseStudy18 from "./pages/CaseStudy18";
import CaseStudy19 from "./pages/CaseStudy19";
import CaseStudy20 from "./pages/CaseStudy20";
import CaseStudy2 from "./pages/CaseStudy2";
import CaseStudy3 from "./pages/CaseStudy3";
import CaseStudy4 from "./pages/CaseStudy4";
import CaseStudy5 from "./pages/CaseStudy5";
import CaseStudy6 from "./pages/CaseStudy6";
import CaseStudy7 from "./pages/CaseStudy7";
import CaseStudy8 from "./pages/CaseStudy8";
import CaseStudy9 from "./pages/CaseStudy9";
import CaseStudy10 from "./pages/CaseStudy10";
import SocialMediaMarketing from "./pages/SocialMediaMarketing";
import Blog1 from "./pages/Blog1";
import Blog from "./pages/Blog";
import ContactUsPage from "./pages/Contactusmap";
import Thankyou from "./pages/Thankyou";
import Error from "./pages/404";
import SearchEngineMarketing from "./pages/SearchEngineMarketing";
import SearchEngineOptimization from "./pages/SearchEngineOptimization";
import PayPerClick from "./pages/PayPerClick";
import SocialMediaOptimization from "./pages/SocialMediaOptimization";
import EmailMarketing from "./pages/EmailMarketing";
import Website from "./pages/Website";
import ContentManagementSystem from "./pages/ContentManagementSystem";
import Ecommercedevelopment from "./pages/Ecommercedevelopment";
import WebApplication from "./pages/WebApplication";
import MobileApplication from "./pages/MobileApplication";
import WebsiteAccessibility from "./pages/WebsiteAccessibility";
import Portfolio from "./pages/Portfolio";
import Career from "./pages/Career";
import Career1 from "./pages/Career1";
import Termsandcondition from "./pages/TermsandCondition"
import Privacypolicy from "./pages/Privacypolicy"
import Portfolio1 from "./pages/Portfolio1"
import Portfolio2 from "./pages/Portfolio2"
import Portfolio3 from "./pages/Portfolio3"
import Portfolio4 from "./pages/Portfolio4"
import Portfolio5 from "./pages/Portfolio5"
import Portfolio6 from "./pages/Portfolio6"
import Portfolio7 from "./pages/Portfolio7"
import Portfolio8 from "./pages/Portfolio8"
import Portfolio9 from "./pages/Portfolio9"
import Portfolio10 from "./pages/Portfolio10"
import Portfolio11 from "./pages/Portfolio11"
import Portfolio12 from "./pages/Portfolio12"
import Portfolio13 from "./pages/Portfolio13"
import Portfolio14 from "./pages/Portfolio14"
import Portfolio15 from "./pages/Portfolio15"
import Portfolio16 from "./pages/Portfolio16"
import Portfolio17 from "./pages/Portfolio17"
import Portfolio18 from "./pages/Portfolio18"
import Portfolio19 from "./pages/Portfolio19"
import Portfolio20 from "./pages/Portfolio20"
import Portfolio21 from "./pages/Portfolio21"
import Portfolio22 from "./pages/Portfolio22"
import Portfolio23 from "./pages/Portfolio23"
import Portfolio24 from "./pages/Portfolio24"
import Portfolio25 from "./pages/Portfolio25"
import Portfolio26 from "./pages/Portfolio26"
import Portfolio27 from "./pages/Portfolio27"
import MainBlogs from "./pages/MainBlogs";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";
import Blog5 from "./pages/Blog5";
import Test15 from "./components/test15";
import Test16 from "./components/Test16";
import Test17 from "./components/Test17";
import Test18 from "./components/Test18";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Techrowth";
        metaDescription = "";
        break;
      
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
      <Header />
        <Routes>
          <Route path="/" element={<NewHomepage />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/digital-marketing/graphic-design" element={<GraphicDesign />} />
          <Route path="/digital-marketing/ecommerce-marketing" element={<Ecommerce />} />
          <Route path="/digital-marketing/intent-we-serve" element={<IntentPrototype />} />
          <Route path="/digital-marketing/content-marketing" element={<Content />} />
          <Route path="/it-services" element={<ITMain />} />
          <Route path="/it-services/quality-testing-service" element={<QualityTestingPage />} />
          <Route path="/it-services/uiux-services" element={<UiUxInterface />} />
          <Route path="/it-services/it-management-service" element={<ITManagement />} />
          <Route path="/it-services/it-development" element={<DevelopmentPage />} />
          <Route path="/it-services/integration-services" element={<IntegrationPage />} />
          <Route path="/terms-and-conditions" element={<Termsandcondition />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/techrowth-knowledge" element={<Blog />} />
          <Route path="/blogs" element={<MainBlogs />} />
          <Route path="/role-of-ai-in-digital-marketing" element={<Blog1 />} />

          <Route path="/the-ultimate-guide-for-ppc-advertising-for-koramangala-businesses" element={<Test15 />} />
          <Route path="/boosting-your-online-presence-seo-tips-for-electronic-city" element={<Test16 />} />
          <Route path="/why-social-media-marketing-is-crucial-for-companies-in-mg-road" element={<Test17 />} />
          <Route path="/result-driven-digital-marketing-strategies-for-businesses" element={<Test18 />} />

          <Route path="/rise-of-ai-in-digital-marketing-how-businesses-are-leveraging-smart-technology" element={<Blog2 />} />
          <Route path="/voice-search-seo-strategies-for-local-businesses-optimizing-for-the-future" element={<Blog3 />} />
          <Route path="/ppc-vs-seo-choosing-the-right-digital-marketing-strategy-for-your-business" element={<Blog4 />} />
          <Route path="/ppc-budgeting-and-bid-strategies-maximizing-roi-for-your-campaigns" element={<Blog5 />} />
          <Route path="/techrowth-case-study" element={<CaseStudy />} />
          <Route path="/boosting-and-expanding-sales-through-emotional-marketing" element={<CaseStudy1 />} />
          <Route path="/discover-why-startups-shine-with-a-strong-social-media" element={<CaseStudy11 />} />
          <Route path="/social-media-for-educational-institutions" element={<CaseStudy12 />} />
          <Route path="/digital-marketing-for-restaurant-businesses" element={<CaseStudy13 />} />
          <Route path="/boosting-the-sales-and-expanding-through-marketing" element={<CaseStudy14 />} />
          <Route path="/scalability-in-web-development-techrowths-approach-to-future-proofing-websites" element={<CaseStudy17 />} />
          <Route path="/customer-centric-web-design-lessons-from-a-top-web-development-company" element={<CaseStudy18 />} />
          <Route path="/mobile-first-e-commerce-optimizing-your-store-for-the-on-the-go-customer" element={<CaseStudy19 />} />
          <Route path="/testing-and-monitoring-ensuring-a-flawless-mobile-e-commerce-experience" element={<CaseStudy20 />} />

  
          <Route path="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" element={<CaseStudy2 />} />
          <Route path="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" element={<CaseStudy3 />} />
          <Route path="/transforming-slow-speed-website-through-compression-of-file-sizes" element={<CaseStudy4 />} />
          <Route path="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" element={<CaseStudy5 />} />
          <Route path="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" element={<CaseStudy6 />} />
          <Route path="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" element={<CaseStudy7 />} />
          <Route path="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" element={<CaseStudy8 />} />
          <Route path="/implemented-digital-marketing-strategies-for-Increasing-walk-ins-in-a-jewelry-store" element={<CaseStudy9 />} />
          <Route path="/maximizing-digital-customer-acquisition-in-the-banking-sector" element={<CaseStudy10 />} />
          <Route path="/digital-marketing/social-media-marketing" element={<SocialMediaMarketing />} />
          <Route path="/digital-marketing/search-engine-marketing" element={<SearchEngineMarketing />} />
          <Route path="/digital-marketing/search-engine-optimization" element={<SearchEngineOptimization />} />
          <Route path="/digital-marketing/pay-per-click" element={<PayPerClick />} />
          <Route path="/digital-marketing/social-media-optimization" element={<SocialMediaOptimization />} />
          <Route path="/digital-marketing/email-marketing" element={<EmailMarketing />} />
          <Route path="/it-services/it-development/website-development-service" element={<Website />} />
          <Route path="/it-services/it-development/cms-development-service" element={<ContentManagementSystem />} />
          <Route path="/it-services/it-development/ecommerce-development-service" element={<Ecommercedevelopment />} />
          <Route path="/it-services/it-development/web-application-development-service" element={<WebApplication />} />
          <Route path="/it-services/it-development/mobile-application-development-service" element={<MobileApplication />} />
          <Route path="/it-services/it-development/website-accessibility-service" element={<WebsiteAccessibility />} />
          <Route path="/about-us" element={<WhoWeAre />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route path="/techrowth-portfolio" element={<Portfolio />} />
          {/* <Route path="/career" element={<Career />} /> */}
          {/* <Route path="/CareerDetails" element={<Career1 />} /> */}
          <Route path="/techrowth-portfolio/spaze-ratiio" element={<Portfolio1 />} />
          <Route path="/techrowth-portfolio/shegan-gastro-pub" element={<Portfolio2 />} />
          <Route path="/techrowth-portfolio/blossoms-banquets" element={<Portfolio3 />} />
          <Route path="/techrowth-portfolio/tasmai-exports" element={<Portfolio4 />} />
          <Route path="/techrowth-portfolio/abhayahasta-hospital" element={<Portfolio5 />} />
          <Route path="/techrowth-portfolio/atomic-academy" element={<Portfolio6 />} />
          <Route path="/techrowth-portfolio/kalpatharu-constructions" element={<Portfolio7 />} />
          <Route path="/techrowth-portfolio/znbs-corporate" element={<Portfolio8 />} />
          <Route path="/techrowth-portfolio/dr-native" element={<Portfolio9 />} />
          <Route path="/techrowth-portfolio/sri-krishna-group" element={<Portfolio10 />} />
          <Route path="/techrowth-portfolio/znbs-property" element={<Portfolio11 />} />
          <Route path="/techrowth-portfolio/neha-prakash-hospital" element={<Portfolio12 />} />
          <Route path="/techrowth-portfolio/hatchlong" element={<Portfolio13 />} />
          <Route path="/techrowth-portfolio/validus-thailand" element={<Portfolio14 />} />
          <Route path="/techrowth-portfolio/pearl-vally-resort" element={<Portfolio15 />} />
          <Route path="/techrowth-portfolio/izb" element={<Portfolio16 />} />
          <Route path="/techrowth-portfolio/grandiose-art" element={<Portfolio17 />} />
          <Route path="/techrowth-portfolio/venus-kart" element={<Portfolio18 />} />
          <Route path="/techrowth-portfolio/techrowth" element={<Portfolio19 />} />
          <Route path="/techrowth-portfolio/prolearn-box" element={<Portfolio20 />} />
          <Route path="/techrowth-portfolio/citizen-car" element={<Portfolio21 />} />
          <Route path="/techrowth-portfolio/business-listing" element={<Portfolio22 />} />
          <Route path="/techrowth-portfolio/arvaa-academy" element={<Portfolio23 />} />
          <Route path="/techrowth-portfolio/zambia-medical-assoc" element={<Portfolio24 />} />
          <Route path="/techrowth-portfolio/vendor-management" element={<Portfolio25 />} />
          <Route path="/techrowth-portfolio/izb-crm" element={<Portfolio26 />} />
          <Route path="/techrowth-portfolio/green-view-medical" element={<Portfolio27 />} />
          <Route path="*" element={<Error/>} />


     {/* <Route path="/DigitalMarketingServices" element={<DigitalMarketingServices />} /> */}

        </Routes>
      <Footer />

    </>
  );
}
export default App;



