import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t1.png" />

      <div className={styles.bus}>
      <h5 >E-Commerce Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>60%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Mobile based Sales</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>55%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Customer Retention</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      Techrowth’s mobile app has revolutionized our e-commerce business! The app's intuitive design and personalized recommendations have significantly improved customer engagement. With features like one-click ordering and real-time order tracking, our sales have increased substantially. Their team's commitment to quality and timely delivery makes them the perfect choice for any e-commerce venture. 
     </p>
    </div>
  );
};

export default TSlide1;
