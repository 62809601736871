import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthh2";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues from "../components/OurCoreValues";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain1 from "../components/OtherServicesmain1";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1"
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthh220";
import SatisfiedClients from "../components/SatisfiedClients";
import { Helmet } from "react-helmet";
import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const Ecommerce = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/Ecommercebg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M9.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "E-commerce", path: "/digital-marketing/ecommerce-marketing" },
    ]);
  }, []);
  const subject = "E-Commerce Marketing ?" 
  const HeroTitel = "E-Commerce Marketing";
  const HeroContent =
    "Fuel your online success with our E-commerce Marketing Service in Bangalore. Amplify your brand, attract customers, and boost sales with our strategic solutions.";
  const RecentText =
    "Experience Explosive Growth with Our Customized Marketing Plans! Contact us now!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/ecommerce-marketing" />
<title>eCommerce Marketing Services | E-Commerce Marketing Agency In Bangalore
</title>
<meta name="description" content="Techrowth provides the best eCommerce Digital Marketing Services in Bangalore. We offer expert ecommerce solutions by harnessing the potential of various e-commerce platforms.
" />
<meta name="keywords" content="
Digital Marketing for Ecommerce Business, e-commerce marketing agency in Bangalore,Ecommerce Marketing Services in Bangalore,Best ecommerce marketing agency in Bangalore.
" />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the ecommerce sales with the best ecommerce marketing services in Bangalore"
        btnTxt="Contact Us"
      />
      </Link>
           <AccelerateYourGrowth />
           <OurCoreValues/>
           <OtherServicesmain1/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default Ecommerce;
