import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>E-Commerce Marketing Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc1.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Explainer Videos</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Our company provides engaging and informative explainer videos that attract your audience by simplifying complex concepts. Our skilled team of animators and video producers combines creativity with concise storytelling to make compelling visual stories. We have the skills to make your ideas come to life, whether you need explainer videos for product demos, educational content, or promotional campaigns. 

                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc2.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Product Listings</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Our company prioritizes fast and effective product listings. Our streamlined processes and devoted team ensure quick uploads and accurate descriptions, attracting potential buyers' attention. Our team of experts will help you with listings that are more visible and profitable, which will save you time and effort. 

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc3.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-Commerce SEO</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Increase visibility, improve the rankings, and bring in more visitors`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                      Our team of skilled SEO experts is aware of the unique opportunities and difficulties that e-commerce enterprises confront. To increase the ranks of your products on e-Commerce platforms, we do thorough keyword research and optimize product descriptions and metadata.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc4.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>E-Commerce PPC</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
                   
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                          We provide excellent Pay-Per-Click (PPC) services tailored especially for e-commerce companies. Our staff of skilled digital marketers is knowledgeable about the complexities of e-commerce PPC. We thoroughly investigate your sector, pinpoint your target market, and then craft persuasive ad campaigns that draw in quality leads and boost conversions. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc5.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Social Media Shops</b>
                        <div className={styles.consistentInGetting}>
                        Turn social media into a shopping paradise
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Our firm provides businesses with a simple and convenient way to sell their products on social media platforms. With the help of our experience, you may promote your products, interact with clients, and increase sales all within the popular and preferred environment of social media. To ensure that your customers have a user-friendly experience, our team of experts will assist you in setting up and optimizing your social media shop. 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc6.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Google Shop</b>
                        <div className={styles.consistentInGetting}>
                        Connect with millions of shoppers
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Through Google Shop, we provide a seamless shopping experience, placing your products in front of millions of potential customers. We optimize your product listings, produce attractive ads, and increase your visibility on the Google platform using our knowledge. With the help of our Google Shop services, you may expand your customer base, boost sales, and expand your company. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc7.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Payment Gateways</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Seamless transactions, secure payments`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                        We provide a secure and seamless payment solution for businesses of all sizes. With the security and dependability of our cutting-edge technology, your consumers can shop with confidence. You can accept numerous payment options, such as credit cards, debit cards, and online wallets, using our payment gateway. Customers may easily make payments on your website or app thanks to our easy integration. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc8.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Customer Support Tools</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
                  
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                          At our company, we are dedicated to offering exceptional customer service and making sure that your clients have a smooth experience. Our selection of customer support tools is designed to improve productivity, improve communication, and exceed client expectations. Performance analytics is one of our customer care tools, and it offers insightful data on the efficiency of your staff and customer satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>E-Commerce Marketing Services We Serve</b>
          <p className={styles.ourOrigin123}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc1.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Explainer Videos</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our company provides engaging and informative explainer videos that attract your audience by simplifying complex concepts. Our skilled team of animators and video producers combines creativity with concise storytelling to make compelling visual stories. We have the skills to make your ideas come to life, whether you need explainer videos for product demos, educational content, or promotional campaigns. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc2.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Product Listings</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our company prioritizes fast and effective product listings. Our streamlined processes and devoted team ensure quick uploads and accurate descriptions, attracting potential buyers' attention. Our team of experts will help you with listings that are more visible and profitable, which will save you time and effort. 
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc3.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-Commerce SEO</b>
                        <div className={styles.consistentInGetting}>
                        Increase visibility and improve the rankings
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our team of skilled SEO experts is aware of the unique opportunities and difficulties that e-commerce enterprises confront. To increase the ranks of your products on e-Commerce platforms, we do thorough keyword research and optimize product descriptions and metadata.  
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc4.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-Commerce PPC</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We provide excellent Pay-Per-Click (PPC) services tailored especially for e-commerce companies. Our staff of skilled digital marketers is knowledgeable about the complexities of e-commerce PPC. We thoroughly investigate your sector, pinpoint your target market, and then craft persuasive ad campaigns that draw in quality leads and boost conversions. 
                    </div>
              </div>
            
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc5.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Social Media Shops</b>
                        <div className={styles.consistentInGetting}>
                        Turn social media into a shopping paradise
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our firm provides businesses with a simple and convenient way to sell their products on social media platforms. With the help of our experience, you may promote your products, interact with clients, and increase sales all within the popular and preferred environment of social media. To ensure that your customers have a user-friendly experience, our team of experts will assist you in setting up and optimizing your social media shop. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc6.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Google Shop</b>
                        <div className={styles.consistentInGetting}>
                        Connect with millions of shoppers
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Through Google Shop, we provide a seamless shopping experience, placing your products in front of millions of potential customers. We optimize your product listings, produce attractive ads, and increase your visibility on the Google platform using our knowledge. With the help of our Google Shop services, you may expand your customer base, boost sales, and expand your company. 
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc7.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Payment Gateways</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We provide a secure and seamless payment solution for businesses of all sizes. With the security and dependability of our cutting-edge technology, your consumers can shop with confidence. You can accept numerous payment options, such as credit cards, debit cards, and online wallets, using our payment gateway. Customers may easily make payments on your website or app thanks to our easy integration. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc8.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Customer Support Tools</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    At our company, we are dedicated to offering exceptional customer service and making sure that your clients have a smooth experience. Our selection of customer support tools is designed to improve productivity, improve communication, and exceed client expectations. Performance analytics is one of our customer care tools, and it offers insightful data on the efficiency of your staff and customer satisfaction.
                    </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
