import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t3.png" />

      <div className={styles.bus}>
      <h5 >Hotel Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>70%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Online Bookings</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>40%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+315212.svg" />
          </div>
          <p className={styles.bus5}>Cost Automation</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      "The web application developed by Techrowth’s team has significantly improved our hotel's efficiency and guest experience. The web app allows guests to check-in and access hotel amenities seamlessly. The integration of dining reservations and room service orders has streamlined our operations, resulting in higher guest satisfaction scores. Their dedication to understanding our hospitality needs and delivering a top-notch web application is commendable”.

     </p>
    </div>
  );
};

export default TSlide1;
