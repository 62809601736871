import styles from "./InterestedDevelopment3.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Mobile Application Development Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`We offer the following Mobile App Development Solutions`}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w51.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Mobile Applications</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    We specialize in delivering exceptional user experiences for mobile users, helping you reach your target audience and achieve your business goals.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Developed more than `}</span>
                    <b className={styles.eCommercePortals}>
                      172
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` mobile applications.`}</span>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w52.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  User Ratings and Reviews
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                     The mobile applications developed by the digital marketing company have received positive feedback from users.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  <span
                      className={styles.weHaveDeveloped}
                    >{` Applications have achieved a rating of `}</span>
                  <b className={styles.eCommercePortals}>
                  4.5
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` out of 5 stars`}</span>
                  
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
            <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w54.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Increased Downloads

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Techrowth has witnessed a significant increase in app downloads .
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Downloads increased by `}</span>
                      <b className={styles.websites}>
                      50%
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w53.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  App Store Presence

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                     Techrowth has successfully published and launched mobile applications on major app stores.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                 
                      <b className={styles.websites}>95%</b>
                      <span className={styles.weHaveDeveloped}> of mobile applications are available on both  Apple App Store and Google Play Store</span>
               

                    </p>
                  </div>
                </div>
              </div>
             
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
