import styles from "./Otherservices2.module.css";
import { Link } from "react-router-dom";

const OtherServices = () => {
  return (
    <>
    <div className={styles.digitalMarketingServices}>
      <div className={styles.digitalMarketingServicesInner}>
        <div className={styles.frameParent}>
          <div className={styles.digitalMarketingServicesParent}>
         
            <div className={styles.intentWeServe}>Explore Other IT Development Services</div>
          </div>
        
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>
      
              <Link to="/it-services/it-development/cms-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms1.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                  <p className={styles.unleashYourBrands}>Content Management System</p>
       
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/website-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms6.svg"


                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Website</p>
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/web-application-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms3.svg"


                />
                <div className={styles.socialMediaServicesContainer}>
                Web Application
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/mobile-application-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms4.svg"


                />
                <div className={styles.socialMediaServicesContainer}>
                Mobile Application
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/website-accessibility-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms5.svg"


                />
                <div className={styles.socialMediaServicesContainer}>
                Website Accessibility
                </div>
              </div>
              </Link>

            </div>
          </div>
        </div>
      </div>
    </div>



    <div className={styles.digitalMarketingServices1}>

      <p className={styles.businessAnalystics3}>Explore Other IT Development Services</p>
    
              <div className={styles.wonder}>
           
              
              <div className={styles.test}>
              <Link to="/it-services/it-development/mobile-application-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms4.svg"

                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Mobile Application</p>
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/website-accessibility-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms5.svg"

                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Website Accessibility</p>
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/web-application-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms3.svg"

                />
                     <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Web Application</p>
                </div>
            
              </div>
              </Link>
              </div>
              
              <div className={styles.test}>
            


              <Link to="/it-services/it-development/cms-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms1.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>Social Media</p> */}
                  <p className={styles.unleashYourBrands}>Content Management System</p>
                </div>
              </div>
              </Link>
              <Link to="/it-services/it-development/website-development-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cms6.svg"

                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Website</p>
                </div>
              </div>
              </Link>
              </div>
              
      
</div>
</div>
</>
  );
};

export default OtherServices;
