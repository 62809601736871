import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth201";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues8 from "../components/OurCoreValues8";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain8 from "../components/OtherServicesmain8";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth221";
import SatisfiedClients from "../components/SatisfiedClients";

import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const IntegrationPage = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/Integrationbg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M23.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
      { name: "Integration", path: "/it-services/integration-services" },
    ]);
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const HeroTitel =
    "Analytics Integration Service";
  const HeroContent =
    "Achieve synergy with our Analytics Integration Services in Bangalore. Seamlessly connect your systems for enhanced functionality and data flow. Streamline processes and embrace efficiency with our cutting-edge solutions.";
  const RecentText =
    "Explore a portfolio of successful IT projects and see how we can help your business grow.";
  const subject = "Analytics Integration Service ?" 

  return (
    <>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Analyze your website flawlessly with our Analytics Integration Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
           <AccelerateYourGrowth />
           <OurCoreValues8/>
           <OtherServicesmain8/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default IntegrationPage;
