import styles from "./WhoWeAre.module.css";
import Testimonial from "./Testimonial";
import { useState } from "react";
import { Link } from "react-router-dom";
import style from "./WhoWeAre1.module.css";
import Testimonial1 from "./Testimonial1";
import style123 from "../pages/NewHomepage1.module.css";

import { useEffect } from "react";
const WhoWeAre = () => {
const WhoWeAreImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/who+we+are+img.png";
const [currentImage, setCurrentImage] = useState('https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/asifsir.png');

const toggleImage = () => {
  if (currentImage === 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/rawathsir.png') {
    setCurrentImage('https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/asifsir.png');
  } else {
    setCurrentImage('https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/rawathsir.png');
  }
};
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
      <div className={styles.rectangleParent}>
        <div
          className="parent"
          style={{ backgroundImage: `url(${WhoWeAreImg})` }}
        >
          <img alt="" src={WhoWeAreImg} className="position-relative" />

          <div
            className="text position-absolute text-white"
            style={{
              top: "175px",
              left: "38%",
              fontSize: "50px",
              fontWeight: "600",
            }}
          >
            About Us
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.searchingForRelevant21stCeParent}>
            <div className={styles.searchingForRelevantContainer}>
              <p className={styles.searchingForRelevant21stCe}>
                <span>{`Searching for relevant 21st-century `}</span>
                <b className={styles.answers}>answers</b>
                <span className={styles.toYour}>{` to your `}</span>
                <b className={styles.answers}>business problems?</b>
              </p>
            </div>
            <div className={styles.meetOurExpertsContainer}>
              <p className={styles.searchingForRelevant21stCe}>
              Meet our experts, who are geared towards guiding you in attaining your organizational objectives. Delivering smart, useful, and efficient solutions that benefit your company is our priority. We are invested in ensuring your success with our effective response and range of IT and Digital Marketing Services in Bangalore.
              </p>
            </div>
         
          </div>
        </div>
      </div>
      <div className="container">
        <b className={styles.ourOrigin}>{`Our Origin `}</b>
        <div className={styles.whoWeAreInner}>
          <div className={styles.frameParent}>
            <div className={styles.in2019AfterRealizingTheLParent}>
              <div className={styles.in2019AfterContainer}>
                <p className={styles.searchingForRelevant21stCe}>
                  In 2019, after realizing the lack of expertise and high
                  pricing for subpar results at digital marketing firms, a new
                  business venture called Proxy Digital Solutions was started by
                  developing proprietary intent-based methods that use focused
                  channels to deliver affordable and budget-friendly,
                  result-driven solutions. Success was not immediate, but we
                  persisted and landed our first client after our 21st
                  continuing proposal.
                </p>
              </div>
              <div className={styles.in2019AfterContainer}>
                We grew exponentially since then and were able to compete with
                the most influential digital marketing firms in India. Then, in
                2020, due to the market overflowing with poorly programmed
                software, Techrowth Pvt. Ltd. was founded to offer a unique
                blend of services that empower businesses to achieve their
                business goals, not just through digital marketing but also
                through exclusive strategic programming solutions. Despite
                challenges, the journey has been worth it as we continue to make
                a difference in the lives of our clients.
              </div>
            </div>
            {/* <img
              className={styles.frameItem}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/rawathsir.png"
            /> */}
     <img
          src={currentImage}
          alt="Toggleable Image"
          onClick={toggleImage}
          style={{ cursor: 'pointer', width: "20vw" }}
        />
          </div>
        </div>
      </div>
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>Our Core Values</b>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Group+1708.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Consistency</b>
                        <div className={styles.consistentInGetting}>
                          Consistent in Getting Results
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                      Our commitment to excellence is reflected in every piece
                      of work we deliver. Our core values are embedded in our
                      approach to ensure we consistently provide top-notch
                      results. We take pride in surpassing our clients'
                      expectations with reliable and creative solutions that
                      maintain a high level of consistency.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-524.svg"
                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Originality</b>
                        <div className={styles.consistentInGetting}>
                          Delivering One-of-A-kind Solutions
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                      Originality is one of the cornerstones of our strength,
                      setting us apart from the competition. We take pride in
                      our consistent delivery of quality work that meets the
                      highest standards of creativity and consistency. Whether
                      it's a small or large-scale project, we aim to exceed
                      expectations with excellent results.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-523.svg"
                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Reliability</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Trustworthy & Transactional Transparency`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                        Reliability is one of the fundamental tenets of our
                        client relationships. The results we speak of have
                        earned the trust of our clients repeatedly. We strive to
                        maintain humility, honest communication, and open
                        dialogue to ensure our clients receive excellent results
                        which supersedes their goals and bring abundance of
                        growth. Providing unparalleled customer service and
                        support to achieve full customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-587.svg"
                    />
                         <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Excellence</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Excellent Customer Satisfaction`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                          One of our primary goals is to achieve full customer
                          satisfaction by delivering best-in-class solutions. We
                          maintain our originality by staying ahead of the curve
                          with cutting-edge solutions that meet the unique needs
                          of your business. Our team is dedicated to delivering
                          exceptional results that exceed expectations, no
                          matter the size of the project. The goal is to help
                          your business reach the next level with our consistent
                          support and excellent services.
                      </p>
                    </div>
                  </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ourTeamParent}>
        <b className={styles.ourTeam}>Our Team</b>
        <div className={styles.rectangleGroup}>
          <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Rectangle+4060111.png" />
          <div className="overlay" />
          <div className={styles.groupItem} />
        </div>
      </div>
     
      <div className={style123.Testimonial}>
        <div style={{marginTop:"4rem"}}>
      <Testimonial1/>
      </div>

      </div >
      <div className={style123.Testimonial1}>
      <Testimonial/>

      </div>
    

      <div className={style.whoWeAre}>

<div className={style.exploreOtherServicesParent}>
  <div className={style.exploreOtherServices}>
    Explore Our Services
  </div>
  <div className={style.groupParent14}>

    <div className={style.groupParent15}>
      <div className={style.groupChild10} />
      <div className={style.unlockThePotentialOfYourBParent}>

        <div className={style.frameWrapper8}>
          <div className={style.frameParent7}>
            <Link to="/digital-marketing/intent-we-serve" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + "ps-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg1.svg"
                  width={35}
                />
                 Intent We Serve{" "}
              </button>
            </Link>
            <Link to="/digital-marketing" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg2.svg"
                  width={35}
                />
                Services{" "}
              </button>
            </Link>
            <Link to="/digital-marketing/ecommerce-marketing" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg3.svg"
                  width={35}
                />
                 E-commerce
              </button>
            </Link>
            <Link to="#" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg4.svg"
                  width={35}
                />
                Graphic Design{" "}
              </button>
            </Link>
            <Link to="/digital-marketing/content-marketing" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg5.svg"
                  width={35}
                />
                Content{" "}
              </button>
            </Link>

           
          </div>
        </div>
        <Link to="/it-services" style={{ width: "100%" }}>
        <div className={style.groupParent17}>
          <div className={style.rectangleWrapper}>
            <div className={style.groupChild11} />
          </div>
          <div className={style.itServicesParent}>
            <div className={style.itServices}>Digital Marketing</div>
            <img
              className={style.arrowRightIcon1}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
            />
            <img
              className={style.groupChild15}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+443712.png"
            />
          </div>
        </div>
        </Link>
      </div>
    </div>
    <div className={style.groupParent15}>
      <div className={style.groupChild10} />
      <div className={style.unlockThePotentialOfYourBParent}>

        <div className={style.frameWrapper8}>
          <div className={style.frameParent7}>
            <Link to="/it-services/it-development" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + "ps-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg1.svg"
                  width={35}
                />
                Development{" "}
              </button>
            </Link>
            <Link to="/it-services/uiux-services" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg2.svg"
                  width={35}
                />
                UI/UX Interface{" "}
              </button>
            </Link>
            <Link to="/it-services/it-management-service" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg3.svg"
                  width={35}
                />
                IT Management
              </button>
            </Link>
            <Link to="/it-services/integration-services" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg4.svg"
                  width={35}
                />
                Integrations{" "}
              </button>
            </Link>
            <Link to="/it-services/quality-testing-service" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg5.svg"
                  width={35}
                />
                Quality Testing{" "}
              </button>
            </Link>

           
          </div>
        </div>
        <Link to="/it-services" style={{ width: "100%" }}>
        <div className={style.groupParent17}>
          <div className={style.rectangleWrapper}>
            <div className={style.groupChild11} />
          </div>
          <div className={style.itServicesParent}>
            <div className={style.itServices}>IT Services</div>
            <img
              className={style.arrowRightIcon1}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
            />
            <img
              className={style.groupChild15}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1093%402x.png"
            />
          </div>
        </div>
        </Link>
      </div>
    </div>
    <div className={style.groupParent15}>
      <div className={style.groupChild10} />
      <div className={style.unlockThePotentialOfYourBParent}>

        <div className={style.frameWrapper8}>
          <div className={style.frameParent7}>

              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + "ps-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg1.svg"
                  width={35}
                />
                Data Analytics{" "}
              </button>
     
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg2.svg"
                  width={35}
                />
                Data Visualization{" "}
              </button>
       
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg3.svg"
                  width={35}
                />
                Business Analytics
              </button>
  
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg4.svg"
                  width={35}
                />
                Data Mining{" "}
              </button>
       
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "18px",
                  width: "18.3rem",
                  height: "3.75rem",
                  fontWeight: "600",
                  color: "#0076A2",
                  textAlign: "start",
                }}
              >
                <img
                  className={style.frameItem + " mr-1"}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg5.svg"
                  width={35}
                />
                Predictive Analytics{" "}
              </button>
   

           
          </div>
        </div>
  

        <div className={style.groupParent17}>
          <div className={style.rectangleWrapper}>
            <div className={style.groupChild11} />
          </div>
          <div className={style.itServicesParent}>
            <div className={style.itServices}>Analytics</div>
            <img
              className={style.arrowRightIcon1}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
            />
            <img
              className={style.groupChild15}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1094%402x.png"
            />
          </div>
        </div>
  
      </div>
    </div>

  </div>
</div>

</div>





















      <div className={styles.cta}>
        <img
          className={styles.ctaChild}
          alt=""
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-402911%402x.png"
        />

        <div className={styles.curiousWhyPeopleAreTalkingParent}>
          <div className={styles.curiousWhyPeople}>
            Curious why people are talking about us?
          </div>
          <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none!important" }}>

          <button className={styles.rectangleParent9}>
            <button className={styles.groupChild19} />
            <div className={styles.contactUs}>Contact Us</div>
          </button>
          </Link>
        </div>
      </div>
    </div>
    <div className={styles.whoWeAree}>
      <div className={styles.rectangleParent}>
        <div
          className="parent"
          style={{ backgroundImage: `url(${WhoWeAreImg})`,height: "15rem"  }}
        >
          <img alt="" src={WhoWeAreImg} className="position-relative w-100"    style={{height: "15rem"  }} />

          <div
            className="text position-absolute text-white"
            style={{
              top: "3%",
              left: "35%",
             
              fontSize: "28px",
              fontWeight: "700",
        
            }}
          >
            About Us
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.searchingForRelevant21stCeParent}>
            <div className={styles.searchingForRelevantContainer}>
              <p className={styles.searchingForRelevant21stCe}>
                <span>{`Searching for relevant 21st-century `}</span>
                <b className={styles.answers}>answers</b>
                <span className={styles.toYour}>{` to your `}</span>
                <b className={styles.answers}>business problems?</b>
              </p>
            </div>
            <div className={styles.meetOurExpertsContainer}>
              <p className={styles.searchingForRelevant21stCe}>
              Meet our experts, who are geared towards guiding you in attaining your organizational objectives. Delivering smart, useful, and efficient solutions that benefit your company is our priority. We are invested in ensuring your success with our effective response and range of IT and Digital Marketing Services in Bangalore.
              </p>
            </div>
   
          </div>
        </div>
      </div>
      <div className="container">
        <b className={styles.ourOrigin}>{`Our Origin `}</b>
        <div className={styles.whoWeAreInner}>
          <div className={styles.frameParent}>
            <div className={styles.in2019AfterRealizingTheLParent}>
              <div className={styles.in2019AfterContainer}>
                <p className={styles.searchingForRelevant21stCee}>
                  In 2019, after realizing the lack of expertise and high
                  pricing for subpar results at digital marketing firms, a new
                  business venture called Proxy Digital Solutions was started by
                  developing proprietary intent-based methods that use focused
                  channels to deliver affordable and budget-friendly,
                  result-driven solutions. Success was not immediate, but we
                  persisted and landed our first client after our 21st
                  continuing proposal.
                </p>
              </div>
              <div className={styles.in2019AfterContainer}>
              <p className={styles.searchingForRelevant21stCee}>

                We grew exponentially since then and were able to compete with
                the most influential digital marketing firms in India. Then, in
                2020, due to the market overflowing with poorly programmed
                software, Techrowth Pvt. Ltd. was founded to offer a unique
                blend of services that empower businesses to achieve their
                business goals, not just through digital marketing but also
                through exclusive strategic programming solutions. Despite
                challenges, the journey has been worth it as we continue to make
                a difference in the lives of our clients.
                </p>

              </div>
            </div>
            <img
          src={currentImage}
          alt="Toggleable Image"
          onClick={toggleImage}
          style={{ cursor: 'pointer', width: "100%" }}
        />
          </div>
        </div>
      </div>
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>Our Core Values</b>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Group+1708.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Consistency</b>
                        <div className={styles.consistentInGetting}>
                          Consistent in Getting Results
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                      Our commitment to excellence is reflected in every piece
                      of work we deliver. Our core values are embedded in our
                      approach to ensure we consistently provide top-notch
                      results. We take pride in surpassing our clients'
                      expectations with reliable and creative solutions that
                      maintain a high level of consistency.
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-524.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Originality</b>
                        <div className={styles.consistentInGetting}>
                        Delivering One-of-A-kind Solutions
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Originality is one of the cornerstones of our strength,
                      setting us apart from the competition. We take pride in
                      our consistent delivery of quality work that meets the
                      highest standards of creativity and consistency. Whether
                      it's a small or large-scale project, we aim to exceed
                      expectations with excellent results.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-523.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Reliability</b>
                        <div className={styles.consistentInGetting}>
                        Trustworthy & Transactional Transparency
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Reliability is one of the fundamental tenets of our
                        client relationships. The results we speak of have
                        earned the trust of our clients repeatedly. We strive to
                        maintain humility, honest communication, and open
                        dialogue to ensure our clients receive excellent results
                        which supersedes their goals and bring abundance of
                        growth.
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-587.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Excellence</b>
                        <div className={styles.consistentInGetting}>
                        Excellent Customer Satisfaction
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    One of our primary goals is to achieve full customer
                          satisfaction by delivering best-in-class solutions. We
                          maintain our originality by staying ahead of the curve
                          with cutting-edge solutions that meet the unique needs
                          of your business. Our team is dedicated to delivering
                          exceptional results that exceed expectations, no
                          matter the size of the project.
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div className={styles.ourTeamParent}>
        <b className={styles.ourOrigin}>Our Team</b>
        <div className={styles.rectangleGroup1}>
          <img className={styles.groupChild11123} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Rectangle+4060111.png" />
          <div className="overlay" />
          <div className={styles.groupItem} />
        </div>
      </div>
 
    


      <div className={style.whoWeAree}>


<div className={style.exploreOtherServicesParent}>
 
  <div className={style.groupParent14}>
  <div className={style.groupParent15}>
<Link to="/digital-marketing" style={{ width: "100%" }}>
<div className={style.rectangleParent5}>
  <div className={style.groupChild10} />
  <div className={style.rectangleWrapper}>
    <div className={style.rectangleWrapper}>
      <div className={style.groupChild11} />
    </div>
    <img
      className={style.groupChild1212}
      alt=""
      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+443712.png"
    />
    <div
      className={style.digitalMarketing}
    >{`Digital Marketing `}</div>
    <img
      className={style.arrowRightIcon}
      alt=""
      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright.svg"
    />
  </div>
</div>
</Link>
<div className={style.frameWrapper8}>
  <div className={style.frameParent7}>
    <Link to="/digital-marketing/intent-we-serve" style={{ width: "100%" }}>
      <button
        className="btn btn-light w-100"
        style={{
          fontSize: "15px",
          width: "18.3rem",
          height: "2rem",

          fontWeight: "600",
          color: "black",
          textAlign: "left",
          padding: "0vw"
        }}
      >
        <img
          className={style.frameItem + "ps-1"}
          alt=""  style={{width:"6vw", marginLeft:"1rem"}}
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg1.svg"
          width={35}
        />
        Intent We Serve{" "}
      </button>
    </Link>
    <Link to="/digital-marketing" style={{ width: "100%" }}>
      <button
        className="btn btn-light w-100"
        style={{
          fontSize: "15px",
          width: "18.3rem",
          height: "2rem",

          fontWeight: "600",
          color: "black",
          textAlign: "left",
          padding: "0vw"
        }}
      >
        <img
          className={style.frameItem + " mr-1"}
          style={{width:"5vw" , marginLeft:"1rem"}}
          alt=""
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg2.svg"
          width={35}
        />
        Services{" "}
      </button>
    </Link>
    <Link to="/digital-marketing/ecommerce-marketing" style={{ width: "100%" }}>
      <button
        className="btn btn-light w-100"
        style={{
          fontSize: "15px",
          width: "18.3rem",
          height: "2rem",

          fontWeight: "600",
          color: "black",
          textAlign: "left",
          padding: "0vw"
        }}
      >
        <img
          className={style.frameItem + " mr-1"}
          alt="" style={{width:"6vw" , marginLeft:"1rem"}}
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg3.svg"
          width={35}
        />
        E-commerce
      </button>
    </Link>
    <Link to="#" style={{ width: "100%" }}>
      <button
        className="btn btn-light w-100"
        style={{
          fontSize: "15px",
          width: "18.3rem",
          height: "2rem",

          fontWeight: "600",
          color: "black",
          textAlign: "left",
          padding: "0vw"
        }}
      >
        <img
          className={style.frameItem + " mr-1"}
          alt="" style={{width:"5vw" , marginLeft:"1rem"}}
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg4.svg"
          width={35}
        />
        Graphic Design{" "}
      </button>
    </Link>
    <Link to="/digital-marketing/content-marketing" style={{ width: "100%" }}>
      <button
        className="btn btn-light w-100"
        style={{
          fontSize: "15px",
          width: "18.3rem",
          height: "2rem",

          fontWeight: "600",
          color: "black",
          textAlign: "left",
          padding: "0vw"
        }}
      >
        <img
          className={style.frameItem + " mr-1"}
          alt="" style={{width:"5vw" , marginLeft:"1rem"}}
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg5.svg"
          width={35}
        />
        Content{" "}
      </button>
    </Link>
  
  </div>
</div>
</div>
  
  </div>

  <div className={style.groupParent14}>

  <div className={style.groupParent15}>
<div className={style.groupChild10} />
<div className={style.unlockThePotentialOfYourBParent}>

  <div className={style.frameWrapper8}>
    <div className={style.frameParent7}>
      <Link to="/it-services/it-development" style={{ width: "100%" }}>
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + "ps-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem", marginRight:"4px"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg1.svg"
            width={35}
          />
          Development{" "}
        </button>
      </Link>
      <Link to="/it-services/uiux-services" style={{ width: "100%" }}>
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg2.svg"
            width={35}
          />
          UI/UX Interface{" "}
        </button>
      </Link>
      <Link to="/it-services/it-management-service" style={{ width: "100%" }}>
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg3.svg"
            width={35}
          />
          IT Management
        </button>
      </Link>
      <Link to="/it-services/integration-services" style={{ width: "100%" }}>
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg4.svg"
            width={35}
          />
          Integrations{" "}
        </button>
      </Link>
      <Link to="/it-services/quality-testing-service" style={{ width: "100%" }}>
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg5.svg"
            width={35}
          />
          Quality Testing{" "}
        </button>
      </Link>

     
    </div>
  </div>
  
  <Link to="/it-services" style={{ width: "100%" }}>
  <div className={style.groupParent17}>
    <div className={style.rectangleWrapper}>
      <div className={style.groupChild11} />
    </div>
    <div className={style.itServicesParent}>
      <div className={style.itServices}>IT Services</div>
      <img
        className={style.arrowRightIcon1}
        alt=""
        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
      />
      <img
        className={style.groupChild15}
        alt=""
        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1093%402x.png"
      />
    </div>
  </div>
  </Link>
</div>
</div>
 
  </div>


  <div className={style.groupParent14}>


  <div className={style.groupParent15}>
<div className={style.groupChild10} />
<div className={style.unlockThePotentialOfYourBParent}>

  <div className={style.frameWrapper8}>
    <div className={style.frameParent7}>
   
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + "mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem", marginRight:"4px"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg1.svg"
            width={35}
          />
          Data Analytics{" "}
        </button>

        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg2.svg"
            width={35}
          />
          Data Visualization{" "}
        </button>

        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg3.svg"
            width={35}
          />
          Business Analytics
        </button>
     
        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg4.svg"
            width={35}
          />
          Data Mining{" "}
        </button>

        <button
          className="btn btn-light w-100"
          style={{
            fontSize: "15px",
            width: "18.3rem",
            height: "2rem",

            fontWeight: "600",
            color: "black",
            textAlign: "left",
            padding: "0vw"
          }}
        >
          <img
            className={style.frameItem + " mr-1"}
            alt="" style={{width:"5vw" , marginLeft:"1rem"}}
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg5.svg"
            width={35}
          />
          Predictive Analytics{" "}
        </button>


     
    </div>
  </div>


  <div className={style.groupParent17}>
    <div className={style.rectangleWrapper}>
      <div className={style.groupChild11} />
    </div>
    <div className={style.itServicesParent}>
      <div className={style.itServices}>Analytics</div>
      <img
        className={style.arrowRightIcon1}
        alt=""
        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
      />
      <img
        className={style.groupChild15}
        alt=""
        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1094%402x.png"
      />
    </div>
  </div>

</div>
</div>
</div>
  
</div>


</div>

























      <div className={styles.wonder}>
  <p className={styles.wonder1}>How can we help transform your business vision?
</p>
<Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none!important" }}>

<button className={styles.letsGetStartedWrapper + "w-auto mb-3"} style={{borderRadius:"30px",padding:".5rem 1rem", color:"#0076A2",background:"white", border:"none",textAlign:"center",position:"relative",left:"6rem"}}>
                <div className={styles.letsGetStarted}>Contact Us</div>
              </button>
              </Link>
</div>
  

    </div>
    </>
  );
};

export default WhoWeAre;
