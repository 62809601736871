import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth1 from "../components/InterestedInTechrowth1";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider1 from "../components/ClientsSlider1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions6 from "../components/SocialMediaMarketingSolutions6";
import Services6 from "../components/Services6";
import ProblemsWeSolved1 from "../components/ProblemsWeSolved1";
import OtherServices1 from "../components/OtherServices1";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial7 from "../components/NewTestimonial7";
import { Link } from "react-router-dom";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthsub22";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthsub2";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";

const Website = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/banner5.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M15.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT", path: "/it-services" },
      { name: "Website", path: "/it-services/it-development/website-development-service" },

    ]);
  }, []);

  const HeroTitel = "Website Development Service";
  const HeroContent =
    "Choose Techrowth, the best web development company in Bangalore with visually appealing designs, cutting-edge functionalities, and seamless responsiveness across devices";
  const subject = "Website Development Service ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://techrowth.com/it-services/it-development/website-development-service" />
<meta charSet="utf-8" />
<title>Web Development Company | Website Development Services in Bangalore
</title>
<meta name="description" content="Techrowth is a leading website designing and development company in Bangalore with years of experience offering stunning, mobile responsive and SEO-friendly website designs.
" />
<meta name="keywords" content="
Website Design Company in Bangalore, Website Development Company in Bangalore, website designing company in bangalore, Web Development Company in Bangalore, Web Design Company in Bangalore
" />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider1/>
      <NewTestimonial7/>
      <div className={style.contact}> 

      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Create a website that captures attention to elevate your brand.
        Contact our experts today!"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions6 />
      <Services6/>
      <ProblemsWeSolved1/>
      <OtherServices1/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default Website;
