import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"1rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">

<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
By using IT services and Analytics, Mid-Sized IT Tech Company may increase customer retention.

</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study6.png" alt="D" width="100%"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>
Mid-Sized IT Tech Company</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The business aimed to improve customer retention. They identified key issues, such as poor communication and customer service, and were willing to shift focus towards <Link to="https://techrowth.com/it-services">software solutions</Link>.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>

Increased customer retention rate by 49%</p>







<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>

<p><strong>1) Improved Communication:</strong></p>
<ul>

<li>We implemented a comprehensive communication plan, including newsletters, email campaigns, and social media updates, to keep customers informed about updates, features, and bug fixes. Rational Marketing campaign improved the onboarding process for new customers, providing information on software capabilities and how to use it effectively.</li>
</ul>
<p><strong>2) Product Development:</strong></p>
<ul>
<li>Thorough market research helped us understand customer needs. We dedicated resources to enhance the software, incorporating new features, improving user experience, and introducing innovative pricing plans to offer more options.</li>




</ul>
<p><strong>3) Customer Service:</strong></p>
<ul>
<li>Changes included developing company scripts, implementing a Standard Operational Process, and hiring additional support staff. A feedback mechanism was introduced to address customer complaints, concerns, and suggestions.</li>

</ul>


<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>
<li>The company's churn rate decreased by 34%, and customer satisfaction increased by 25%.</li>
<li>The company's revenue increased by 15%, primarily due to the introduction of new pricing plans.</li>
<li>The customer retention rate increased to 49% after the implementation of all the various techniques.</li>



</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>
<li>We used an email marketing campaign to keep customers informed.</li>
<li>An <Link to="https://techrowth.com/it-services">IT development</Link> using agile methodologies made sure we were able to use the customer feedback to its optimum.</li>
<li>By integrating chat support on its website for real-time assistance, we were able to improve customer satisfaction and retention rates.</li>
<li><Link to="https://techrowth.com/it-services">CRM software</Link> was implemented to track customer interactions and data, enabling customer service and sales teams to work more efficiently.</li>
<li>Social media monitoring was used to identify and address customer concerns quickly.</li>
<li>A customer loyalty program incentivized customers with discounts, exclusive content, and other perks, leading to increased customer retention rates.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>
<li>A $48k budget for developing and maintaining the software till the handover provided them with their desired effect all the while making it possible for us to make a specs specific software which was reliable.</li>

</ul>

</div>


</section>
</section>
</div>


    </>
  );
};

export default Test;
