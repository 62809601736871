import styles from "./InterestedDevelopment5.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Email Marketing Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`Technological Infrastructure We Employ. `}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/g7.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Conversion Rate</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Measure the success of your campaign by tracking the<br/> percentage of website visitors who take desired actions and filling a form.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Increased conversion rate by `}</span>
                    <b className={styles.eCommercePortals}>
                      9%
                    </b>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page34.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Customer Retention
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Building lasting relationships and ensuring loyalty for sustained business growth.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                    <b className={styles.websites}>
                      36%
                      </b>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` increase in customer retention`}</span>
                   
                    </p>
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
            <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page32.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Budget Optimization
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Achieving impactful results with a reduced budget through strategic optimization and resourceful creativity.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                
                    <span
                      className={styles.weHaveDeveloped}
                    >{`The marketing budget decreased by `}</span>
                    <b className={styles.eCommercePortals}>
                  21%
                    </b>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page33.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  Customer Engagement

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Building lasting connections through personalized interactions and valuable experiences.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Increase in engagement by `}</span>
                      <b className={styles.websites}>68% </b>
                    

                    </p>
                  </div>
                </div>
              </div>
             
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
