import styles from "./AdvantageOfTechrowth.module.css";
import NumberCounter from 'number-counter';

const InterestedInTechrowth1 = ({ title}) => {
  return (
    <div className={styles.secondPageIntent}>
      <div className="container py-5">
      <b className={styles.advantageOfTechrowth} style={{fontWeight:"600"}}>Interested in {title}</b>
      <div className={styles.weExploreDifferentContainer +" my-4"}>
        <p className={styles.weExploreDifferent}>
        Creative Graphic Design Services for a Streamlined Journey
        </p>
      </div>
      <div className={styles.secondPageIntentInner +" my-4"}>
        <div className={styles.frameParent }>
          <div className={styles.parent}>
          {/* <NumberCounter end={34} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>4.9/5</div>
            <div className={styles.incrementInGross}>
            Average Client Rating
            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={21} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>100%</div>
            <div className={styles.incrementInSocial}>
            Brand Consistency
            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={9} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>300+</div>
            <div className={styles.incrementInSocial}>
            Logos Created

            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={36} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>100+</div>
            <div className={styles.incrementInSocial}>
            Company Profiles created

            </div>
          </div>
        </div>
      </div>
      <div className={styles.inTheRealmContainer}>
        <p className={styles.weExploreDifferent}>
        Here's a glimpse of our outstanding achievements and the impact we've made with our graphic design services in Bangalore

        </p>
      </div>
    </div>
    </div>
  );
};

export default InterestedInTechrowth1;
