import React, { useState } from "react";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const DropdownMenu = ({ sendDataToParent }) => {
  const [setHover, setSetHover] = useState(false);
  const handleClick = () => {
    const data = false;
    sendDataToParent(data);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
      className="container-fluid position-absolute"
      style={{ backgroundColor: "#E9EEF4", zIndex: "1", top: "6.5%" }}
      
    >
      <div className="row py-4 px-4" style={{ gap: "2rem" }}>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center my-3" style={{listStyle:"none", padding:"0"}}>
            <li className="mb-3">
            <a  href="tel:+918073162943" style={{textDecoration:"none"}}>
                <p className="text-white" >
                Sales +91 8073162943
                  {/* <AiOutlineArrowRight onMouseEnter={() => setSetHover(true)} /> */}
                </p>
          </a>
            </li>
            <li className="mb-3">
            <a  href="tel:+919353937262" style={{textDecoration:"none"}}>
                {/* <p className="text-white">
                Support +91 9353937262 */}
                  {/* <AiOutlineArrowRight onMouseEnter={() => setSetHover(true)} /> */}
                {/* </p> */}
            </a>
           
            </li>
          </ul>
        </div>
    
      </div>
    </motion.div>
  );
};

export default DropdownMenu;
