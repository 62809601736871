import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t4.png" />

      <div className={styles.bus}>
      <h5 >Banking Sector</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>60%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Engagement</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>50%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+315212.svg" />
          </div>
          <p className={styles.bus5}>Customer Support Calls</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      “The mobile application Techrowth has created for our bank has elevated our customer experience to new heights! The app's security features and ease of use have fostered trust among our customers. It has simplified banking transactions and allowed us to offer innovative services. Working with their team has been a pleasure, and we've received positive feedback from our clients”.
     </p>
    </div>
  );
};

export default TSlide1;
