import React, { useEffect, useState } from "react";
import WhoWeAre from "../components/WhoWeAre";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactUsForm from "../components/ContactUsForm";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
// import Hero1 from "../components/Hero1";
import styles from "./NewHomepage.module.css";
import { Helmet } from "react-helmet";

const WhoWeArePage = () => {
  const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/who+we+are+img.png";
  const HeroTitel = "About Us";
  const HeroContent =""
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "About Us", path: "/about-us" },
    ]);
  }, []);
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/about-us" />
<title>Discover Our Story: Techrowth - One Stop IT & Marketing Solutions</title>
<meta name="description" content="Techrowth is the best IT and digital marketing company in Bangalore, providing innovative IT and marketing solutions to businesses worldwide. Discover why 100+ Clients rave about our services." />
</Helmet>
        <div className={styles.newHomepage}>
      <Breadcrumbs breadcrumb={breadcrumb} />
      <WhoWeAre />
      {/* who we are */}
      <ContactForm1 /></div>
      <TrustedByBrands />
    </>
  );
};

export default WhoWeArePage;
