import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth1 from "../components/InterestedInTechrowth1";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider1 from "../components/ClientsSlider1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions10 from "../components/SocialMediaMarketingSolutions10";
import Services10 from "../components/Services10";
import ProblemsWeSolved1 from "../components/ProblemsWeSolved1";
import OtherServices5 from "../components/OtherServices5";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial11 from "../components/NewTestimonial11";
import { Link } from "react-router-dom";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthsub224";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthsub204";
import style from "./NewHomepage1.module.css";
const MobileApplication = () => {
  const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/banner4.png";
  const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M19.png";


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT", path: "/it-services" },
      { name: "Mobile Application", path: "/it-services/it-development/mobile-application-development-service" },

    ]);
  }, []);

  const HeroTitel = "Mobile Application Development Service";
  const HeroContent =
    "Embrace mobile excellence with our Mobile Application Development Service in Bangalore. From idea to reality, we create captivating mobile experiences.";
  const subject = "Mobile Application Development Service ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/it-services/it-development/mobile-application-development-service" />
<title>Leading the Way with the best Mobile App Development services in Bangalore | Techrowth</title>
<meta name="description" content="Techrowth offers impactful mobile app development services in Bangalore to bring your project to market on every device and platform. Contact us today for a free consultation." />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider1/>
      <NewTestimonial11/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your mobile app’s potential with our Mobile Application Development Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions10 />
      <Services10/>
      <ProblemsWeSolved1/>
      <OtherServices5/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default MobileApplication;
