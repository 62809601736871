import styles from "./DigitalMarekting1.module.css";
import { Link, useLocation } from "react-router-dom";

const DigitalMarektingComponent = () => {
  return (
    <>
    <div className={styles.digitalMarekting}>
      <div className={styles.trafficAndRevenueGeneratedParent}>
        <b className={styles.trafficAndRevenueContainer}>
          <p style={{color:"black", fontSize:"18.08px", fontWeight:"300"}}>Experience Exceptional Digital Marketing Services</p>
          <p className={styles.trafficAndRevenue + "mb-5"} style={{fontWeight:"600"}}>
          Growth and Success
        
          </p>
        </b>
        <div className={styles.groupParent}>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
              
                <b className={styles.b}>{`21% `}</b>
                <div className={styles.increaseInVertical}>
                Increase in Customer Engagement
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr1.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b1}>{`34% `}</b>
                <div className={styles.increaseInOrganic}>
                Increase in Website Visits
                </div>
              </div>
            </div>
            <img className={styles.groupItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr2.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b2}>{`15% `}</b>
                <div className={styles.rateOfPpc}>
                Increase in  Customer Interaction
                </div>
              </div>
            </div>
            <img className={styles.groupInner} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr3.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b3}>{`12% `}</b>
                <div className={styles.increaseInOrganic}>
                Increase in Customer Conversion 
                </div>
              </div>
            </div>
            <img className={styles.groupIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr4.svg" />
          </div>
        </div>
      </div>
      
   
    </div>
    <div className={styles.digitalMarekting11}>

<div className={styles.businessAnalystics2}>
    <h4  style={{color:"black", fontWeight:"300", fontSize:"17px" , fontWeight:"500" }}>Experience Exceptional Digital Marketing Services</h4>
    <h4  style={{color:"#0076A2", fontSize:"28.21px", fontWeight:"600"}}>Growth and Success</h4>
    </div>
        <div className={styles.groupParent}>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
              
                <b className={styles.b}>{`21% `}</b>
                <div className={styles.increaseInVertical}>
                Increase in Customer Engagement
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr1.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b}>{`34% `}</b>
                <div className={styles.increaseInVertical}>
                Increase in Website Visits
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr2.svg" />
          </div>
         
        </div>
        <div className={styles.groupParent}>
 
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b}>{`15% `}</b>
                <div className={styles.increaseInVertical}>
                Increase in  Customer Interaction
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr3.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b}>{`12% `}</b>
                <div className={styles.increaseInVertical}>
                Increase in Customer Conversion 
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr4.svg" />
          </div>
        </div>

    
    </div>
</>
  );
};

export default DigitalMarektingComponent;
