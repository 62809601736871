import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>Content Marketing Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc32.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Website</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    With our company, you'll find appealing website content! We specialize in creating captivating and informative material that holds the attention of your audience. Our team of skilled writers is aware of the significance of language and how to convey your company's message effectively. We offer writing blogs, and articles, SEO-optimized content that generates outcomes, from compelling landing pages to enticing product descriptions.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc33.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Copywriting</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    We are aware of the effectiveness of compelling and convincing content. Our team of talented copywriters is committed to producing creative and powerful content that grabs customers' attention and delivers results. Our copywriting services will help you successfully convey the distinctive value proposition of your business, captivating your audience, and boosting consumer engagement and conversions. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc34.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Proofreading</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Increase visibility, improve the rankings, and bring in more visitors`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                     We are committed to making sure your content is perfectly polished and error-free. Your documents are carefully reviewed by our experienced team of proofreaders, who fix any mistakes in grammar, spelling, punctuation, and the structure of sentences. We promise that your message will be delivered accurately and professionally, thanks to our meticulous attention to detail.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc35.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Blogs/Articles</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
               
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                        We are a trustworthy provider of high-quality content created to satisfy your unique needs. The compelling articles and blog posts that our expert team of writers creates will capture your target audience. We value originality, accuracy, and creativity in our organization. Our content writers carry out in-depth research and create well-structured articles beneficial to your readers. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc36.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>White Paper</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    We are experts in creating superior white papers that clearly explain complex ideas and promote corporate expansion. Our talented team of writers combines subject-matter expertise with persuasive writing skills to produce appealing white papers catered to your particular requirements. We meticulously examine and pay close attention to every last detail as we delve into your subject to find useful data.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc37.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>PR Articles</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    We specialize in creating compelling and significant PR pieces that will help you reach your target audience in an effective manner. Our skilled writing team is aware of the value of producing attention-grabbing content that sparks interest. Our talented writers can create articles that not only inform but also stir emotions and motivate action since they are brilliant storytellers. Our writing prioritizes precision and clarity to successfully communicate your important themes. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>Content Marketing Services We Serve</b>
          <p className={styles.ourOrigin123}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc32.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Website</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    With our company, you'll find appealing website content! We specialize in creating captivating and informative material that holds the attention of your audience. Our team of skilled writers is aware of the significance of language and how to convey your company's message effectively. We offer writing blogs, and articles, SEO-optimized content that generates outcomes, from compelling landing pages to enticing product descriptions.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc33.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Copywriting</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We are aware of the effectiveness of compelling and convincing content. Our team of talented copywriters is committed to producing creative and powerful content that grabs customers' attention and delivers results. Our copywriting services will help you successfully convey the distinctive value proposition of your business, captivating your audience, and boosting consumer engagement and conversions. 
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc34.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Proofreading</b>
                        <div className={styles.consistentInGetting}>
                        Increase visibility and improve the rankings
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We are committed to making sure your content is perfectly polished and error-free. Your documents are carefully reviewed by our experienced team of proofreaders, who fix any mistakes in grammar, spelling, punctuation, and the structure of sentences. We promise that your message will be delivered accurately and professionally, thanks to our meticulous attention to detail.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc35.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Blogs/Articles</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We are a trustworthy provider of high-quality content created to satisfy your unique needs. The compelling articles and blog posts that our expert team of writers creates will capture your target audience. We value originality, accuracy, and creativity in our organization. Our content writers carry out in-depth research and create well-structured articles beneficial to your readers. 
                    </div>
              </div>
            
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc36.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>White Paper</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We are experts in creating superior white papers that clearly explain complex ideas and promote corporate expansion. Our talented team of writers combines subject-matter expertise with persuasive writing skills to produce appealing white papers catered to your particular requirements. We meticulously examine and pay close attention to every last detail as we delve into your subject to find useful data.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc37.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>PR Articles</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We specialize in creating compelling and significant PR pieces that will help you reach your target audience in an effective manner. Our skilled writing team is aware of the value of producing attention-grabbing content that sparks interest. Our talented writers can create articles that not only inform but also stir emotions and motivate action since they are brilliant storytellers. Our writing prioritizes precision and clarity to successfully communicate your important themes. 
                    </div>
              </div>
         
            </div>
           
          </div>
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
