import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>IT Development Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc6.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-commerce</b>
                        <div className={styles.consistentInGetting}>
                        Empower your online business with tailored solutions.
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Our experienced team works directly with you to obtain a thorough grasp of your company requirements. Using this expertise, we create personalized e-commerce systems that are designed to increase sales and client loyalty. Our mission is to provide efficient solutions that match your specific needs and contribute to your company's growth.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc7.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Mobile Applications</b>
                        <div className={styles.consistentInGetting}>
                        Innovative apps for exceptional user experiences
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    We are committed to delivering outstanding mobile user experiences, ensuring that your target audience is effectively addressed. Our experience is in developing effective mobile applications that assist you in meeting your business objectives. You can connect with your target audience, increase engagement, and drive success in the digital landscape with our solutions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc8.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>CMS</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Effortless content management made simple`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Keep your website running smoothly and up-to-date with our expert team. We take care of the technical aspects, so you can concentrate on growing your business. With our reliable Content Management System, maintaining your website is a breeze. Stay in control of your content and ensure that your website is always current and engaging.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc9.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Corporate Websites</b>
                        <div className={styles.consistentInGetting}>
                        Showcase your brand with captivating websites

                        </div>
                      </div>
                    </div>
                     
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
          Get a team dedicated to crafting user-friendly and efficient applications that will enable you to achieve your business objectives. We prioritize a smooth user experience and optimize our designs for far quicker page load times. Whether you require a website, web application, or e-commerce platform, we can assist you in establishing a strong online presence.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc10.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Web Applications</b>
                        <div className={styles.consistentInGetting}>
                        Building robust and scalable web apps
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Our skilled team is committed to keeping your website up to date and working securely, so you can focus on expanding your business. With our dependable services, you can be assured that you are in capable hands. We handle all technical aspects of your requirement, making sure your web app is up to date and optimized for speed and your case. 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc11.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Website Accessibility</b>
                        <div className={styles.consistentInGetting}>
                        Inclusive websites for all users
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Enhance your website's performance with our inclusive optimization expertise. We prioritize accessibility, ensuring a user-friendly experience for all. Reach your target audience effectively through tailored marketing strategies. Maximize online visibility, attract qualified leads, and drive sustainable growth. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>IT Development Services We Serve</b>
          <p className={styles.ourOrigin123}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc6.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>E-commerce</b>
                        <div className={styles.consistentInGetting}>
                        Empower your online business with tailored solutions.
                        </div>
                      </div>
                    </div>
            
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our experienced team works directly with you to obtain a thorough grasp of your company requirements. Using this expertise, we create personalized e-commerce systems that are designed to increase sales and client loyalty. Our mission is to provide efficient solutions that match your specific needs and contribute to your company's growth.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc7.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Mobile Applications</b>
                        <div className={styles.consistentInGetting}>
                        Innovative apps for exceptional user experiences
                        </div>
                      </div>
                    </div>
              
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We are committed to delivering outstanding mobile user experiences, ensuring that your target audience is effectively addressed. Our experience is in developing effective mobile applications that assist you in meeting your business objectives. You can connect with your target audience, increase engagement, and drive success in the digital landscape with our solutions.
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc8.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>CMS</b>
                        <div className={styles.consistentInGetting}>
                        Effortless content management made simple
                        </div>
                      </div>
                    </div>
            
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Keep your website running smoothly and up-to-date with our expert team. We take care of the technical aspects, so you can concentrate on growing your business. With our reliable Content Management System, maintaining your website is a breeze. Stay in control of your content and ensure that your website is always current and engaging.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc9.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Corporate Websites</b>
                        <div className={styles.consistentInGetting}>
                        Showcase your brand with captivating websites 
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Get a team dedicated to crafting user-friendly and efficient applications that will enable you to achieve your business objectives. We prioritize a smooth user experience and optimize our designs for far quicker page load times. Whether you require a website, web application, or e-commerce platform, we can assist you in establishing a strong online presence.
                    </div>
              </div>
        
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc10.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Web Applications</b>
                        <div className={styles.consistentInGetting}>
                        Building robust and scalable web apps
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our skilled team is committed to keeping your website up to date and working securely, so you can focus on expanding your business. With our dependable services, you can be assured that you are in capable hands. We handle all technical aspects of your requirement, making sure your web app is up to date and optimized for speed and your case. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc11.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Website Accessibility</b>
                        <div className={styles.consistentInGetting}>
                        Inclusive websites for all users
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Enhance your website's performance with our inclusive optimization expertise. We prioritize accessibility, ensuring a user-friendly experience for all. Reach your target audience effectively through tailored marketing strategies. Maximize online visibility, attract qualified leads, and drive sustainable growth. 
                    </div>
              </div>
            
            </div>
           
          </div>
         
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
