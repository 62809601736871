import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's Search Engine Marketing Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Enhance your online visibility and attract targeted traffic. `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Expertise in Paid Advertising Platforms</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth's SEM team is well-versed in utilizing various paid advertising platforms, including Google Ads, Bing Ads, and social media advertising networks. They have the expertise to create effective ad campaigns that align with your goals, target the right audience, and maximize your return on investment (ROI).</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Customized SEM Strategies`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth understands that every business is unique, with specific marketing goals and target markets. They develop customized SEM strategies that align with your business objectives and budget. Whether you want to increase website traffic, drive conversions, or boost brand awareness, their tailored approach ensures you get the most out of your SEM efforts.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Keyword Research and Optimization</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Effective keyword research is crucial for successful SEM campaigns. Techrowth conducts in-depth keyword research to identify relevant and high-impact keywords that potential customers are using to find products or services like yours. They optimize your ad campaigns to target these keywords, increasing the chances of reaching your target audience.</p>

              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Ad Copy and Creative Design</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Crafting compelling ad copy and visually appealing ad designs can significantly impact the performance of your SEM campaigns. Techrowth's team of skilled copywriters and designers create attention-grabbing ad content that resonates with your audience, encouraging clicks and conversions.</p>

      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Continuous Campaign Monitoring`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>SEM campaigns require ongoing monitoring and optimization to ensure they perform at their best. Techrowth continuously monitors the performance of your ads, analyzing data and making necessary adjustments to maximize your campaign's effectiveness. This proactive approach ensures that your budget is spent wisely and that your campaigns remain relevant and successful.</p>

      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Transparent Reporting and Analytics</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}> Techrowth provides detailed performance tracking and regular reports on the key metrics of your SEM campaigns. You'll have clear insights into how your campaigns are performing, the ROI they are generating, and the progress towards achieving your marketing goals. This transparent reporting allows you to make informed decisions and adjust strategies if needed.</p>

    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's Search Engine Marketing Services</h4>
      <p className={styles.businessAnalystics3}>Enhance your online visibility and attract targeted traffic.</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Expertise in Paid Advertising Platforms</h4>
      <p className={styles.itServices12345}>Techrowth's SEM team is well-versed in utilizing various paid advertising platforms, including Google Ads, Bing Ads, and social media advertising networks. They have the expertise to create effective ad campaigns that align with your goals, target the right audience, and maximize your return on investment (ROI).</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized SEM Strategies</h4>
      <p className={styles.itServices12345}>Techrowth understands that every business is unique, with specific marketing goals and target markets. They develop customized SEM strategies that align with your business objectives and budget. Whether you want to increase website traffic, drive conversions, or boost brand awareness, their tailored approach ensures you get the most out of your SEM efforts.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Keyword Research and Optimization</h4>
      <p className={styles.itServices12345}>Effective keyword research is crucial for successful SEM campaigns. Techrowth conducts in-depth keyword research to identify relevant and high-impact keywords that potential customers are using to find products or services like yours. They optimize your ad campaigns to target these keywords, increasing the chances of reaching your target audience.</p>


     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Ad Copy and Creative Design</h4>
      <p className={styles.itServices12345}>Crafting compelling ad copy and visually appealing ad designs can significantly impact the performance of your SEM campaigns. Techrowth's team of skilled copywriters and designers create attention-grabbing ad content that resonates with your audience, encouraging clicks and conversions.</p>

      
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Continuous Campaign Monitoring</h4>
      <p className={styles.itServices12345}>SEM campaigns require ongoing monitoring and optimization to ensure they perform at their best. Techrowth continuously monitors the performance of your ads, analyzing data and making necessary adjustments to maximize your campaign's effectiveness. This proactive approach ensures that your budget is spent wisely and that your campaigns remain relevant and successful.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Campaign Performance Tracking</h4>
      <p className={styles.itServices12345}>Techrowth provides detailed performance tracking and regular reports on the key metrics of your SEM campaigns. You'll have clear insights into how your campaigns are performing, the ROI they are generating, and the progress towards achieving your marketing goals. This transparent reporting allows you to make informed decisions and adjust strategies if needed.</p>

 


</div>


    
</div>
</>
  );
};

export default Services;
