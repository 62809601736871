import styles from "./Ppcbanner.module.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useRef,useEffect } from 'react';
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";

const redirectToThankYouPage = () => {
    window.location.href = "/Thankyou";
  };

const Hero = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const isInvalidEmail = (email) => {
        // Add the forbidden domains here
        const forbiddenDomains = ["email.com", "gmail.com"];
    
        const domain = email.split("@")[1];
        return forbiddenDomains.includes(domain);
      };
    
      const handleEmailChange = (e) => {
        const emailInput = e.target;
        const errorMessage = document.getElementById("emailError");
    
        if (isInvalidEmail(emailInput.value)) {
          errorMessage.textContent = "Please use a valid business email.";
          emailInput.setCustomValidity("Invalid email domain");
        } else {
          errorMessage.textContent = "";
          emailInput.setCustomValidity("");
        }
      };
      const form1 = useRef();
      const form2 = useRef();
     
      const [value, setValue] = useState()
      const sendEmail = (e, formRef) => {
        e.preventDefault();
        const selectedServices = [];
        const checkboxes = formRef.current.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          if (checkbox.checked) {
            selectedServices.push(checkbox.value);
          }
        });
      
        // Prepare data to send in the email
        const emailData = {
          name: formRef.current.name.value,
          designation: formRef.current.designation.value,
          phoneno: formRef.current.phoneno.value,
          email: formRef.current.email.value,
          compname: formRef.current.compname.value,
          webpage: formRef.current.webpage.value,
           // Combine selected services into a comma-separated string
        };
        emailjs.sendForm('service_ecyz8eo', 'template_8xq46kb', formRef.current, '-PZNCD8ehqiwS8RhW')
        // emailjs.sendForm('service_0ura04e', 'template_213qzbw', formRef.current, '5V6mQ1jA1-x6dKO3_')
    
          .then((result) => {
              console.log(result.text);
              e.target.reset();
          redirectToThankYouPage(); 
    
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };
    

  return  (
    <>
      <div className={styles.servicesbanner} >
        <div className="container">
          <div className="row">
            <div className={`col-lg-8 col-12 ${styles.update1}`}>

                    <div className="col-11">
                        <div className={styles.servicesdetails}>
                            <h1> <span>#1 PPC Company</span> to Dominate your Market and Get Ahead of
                                Competitors</h1>
                        </div>

                        <div className={styles.serviceshighlights}>
                            <div className="row ">
                                <div className="col-md-6 col-12">
                                    <div className="highlight-line">
                                        <p style={{fontSize:"18px"}}><span>12%</span> Average CTR</p>
                                    </div>

                                    <div className="highlight-line">
                                        <p style={{fontSize:"18px"}}><span>25%</span> Increase in Remarketing</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="highlight-line">
                                        <p style={{fontSize:"18px"}}><span>56%</span> Increase in Website Visits</p>
                                    </div>
                                    <div className="highlight-line">
                                        <p style={{fontSize:"18px"}}><span>11Million+</span> Leads&nbsp;Generated</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
                <div className="col-lg-4 col-12">
                    <div className={styles.update}>
                        {/* <h1> <span> PPC Company</span><br/>to Dominate your Market and Get Ahead of
                            Competitors</h1>
                        <br /> */}



                     
                       
                      



                    </div>
            
                    <div className="update1" style={{textAlign:"center"}}>
                    <h1 className={styles.updateeee}> <span> PPC Company</span><br/>to Dominate your Market and Get Ahead of
                            Competitors</h1>
                        <br />



                       
                        <form ref={form1} onSubmit={(e) => sendEmail(e, form1)} className={styles.form2}>
                            <h3 style={{color:"#0076a2", marginTop:"10px",textAlign:"center"}}>Get Free Consultation</h3>
                            <div className={`form ${styles.mainform}`} >
                                <div className="row justify-content-center" style={{padding:"0rem 1rem"}}>
                                    <div className="col-md-12 col-12">
                                        <div className="form-sec-input my-3">
                                            <div className="mb-4">
                                                <input type="text" className="form-control"  name="name"
                                                    placeholder="Full Name*" style={{height:"44px"}} required
                                                    pattern="[A-Za-z\s]*" title="Only alphabet characters are allowed."
                                                    inputmode="text"/>
                                                  <input type="text" className="form-control"  name="webpage" style={{display:"none"}}
                                               value="PPC Page"
                                                    inputmode="text"/>
                                            </div>

                                        </div>
                                        <div className="form-sec-input my-3  mb-4">
                                            <div className="input-group" >
                                                <span className="input-group-text" id="basic-addon1"
                                                    style={{fontSize:"1rem", fontWeight:"400"}}>+91</span>
                                                <input type="number" className="form-control" 
                                                    name="phoneno" style={{height:"44px"}}
                                                    placeholder="Contact Number*" required/>
                                            </div>
                                      
                                        </div>
                                        <div className="form-sec-input">
                                            <div className="mb-4">
                                                <input type="email" className="form-control"  name="email"
                                                    style={{height:"44px"}} placeholder="Work Email*" 
                                                   onChange={handleEmailChange} required/>
            <span id="emailError" className="error-message" style={{color:"red"}}></span>
                                            </div>
                                        </div>
                     
                             <div className="form-sec-input my-3">
                                            <div className="mb-4">
                                                <input type="text" className="form-control"  name="compname"
                                                    placeholder="Company Name*" style={{height:"44px"}} required
                                                    pattern="[A-Za-z\s]*" title="Only alphabet characters are allowed."
                                                    inputmode="text"/>
                                               
                                            </div>

                                        </div>
                                        <div className="mb-4">
                                            <select className="form-control" name="designation"
                                                style={{height:"44px", backgroundColor:"#ffffff"}} required>
                                                <option value="" disabled selected>Select Designation*</option>
                                                <option value="MD" >MD</option>
                                                <option value="Manager">Manager</option>
                                                <option value="CEO">CEO</option>
                                                <option value="Owner">Owner</option>
                                                <option value="Team_Lead">Team Lead</option>

                                            </select>
                                           
 

                                        </div>

                                    </div>
                                   
                                    <div className="col-12">


                                        <div className="form-sec-input mb-4" style={{display:"none"}}>
                                            <div className="form-group">
                                                <textarea className="form-control custom-textarea" 
                                                    name="message" placeholder="Message" rows="2"
                                                    spellcheck="false"></textarea>
                                            </div>
                                        </div>
                                        <h5 style={{color:"red", marginTop:"10px",textAlign:"center"}}>For Business
                                            Enquiries only</h5>
                                            <button className={styles.letsGetStartedWrapper + "w-auto mb-3"} style={{border:"none"}}>
              <input type="submit" className={styles.letsGetStarted} value="Submit" style={{borderRadius:"30px",padding:".8rem 3rem", background:"#0076A2", border:"none", color:"white"}}/>
              </button>
                                    </div>
                                </div>
                            </div>


                        </form>
                      






                    </div>

                </div>

            </div>
        </div>
    </div>
      
    </>
  );
};

export default Hero;
