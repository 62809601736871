import styles from "./InterestedDevelopment4.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            CMS Development Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`We offer the following CMS Development Solutions`}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w21.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Installation and optimisation</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our expert team ensures that your website is up-to-date and running smoothly, so you can focus on growing your business.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Installed and optimized over `}</span>
                    <b className={styles.eCommercePortals}>
                      144
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` CMS platforms`}</span>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w22.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Website Performance
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                     Clients who have adopted a CMS have witnessed significant improvements in their website performance.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  <b className={styles.eCommercePortals}>
                  25%
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` increase in page loading speed`}</span>
                  
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w23.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                   Improved Efficiency

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                     primarily due to automated publishing processes, and improved collaboration among team members.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                
                      <b className={styles.websites}>40% </b>
                      <span className={styles.weHaveDeveloped}> increase in content creation efficiency</span>
          

                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w24.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Content Engagement
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Techrowth’s clients using a CMS have reported that it helped optimize content delivery and enhance overall audience interaction.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                    <b className={styles.websites}>
                      50%
                      </b>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` increase  in user engagement metrics.`}</span>
                     
                    </p>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
