import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Ppcbanner from "../components/ppcbanner";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth from "../components/InterestedInTechrowth";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider from "../components/ClientsSlider";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions3 from "../components/SocialMediaMarketingSolutions3";
import Services3 from "../components/Services3";
import ProblemsWeSolved from "../components/ProblemsWeSolved";
import OtherServices04 from "../components/OtherServices04";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial5 from "../components/NewTestimonial5";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TechnologiesWeUseAndServiSocia5 from "../components/TechnologiesWeUseAndServiSocia5";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth114";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth14";
import style from "./NewHomepage1.module.css";
const PayPerClick = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Hero+Section-3.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M7.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Pay Per Click", path: "/digital-marketing/pay-per-click" },

    ]);
  }, []);

  const HeroTitel = "Pay Per Click";
  const HeroContent =
    "Choose the Expert PPC services in Bangalore, driving qualified traffic, generating leads, and boosting conversions.";
  const subject = "Pay Per Click ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/pay-per-click" />
<title>
PPC Services in Bangalore | PPC Company in Bangalore | Google Ads Agency
</title>
<meta name="description" content="PPC Services in Bangalore: Techrowth is one of the best PPC services provider in Bangalore to maximize your online advertising potential using targeted ads
" />
<meta name="keywords" content="PPC Company in Bangalore, Pay Per Click PPC services in Bangalore, PPC companies/agencies in Bangalore, Google Ads Pay Per Click (PPC) Agency Bangalore. PPC Agency in Bangalore.
" />
</Helmet>
       
      {/* <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      /> */}
      <Ppcbanner id="ppcBanner"/>
      <div className={styles.newHomepage}>
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider/>
      <NewTestimonial5/>
      <div className={style.contact}> 
      <Link to="/digital-marketing/pay-per-click#ppcBanner" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the potential of your online reach with the best PPC company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions3 />
      <Services3/>
      <ProblemsWeSolved/>
      <TechnologiesWeUseAndServiSocia5/>

      <OtherServices04/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default PayPerClick;
