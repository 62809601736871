





import styles from "./AdvantageOfTechrowth1.module.css";
import NumberCounter from 'number-counter';

const InterestedInTechrowth1 = ({ title}) => {
  return (
    <div className={styles.secondPageIntent}>
      <div className="container py-5">
      <b className={styles.advantageOfTechrowth}>Interested in {title}</b>
      <div className={styles.weExploreDifferentContainer +" my-4"}>
        <p className={styles.weExploreDifferent}>
        Experience a transformation that delivers remarkable results
        </p>
      </div>
      <div className={styles.secondPageIntentInner +" my-4"} style={{height:"19rem"}}>
        <div className={styles.frameParent }>
          <div className={styles.parent}>
          {/* <NumberCounter end={34} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>12%</div>
            <div className={styles.incrementInGross}>
            Average CTR
            </div>
          </div>
          <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>

          <div className={styles.parent}>
          {/* <NumberCounter end={21} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>56%</div>
            <div className={styles.incrementInSocial}>
            Increase in Website Visits
            </div>
          </div>
          <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>

          <div className={styles.parent}>
          {/* <NumberCounter end={9} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>25%</div>
            <div className={styles.incrementInSocial}>
            Increase in Remarketing 
            </div>
          </div>
          <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>

          <div className={styles.parent}>
          {/* <NumberCounter end={36} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>19%</div>
            <div className={styles.incrementInSocial}>
            Higher CTR for Mobile
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inTheRealmContainer}>
        <p className={styles.weExploreDifferent}>
        Here's a glimpse of our outstanding achievements and the impact we've made with our PPC services in Bangalore

        </p>
      </div>
    </div>
    </div>
  );
};

export default InterestedInTechrowth1;
