import React from "react";
import styles from "./Testimonial.module.css";
import TSlide1 from "./TestimonialSlider/TSlide1";
import TSlide2 from "./TestimonialSlider/TSlide2";
import TSlide3 from "./TestimonialSlider/TSlide3";
import TSlide4 from "./TestimonialSlider/TSlide4";
import Slider from "react-slick";
import TSlide111 from "./TestimonialSlider/TSlide111";

// import LeftArrow from "../assets/group-358.svg";
// import RightArrow from "../assets/group-359.svg";

const Testimonial = () => {
  const LeftArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-358.svg";
  const RightArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-359.svg";
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        onClick={onClick}
        alt=""
        style={{
          ...style,
          display: "none",
          width: "20px",
          height: "20px",
          left:"-26px"
        }}
        src={LeftArrow}
      />
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={RightArrow}
        className={className}
        style={{
          ...style,
          display: "none",
          width: "20px",
          height: "20px",
         right:"-26px"
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <>
      <div className={styles.stories} style={{ margin: "0" }}>
        <div className={styles.frameParent}>
          <div className={styles.discoverWhy100Container}  style={{color:"#000000"}}>
          What Our{" "}
            <span className="h1" style={{color:"#0076A2", fontWeight:"600"}}>
              <b> Clients</b>
            </span>{" "}
              Say
          </div>
            <TSlide111 />

          <div className={styles.clientReview} style={{width:"21rem"}}>
            <div className={styles.frameContainer}>

              <div className="container d-block">
                <div className={styles.group}>
                  <Slider {...settings}>
                    <div className="w-auto">
                      <TSlide1 />
                    </div>
                    <div className="w-auto">
                      <TSlide2 />
                    </div>
                    <div className="w-auto">
                      <TSlide3 />
                    </div>
                    <div className="w-auto">
                      <TSlide4 />
                    </div>
                  </Slider>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
