import styles from "./AdvantageOfTechrowth1.module.css";
import NumberCounter from 'number-counter';

const InterestedInTechrowth1 = ({ title}) => {
  return (
    <div className={styles.secondPageIntent}>
      <div className="container py-5">
      <b className={styles.advantageOfTechrowth}>Interested in {title}</b>
      <div className={styles.weExploreDifferentContainer}>
        <p className={styles.weExploreDifferent}>
        Expert E-Commerce Website Development Services for a Streamlined Journey
        </p>
      </div>
      <div className={styles.secondPageIntentInner +" my-4"}>
        <div className={styles.frameParent }>
          <div className={styles.parent}>
          {/* <NumberCounter end={34} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>38%</div>
            <div className={styles.incrementInGross}>
            Increase in Conversion Rate
            </div>
          </div>
          <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>

          <div className={styles.parent}>
          {/* <NumberCounter end={21} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>18%</div>
            <div className={styles.incrementInSocial}>
            Increase in Average Order Value
            </div>
          </div>
          <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>

          <div className={styles.parent}>
          {/* <NumberCounter end={9} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>35%</div>
            <div className={styles.incrementInSocial}>
            Increase in Mobile Sales Growth
            </div>
          </div>
          <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>

          <div className={styles.parent}>
          {/* <NumberCounter end={36} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>25%</div>
            <div className={styles.incrementInSocial}>
            Increase in Customer Retention Rate
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inTheRealmContainer}>
        <p className={styles.weExploreDifferent} style={{color:"black", marginTop:"1.5rem"}}>
        Here's a glimpse of our outstanding achievements and the impact we've made with our E-Commerce Website Development service in Bangalore

        </p>
      </div>
    </div>
    </div>
  );
};

export default InterestedInTechrowth1;
