import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t8.png" />

      <div className={styles.bus}>
      <h5 >Real Estate Company</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>30%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+315212.svg" />
          </div>
          <p className={styles.bus5}>Updation Time</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>45%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Property Enquiries</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      “The CMS service that Techrowth provided has elevated our real estate business to new heights! We can now manage property listings, add virtual tours, and create captivating property descriptions effortlessly. The user-friendly CMS has empowered our agents to showcase properties effectively, resulting in increased leads and conversions. Their CMS expertise has become an invaluable asset to our company".
     </p>
    </div>
  );
};

export default TSlide1;
