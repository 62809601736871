import styles from "./Otherservices.module.css";
import { Link } from "react-router-dom";

const OtherServices = () => {
  return (
    <>
    <div className={styles.digitalMarketingServices}>
      <div className={styles.digitalMarketingServicesInner}>
        <div className={styles.frameParent}>
          <div className={styles.digitalMarketingServicesParent}>
         
            <div className={styles.intentWeServe}>Explore Other Digital Marketing Services</div>
          </div>
        
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>

              <Link to="/digital-marketing/social-media-marketing" style={{textDecoration:"none", color:"white"}}>
            <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3162222.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>Social Media</p> */}
                  <p className={styles.unleashYourBrands}>Social Media 
Marketing</p>
                </div>
              </div>
              </Link>
              <Link to="/digital-marketing/social-media-optimization" style={{textDecoration:"none", color:"white"}}>
            <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2838.svg"

                />
                <div className={styles.socialMediaServicesContainer}>
                Social Media Optimization
                </div>
              </div>
              </Link>
              <Link to="/digital-marketing/search-engine-optimization" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2836.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                  <p className={styles.unleashYourBrands}>SEO</p>
       
                </div>
              </div>
              </Link>
              <Link to="/digital-marketing/search-engine-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2840.svg"

                />
                <div className={styles.socialMediaServicesContainer}>
                Search Engine Marketing
                </div>
              </div>
              </Link>
              <Link to="/digital-marketing/email-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2837.svg"

                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Email Marketing</p>
                </div>
              </div>
              </Link>
      
         
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className={styles.digitalMarketingServices1}>

      <p className={styles.businessAnalystics3}>Explore Other Digital Marketing Services</p>
    
              <div className={styles.wonder}>
             
              
              <div className={styles.test}>
              <Link to="/digital-marketing/social-media-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3162222.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>Social Media</p> */}
                  <p className={styles.unleashYourBrands}>Social Media 
Marketing</p>
                </div>
              </div>
              </Link>
              <Link to="/digital-marketing/social-media-optimization" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2838.svg"

                />
                     <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Social Media Optimization</p>
                </div>
            
              </div>
              </Link>
              <Link to="/digital-marketing/search-engine-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2840.svg"

                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}> Search Engine Marketing</p>
                </div>
              </div>
              </Link>
              </div>
              
              <div className={styles.test}>
              <Link to="/digital-marketing/search-engine-optimization" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2836.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                  <p className={styles.unleashYourBrands}>SEO</p>
       
                </div>
              </div>
              </Link>
          
              <Link to="/digital-marketing/email-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+2837.svg"

                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Email Marketing</p>
                </div>
              </div>
                     </Link>
                     </div>
              
             
</div>
</div>
</>
  );
};

export default OtherServices;
