import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Ppcbanner from "../components/ppcbanner2";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth1 from "../components/InterestedInTechrowth1";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider1 from "../components/ClientsSlider1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions8 from "../components/SocialMediaMarketingSolutions8";
import Services8 from "../components/Services8";
import ProblemsWeSolved1 from "../components/ProblemsWeSolved1";
import OtherServices3 from "../components/OtherServices3";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial9 from "../components/NewTestimonial9";
import { Link } from "react-router-dom";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthsub222";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthsub202";
import style from "./NewHomepage1.module.css";
const Ecommercedevelopment = () => {
  const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/banner3.png";
  const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M17.png";


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT", path: "/it-services" },
      { name: "E-Commerce", path: "/it-services/it-development/ecommerce-development-service" },

    ]);
  }, []);

  const HeroTitel = "E-Commerce Development Service";
  const HeroContent =
    "Partner with the best E-Commerce Website Development Company in Bangalore to maximize the potential of your online store. Utilize simple tools to easily develop and administer your e-commerce site.";
  const subject = "E-Commerce Development Service ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/it-services/it-development/ecommerce-development-service" />
<title>Best eCommerce Website Development Company in Bangalore | Techrowth

</title>
<meta name="description" content="Techrowth is the best Ecommerce Website Design & Development Company in Bangalore, India. Our experienced eCommerce developers can develop your website fast and efficiently.
" />
<meta name="keywords" content="
ecommerce Website development company in Bangalore, ecommerce web development company in Bangalore,ecommerce developers in Bangalore, ecommerce website design in Bangalore, ecommerce web design in Bangalore
" />
</Helmet>
<Ppcbanner id="ppcBanner"/>
        <div className={styles.newHomepage}>

      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider1/>
      <NewTestimonial9/>
      <div className={style.contact}> 
      <Link to="/it-services/it-development/ecommerce-development-service#ppcBanner" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Maximize your ecommerce sales with the best ecommerce website development company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions8 />
      <Services8/>
      <ProblemsWeSolved1/>
      <OtherServices3/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default Ecommercedevelopment;
