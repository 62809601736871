import styles from "./ExploreOurCaseStudies.module.css";
import { Link } from "react-router-dom";
const ExploreOurCaseStudies = () => {
  return (
    <>
    <div className={styles.exploreourcasestudies}>
      <div className={styles.exploreourcasestudiesInner}>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.exploreOurCaseStudiesParent}>
              <b className={styles.exploreOurCase} style={{fontWeight:"600"}}>Explore Our Case Studies</b>
              <div className={styles.ourDataDrivenStrategies}>
                Our Data-Driven Strategies for Real-World Problems
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <div className={styles.groupDiv}>
                  <div className={styles.boostingAndExpandingSalesTParent}>
                    <div className={styles.boostingAndExpanding}>
                    Boosting and Expanding sales through Emotional Marketing

                    </div>
                    <div className={styles.dairySectorProblemStatementParent}>
                      <div className={styles.dairySectorProblemContainer}>
                        <p className={styles.dairySector}>Dairy Sector</p>
                        <p className={styles.problemStatementTheBusiness}>
                          <span
                            className={styles.problemStatement}
                          >{`Problem Statement `}</span>
                          <span>
                            The business wanted to boost their sales and expand
                            their reach to the South Indian market by connecting
                            emotionally.
                          </span>
                        </p>
                      </div>
                      <div className={styles.lineParent}>
                        <div className={styles.groupChild} />
                        <Link to="/boosting-and-expanding-sales-through-emotional-marketing" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles.groupItem}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/Group+2823.svg"
                  />
                </div>
                <div className={styles.groupWrapper}>
                  <div className={styles.frameParent1}>
                    <div className={styles.transformingSlowSpeedWebsitParent}>
                      <div className={styles.transformingSlowSpeed}>
                      Transforming slow speed website through compression of file sizes

                      </div>
                      <div className={styles.groupContainer}>
                        <div className={styles.bankingSectorParent}>
                          <div className={styles.bankingSector}>
                            Banking Sector
                          </div>
                          <div className={styles.problemStatementTheContainer}>
                            <span
                              className={styles.problemStatement1}
                            >{`Problem Statement `}</span>
                            <span>
                              The banking website had slow loading speed,
                              leading to low engagement among users which is
                              what the company wanted resolved. 
                            </span>
                          </div>
                        </div>
                        <div className={styles.lineGroup}>
                          <div className={styles.groupChild} />
                          <Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.groupItem}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-251.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameParent2}>
                  <div className={styles.revitalizingEngagementThrougParent}>
                    <div className={styles.revitalizingEngagementThroug}>
                    Revitalizing Engagement through Social media tools resulting in increased bookings

                    </div>
                    <div className={styles.groupParent1}>
                      <div className={styles.transportationCompanyParent}>
                        <div className={styles.transportationCompany}>
                          Transportation company
                        </div>
                        <div className={styles.problemStatementTheContainer1}>
                          <p className={styles.problemStatementTheBusiness}>
                            <span
                              className={styles.problemStatement}
                            >{`Problem Statement `}</span>
                            <span>
                              The transportation service company was struggling
                              with low engagement for their AC buses in
                              comparison to their Non-AC buses. Due to a lack of
                              amenities and available comfort, causing customers
                              to choose the Non-AC option instead.
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className={styles.lineContainer}>
                        <div className={styles.groupChild} />
                        <Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles.groupChild1}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-555.svg"
                  />
                </div>
                <div className={styles.groupParent2}>
                  <div className={styles.rectangleParent}>
                    <div className={styles.rectangleDiv} />
                    <div className={styles.overcomingLowSales}>
                      Overcoming low sales and Increasing brand Visibility
                      through Rational Marketing
                    </div>
                    <div className={styles.groupParent3}>
                      <div className={styles.lineParent1}>
                        <div className={styles.groupChild} />
                        <Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                      <div className={styles.problemStatementTheAviationParent}>
                        <div className={styles.problemStatementTheContainer2}>
                          <p className={styles.problemStatementTheBusiness}>
                            <span
                              className={styles.problemStatement}
                            >{`Problem Statement `}</span>
                            <span>
                              The aviation company wanted to overcome its low
                              sales and increase brand visibility while
                              transitioning from their government to private
                              sector.
                            </span>
                          </p>
                        </div>
                        <div className={styles.aviationSector}>
                          Aviation Sector
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles.groupChild3}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-625.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        <Link to="/techrowth-knowledge" style={{textDecoration:"none"}}>
          <button className={styles.exploreStudiesWrapper} autoFocus>
            <div className={styles.exploreStudies}>Explore Studies</div>
          </button>
          </Link>
        </div>
      </div>
    </div>
    <div className={styles.exploreourcasestudies1}>
    <h4 className={styles.businessAnalystics2}>Explore Our Case Studies</h4>
      <p className={styles.businessAnalystics3}>with Our Versatile Technology Proficiency</p>
      <div className={styles.groupDiv}>
                  <div className={styles.boostingAndExpandingSalesTParent}>
                    <div className={styles.boostingAndExpanding}>
                      Boosting and Expanding sales through Emotional Marketing
                    </div>
                    <div className={styles.dairySectorProblemStatementParent}>
                      <div className={styles.dairySectorProblemContainer}>
                        <p className={styles.dairySector}>Dairy Sector</p>
                        <p className={styles.problemStatementTheBusiness}>
                          <span
                            className={styles.problemStatement}
                          >{`Problem Statement `}</span>
                          <span>
                            The business wanted to boost their sales and expand
                            their reach to the South Indian market...
                          </span>
                        </p>
                      </div>
                      <div className={styles.lineParent}>
                        <div className={styles.groupChild} />
                        <Link to="/boosting-and-expanding-sales-through-emotional-marketing" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineeeParent}>
                  <img
                    className={styles.groupItem}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/Group+2823.svg"
                  />
                  </div>
                </div>
                <div className={styles.groupDiv}>
                  <div className={styles.boostingAndExpandingSalesTParent}>
                    <div className={styles.boostingAndExpanding}>
                    Transforming slow speed website through compression of
                        file sizes
                    </div>
                    <div className={styles.dairySectorProblemStatementParent}>
                      <div className={styles.dairySectorProblemContainer}>
                        <p className={styles.dairySector}>Banking Sector</p>
                        <p className={styles.problemStatementTheBusiness}>
                          <span
                            className={styles.problemStatement}
                          >{`Problem Statement `}</span>
                          <span>
                          The banking website had slow loading speed,
                              leading to low engagement among users...
                          </span>
                        </p>
                      </div>
                      <div className={styles.lineParent}>
                        <div className={styles.groupChild} />
                        <Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineeeParent}>
                  <img
                    className={styles.groupItem}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-251.svg"

                  />
                  </div>
                </div>
                <div className={styles.groupDiv}>
                  <div className={styles.boostingAndExpandingSalesTParent}>
                    <div className={styles.boostingAndExpanding1}>
                    Revitalizing Engagement through Social media tools
                      resulting in increased bookings
                    </div>
                    <div className={styles.dairySectorProblemStatementParent}>
                      <div className={styles.dairySectorProblemContainer}>
                        <p className={styles.dairySector}>Transportation company</p>
                        <p className={styles.problemStatementTheBusiness}>
                          <span
                            className={styles.problemStatement}
                          >{`Problem Statement `}</span>
                          <span>
                          The transportation service company was struggling
                              with low engagement for their AC buses...
                          </span>
                        </p>
                      </div>
                      <div className={styles.lineParent}>
                        <div className={styles.groupChild} />
                        <Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineeeParent}>

                  <img
                    className={styles.groupItem}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-555.svg"


                  />
                  </div>
                </div>
                <div className={styles.groupDiv}>
                  <div className={styles.boostingAndExpandingSalesTParent}>
                    <div className={styles.boostingAndExpanding}>
                    Overcoming low sales and Increasing brand Visibility
                      through Rational Marketing
                    </div>
                    <div className={styles.dairySectorProblemStatementParent}>
                      <div className={styles.dairySectorProblemContainer}>
                        <p className={styles.dairySector}>  Aviation Sector</p>
                        <p className={styles.problemStatementTheBusiness}>
                          <span
                            className={styles.problemStatement}
                          >{`Problem Statement `}</span>
                          <span>
                          The aviation company wanted to increase brand visibility while
                              transitioning from their government...
                          </span>
                        </p>
                      </div>
                      <div className={styles.lineParent}>
                        <div className={styles.groupChild} />
                        <Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" style={{textDecoration:"none"}}>
                        <button className={styles.exploreMore}>
                          Explore More.....
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineeeParent}>
                  <img
                    className={styles.groupItem}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-625.svg"


                  />
                  </div>
                </div>
              
</div>
</>
  );
};

export default ExploreOurCaseStudies;
