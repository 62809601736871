import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth’s Email Marketing  Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Establish effective communication with audience and increase engagement `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Expertise in Email Marketing</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}> Techrowth's Email Marketing team consists of experienced professionals who understand the intricacies of email marketing. They are well-versed in industry best practices, ensuring that your email campaigns adhere to relevant regulations, deliverability standards, and engage your audience effectively.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Customized Email Campaigns `}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth develops personalized email marketing strategies tailored to your business objectives and target audience. Whether you want to nurture leads, promote new products, or engage existing customers, they create custom campaigns that resonate with your recipients and drive desired actions.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Engaging Content Creation</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Compelling and relevant content is essential for successful email marketing. Techrowth's team of content creators crafts engaging email content, including attention-grabbing subject lines, persuasive copy, and visually appealing designs. They ensure that your emails deliver value to recipients and encourage interaction.</p>

              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Segmentation and Personalization</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth employs segmentation and personalization strategies to deliver highly targeted and relevant content to different segments of your email list. By sending personalized emails based on recipients' preferences, behaviors, and demographics, they improve open rates, click-through rates, and overall engagement.</p>

      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Automated Email Campaigns`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth leverages automation to set up email workflows that streamline your marketing efforts. They create automated sequences for welcome emails, abandoned cart reminders, follow-ups, and more. Automation saves time and ensures that your audience receives timely, relevant messages.</p>

      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Email Analytics and Reporting</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Understanding the performance of your email campaigns is crucial for optimization. Techrowth provides comprehensive email analytics and reporting, giving you insights into key metrics such as open rates, click-through rates, conversion rates, and subscriber engagement. This data-driven approach enables you to refine your email marketing strategy for better results.</p>

    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth’s Email Marketing  Services</h4>
      <p className={styles.businessAnalystics3}>Establish effective communication with audience and increase engagement</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Expertise in Email Marketing</h4>
      <p className={styles.itServices12345}>Techrowth's Email Marketing team consists of experienced professionals who understand the intricacies of email marketing. They are well-versed in industry best practices, ensuring that your email campaigns adhere to relevant regulations, deliverability standards, and engage your audience effectively.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized Email Campaigns: </h4>
      <p className={styles.itServices12345}>Techrowth develops personalized email marketing strategies tailored to your business objectives and target audience. Whether you want to nurture leads, promote new products, or engage existing customers, they create custom campaigns that resonate with your recipients and drive desired actions.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Engaging Content Creation</h4>
      <p className={styles.itServices12345}>Compelling and relevant content is essential for successful email marketing. Techrowth's team of content creators crafts engaging email content, including attention-grabbing subject lines, persuasive copy, and visually appealing designs. They ensure that your emails deliver value to recipients and encourage interaction.</p>


     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Segmentation and Personalization</h4>
      <p className={styles.itServices12345}>Techrowth employs segmentation and personalization strategies to deliver highly targeted and relevant content to different segments of your email list. By sending personalized emails based on recipients' preferences, behaviors, and demographics, they improve open rates, click-through rates, and overall engagement.</p>

      
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Automated Email Campaigns</h4>
      <p className={styles.itServices12345}>Techrowth leverages automation to set up email workflows that streamline your marketing efforts. They create automated sequences for welcome emails, abandoned cart reminders, follow-ups, and more. Automation saves time and ensures that your audience receives timely, relevant messages.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Email Analytics and Reporting</h4>
      <p className={styles.itServices12345}>Understanding the performance of your email campaigns is crucial for optimization. Techrowth provides comprehensive email analytics and reporting, giving you insights into key metrics such as open rates, click-through rates, conversion rates, and subscriber engagement. This data-driven approach enables you to refine your email marketing strategy for better results.</p>

 


</div>


    
</div>
</>
  );
};

export default Services;
