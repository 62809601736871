import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"1rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">

<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
Boosting and Expanding sales through Emotional Marketing.
</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-111111.png" width="100%"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>Dairy Sector</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The business wanted to boost their sales and expand their reach to the South Indian market.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>Increased sales by 47%</p>







<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>
<p>To overcome the problem of not being able to reach the south Indian market and increase sales, the following strategies were executed:</p>
<p><strong>1) Cultural marketing:</strong></p>
<ul>

<li>Promoted milk through other dairy products, for example, by promoting the goodness of milk through their curd, cheese, or paneer. This involved reaching out to people through culture, values, and creating a bond between the product and the customer.</li>
</ul>
<p><strong>2) Awareness of other variants of milk:</strong></p>
<ul>
<li>Created awareness of other variants of milk that they are already manufacturing. Buffalo's milk, for example, highlights its nutritious ingredients as well as other important elements required to build a healthy lifestyle. People were invited to visit the factory and learn more about the process through an awareness program.</li>
</ul>
<p><strong>3) Increased sponsorship:</strong></p>
<ul>
<li>Increased sponsorship in cricket and football to create awareness amongst the people about what makes them unique and why people should choose them.</li>

</ul>

<p><strong>4) Dairy Products Subscription:</strong></p>
<ul>
<li>We created a Dairy Products Subscription and added all dairy products with a comparison of our cheaper products above other expensive products to highlight our cost-effectiveness.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>

<li>The implementation of emotional marketing resulted in a significant 47% increase in sales during the campaign.</li>
<li>Furthermore, we applied additional <Link to="https://techrowth.com/digital-marketing">digital marketing strategies</Link> to achieve a course correction, resulting in a commendable 34% surge in e-commerce app downloads.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>

<li>Utilized tools like Meta Business Suite and Google Analytics for analyzing the campaign</li>
<li>The utilization of <Link to="https://techrowth.com/digital-marketing/social-media-marketing">social media advertising</Link> tactics resulted in an enhanced brand image and increased traffic</li>
<li>Focused email marketing efforts successfully generated buzz among the targeted audience.</li>
<li>Through App Store optimization, the level of engagement was considerably increased.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>

<li>
A $25K meant an extended engagement with our team, leading to their desired effect in turn.</li>
</ul>

</div>


</section>
</section>
</div>


    </>
  );
};

export default Test;
