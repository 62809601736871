import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t1.png" />

      <div className={styles.bus}>
      <h5 >E-Commerce Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>60%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+315212.svg" />
          </div>
          <p className={styles.bus5}>Cart abandonment</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>50%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Customer Loyalty</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      "The web application developed by Techrowth’s team has been a game-changer for our e-commerce business! It offers a seamless shopping experience for our customers, with intuitive navigation and fast-loading product pages. The integrated payment gateway ensures secure transactions, and the real-time order tracking feature has received positive feedback from our customers. Their expertise in web application development has been crucial to our online success”.
     </p>
    </div>
  );
};

export default TSlide1;
