import styles from "./DigitalMarekting2.module.css";
import { Link, useLocation } from "react-router-dom";

const DigitalMarektingComponent = () => {
  return (
    <>
    <div className={styles.digitalMarekting}>
      <div className={styles.trafficAndRevenueGeneratedParent}>
        <b className={styles.trafficAndRevenueContainer}>
          <p className={styles.trafficAndRevenue + "mb-5"} style={{color:"black", fontWeight:"500"}}>
            Traffic and Revenue generated through our
         <br/>
            Digital Marketing Services
          </p>
        </b>
        <div className={styles.groupParent}>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
              
                <b className={styles.b}>{`11 Mn+ `}</b>
                <div className={styles.increaseInVertical}>
                Leads Generated
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr23.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b1}>{`86% `}</b>
                <div className={styles.increaseInOrganic}>
                Increase in Organic Traffic
                </div>
              </div>
            </div>
            <img className={styles.groupItem} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr24.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b2}>{`120% `}</b>
                <div className={styles.rateOfPpc}>
                Rate of PPC Conversion Increased
                </div>
              </div>
            </div>
            <img className={styles.groupInner} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr25.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b3}>{`48% `}</b>
                <div className={styles.increaseInOrganic}>
                Improvement in cost per lead
                </div>
              </div>
            </div>
            <img className={styles.groupIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr26.svg" />
          </div>
        </div>
      </div>
      
      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.problemsWeHave}>Problems We have Solved with BISCCBOR</div>
        <div className="container">

        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr15.svg" />
              <div
                className={styles.businessEnquiriesAnd}
              >{`Business Enquiries and Branding Revamp `}</div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr16.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Cross-Sell or Up-Sell Strategies

              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr17.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Reactivation of Dormant Accounts
              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr18.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Compelling Content Creation
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.groupParent4}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr19.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Boosting Downloads and E-commerce Sales
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr20.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Strategic Remarketing Campaigns
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr21.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Establishing a Distinct Business Voice
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr22.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Expanding International Market Presence
              </div>
            </div>
          </div>
        </div>
          </div>
      </div>
   
    </div>










    <div className={styles.digitalMarekting11}>
    <h4 className={styles.businessAnalystics2}>Traffic and Revenue</h4>
        <div className={styles.groupParent}>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
              
                <b className={styles.b}>{`11 Mn+ `}</b>
                <div className={styles.increaseInVertical}>
                Leads Generated
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr23.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b}>{`86% `}</b>
                <div className={styles.increaseInVertical}>
                Increase in Organic Traffic
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr24.svg" />
          </div>
         
        </div>
        <div className={styles.groupParent}>
 
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b}>{`120% `}</b>
                <div className={styles.increaseInVertical}>
                Rate of PPC Conversion Increased
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr25.svg" />
          </div>
          <div className={styles.percentageBoxParent}>
            <div className={styles.percentageBox}>
              <div className={styles.parent}>
                <b className={styles.b}>{`48% `}</b>
                <div className={styles.increaseInVertical}>
                Improvement in cost per lead
                </div>
              </div>
            </div>
            <img className={styles.groupChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr26.svg" />
          </div>
        </div>
    <h4 className={styles.businessAnalystics2}>Problems We have Solved with <span style={{color:"var(--color-steelblue-100)"}}>BISCCBOR</span></h4>
    <div className={styles.frameGroup}>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr15.svg" />
              <div
                className={styles.businessEnquiriesAnd}
              >{`Business Enquiries and Branding Revamp `}</div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr16.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Cross-Sell or Up-Sell Strategies

              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr17.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Reactivation of Dormant Accounts
              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr18.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Compelling Content Creation

              </div>
            </div>
        
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr19.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Boosting Downloads and E-commerce Sales
              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr20.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Strategic Remarketing Campaigns
              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr21.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Establishing a Distinct Business Voice
              </div>
            </div>
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr22.svg" />
              <div className={styles.businessEnquiriesAnd}>
              Expanding International Market Presence
              </div>
            </div>
          </div>
          
    
    </div>
</>
  );
};

export default DigitalMarektingComponent;
