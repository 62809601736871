import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case1.module.css";
import styless from "../components/ContactUsBar.module.css";
import { Link } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Helmet } from "react-helmet";


const CaseStudy1 = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Case+Study+1.pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>
        <Helmet>
<meta charSet="utf-8" />
<title>Techrowth Portfolio - IT & Marketing Success Stories
</title>
<meta name="description" content="Explore our work at Techrowth Portfolio, showcasing IT & marketing success stories from the best IT and digital marketing company in Bangalore." />
</Helmet>
        <div className={styles1.newHomepage}>
      <div className="container" style={{ padding: "2rem 2rem" }}>
        <div className={styles.resourcePage24}>
        <div className={styles.sliderParent}>
          
          <div className={styles.slider}>
            <div className={styles.groupParent}>
              <img
                className={styles.frameChild}
                alt=""
                src=""
              />
              <img
                className={styles.frameChild}
                alt=""
                src=""
              />
            </div>
          </div>
          <div className={styles.executionStrategy} >
             Our Case Studies:
            </div>
          <div className={styles.blog}>
      
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
                <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blogs/4.png" />

          
                <div style={{padding:"1.5rem"}}>
                <Link to="/scalability-in-web-development-techrowths-approach-to-future-proofing-websites" className="text-white" style={{textDecoration:"none"}}>
               
              
            <h4 style={{color:"#0076a2"}}>Scalability in Web Development: Techrowth's Approach to Future-Proofing Websites
</h4>  </Link>
        
            <h6> <span style={{color:"black"}}>Scalability stands out as a critical component in the ever-changing web development setting when it comes to assuring the continued existence and sustainability of websites. Websites need to be flexible enough to develop and adapt as technology advances and user needs change in order to handle rising traffic, content, and functio...</span></h6>


            <div className={styles.blogInner} style={{marginTop:"2rem"}}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/scalability-in-web-development-techrowths-approach-to-future-proofing-websites" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
                </div>
            </div>
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blogs/2.png" />

       
             <div style={{padding:"1.5rem"}}>
             <Link to="/customer-centric-web-design-lessons-from-a-top-web-development-company" className="text-white" style={{textDecoration:"none"}}>
            
           
         <h4 style={{color:"#0076a2"}}>Customer-Centric Web Design: Lessons from a Top Web Development Company
</h4>  </Link>
     
         <h6> <span style={{color:"black"}}>In the present-day digital era, where having a strong online presence is essential for business success, customer-centric web design has become a crucial distinction for businesses trying to make an identity for themselves. User experience (UX) and customer-centric design are becoming essential priorities for organizations as they...</span></h6>


         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/customer-centric-web-design-lessons-from-a-top-web-development-company" className="text-white" style={{textDecoration:"none"}}>
             <div className={styles.continueReading}>Continue Reading</div>
             </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
                <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blogs/3.png" />

          
                <div style={{padding:"1.5rem"}}>
                <Link to="/mobile-first-e-commerce-optimizing-your-store-for-the-on-the-go-customer" className="text-white" style={{textDecoration:"none"}}>
               
              
            <h4 style={{color:"#0076a2"}}>Mobile-First E-commerce: Optimizing Your Store for the On-the-Go Customer
</h4>  </Link>
        
            <h6> <span style={{color:"black"}}>In the present-day digital age, where mobile phones are common and people rely on them for almost every part of their life, mobile commerce, or m-commerce, has become a major player in the e-commerce market. Businesses need to give mobile optimization first priority in order to meet the demands of customers who buy on the go, as ...</span></h6>


            <div className={styles.blogInner} style={{marginTop:"2rem"}}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/mobile-first-e-commerce-optimizing-your-store-for-the-on-the-go-customer" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
                </div>
            </div>
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
                <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/blogs/1.png" />

          
                <div style={{padding:"1.5rem"}}>
                <Link to="/testing-and-monitoring-ensuring-a-flawless-mobile-e-commerce-experience" className="text-white" style={{textDecoration:"none"}}>
               
              
            <h4 style={{color:"#0076a2"}}>Testing and Monitoring: Ensuring a Flawless Mobile E-commerce Experience
</h4>  </Link>
        
            <h6> <span style={{color:"black"}}>Online sellers must succeed in the fast-paced world of mobile e-commerce, where customers expect flawless performance and seamless experiences. This is where testing and monitoring come in. In this extensive blog, we'll go over the significance of testing and keeping an eye on the functionality and user experience of your mobile ...</span></h6>


            <div className={styles.blogInner} style={{marginTop:"2rem"}}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/testing-and-monitoring-ensuring-a-flawless-mobile-e-commerce-experience" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
                </div>
            </div>
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
                <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/new111.png" />

          
                <div style={{padding:"1.5rem"}}>
                <Link to="/digital-marketing-for-restaurant-businesses" className="text-white" style={{textDecoration:"none"}}>
               
              
            <h4 style={{color:"#0076a2"}}>Digital Marketing for Restaurant Businesses</h4>  </Link>
        
            <h6> <span style={{color:"black"}}>In this dynamic and competitive world of restaurants, businesses face challenges that can delay growth. From attracting and retaining loyal customers to expanding reach, efficient operations, and gathering valuable customer insights, restaurants must constantly adapt and innovate to stay ahead of the ever-evolving digital landscape...</span></h6>

            <div className={styles.blogInner} style={{marginTop:"2rem"}}>
              <div className={styles.continueReadingWrapper}>
              <Link to="/digital-marketing-for-restaurant-businesses" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
              </div>
            </div>
                </div>
            </div>



            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/lp/case12.png" />

       
             <div style={{padding:"1.5rem"}}>
             <Link to="/social-media-for-educational-institutions" className="text-white" style={{textDecoration:"none"}}>
            
           
         <h4 style={{color:"#0076a2"}}>10 Ways To Use social media for Education Institutions</h4>  </Link>
     
         <h6> <span style={{color:"black"}}>Today we'll explore the dynamic landscape of educational institutions and uncover the impactful role of social media for educational institutions in transforming traditional learning patterns. It's not just about memes and likes; it's about reshaping the very essence of education through the lens of social media for educational institutions...</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/social-media-for-educational-institutions" className="text-white" style={{textDecoration:"none"}}>
             <div className={styles.continueReading}>Continue Reading</div>
             </Link>
           </div>
         </div>
             </div>
         </div>
            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/case11.jpg" />

       
             <div style={{padding:"1.5rem"}}>
             <Link to="/discover-why-startups-shine-with-a-strong-social-media" className="text-white" style={{textDecoration:"none"}}>
            
           
         <h4 style={{color:"#0076a2"}}>Discover Why Startups Shine With a Strong Social Media Presence - Insights for Success</h4>  </Link>
         <h6><span style={{fontWeight:"600", }}>Startup Success: </span> <span style={{color:"black"}}>The digital landscape is a symbiotic relationship between social media for startups and a finely-tuned digital marketing strategy.</span></h6>
         <h6> <span style={{color:"black"}}>This blog post aims to give you information on why social media marketing plays an essential ...</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/discover-why-startups-shine-with-a-strong-social-media" className="text-white" style={{textDecoration:"none"}}>
             <div className={styles.continueReading}>Continue Reading</div>
             </Link>
           </div>
         </div>
             </div>
         </div>




            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-111111.png" />

       
             <div style={{padding:"1.5rem"}}>
             <Link to="/boosting-and-expanding-sales-through-emotional-marketing" className="text-white" style={{textDecoration:"none"}}>
            
           
         <h4 style={{color:"#0076a2"}}>Boosting and Expanding sales through Emotional Marketing.</h4>  </Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>Dairy Sector</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>The business wanted to boost their sales and expand their reach to the South Indian market.</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>Increased sales by 47%</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/boosting-and-expanding-sales-through-emotional-marketing" className="text-white" style={{textDecoration:"none"}}>
             <div className={styles.continueReading}>Continue Reading</div>
             </Link>
           </div>
         </div>
             </div>
         </div>

            <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study2.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Overcoming low sales and Increasing brand Visibility through Rational Marketing.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>Aviation Sector</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>The aviation company wanted to overcome its low sales and
                increase brand visibility while transitioning from the government
                to private sector.</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>Increased ticket sales by 21%</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/overcoming-low-sales-and-increasing-brand-visibility-through-rational-marketing" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>


         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study3.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Revitalizing Engagement through Social media tools resulting in increased bookings.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>Transportation company</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>                The transportation service company was struggling with low engagement for their AC buses in comparison to their Non-AC buses. Due to a lack of amenities and available comfort, ca using customers to choose the Non-AC option instead.
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased overall engagement by 78%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/revitalizing-engagement-through-social-media-tools-resulting-in-increased-bookings" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>


         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study4.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Transforming slow speed website through compression of file sizes.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>Banking Sector</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>                The banking website had low engagement among users, which is what the company wanted to be resolved.
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased website loading speed by 81%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/transforming-slow-speed-website-through-compression-of-file-sizes" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study5.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Enhancing B2B E-commerce
website for increased Sales &
Inquiries.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>B2B E-commerce
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>The business wanted to revamp their website for sales and
inquiries and wanted a website that could adequately handle their
incoming traffic and leave room for further infrastructure
improvements if needed.</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased sales enquiry rates by 62%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/enhancing-b2b-ecommerce-website-for-increased-sales-and-Inquiries" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study6.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>By using IT services and
Analytics, Mid-Sized IT Tech
Company may increase
customer retention.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>                    Mid-Sized IT Tech Company
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>The business aimed to improve customer retention. They identified
key issues, such as poor communication and customer service, and
were willing to shift focus towards software solutions.</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased customer retention rate by 49%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/by-using-it-services-and-analytics-mid-sized-it-tech-company-may-increase-customer-retention" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-71.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Promoting a new menu Item to drive more sales for a hotel.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>A Hotel Chain</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>The hotel chain was supposed to introduce a new menu item and wanted to promote it to their customers while highlighting its high nutritional value.
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased sales by 77%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/promoting-a-new-menu-item-to-drive-more-sales-or-a-hotel" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study8.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Enhancing Online Presence and
Customer Engagement for an
Online Clothing Retailer.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>                    Online Clothing Retailer
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>With an Economical Marketing Strategy the online clothing
retailer wanted to resolve their weak online presence, and attract
new customers to their website.</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased traffic to their website by 30%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/enhancing-Online-presence-and-customer-engagement-for-an-online-clothing-retailer" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study9.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/implemented-digital-marketing-strategies-for-Increasing-walk-ins-in-a-jewelry-store" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Implemented Digital Marketing Strategies for Increasing Walk-ins in a Jewelry Store.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>A Jewelry business store</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>                The jewelry store wanted to increase their walk-Ins through digital marketing.
</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    144% Increase in Store Walk-Ins.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/implemented-digital-marketing-strategies-for-Increasing-walk-ins-in-a-jewelry-store" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
         <div className={styles.dflex} style={{border:"2px solid #e9eef4", padding:"1rem", borderRadius:"5px"}}>
             
             <img className={styles.blogChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/CS-101.png" />

       
             <div style={{padding:"1.5rem"}}>
           <Link to="/maximizing-digital-customer-acquisition-in-the-banking-sector" className="text-white" style={{textDecoration:"none"}}>

         <h4 style={{color:"#0076a2"}}>Maximizing Digital Customer
Acquisition in the Banking Sector.</h4></Link>
         <h6><span style={{fontWeight:"600", }}>Business: </span> <span style={{color:"black"}}>Banking Sector</span></h6>
         <h6><span style={{fontWeight:"600", }}>Business Statement: </span> <span style={{color:"black"}}>The business wanted to increase their number of digital account
holders through new customer acquisition and reduce the cost
associated with digital marketing facilities.</span></h6>
         <h6><span style={{fontWeight:"600", }}>Impact: </span> <span style={{color:"black"}}>                    Increased new account holders by 18%.
</span></h6>

         <div className={styles.blogInner} style={{marginTop:"2rem"}}>
           <div className={styles.continueReadingWrapper}>
           <Link to="/maximizing-digital-customer-acquisition-in-the-banking-sector" className="text-white" style={{textDecoration:"none"}}>
                <div className={styles.continueReading}>Continue Reading</div>
                </Link>
           </div>
         </div>
             </div>
         </div>
          </div>







     
          <div />
        </div>
          
          <div className={styles.sidebar}>
            <div className={styles.sidebarform}>
              <div className={styles.frameParent8}>
                <div className={styles.letsConnectParent}>
                  <div className={styles.executionStrategy}>Let’s Connect</div>
                  <div className={styles.frameParent9}>
                    <input
                      className={styles.frameInput}
                      type="text"
                      placeholder="Full Name"
                      required
                    />
                    <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Designation"
                    />
                  <input
        className={styles.frameInput}
        type="text"
        placeholder="Official Contact No."
        name="phoneno"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
      />
              <input
                    className={styles.frameChild7}
                    type="email"
                    placeholder="Business Email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  />
                    <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Company Name"
                    />
                     <input
                      className={styles.frameChild7}
                      type="text"
                      placeholder="Website"
                    />
                    <div className={styles.squareRadioWrapper}>
              <p style={{color:"var(--blue)"}}>Interested Service -</p>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="digitalmarketing"
              value="Digital_Marketing"
              required
            />
            Digital Marketing
          </label>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="itdevelopment"
              value="IT_Development"
              required
            />
            IT Development
          </label>
          <label className={styles.squareRadioLabel}>
            <input
              type="checkbox"
              name="analytics"
              value="Analytics"
              required
            />
            Analytics
          </label>
        </div>
                    <input
                      className={styles.frameChild10}
                      type="text"
                      placeholder="Message"
                    />
                  </div>
                </div>
                <div className={styles.frameWrapper4}>
                  <button className={styles.submitWrapper}>
                    <div className={styles.submit}>Submit</div>
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.sidebarcontactdetails}>
              <div className={styles.sidebarcontactdetailsInner}>
                <div className={styles.frameParent10}>
                  <div className={styles.frameWrapper5}>
                    <div className={styles.frameParent11}>
                      <img
                        className={styles.frameChild1}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-572%402x.png"
                      />
                      <div className={styles.contactUsParent}>
                        <div
                          className={styles.executionStrategy}
                        >{`Contact Us `}</div>
                                  <a href="tel:+918073162943" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>India: +91 80731 62943</p></a>
          <a href="tel:+260767527931" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>Zambia: +260 767527931</p></a>
          <a href="tel:+610402049350" style={{color:"white", textDecoration:"none"}}><p style={{ margin:"0px 0px 0px"}}>Australia: +61 04020 49350</p></a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.lineDiv} />
                  <div className={styles.contactform11}>

                    <h4>Global Support</h4>
                    <a href="mailto:info@techrowth.com" target="_blank" style={{color:"white", textDecoration:"none"}}><p style={{fontSize:"13px"}}>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Frame+3050.svg" 
                    />info@techrowth.com</p></a>
                    <a href="https://join.skype.com/invite/pR5oMI4Q8bAy" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697123.svg" 
                    />Skype Techrowth</p></a>
                    <a href="https://teams.live.com/l/invite/FEAzL5s01dTUWIILwE" target="_blank" style={{color:"white", textDecoration:"none"}}><p>
                      <img
                      className={styles.frameChild111}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2721123.svg"

                    />Teams  Techrowth</p></a>
                      <a  target="_blank" style={{color:"white", textDecoration:"none", cursor:"pointer"}}><p>
                      <img
                      className={styles.frameChild11}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+2697-123.svg"
                    />+91 935-393-7262</p></a>
                    </div>




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default CaseStudy1;


