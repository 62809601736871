import React, { useState, useEffect } from "react";
import FullScreenButton from "./example24";
import TrustedByBrands from "../components/TrustedByBrands";
import "./PhoneDetector.css";
import 'react-phone-number-input/style.css'
import styles from '../components/ImageRow.module.css';
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Link } from "react-router-dom";




const PhoneDetector = () => {
  return (
    <>
    <div className={styles1.newHomepage}>
    <div className="portfolio-headings pt-4 pb-2" style={{width:"100%" }}>
      <div className="container">
        <div className="product-heading-display">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="product-header">
                <div className="row">
                  <div className="col-lg-1 col-2" style={{margin:"auto 0"}}>
                    <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/21.png" alt="" className="img-fluid" style={{width:"5rem"}}/>
                  </div>
                  <div className="col-lg-10 col-10">
                    <h2 className="mb-0" style={{ color: "#0076a2" }}>Zambia Medical Assoc</h2>
                    <h5 style={{ color: "#0076a2" }}>#Hospital Sector <br/>
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}>Hire Us</span>
                    </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    
    </div>
1
    <div className="App">
      <FullScreenButton />
    </div>
    <div className="App1 container">
    <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture123.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
              <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture124.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture125.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture126.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture127.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
    

    </div>
        <div className="container my-5">
    <div className="row" style={{marginTop:"10rem"}}>
        <div className="col-md-8 col-12">
        <h1 style={{ color: "#0076a2" }}>Zambia Medical Assoc</h1>
      <h5>We've created user-friendly platforms to unite healthcare professionals. Dive into our portfolio to see how we've helped associations connect with members effortlessly. Our websites are designed to simplify information sharing, showcase expertise, and foster seamless collaboration among healthcare experts.</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Sector : </span>Hospital Sector</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Problem Statement : </span>The Zambia Medical Association wanted a new website that would not only introduce more features but also prioritize user-friendliness and simplify the appointment booking process for patients.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Solution : </span>We took on the challenge with a commitment to enhancing healthcare accessibility. Our design and development efforts resulted in a feature-rich website that enriches the user experience and streamlines appointment booking. The outcome is an advanced online healthcare platform that prioritizes patient convenience and offers an array of valuable features for both medical professionals and patients alike.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Tools and Techniques : </span>NodeJS, ReactJS, Bootstrap, Figma</h5>





      <h5 style={{ color: "#0076a2",fontWeight:"600" }}>Need a Website ? 
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}> Hire Us</span>
                    </Link>
                    </h5>
        </div>
        <div className="col-md-4 col-12" style={{height:"33rem", overflowY:"scroll",border:"2px solid #e9eef4", padding:"10px"}}>
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Fullscreen/27.png" alt="Image 2" className="img-fluid"/>

        </div>
      </div>

                    <hr className="my-5" style={{ color: "#0076a2" }} />
                    <h4>You might also like</h4>
                    <div className={styles.imageContainer}>

                    <Link to="/techrowth-portfolio/vendor-management" style={{textDecoration:"none", color:"black"}}>
    
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/25.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/26.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Vendor Management</p>
          </div>
        </div>
     
         </Link>
         <Link to="/techrowth-portfolio/izb-crm" style={{textDecoration:"none", color:"black"}}>
      
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/26.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/20.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>IZB CRM</p>
          </div>
        </div>
    
      </Link>
      <Link to="/techrowth-portfolio/green-view-medical" style={{textDecoration:"none", color:"black"}}>
      
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/27.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/7.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Green View Medical</p>
          </div>
        </div>
           
             </Link>
      </div>
    </div>
    <div className={styles1.newHomepage}>

    <ContactForm1/></div>
      <TrustedByBrands />
      </>
  );

};





export default PhoneDetector;
