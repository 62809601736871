import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Ppcbanner from "../components/ppcbanner3";

import RecentResult from "../components/RecentResult";
import InterestedInTechrowth from "../components/InterestedInTechrowth";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider from "../components/ClientsSlider";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions from "../components/SocialMediaMarketingSolutions";
import Services from "../components/Services";
import ProblemsWeSolved from "../components/ProblemsWeSolved";
import OtherServices01 from "../components/OtherServices01";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial1 from "../components/NewTestimonial1";
import { Link } from "react-router-dom";
import TechnologiesWeUseAndServiSocia1 from "../components/TechnologiesWeUseAndServiSocia1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth11";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth1";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";
const SocialMediaMarketing = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Hero+Section.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M3.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Social Media Marketing", path: "/digital-marketing/social-media-marketing" },

    ]);
  }, []);

  const HeroTitel = "Social Media Marketing";
  const HeroContent =
    "Providing businesses with expert social media marketing service in Bangalore to maximize their online presence and engage target audiences effectively.";
  const subject = "Social Media Marketing ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with the best social media marketing company in Bangalore";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/social-media-marketing" />
<title>Social Media Marketing Agency | Best SMM Services in Bangalore
</title>
<meta name="description" content="Techrowth is the Best Social Media Marketing Company in Bangalore India. We offer result-driven Social Media Marketing Services in Bangalore at resonable prices.
" />
<meta name="keywords" content="social media marketing companies in Bangalore, social media marketing agency in Bangalore, social media marketing company Bangalore, social media marketing agency, social media, social media agency in Bangalore, social media marketing companies, social media marketing services, social media marketing company, social media marketing in Bangalore, social media agency
" />
</Helmet>
<Ppcbanner id="ppcBanner"/>
        <div className={styles.newHomepage}>
      {/* <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      /> */}
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider/>
      <NewTestimonial1/>
      <div className={style.contact}> 
      <Link to="/digital-marketing/social-media-marketing#ppcBanner" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the potential of your online reach with the best social media marketing company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions />
      <Services/>
      <ProblemsWeSolved/>
      <TechnologiesWeUseAndServiSocia1/>
      <OtherServices01/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default SocialMediaMarketing;
