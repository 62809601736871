import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t2.png" />

      <div className={styles.bus}>
      <h5 >Interiors Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>30%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Customer Satisfaction</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>50%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Brand awareness</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      "The web application that techrowth created for our interior design firm has been a game-changer in how we collaborate with clients. The web app's interactive design features and virtual 3D renderings allow clients to visualize our concepts better. The smooth communication and project tracking tools have improved our productivity and client satisfaction. Their expertise in web application development has elevated our interior design services”.
     </p>
    </div>
  );
};

export default TSlide1;
