import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case1.module.css";
import styless from "../components/ContactUsBar.module.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import Test from "../components/test10"
import Test1 from "../components/testph10"
import Test11 from "../components/test11";
import { Helmet } from "react-helmet";
const Blog2 = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Case+Study+1.pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<title>Unlocking Growth: How AI is Revolutionizing Digital Marketing
</title>
<meta name="description" content="looking for the best digital marketing company in Bangalore? Our expert team delivers top-notch SEO, social media, and PPC  services" />
</Helmet>

        <div className={styles1.newHomepage}>
        <Test11 />

      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default Blog2;
