import React from "react";
import styles from "../Stories1.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t1.png" />

      <div className={styles.bus}>
      <h5 >E-Commerce Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>200%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Paid Traffic</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>15%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Conversion Rate</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      "We can't thank Techrowth enough for their outstanding services! Since partnering with them, we have experienced remarkable growth. Their team's expertise in targeting the right audience has brought in a steady stream of qualified leads!"  </p>
    </div>
  );
};

export default TSlide1;
