import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>UI/UX Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Grow With the best UI/UX Services in Bangalore
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc12.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>UI/UX Website Design</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your digital presence with captivating UI/UX design
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    We believe in the power of a well-designed website to enhance customer experience and fuel business growth. With our expertise, we can help you create a website that not only captivates your audience but also delivers a seamless and engaging user experience. By leveraging our understanding of user-centric design principles, we ensure that your website not only looks visually appealing but also functions flawlessly across all devices.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc13.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>UI/UX Website Redesign</b>
                        <div className={styles.consistentInGetting}>
                        Revamp your website for a seamless user experience
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    We specialize in website improvement and redesign services, focusing on enhancing your online presence and driving conversions. Our expert team is dedicated to creating a smoother customer interface that enhances engagement and maximizes conversions. With our tailored website solutions, we optimise user experience to ensure seamless navigation and increased customer satisfaction. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc14.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>User Experience Testing</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Ensure perfection with rigorous user experience testing`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Our team specializes in identifying and resolving user experience issues to enhance customer satisfaction. By leveraging cutting-edge technology and industry best practices, we ensure that your users have a seamless and enjoyable experience with your products or services. Our approach not only improves customer satisfaction but also helps your company save money by optimizing user interactions.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc15.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Landing Pages & Funnels</b>
                        <div className={styles.consistentInGetting}>
                        Convert visitors into customers with irresistible landing pages
                        </div>
                      </div>
                    </div>
                 
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
          We specialize in optimizing websites for conversions, ensuring that our clients achieve their marketing goals. Our dedicated team creates highly effective landing pages and sales funnels that are designed to drive brand engagement and deliver impressive results. With our expertise, we consistently help businesses increase brand engagement by 13% to 21%. Trust us to enhance your online presence and maximize the impact of your marketing efforts
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc16.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Conversion Rate Optimization</b>
                        <div className={styles.consistentInGetting}>
                        Maximize results with data-driven conversion rate optimization
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    At Techrowth, we specialize in optimizing websites and increasing conversions to help businesses achieve their objectives. Using data-driven strategies, our experienced team has consistently delivered remarkable results, with our clients seeing revenue growth of 21% to 34%. We understand the importance of maximizing your online presence and tailoring it to drive successful outcomes. 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc17.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Rapid Web Design</b>
                        <div className={styles.consistentInGetting}>
                        Get online faster with our lightning-fast web design solutions
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    We specialize in delivering high-quality websites that help businesses generate revenue rapidly. With our expertise, we have achieved total revenue growth of 7%-12% for our clients. Our dedicated team is committed to providing you with a seamless experience, delivering high-quality websites quickly. Start generating revenue and growing your business as soon as possible.
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>UI/UX Services We Serve</b>
          <p className={styles.ourOrigin123}>Grow With the best UI/UX Services in Bangalore
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc12.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>UI/UX Website Design</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your digital presence with captivating UI/UX design
                        </div>
                      </div>
                    </div>
             
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We believe in the power of a well-designed website to enhance customer experience and fuel business growth. With our expertise, we can help you create a website that not only captivates your audience but also delivers a seamless and engaging user experience. By leveraging our understanding of user-centric design principles, we ensure that your website not only looks visually appealing but also functions flawlessly across all devices.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc13.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>UI/UX Website Redesign</b>
                        <div className={styles.consistentInGetting}>
                        Revamp your website for a seamless user experience
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We specialize in website improvement and redesign services, focusing on enhancing your online presence and driving conversions. Our expert team is dedicated to creating a smoother customer interface that enhances engagement and maximizes conversions. With our tailored website solutions, we optimise user experience to ensure seamless navigation and increased customer satisfaction. 
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc14.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>User Experience Testing</b>
                        <div className={styles.consistentInGetting}>
                        Ensure perfection with rigorous user experience testing
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our team specializes in identifying and resolving user experience issues to enhance customer satisfaction. By leveraging cutting-edge technology and industry best practices, we ensure that your users have a seamless and enjoyable experience with your products or services. Our approach not only improves customer satisfaction but also helps your company save money by optimizing user interactions.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc15.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Landing Pages & Funnels</b>
                        <div className={styles.consistentInGetting}>
                        Convert visitors into customers with irresistible landing pages
                        </div>
                      </div>
                    </div>
              
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We specialize in optimizing websites for conversions, ensuring that our clients achieve their marketing goals. Our dedicated team creates highly effective landing pages and sales funnels that are designed to drive brand engagement and deliver impressive results. With our expertise, we consistently help businesses increase brand engagement by 13% to 21%. Trust us to enhance your online presence and maximize the impact of your marketing efforts
                    </div>
              </div>
           
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc16.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Conversion Rate Optimization</b>
                        <div className={styles.consistentInGetting}>
                        Maximize results with data-driven conversion rate optimization
                        </div>
                      </div>
                    </div>
             
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    At Techrowth, we specialize in optimizing websites and increasing conversions to help businesses achieve their objectives. Using data-driven strategies, our experienced team has consistently delivered remarkable results, with our clients seeing revenue growth of 21% to 34%. We understand the importance of maximizing your online presence and tailoring it to drive successful outcomes. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc17.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Rapid Web Design</b>
                        <div className={styles.consistentInGetting}>
                        Get online faster with our lightning-fast web design solutions
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We specialize in delivering high-quality websites that help businesses generate revenue rapidly. With our expertise, we have achieved total revenue growth of 7%-12% for our clients. Our dedicated team is committed to providing you with a seamless experience, delivering high-quality websites quickly. Start generating revenue and growing your business as soon as possible.
                    </div>
              </div>
            
            </div>
           
          </div>
         
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
