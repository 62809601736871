import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t3.png" />

      <div className={styles.bus}>
      <h5 >Hospital</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>35%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Bookings</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>50%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Online Reputation</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      Techrowth website accessibility service has been invaluable in ensuring that our bank's online presence is accessible to everyone, regardless of their abilities. Their team conducted a thorough audit and made necessary changes to our website, improving its usability for individuals with visual  impairments. This commitment to accessibility aligns perfectly with our core values, and we're proud to offer an inclusive digital banking experience to all our customers.
     </p>
    </div>
  );
};

export default TSlide1;
