import React, { useState } from "react";
import { AiFillCaretDown, AiOutlinePhone } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { motion } from "framer-motion";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Dropdown, DropdownButton } from 'react-bootstrap';
const DropdownMenu2 = ({ sendDataToParent }) => {
  const [setHover, setSetHover] = useState(false);
  const handleClick = () => {
    const data = false;
    sendDataToParent(data);
  };
  const Logo = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/logo.png";
  const Phone = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/PhoneCall.png";
  const PlayStore = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/PlayStore.png";
  const apple = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/apple.png";
  const notification = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB/Bell_red.svg";
  const handleDropdownSelect = (selectedOption) => {

    console.log('Selected option:', selectedOption);
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 50 }}
      className="container-fluid position-absolute"
      style={{ backgroundColor: "#E9EEF4", zIndex: "1", top: "6.5%" }}
      
    >
      <div className="row py-4 px-4" style={{ gap: "2rem" }}>
      <div className="mobile-nav__wrapper">
       
   
       
       <div className="mobile-nav__content">

    

         <div className="mb-3 mx-0">
           <Sidebar style={{width:"100%"}}>
             <Menu>
               <MenuItem component={<Link to="/" />}   style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2",  color:"#0076A2" , fontWeight:"500"}}>Home</MenuItem>

               <SubMenu label="Digital Marketing" style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 <MenuItem component={<Link to="/digital-marketing/intent-we-serve" />}  style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }} >
                 Intent We Serve
                 </MenuItem>

                 <SubMenu label="Our Services" style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 <MenuItem component={<Link to="/digital-marketing/social-media-marketing" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Social Media Marketing
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/social-media-optimization" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Social Media Optimization
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/search-engine-optimization" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Search Engine Optimization
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/search-engine-marketing" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Search Engine Marketing
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/pay-per-click" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Pay Per Click
                   </MenuItem>
                   <MenuItem component={<Link to="/digital-marketing/email-marketing" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Email Marketing
                   </MenuItem>
                 </SubMenu>

                 {/* <MenuItem component={<Link to="/digital-marketing/graphic-design" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 Graphic Design 
                 </MenuItem> */}

                 <MenuItem component={<Link to="/digital-marketing/ecommerce-marketing" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 E-commerce 
                 </MenuItem>
                 <MenuItem component={<Link to="/digital-marketing/content-marketing" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>Content</MenuItem>
               </SubMenu>
               <SubMenu
                 
                 label="IT Services"  style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}
               >
                 <SubMenu label="Development" style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   <MenuItem component={<Link to="/it-services/it-development/cms-development-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Content Management System


          
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/website-development-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Website
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/ecommerce-development-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   E-Commerce
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/web-application-development-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Web Application
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/mobile-application-development-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Mobile Application
                   </MenuItem>
                   <MenuItem component={<Link to="/it-services/it-development/website-accessibility-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                   Website Accessibility
                   </MenuItem>
                 </SubMenu>
             

                 <MenuItem
                   component={<Link to="/it-services/uiux-services" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}
                 >
                   UI/UX  Interactive
                 </MenuItem>

                 <MenuItem component={<Link to="/it-services/it-management-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 IT management 
                 </MenuItem>
                 <MenuItem component={<Link to="/it-services/integration-services" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 Integration
                 </MenuItem>
                 <MenuItem component={<Link to="/it-services/quality-testing-service" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 Quality Testing 
                 </MenuItem>
               </SubMenu>
          
               <SubMenu label="Who We Are" style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 <MenuItem component={<Link to="/about-us" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 About Us
                 </MenuItem>
                 <MenuItem component={<Link to="/contact-us" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 Contact Us
                 </MenuItem>
                
               </SubMenu>
               <SubMenu label="Resources" style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
               <MenuItem component={<Link to="/techrowth-knowledge" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
               Techrowth Knowledge
                 </MenuItem>
                 <MenuItem component={<Link to="/techrowth-case-study" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 Case Studies
                 </MenuItem>
                 <MenuItem component={<Link to="/contact-us" />} style={{ backgroundColor: "white", borderBottom: "2px solid #0076A2", color:"#0076A2" , fontWeight:"500" }}>
                 News
                 </MenuItem>
               </SubMenu>
             </Menu>
           </Sidebar>
         </div>

     
       </div>
     </div>
    
      </div>
    </motion.div>
  );
};

export default DropdownMenu2;
