import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>Analytics Integration Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Grow with the Best Analytics Integration Services in Bangalore
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc24.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Salesforce</b>
                        <div className={styles.consistentInGetting}>
                        Unify and empower your business with Salesforce integration
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    By integrating Salesforce, you can streamline your sales and customer relationship management processes. Capture leads, manage customer interactions, and track sales activities more efficiently. With enhanced customer data and insights, you can target your marketing efforts effectively, resulting in improved conversion rates and increased revenue.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc25.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Adobe</b>
                        <div className={styles.consistentInGetting}>
                        Seamless connections with Adobe integration for creative excellence
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Leveraging Adobe's powerful marketing and analytics tools can help you optimise your digital marketing efforts. Personalise customer experiences, deliver targeted campaigns, and measure campaign effectiveness. The ability to analyse customer behaviour and engagement empowers you to make data-driven decisions, optimise conversions, and drive revenue growth.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc26.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Power BI</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Data-driven decisions with Power BI integration – the power of insights`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Integrating Power BI, Microsoft's business intelligence and analytics platform, enables you to gain valuable insights from your data. Visualise data, create interactive dashboards, and share reports to drive data-informed decision-making across your organisation. By leveraging actionable insights, you can identify revenue opportunities, optimise operations, and drive business growth.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc27.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Zoho</b>
                        <div className={styles.consistentInGetting}>
                        Streamline your workflows with Zoho integration – efficiency redefined 
                        </div>
                      </div>
                    </div>
                  
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
          Integrating Zoho's suite of business applications enhances your operational efficiency and customer relationship management. Streamline processes, automate workflows, and improve collaboration within your organisation. By delivering exceptional customer experiences and streamlining sales processes, you can increase customer satisfaction, loyalty, and ultimately, revenue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc28.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Dynamic 365</b>
                        <div className={styles.consistentInGetting}>
                        Connect, adapt, and grow with Dynamics 365 integration
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Microsoft Dynamics 365 provides a comprehensive set of business applications that integrate seamlessly across sales, marketing, customer service, and operations. By unifying your data and processes, you can gain a 360-degree view of your customers, improve customer engagement, and optimize sales and service interactions. This leads to increased customer retention, cross-selling, and upselling opportunities, resulting in higher revenue.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc29.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Tableau</b>
                        <div className={styles.consistentInGetting}>
                        Visualize success through Tableau integration – data brought to life
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Integrating Tableau's data visualization and analytics capabilities allows you to uncover valuable insights from your data. Create interactive visualizations, explore data trends, and share meaningful reports across your organization. By leveraging data-driven insights, you can identify revenue drivers, optimize pricing strategies, and make informed business decisions that positively impact your bottom line.
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>Analytics Integration Services We Serve</b>
          <p className={styles.ourOrigin123}>Grow with the Best Analytics Integration Services in Bangalore
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc24.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Salesforce</b>
                        <div className={styles.consistentInGetting}>
                        Unify and empower your business with Salesforce integration
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    By integrating Salesforce, you can streamline your sales and customer relationship management processes. Capture leads, manage customer interactions, and track sales activities more efficiently. With enhanced customer data and insights, you can target your marketing efforts effectively, resulting in improved conversion rates and increased revenue.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc25.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Adobe</b>
                        <div className={styles.consistentInGetting}>
                        Seamless connections with Adobe integration for creative excellence
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Leveraging Adobe's powerful marketing and analytics tools can help you optimise your digital marketing efforts. Personalise customer experiences, deliver targeted campaigns, and measure campaign effectiveness. The ability to analyse customer behaviour and engagement empowers you to make data-driven decisions, optimise conversions, and drive revenue growth.
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc26.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Power BI</b>
                        <div className={styles.consistentInGetting}>
                        Data-driven decisions with Power BI integration – the power of insights
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Integrating Power BI, Microsoft's business intelligence and analytics platform, enables you to gain valuable insights from your data. Visualise data, create interactive dashboards, and share reports to drive data-informed decision-making across your organisation. By leveraging actionable insights, you can identify revenue opportunities, optimise operations, and drive business growth.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc27.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Zoho</b>
                        <div className={styles.consistentInGetting}>
                        Streamline your workflows with Zoho integration – efficiency redefined
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Integrating Zoho's suite of business applications enhances your operational efficiency and customer relationship management. Streamline processes, automate workflows, and improve collaboration within your organisation. By delivering exceptional customer experiences and streamlining sales processes, you can increase customer satisfaction, loyalty, and ultimately, revenue.
                    </div>
              </div>
           
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc28.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Dynamic 365</b>
                        <div className={styles.consistentInGetting}>
                        Connect, adapt, and grow with Dynamics 365 integration
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Microsoft Dynamics 365 provides a comprehensive set of business applications that integrate seamlessly across sales, marketing, customer service, and operations. By unifying your data and processes, you can gain a 360-degree view of your customers, improve customer engagement, and optimize sales and service interactions. This leads to increased customer retention, cross-selling, and upselling opportunities, resulting in higher revenue.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc29.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Tableau</b>
                        <div className={styles.consistentInGetting}>
                        Visualize success through Tableau integration – data brought to life
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Integrating Tableau's data visualization and analytics capabilities allows you to uncover valuable insights from your data. Create interactive visualizations, explore data trends, and share meaningful reports across your organization. By leveraging data-driven insights, you can identify revenue drivers, optimize pricing strategies, and make informed business decisions that positively impact your bottom line.
                    </div>
              </div>
            
            </div>
           
          </div>
         
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
