import React from "react";
import styles from "./ContactUsBar.module.css";
import { Link, useLocation } from "react-router-dom";
const ContactUsBar = ({ content, btnTxt }) => {
const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";

  return (
    <>
    <div className={styles.wonderr1}>
    
    <div className="container my-5" style={{ padding: "0 4rem" }}>
      <div
        className="row d-flex p-5"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          borderRadius: "30px",
        }}
      >
        <div
          className="col-8 my-1 a p-0 text-white text-left d-flex align-items-center"
          style={{ fontSize: "18px", textDecoration:"none",fontFamily:"var(--font-montserrat)" }}
        >
          {content}
        </div>
        <div className="col-4 text-center d-flex justify-content-end align-items-center">
          <button
            type="button"
            className="btn btn-light w-75"
            style={{ borderRadius: "15px", color: "#0076A2" }}
          >
            <b>{btnTxt}</b>
          </button>
        </div>
      </div>
    </div>
    </div>
    <div className={styles.wonderr}>

    <div className={styles.wonder}>
  <p className={styles.wonder1}>
  {content}
</p>
<Link to="/contact-us" style={{ color: "#ffffff" }}>

<button className={styles.letsGetStartedWrapper + "w-auto mb-3"} style={{borderRadius:"30px",padding:".5rem 1rem", color:"#0076A2",background:"white", border:"none",textAlign:"center"}}>
                <div className={styles.letsGetStarted } style={{fontWeight:"600"}}>{btnTxt}</div>
              </button>
            </Link>
</div>
</div>
    </>
  );
};

export default ContactUsBar;
