import React, { useState, useEffect } from "react";
import FullScreenButton from "./example11";
import TrustedByBrands from "../components/TrustedByBrands";
import "./PhoneDetector.css";
import 'react-phone-number-input/style.css'
import styles from '../components/ImageRow.module.css';
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Link } from "react-router-dom";




const PhoneDetector = () => {
  return (
    <>
    <div className={styles1.newHomepage}>
    <div className="portfolio-headings pt-4 pb-2" style={{width:"100%" }}>
      <div className="container">
        <div className="product-heading-display">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="product-header">
                <div className="row">
                  <div className="col-lg-1 col-2" style={{margin:"auto 0"}}>
                    <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/8.png" alt="" className="img-fluid" style={{width:"5rem"}}/>
                  </div>
                  <div className="col-lg-10 col-10">
                    <h2 className="mb-0" style={{ color: "#0076a2" }}>ZNBS Property</h2>
                    <h5 style={{ color: "#0076a2" }}>#RealEstate Sector <br/>
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}>Hire Us</span>
                    </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    
    </div>
1
    <div className="App">
      <FullScreenButton />
    </div>
    <div className="App1 container">
    <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture58.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
              <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture59.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture60.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture61.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture62.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
  

    </div>
        <div className="container my-5">
    <div className="row" style={{marginTop:"10rem"}}>
        <div className="col-md-8 col-12">
        <h1 style={{ color: "#0076a2" }}>ZNBS Property</h1>
      <h5>Discover our real estate website designs. We've created user-friendly platforms that bring properties to life. Dive into our portfolio to see how we've helped real estate professionals connect with buyers and sellers seamlessly. Our websites are designed to simplify property searches, showcase listings, and facilitate smooth transactions, making the real estate journey a breeze.</h5>
   <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Sector : </span>RealEstate Sector</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Problem Statement : </span>ZBNS Property recognized this need and sought to revamp their digital image with a brand-new website. They approached us with a clear objective of a modern website that could effectively showcase their real estate listings and offer seamless user interaction. Their existing website lacked functionality to capture leads and provide a user-friendly experience.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Solution : </span>Our team swiftly crafted a sleek, user-centric website for ZBNS Property, featuring state-of-the-art real estate portals. We understood the need for a dynamic, responsive website that would not only serve as a virtual property portal but also engage users effectively. 
    </h5>

    <h5  className="my-3"><span>And, This is How We Delivered Excellence
 : </span>

</h5>
<h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>User-Centric Design : </span>Our design team conceptualized a user-centric layout that focused on easy navigation, ensuring visitors could find properties effortlessly.
</h5>
<h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Property Listings : </span>The Essential Real Estate integration made property management a breeze. The listings were categorized, complete with high-resolution images and detailed descriptions, making it easier for potential buyers to make informed decisions.
</h5>
<h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Lead Generation : </span>We incorporated user-friendly contact forms, strategically placed across the website, to capture potential clients' inquiries efficiently.
</h5>
<h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Mobile Responsiveness : </span>Recognizing the importance of mobile users, we ensured that the website was fully responsive, providing a seamless experience on all devices.
</h5>

    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Tools and Techniques : </span>WordPress, Essential Real Estate, WP Bakery Page</h5>





      <h5 style={{ color: "#0076a2",fontWeight:"600" }}>Need a Website ? 
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}> Hire Us</span>
                    </Link>
                    </h5>
        </div>
        <div className="col-md-4 col-12" style={{height:"57rem", overflowY:"scroll",border:"2px solid #e9eef4", padding:"10px"}}>
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Fullscreen/12.jpg" alt="Image 2" className="img-fluid"/>

        </div>
      </div>

                    <hr className="my-5" style={{ color: "#0076a2" }} />
                    <h4>You might also like</h4>
                    <div className={styles.imageContainer}>

                    <Link to="/techrowth-portfolio/neha-prakash-hospital" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/12.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/19.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Neha Prakash Hospital</p>
          </div>
        </div>
  
      </Link>
      <Link to="/techrowth-portfolio/hatchlong" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/13.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/10.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Hatchlong</p>
          </div>
        </div>
     
          </Link>
          <Link to="/techrowth-portfolio/validus-thailand" style={{textDecoration:"none", color:"black"}}>
      
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/14.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/24.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Validus Thailand</p>
          </div>
        </div>
  
      </Link>
      </div>
    </div>
    <div className={styles1.newHomepage}>

    <ContactForm1/></div>
      <TrustedByBrands />
      </>
  );

};





export default PhoneDetector;
