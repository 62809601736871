import styles from "./TechnologiesWeUseAndServiSocia1.module.css";
const TechnologiesWeUseAndServiSocia = () => {
  return (
    <>
    <div className={styles.technologiesWeUseAndServi }>
      <div className={styles.technologiesWeUse }>
        <div className={styles.frameParent }>

          <div className={styles.frameParent }>
            
            <div className={styles.frameContainer }>
              <div className={styles.technologiesWeUseWrapper}>
                <b
                  className={styles.technologiesWeUse1}
                >Technologies We Use</b>
              </div>
              <div className={styles.digitalSolutionsTools}>
                Digital Solution Tools for a Streamlined Journey
              </div>
            </div>
            <div className="container pb-5">
            <div className={styles.frameDiv}>
              <div className={styles.frameParent1}>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr23.svg"

                  />
                  <div className={styles.sproutSocial}>Google Ads</div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.image2Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr24.svg"

                  />
                  <div className={styles.sproutSocial}>Semrush</div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr25.svg"

                  />
                  <div className={styles.sproutSocial}>Bing Ads</div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.simpleIconshubspot}
                    alt="" style={{    width: "8.75rem"}}
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr26.svg"

                  />
                  <div className={styles.sproutSocial}>Spy Fu</div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.simpleIconshubspot}
                    alt="" style={{    width: "8.75rem"}}
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr27.svg"

                  />
                  <div className={styles.sproutSocial}>Google Analytics</div>
                </div>
         
              </div>
        
            </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <div className={styles.technologiesWeUseAndServi1 }>
 
    <h4 className={styles.businessAnalystics2}>Technologies We Use </h4>
      <p className={styles.businessAnalystics3}>Digital Solution Tools for a Streamlined Journey</p>
      <div className={styles.frameParent17}>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr23.svg"


                  />
                  <div className={styles.searchEngineMarketing}>
                  Google Ads
                  </div>
                </div>
                
               
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr24.svg"

                  />
                  <div className={styles.programmaticMarketing}>
                  Semrush
                  </div>
                </div>
    
              </div>
              <div className={styles.frameParent17}>
              <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr25.svg"

                  />
                  <div className={styles.programmaticMarketing}>
                  Bing Ads
                  </div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr26.svg"

                  />
                  <div className={styles.searchEngineMarketing}>
                  Spy Fu
                  </div>
                </div>
                
               
            
              
              </div>
   
              <div className={styles.frameParent17}>
          
                
               
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/fr27.svg"

                  />
                  <div className={styles.programmaticMarketing}>
                  Google Analytics
                  </div>
                </div>
              
              </div>

           
</div>
</>
  );
};

export default TechnologiesWeUseAndServiSocia;
