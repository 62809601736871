import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth from "../components/InterestedInTechrowth";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider from "../components/ClientsSlider";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions5 from "../components/SocialMediaMarketingSolutions5";
import Services5 from "../components/Services5";
import ProblemsWeSolved from "../components/ProblemsWeSolved";
import OtherServices06 from "../components/OtherServices06";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial4 from "../components/NewTestimonial4";
import { Link } from "react-router-dom";
import TechnologiesWeUseAndServiSocia4 from "../components/TechnologiesWeUseAndServiSocia4";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth112";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth12";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";
const SearchEngineMarketing = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Hero+Section-5.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M6.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Search Engine Marketing", path: "/digital-marketing/search-engine-marketing" },

    ]);
  }, []);

  const HeroTitel = "Search Engine Marketing";
  const HeroContent =
    "Boost your online visibility and drive targeted traffic with our powerful Search Engine Marketing services in Bangalore";
  const subject = "Search Engine Marketing ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/search-engine-marketing" />
<title>
Search Engine Marketing Company | SEM Services in Bangalore
</title>
<meta name="description" content="Leading search engine marketing company in Bangalore, India provides the best SEM services. in Bangalore by driving quality leads/sales to achieve your targeted ROI
" />
<meta name="keywords" content="SEM Company in Bangalore, Best SEM Company in Bangalore, Top SEM Company in Bangalore, SEM Agency in Bangalore, Best SEM Agency in Bangalore, Top SEM Agency in Bangalore, SEM Services in Bangalore, Best SEM Services in Bangalore, Search Engine Marketing Company in Bangalore, Search Engine Marketing Services in Bangalore, Search Engine Marketing Agency in Bangalore
" />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider/>
      <NewTestimonial4/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the potential of your online reach with the best search engine marketing company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions5 />
      <Services5/>
      <ProblemsWeSolved/>
      <TechnologiesWeUseAndServiSocia4/>

      <OtherServices06/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default SearchEngineMarketing;
