import React from "react";
import styles from "../Stories.module.css";


const TSlide3 = () => {
  return (
    <div class={styles.testimonial}>
                           

                           <div class="clients-testo my-auto ">
                                <iframe width="100%" height="280px"
                                    src="https://www.youtube.com/embed/AJ22DUYiISM?si=faTzz9joprEYIYoM" loading="lazy"
                                    title="See How Techrowth Grew Interior Company&#39;s Online Presence #shorts #digitalmarketing"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>




</div>
  );
};

export default TSlide3;
