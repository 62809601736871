import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth204";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues6 from "../components/OurCoreValues6";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain6 from "../components/OtherServicesmain6";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth224";
import SatisfiedClients from "../components/SatisfiedClients";

import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";

const UiUxInterface = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/UIUX.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M21.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
      { name: "UI/UX Interactive", path: "/it-services/uiux-services" },
    ]);
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const HeroTitel = "UI/UX Interactive Service";
  const HeroContent =
    "Transform your online business presence with the Best UI/UX Service in Bangalore. Our expert team crafts captivating, user-friendly interfaces to engage and captivate your customers.";
  const RecentText =
    "Interested in Getting In ? Discover how Techrowth can help grow your Business.";
  const subject = "UI/UX Service ?" 

  return (
    <>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your website's potential with our UI/UX Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
           <AccelerateYourGrowth />
           <OurCoreValues6/>
           <OtherServicesmain6/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default UiUxInterface;
