import styles from "./Otherservices3.module.css";
import { Link } from "react-router-dom";
const OtherServices = () => {
  return (
    <>
    <div className={styles.digitalMarketingServices}>
      <div className={styles.digitalMarketingServicesInner}>
        <div className={styles.frameParent}>
          <div className={styles.digitalMarketingServicesParent}>
         
            <div className={styles.intentWeServe}>Explore Digital Marketing Services</div>
          </div>
        
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>
           
            <Link to="/digital-marketing/intent-we-serve" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc9.svg"



                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Intent We Serve</p>
                </div>
              </div>
              </Link>
            <Link to="/digital-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc10.svg"



                />
                <div className={styles.socialMediaServicesContainer}>
                Digital Marketing  services
                </div>
              </div>
              </Link>
            <Link to="#" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc11.svg"



                />
                <div className={styles.socialMediaServicesContainer}>
                Graphic Design
                </div>
              </div>
              </Link>
            <Link to="/digital-marketing/ecommerce-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc13.svg"



                />
                <div className={styles.socialMediaServicesContainer}>
                E-Commerce
                </div>
              </div>
              </Link>
 
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className={styles.digitalMarketingServices1}>

      <p className={styles.businessAnalystics3}>Explore Digital Marketing Services</p>
    
              <div className={styles.wonder}>
       
          <div className="d-flex" style={{justifyContent:"center"}}>
            <Link to="/digital-marketing/intent-we-serve" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc9.svg"


                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Intent We Serve</p>
                </div>
              </div>
              </Link>
            <Link to="/digital-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc10.svg"


                />
                     <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Digital Marketing  services</p>
                </div>
            
              </div>
              </Link>
              </div>
          <div className="d-flex" style={{justifyContent:"center"}}>
            <Link to="#" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc11.svg"


                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Graphic Design</p>
                </div>
              </div>
              </Link>
            <Link to="/digital-marketing/ecommerce-marketing" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc13.svg"


                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>E-Commerce</p>
                </div>
              </div>
              </Link>
              </div>
   
</div>
</div>
</>
  );
};

export default OtherServices;
