import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>Graphic Designing Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc24.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Logo Design</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Techrowth specializes in providing outstanding logo design services that capture your brand's soul and make a lasting impression. With the intent of developing unique, memorable logos that connect with your target audience, our professional team of designers blends creativity and strategic thinking. We’ll help you design a visually attractive and enduring logo that accurately captures the essence of your brand.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc25.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Branding Material</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Techrowth specializes in providing comprehensive branding material services. Our team of experts produces compelling designs and marketing materials that are consistent with the identity and core values of your company. We make sure that your branding materials leave a long-lasting impression on your audience and help you stand out in an increasingly competitive marketplace, from logo design to brochures, business cards, and packaging. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc26.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Brochure</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Increase visibility, improve the rankings, and bring in more visitors`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                      Techrowth offers exceptional brochure design services to support your company's long-term success. To develop brochures that engage your target audience, our creative team combines stunning pictures, appealing information, and efficient layouts. Our knowledge will help your brochures stand out and successfully communicate your brand's message, products, and services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc27.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Company Profiles</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
            
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                         Techrowth offers expert services for designing corporate profiles. Our talented staff produces visually stunning profiles that effectively convey the strengths and history of your company. We develop great profiles that have an effect, from captivating content to the seamless integration of your brand aspects. Increase your brand's visibility and make a lasting impression on your stakeholders with Techrowth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
   
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc28.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Catalogs</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    We specialize in producing engaging catalogs that highlight the spirit of your company. Our talented team creates compelling catalogs that convey the essence of your company through powerful language and amazing imagery. We handle every aspect, from conception to execution, to provide designs that are suitable for both print and digital platforms. 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc29.svg"

                  />
                  <div className={styles.groupParent2}>
                  <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Photoshoot/ Video Graphics</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
            
           
                    <div className={styles.originalityIsOne}>
                    We are experts in producing attention-grabbing video graphics and stunning images. Our highly skilled team produces outstanding designs that are true to your brand and goals using cutting-edge tools. We understand the idea you have for engaging video graphics and expert photography. Turn your concepts into stunning visual works with us.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc30.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Social Media Poster</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Seamless transactions, secure payments`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
                       Our company specializes in producing visually appealing and powerful content that will improve your social media presence. Our creative team is aware of the importance of powerful poster designs for reaching a broad audience with your message. We have the posters you need for brand recognition, event announcements, or advertising campaigns. With our skillfully produced social media poster designs, stand out from the crowd and leave a memorable impression. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc31.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Video Production/ Editing</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Enhance customer experiences `}</div>
                      </div>
                    </div>
                
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
                         We create high-quality, engaging videos tailored to your needs. Our skilled staff makes use of the latest tools, from design to editing, to provide excellent outcomes. We understand your concept for promotional videos, business presentations, or event coverage. Get the most refined and compelling video content from us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>Graphic Designing Services We Serve</b>
          <p className={styles.ourOrigin123}>Increasing brand awareness, driving website traffic, generating leads, boosting sales, and building customer loyalty.
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc24.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Logo Design</b>
                        <div className={styles.consistentInGetting}>
                        Tell your story with captivating visuals
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Techrowth specializes in providing outstanding logo design services that capture your brand's soul and make a lasting impression. With the intent of developing unique, memorable logos that connect with your target audience, our professional team of designers blends creativity and strategic thinking. We’ll help you design a visually attractive and enduring logo that accurately captures the essence of your brand.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc25.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Branding Material</b>
                        <div className={styles.consistentInGetting}>
                        Elevate your brand with enticing listing
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Techrowth specializes in providing comprehensive branding material services. Our team of experts produces compelling designs and marketing materials that are consistent with the identity and core values of your company. We make sure that your branding materials leave a long-lasting impression on your audience and help you stand out in an increasingly competitive marketplace, from logo design to brochures, business cards, and packaging. 
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc26.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Brochure</b>
                        <div className={styles.consistentInGetting}>
                        Increase visibility and improve the rankings
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Techrowth offers exceptional brochure design services to support your company's long-term success. To develop brochures that engage your target audience, our creative team combines stunning pictures, appealing information, and efficient layouts. Our knowledge will help your brochures stand out and successfully communicate your brand's message, products, and services.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc27.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Company Profiles</b>
                        <div className={styles.consistentInGetting}>
                        Drive targeted traffic, maximize ROI 
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Techrowth offers expert services for designing corporate profiles. Our talented staff produces visually stunning profiles that effectively convey the strengths and history of your company. We develop great profiles that have an effect, from captivating content to the seamless integration of your brand aspects. Increase your brand's visibility and make a lasting impression on your stakeholders with Techrowth.
                    </div>
              </div>
           
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc28.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Catalogs</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We specialize in producing engaging catalogs that highlight the spirit of your company. Our talented team creates compelling catalogs that convey the essence of your company through powerful language and amazing imagery. We handle every aspect, from conception to execution, to provide designs that are suitable for both print and digital platforms. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc29.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Photoshoot/ Video Graphics</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We are experts in producing attention-grabbing video graphics and stunning images. Our highly skilled team produces outstanding designs that are true to your brand and goals using cutting-edge tools. We understand the idea you have for engaging video graphics and expert photography. Turn your concepts into stunning visual works with us.
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc30.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Social Media Poster</b>
                        <div className={styles.consistentInGetting}>
                        Seamless transactions, secure payments
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Our company specializes in producing visually appealing and powerful content that will improve your social media presence. Our creative team is aware of the importance of powerful poster designs for reaching a broad audience with your message. We have the posters you need for brand recognition, event announcements, or advertising campaigns. With our skillfully produced social media poster designs, stand out from the crowd and leave a memorable impression. 
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/abc31.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Video Production/ Editing</b>
                        <div className={styles.consistentInGetting}>
                        Enhance customer experiences 
                        </div>
                      </div>
                    </div>
              
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    We create high-quality, engaging videos tailored to your needs. Our skilled staff makes use of the latest tools, from design to editing, to provide excellent outcomes. We understand your concept for promotional videos, business presentations, or event coverage. Get the most refined and compelling video content from us.
                    </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
