import styles from "./BISCCBOR.module.css";
const BISCCBOR = () => {
const bisccbor = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/Biccbor.png";

  return (
    <>
    <div className={styles.bisccbor}>
      <div className={styles.main}>
        <div className={styles.heading}>
         
        <div className="container">
          <img className={styles.biss} src={bisccbor}  width={"100%"}/>
        </div>
          <div className={styles.experienceTheChange} style={{marginTop:"1rem"}}>
            Experience the Change
          </div>
        </div>
        <div className={styles.subheading}>
          <div style={{fontWeight:"500", textAlign:"justify"}}>
          BISCCBOR is a proven digital marketing strategy framework developed by experts of Techrowth with over 30 years of experience. This framework has been successfully implemented in 100+ businesses globally, resulting in a 17% reduction in the marketing budget and a 25% increase in business goals.

          </div>
        </div>

        <div className={styles.colContainer}>
          <div className={styles.col1}>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-366.svg" />
              <div className={styles.businessParent}>
                <div className={styles.bisccborIsA}>Business</div>
                <div className={styles.definingWhatYour}>
                  Defining what your business is and who your clients are paves
                  the pathway for an unprecedented growth plan with
                  result-driven strategies.
                </div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-373.svg" />
              <div className={styles.businessParent}>
                <div className={styles.bisccborIsA}>Intents</div>
                <div className={styles.businessIntentRepresents}>
                  Business intent represents the direction in which a company
                  intends to go with their digital marketing campaign. This
                  defines what the organization wants to achieve. 
                </div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-378.svg" />
              <div className={styles.businessParent}>
                <div className={styles.bisccborIsA}>Segment</div>
                <div className={styles.audienceSegmentationIsContainer}>
                  <p className={styles.audienceSegmentationIs}>
                    Audience segmentation is the process of dividing your target
                    market into smaller groups based on the goals of your
                    campaign. 
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-374.svg" />
              <div className={styles.businessParent}>
                <div className={styles.bisccborIsA}>Channels</div>
                <div className={styles.hereWeIdentifyContainer}>
                  <p className={styles.audienceSegmentationIs}>
                    Here, we identify the digital marketing channels that will
                    best serve your needs. depending on the choices
                    made here.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.col1}>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-379.svg" />
              <div className={styles.businessParent}>
                <div className={styles.bisccborIsA}>Communication</div>
                <div className={styles.aClearCommunication}>
                  A clear communication strategy strategizes your digital
                  marketing plan. This will establish how your business will
                  reach out.
                </div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-375.svg" />
              <div className={styles.businessParent}>
                <div className={styles.budget}>Budget</div>
                <div className={styles.afterTheOptimization}>
                  After the optimization, you'll realize a significant reduction
                  in budget costs compared to your original digital marketing
                  plans.
                </div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.groupWrapper}>
                <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-376.svg"/>
              </div>
              <div className={styles.businessParent}>
                <div className={styles.budget}>Optimization</div>
                <div className={styles.thisIsWhere}>
                  This is where we'll work our magic and optimize various
                  aspects of your campaign that will have a significant impact
                  on the success.
                </div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-377.svg" />
              <div className={styles.budgetParent}>
                <div className={styles.budget}>Results</div>
                <div className={styles.withOurApproach}>
                  With our approach, you'll notice a significant change from
                  your previous results and achieve your established goals. 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.achieveMaximumDigitalContainer}>
          <p className={styles.audienceSegmentationIsss} style={{fontWeight:"500"}}>
            <span>{`Achieve maximum digital marketing success by using `}</span>
            <span className={styles.easyToMeasure}>Easy-To-Measure</span>
            <span className={styles.strategiesAndSpecific}>
              {" "}
              strategies and specific metrics.
            </span>
          </p>
        </div>
      </div>
    </div>

</>
  );
};

export default BISCCBOR;
