import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthh23";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues4 from "../components/OurCoreValues4";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain4 from "../components/OtherServicesmain3";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthh223";
import SatisfiedClients from "../components/SatisfiedClients";
import { Helmet } from "react-helmet";
import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const Content = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/ContentImage.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M12.png";

  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Content", path: "/digital-marketing/content-marketing" },
    ]);
  }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
      const subject = "Content Writing?" 
      const HeroTitel = "Content Marketing";
      const HeroContent =
        "Showcase your brand effortlessly with our top-notch content marketing services in Bangalore. Bring your brand voice out to the public flawlessly.";
      const RecentText =
        "A Content Curation To Bring Your Ideas Out & Establish A Brand Voice with Authority! Consult our Experts now!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/content-marketing" />
<title>Content Marketing Services in Bangalore | Content Marketing Agency
</title>
<meta name="description" content="Techrowth's professional content marketing service in Bangalore empowers businesses to enhance your digital footprint. Boost your online traffic and stay ahead of the competition.
" />
<meta name="keywords" content="Content marketing Company, Content marketing Company in bangalore, Content marketing Services, Content marketing services in Bangalore, Content marketing agencies in bangalore, Content marketing agency, Content writing agency, Content marketing services, Content writing services,
" />
</Helmet>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the website traffic and engagement with the best content marketing services in Bangalore"
        btnTxt="Contact Us"
      />
      </Link>
           <AccelerateYourGrowth />
           <OurCoreValues4/>
           <OtherServicesmain4/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />

    </>
  )
}

export default Content