import React, { useState, useEffect } from "react";
import FullScreenButton from "./example16";
import TrustedByBrands from "../components/TrustedByBrands";
import "./PhoneDetector.css";
import 'react-phone-number-input/style.css'
import styles from '../components/ImageRow.module.css';
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Link } from "react-router-dom";




const PhoneDetector = () => {
  return (
    <>
    <div className={styles1.newHomepage}>
    <div className="portfolio-headings pt-4 pb-2" style={{width:"100%" }}>
      <div className="container">
        <div className="product-heading-display">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="product-header">
                <div className="row">
                  <div className="col-lg-1 col-2" style={{margin:"auto 0"}}>
                    <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/20.png" alt="" className="img-fluid" style={{width:"5rem"}}/>
                  </div>
                  <div className="col-lg-10 col-10">
                    <h2 className="mb-0" style={{ color: "#0076a2" }}>IZB</h2>
                    <h5 style={{ color: "#0076a2" }}>#Banking Sector <br/>
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}>Hire Us</span>
                    </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    
    </div>
1
    <div className="App">
      <FullScreenButton />
    </div>
    <div className="App1 container">
    <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture83.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
              <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture84.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture85.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture86.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture87.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />


    </div>
        <div className="container my-5">
    <div className="row" style={{marginTop:"10rem"}}>
        <div className="col-md-8 col-12">
        <h1 style={{ color: "#0076a2" }}>IZB</h1>
      <h5>Dive into our portfolio to see how we've helped banks connect with customers effortlessly. Our websites are designed to simplify financial transactions, provide easy access to services, and ensure a smooth and secure banking experience, putting your customers' needs first</h5>
   <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Sector : </span>Banking Sector</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Problem Statement : </span>Indo Zambia Bank, a prominent financial institution, approached us with a challenge. They needed a complete overhaul to stay competitive in the digital landscape. The initial website suffered from several shortcomings. Its old design no longer reflected the bank's modern outlook. The slow loading times frustrated visitors, causing a high bounce rate. The website lacked customization, making it difficult for the bank to showcase its unique offerings. And, the user-unfriendly interface hindered seamless navigation.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Solution : </span>Techrowth stepped in with a mission to transform Indo Zambia Bank's online presence. We designed a fresh, contemporary website that perfectly encapsulated Indo Zambia Bank's brand vision and identity. Our team optimized the site for lightning-fast loading, ensuring a smooth browsing experience, even on mobile devices. 
<br/><br/>Customization was a priority. We built a user-friendly content management system (CMS) that allowed the bank's team to effortlessly update content, ensuring the site remained fresh and relevant. Our user-centric approach led to an intuitive and easy-to-navigate website, enhancing the overall user experience. Techrowth's expertise and innovative solutions completely revamped the Indo Zambia Bank website, propelling our client towards new horizons.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Tools and Techniques : </span>ReactJS, Email JS, Bootstrap</h5>





      <h5 style={{ color: "#0076a2",fontWeight:"600" }}>Need a Website ? 
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}> Hire Us</span>
                    </Link>
                    </h5>
        </div>
        <div className="col-md-4 col-12" style={{height:"48rem", overflowY:"scroll",border:"2px solid #e9eef4", padding:"10px"}}>
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Fullscreen/20.jpg" alt="Image 2" className="img-fluid"/>

        </div>
      </div>

                    <hr className="my-5" style={{ color: "#0076a2" }} />
                    <h4>You might also like</h4>
                    <div className={styles.imageContainer}>

                    <Link to="/techrowth-portfolio/grandiose-art" style={{textDecoration:"none", color:"black"}}>
    
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/17.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/12.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Grandiose Art</p>
          </div>
        </div>
  
    </Link>
    <Link to="/techrowth-portfolio/venus-kart" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/18.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/13.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Venus Kart</p>
          </div>
        </div>
       
      </Link>
      <Link to="/techrowth-portfolio/techrowth" style={{textDecoration:"none", color:"black"}}>
     
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/19.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/15.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Techrowth</p>
          </div>
        </div>
      
        </Link>
      </div>
    </div>
    <div className={styles1.newHomepage}>

    <ContactForm1/></div>
      <TrustedByBrands />
      </>
  );

};





export default PhoneDetector;
