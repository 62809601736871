import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's Website Accessibility Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Create an inclusive online presence and comply with accessibility regulations. `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Expertise in Website Accessibility</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth's team has specialized knowledge and expertise in website accessibility standards, including the Web Content Accessibility Guidelines (WCAG). They are well-versed in the best practices and techniques to ensure that your website is accessible to all users, including those with disabilities.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Compliance with Accessibility Regulations`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth's Website Accessibility Service ensures that your website complies with accessibility regulations, such as the Americans with Disabilities Act (ADA) in the United States or similar laws in other countries. This helps protect your business from potential legal issues and demonstrates your commitment to inclusivity.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Improved User Experience</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Website accessibility is not just about compliance; it also enhances the user experience for all visitors. Techrowth optimizes your website's design and functionality to make it user-friendly and easy to navigate for people with disabilities, as well as those using different assistive technologies.</p>
              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Future-Proofing Your Website</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth's Website Accessibility Service ensures that your website remains accessible as technologies and guidelines evolve. By implementing best practices, your website is prepared for future accessibility requirements and can adapt to changes in user needs.</p>
      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Positive Brand Image`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Demonstrating a commitment to accessibility and inclusivity can enhance your brand image and reputation. By choosing Techrowth's Website Accessibility Service, you show your customers and stakeholders that your organization values diversity and strives to create an inclusive online environment.</p>
      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Comprehensive Accessibility Audit</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth conducts a thorough accessibility audit of your website, identifying any accessibility barriers or issues. Based on the audit findings, they provide recommendations and implement the necessary changes to ensure compliance and accessibility.</p>
    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's Website Accessibility Services</h4>
      <p className={styles.businessAnalystics3}>Create an inclusive online presence and comply with accessibility regulations. </p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Expertise in Website Accessibility</h4>
      <p className={styles.cms1}>Techrowth's team has specialized knowledge and expertise in website accessibility standards, including the Web Content Accessibility Guidelines (WCAG). They are well-versed in the best practices and techniques to ensure that your website is accessible to all users, including those with disabilities.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Compliance with Accessibility Regulations</h4>
      <p className={styles.cms1}>Techrowth's Website Accessibility Service ensures that your website complies with accessibility regulations, such as the Americans with Disabilities Act (ADA) in the United States or similar laws in other countries. This helps protect your business from potential legal issues and demonstrates your commitment to inclusivity.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Improved User Experience</h4>

      <p className={styles.cms1}>Website accessibility is not just about compliance; it also enhances the user experience for all visitors. Techrowth optimizes your website's design and functionality to make it user-friendly and easy to navigate for people with disabilities, as well as those using different assistive technologies.</p>
     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Future-Proofing Your Website</h4>
      
      <p className={styles.cms1}>Techrowth's Website Accessibility Service ensures that your website remains accessible as technologies and guidelines evolve. By implementing best practices, your website is prepared for future accessibility requirements and can adapt to changes in user needs.</p>
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Positive Brand Image</h4>
      <p className={styles.cms1}>Demonstrating a commitment to accessibility and inclusivity can enhance your brand image and reputation. By choosing Techrowth's Website Accessibility Service, you show your customers and stakeholders that your organization values diversity and strives to create an inclusive online environment.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Comprehensive Accessibility Audit</h4>
 
      <p className={styles.cms1}>Techrowth conducts a thorough accessibility audit of your website, identifying any accessibility barriers or issues. Based on the audit findings, they provide recommendations and implement the necessary changes to ensure compliance and accessibility.</p>


</div>


    
</div>
</>
  );
};

export default Services;
