import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case1.module.css";
import styless from "../components/ContactUsBar.module.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import Test from "../components/test10"
import Test1 from "../components/testph10"
import Test12 from "../components/Test12";
import Test13 from "../components/Test13";
import Test14 from "../components/Test14";
import { Helmet } from "react-helmet";
const Blog5 = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Case+Study+1.pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>

<Helmet>
<meta charSet="utf-8" />
<title>PPC Budget & Bid Tactics: Maximizing ROI for your Campaign


</title>
<meta name="description" content="Discover top-tier social media marketing in Bangalore. Enhance your brand's success with our expert strategies." />
</Helmet>

        <div className={styles1.newHomepage}>
        <Test14 />

      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default Blog5;
