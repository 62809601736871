import styles from "./InterestedDevelopment6.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Pay Per Click Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`Technological Infrastructure We Employ. `}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/g6.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>App Downloads</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Boost app downloads and increase user acquisition<br/> with our proven strategies and expertise in app marketing. 

                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                  <b className={styles.eCommercePortals}>
                      10k+
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` App Downloads`}</span>
                  
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page42.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Lead Generation
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Achieve exceptional lead generation results with our strategic approach, delivering quality leads that fuel business growth.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Lead Generation increased by `}</span>
                  <b className={styles.eCommercePortals}>
                  37%
                    </b>
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
            <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page44.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Conversion Rate
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Turning potential customers into valuable customers for your business.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Increased conversion rate by `}</span>
                      <b className={styles.websites}>
                      43%
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page43.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  Google Shop

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    With Google Shop, you can improve your online retail presence while reaching millions of potential customers and boosting your sales.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                    <b className={styles.websites}>32%</b>

                      <span
                        className={styles.weHaveDeveloped}
                      >{` rise in conversion rate`}</span>
                     

                    </p>
                  </div>
                </div>
              </div>
           
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
