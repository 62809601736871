import React from "react";
import styles from "../Stories.module.css";


const TSlide2 = () => {
  return (
    <div class={styles.testimonial}>
                           

                           <div class="clients-testo my-auto ">
                                <iframe width="100%" height="280px"
                                    src="https://www.youtube.com/embed/QODghhfS_n4?si=i4BOBs42nboEGjTA"
                                    title="How Techrowth Helped a School Get More Enrollments. #shorts #digitalmarketing"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen loading="lazy"></iframe>

                            </div>




</div>
  );
};

export default TSlide2;
