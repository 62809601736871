import styles from "./TechnologiesWeUseAndServiSocia.module.css";
const TechnologiesWeUseAndServiSocia = () => {
  return (
    <>
    <div className={styles.technologiesWeUseAndServi }>
      <div className={styles.technologiesWeUse }>
        <div className={styles.frameParent }>
        <div className={styles.frameParent8}>
            <div className={styles.servicesWeServeParent}>
              <b className={styles.servicesWeServe} style={{fontSize:"35.04px", fontWeight:"600"}}>Digital Marketing Services We Serve</b>
              <div className={styles.technologicalInfrastructureW}>
                Technological Infrastructure We Employ.
              </div>
            </div>
            <div className="container ">
            <div className={styles.frameParent9}>
              <div className={styles.frameParent10}>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-442%402x.png"
                  />
                  <div className={styles.socialMediaMarketing}>
                    Social Media Marketing
                  </div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-443%402x.png"
                  />
                  <div className={styles.technologiesWeUseWrapper}>
                    <div className={styles.searchEngineOptimization}>
                      Search Engine Optimization
                    </div>
                  </div>
                </div>

                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-134%402x.png"
                  />
                  <div className={styles.searchEngineMarketing}>
                    Search Engine Marketing
                  </div>
                </div>
               
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-447%402x.png"
                  />
                  <div className={styles.technologiesWeUseWrapper}>
                    <div className={styles.searchEngineOptimization}>
                      APP Store Optimization
                    </div>
                  </div>
                </div>
          
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-445%402x.png"
                  />
                  <div className={styles.technologiesWeUseWrapper}>
                    <div className={styles.socialMediaOptimization}>
                      Social Media Optimization
                    </div>
                  </div>
                </div>
               
              </div>
              <div className={styles.frameParent10}>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-446%402x.png"
                  />
                  <div className={styles.socialMediaMarketing}>
                  Influencer Marketing
                  </div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-131%402x.png"
                  />
                  <div className={styles.technologiesWeUseWrapper}>
                    <div className={styles.searchEngineOptimization}>
                    Email Marketing
                    </div>
                  </div>
                </div>

                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-444%402x.png"
                  />
                  <div className={styles.searchEngineMarketing}>
                  PPC Services
                  </div>
                </div>
               
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-136%402x.png"
                  />
                  <div className={styles.technologiesWeUseWrapper}>
                    <div className={styles.searchEngineOptimization}>
                    Performance Marketing
                    </div>
                  </div>
                </div>
          
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/frame-137%402x.png"
                  />
                  <div className={styles.technologiesWeUseWrapper}>
                    <div className={styles.socialMediaOptimization}>
                    Programmatic Marketing
                    </div>
                  </div>
                </div>
               
              </div>
          
            </div>
            </div>
          </div>
       
        
        </div>
      </div>
    </div>
    <div className={styles.technologiesWeUseAndServi1 }>
 
            <h4 className={styles.businessAnalystics2} style={{fontWeight:"600"}}>Digital Marketing Services We Serve</h4>
      <p className={styles.businessAnalystics3} style={{fontWeight:"500"}}>Technological Infrastructure We Employ.</p>
      <div className={styles.frameParent17}>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-442%402x.png"

                  />
                  <div className={styles.searchEngineMarketing} style={{fontWeight:"500"}}>
                  Social Media Marketing
                  </div>
                </div>
                
               
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-443%402x.png"
                  />
                  <div className={styles.programmaticMarketing} style={{fontWeight:"500"}}>
                  Search Engine Optimization
                  </div>
                </div>
               
              </div>
              <div className={styles.frameParent17}>
              <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-131%402x.png"
                  />
                  <div className={styles.programmaticMarketing} style={{fontWeight:"500"}}>
                  Email Marketing
                  </div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-444%402x.png"
                  />
                  <div className={styles.searchEngineMarketing} style={{fontWeight:"500"}}>
                  PPC Services
                  </div>
                </div>
                
               
               
               
              </div>
              <div className={styles.frameParent17}>
              <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-445%402x.png"
                  />
                  <div className={styles.programmaticMarketing} style={{fontWeight:"500"}}>
                  Social Media Optimization
                  </div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-134%402x.png"
                  />
                  <div className={styles.searchEngineMarketing} style={{fontWeight:"500"}}>
                    Search Engine Marketing
                  </div>
                </div>
                
                </div>
              <div className={styles.frameParent17}>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-136%402x.png"
                  />
                  <div className={styles.programmaticMarketing} style={{fontWeight:"500"}}>
                  Performance Marketing
                  </div>
                </div>
                <div className={styles.image2Parent}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-447%402x.png"
                  />
                  <div className={styles.programmaticMarketing} style={{fontWeight:"500"}}>
                  APP Store Optimization
                  </div>
                </div>
              </div>

</div>
</>
  );
};

export default TechnologiesWeUseAndServiSocia;
