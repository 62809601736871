import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case.module.css";
import styless from "../components/ContactUsBar.module.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import Test from "../components/testnew1"
import Test1 from "../components/testphnew1"
import { Helmet } from "react-helmet";

const CaseStudy1 = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/10+Ways+to+Use+social+media+for+Education+Institutions+(1).pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>
        <Helmet>
<meta charSet="utf-8" />
{/* <title>Social Media for Education Institutions</title> */}
{/* <meta name="description" content="Learn how startups can leverage social media to build brand recognition, engage customers, drive traffic, and achieve sustainable growth using social media marketing" /> */}
</Helmet>
        <div className={styles1.newHomepage}>
          <div className={styles1.ph}>
          <Test />

          </div>
          <div className={styles1.ph1}>
          <Test1 />

</div>

      <div className={styless.wonderr1} style={{width:"65rem"}}>
    
    <div className="container my-5" style={{ padding: "0 4rem" }}>
      <div
        className="row d-flex p-5"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          borderRadius: "30px",
        }}
      >
        <div
          className="col-8 my-1 a p-0 text-white text-left d-flex align-items-center"
          style={{ fontSize: "18px" }}
        >
          Want to Study this closely?
        </div>
        <div className="col-4 text-center d-flex justify-content-end align-items-center">
          <button
            type="button"   onClick={handleDownload}
            className="btn btn-light w-75"
            style={{ borderRadius: "15px", color: "#0076A2" }}
          >
            <b>Download Now</b>
          </button>
        </div>
      </div>
    </div>
    </div>
    <div className={styless.wonderr}>

    <div className={styless.wonder}>
  <p className={styless.wonder1}>
  Want to Study this closely?
</p>
<button className={styless.letsGetStartedWrapper + "w-auto mb-3"}   onClick={handleDownload} style={{borderRadius:"30px",padding:".5rem 1rem", color:"#0076A2",background:"white", border:"none",textAlign:"center"}}>
                <div className={styless.letsGetStarted}>Download Now</div>
              </button>
</div>
</div>
      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default CaseStudy1;
