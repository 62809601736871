import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"1rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">

<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
Revitalizing Engagement through Social media tools resulting in increased bookings.

</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study3.png" alt="D" width="100%"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>
Transportation company.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The transportation service company was struggling with low engagement for their AC buses in comparison to their Non-AC buses. Due to a lack of amenities and available comfort, ca using customers to choose the Non-AC option instead.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>Increased overall engagement by 78%.</p>







<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>
<p>The company executed an emotional marketing strategy to increase engagement and bookings for AC buses. The <Link to="https://techrowth.com/digital-marketing">digital marketing strategy</Link> included:</p>
<p><strong>1) Identifying the Issue:</strong></p>
<ul>

<li>Lack of Amenities and Comfort - The company identified that the lace of basic amenities and comfort was the main reason for the low engagement of AC buses.</li>
</ul>
<p><strong>2) Upgrading Amenities:</strong></p>
<ul>
<li>Wash room, Ventilation, WIFI - The company upgraded basic amenities like washrooms, ventilation, and WIFI to provide more comfort to passengers.</li>

</ul>
<p><strong>3) Adding Services:</strong></p>
<ul>
<li>Snacks, Newspapers, Books, Comics - Additional services such as snacks, newspapers, boons, and comics were added to enhance the passenger experience.</li>

</ul>

<p><strong>4) Providing Offers:</strong></p>
<ul>
<li>Coupons and Discounts - The company provided offers and coupons to customers to encourage the booking of AC buses.</li>

</ul>
<p><strong>5) Securing Brand Partners:</strong></p>
<ul>
<li>Increased Brand Visibility and Loyalty More brand partners were secured to increase brand visibility and loyalty.</li>

</ul>
<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>

<li>The executed emotional marketing strategy resulted in increased bookings by 71%.</li>
<li>78% increase in social media engagement for the transportation service company.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>
<li><Link to="https://techrowth.com/digital-marketing/social-media-marketing">Social media</Link> platforms were used as tools to execute the emotional marketing strategy to reach a larger audience online.</li>
<li>Utilized tools like SEM Rush, Meta Business Suite and Google Analytics for analyzing the result of the campaign.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>
<li>
$24K meant an extended engagement with our team, leading to their desired effect in turn.</li>

</ul>

</div>


</section>
</section>
</div>


    </>
  );
};

export default Test;
