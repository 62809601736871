import styles from "./IndustiresWorkedWIth.module.css";
const IndustiresWorkedWIth = () => {
  return (
    <>
    <div className={styles.industiresWorkedWith}>
      <div className={styles.industriesWeveWorkedWithParent}>
        <div className={styles.industriesWeveWorked}>
          Industries we’ve worked with
        </div>
        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-556.svg" />
              <div className={styles.automotive}>Automotive</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupChild} />
              <div className={styles.groupItem} />
            </div>
            <div className={styles.frameDiv}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-557.svg" />
              <div className={styles.automotive}>Banking</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupChild} />
              <div className={styles.groupItem} />
            </div>
            <div className={styles.groupParent2}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-558.svg" />
              <div className={styles.automotive}>Financial</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupChild} />
              <div className={styles.groupItem} />
            </div>
            <div className={styles.groupParent4}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-559.svg" />
              <div className={styles.automotive}>Healthcare</div>
            </div>
          </div>
        </div>
        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
            <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Realstates.png" />
              <div className={styles.automotive}>Real Estate</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupChild} />
              <div className={styles.groupItem} />
            </div>
            <div className={styles.frameDiv}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-774.svg" />
              <div className={styles.automotive}>Manufacturing</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupChild} />
              <div className={styles.groupItem} />
            </div>
            <div className={styles.groupParent2}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group+(2).svg" />
              <div className={styles.automotive}>Retail</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupChild} />
              <div className={styles.groupItem} />
            </div>
            <div className={styles.groupParent4}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-568.svg" />
              <div className={styles.automotive}>Travel</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div className={styles.industiresWorkedWith1}>
   
        <div className={styles.industriesWeveWorked}>
          Industries we’ve worked with
        </div>
        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-556.svg" />
              <div className={styles.automotive}>Automotive</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-557.svg" />
              <div className={styles.automotive}>Banking</div>
            </div>
          </div>
       
        </div>


        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-558.svg" />
              <div className={styles.automotive}>Financial</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
              <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-559.svg" />
              <div className={styles.automotive}>Healthcare</div>
            </div>
          </div>
       
        </div>


        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
             <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Realstates.png" />
              <div className={styles.automotive}>Real Estate</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
             <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-774.svg" />
              <div className={styles.automotive}>Manufacturing</div>
            </div>
          </div>
       
        </div>


        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
             <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group+(2).svg" />
              <div className={styles.automotive}>Retail</div>
            </div>
          </div>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
             <div className={styles.groupContainer}>
             <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-568.svg" />
              <div className={styles.automotive}>Travel</div>
            </div>
          </div>
       
        </div>
</div>
</>
  );
};

export default IndustiresWorkedWIth;
