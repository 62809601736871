import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's Web Application Development Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Create robust, user-friendly, and innovative applications`}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Expertise in Web Application Development </div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth's team comprises skilled developers with extensive expertise in web application development. They stay up-to-date with the latest technologies and best practices, ensuring that your web application is built using cutting-edge solutions.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`User-Centric Approach on Web Application`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>User experience is at the forefront of Techrowth's web application development process. They prioritize intuitive navigation, smooth workflows, and interactive interfaces, ensuring an excellent user experience that keeps visitors engaged and satisfied.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Scalability and Flexibility</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth designs web applications with scalability in mind, allowing your application to adapt and grow as your business evolves. Whether you need to handle increased user traffic or introduce new features, their web applications can easily accommodate your changing needs.</p>
              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Rigorous Testing and Quality Assurance</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth follows a rigorous testing and quality assurance process to identify and fix potential issues in the web application before launch. This guarantees that your application is stable, secure, and performs optimally across different scenarios.</p>
      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Timely Delivery and Support`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth is committed to delivering web application projects on time and within budget. Their project management expertise and dedicated support team ensure that your web application runs smoothly and receives ongoing maintenance and updates.</p>
      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Security and Data Protection</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth prioritizes web application security and implements robust measures to protect your application from cyber threats and data breaches. Their focus on security ensures that your web application and user data remain safeguarded.</p>
    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's Web Application Development Services</h4>
      <p className={styles.businessAnalystics3}>Create robust, user-friendly, and innovative applications </p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Expertise in Web Application Development </h4>
      <p className={styles.cms1}>Techrowth's team comprises skilled developers with extensive expertise in web application development. They stay up-to-date with the latest technologies and best practices, ensuring that your web application is built using cutting-edge solutions.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>User-Centric Approach on Web Application</h4>
      <p className={styles.cms1}>User experience is at the forefront of Techrowth's web application development process. They prioritize intuitive navigation, smooth workflows, and interactive interfaces, ensuring an excellent user experience that keeps visitors engaged and satisfied.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Scalability and Flexibility</h4>

      <p className={styles.cms1}>Techrowth designs web applications with scalability in mind, allowing your application to adapt and grow as your business evolves. Whether you need to handle increased user traffic or introduce new features, their web applications can easily accommodate your changing needs.</p>
     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Rigorous Testing and Quality Assurance</h4>
      
      <p className={styles.cms1}>Techrowth follows a rigorous testing and quality assurance process to identify and fix potential issues in the web application before launch. This guarantees that your application is stable, secure, and performs optimally across different scenarios.</p>
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Timely Delivery and Support</h4>
      <p className={styles.cms1}>Techrowth is committed to delivering web application projects on time and within budget. Their project management expertise and dedicated support team ensure that your web application runs smoothly and receives ongoing maintenance and updates.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Security and Data Protection</h4>
 
      <p className={styles.cms1}>Techrowth prioritizes web application security and implements robust measures to protect your application from cyber threats and data breaches. Their focus on security ensures that your web application and user data remain safeguarded.</p>


</div>


    
</div>
</>
  );
};

export default Services;
