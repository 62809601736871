import Slider from "react-slick";
import styles from "./ToolsSlider.module.css";
// import LeftArrow from "../assets/group-358.svg";
// import RightArrow from "../assets/group-359.svg";
const ClientsSlider = () => {
  const LeftArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-358.svg";
  const RightArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-359.svg";
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        onClick={onClick}
        alt=""
        style={{
          ...style,
          display: "none",
          width: "40px",
          height: "40px",
          left: "0px",
        }}
        src={LeftArrow}
      />
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={RightArrow}
        className={className}
        style={{
          ...style,
          display: "none",
          width: "40px",
          height: "50px",
          right: "0px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container" style={{margin:"1rem 2rem 2rem 2rem"}}>
      <div className={styles.interestedinsoftwaredevelopmenInner}>
        <div className={styles.frameParent}>
          <div className={styles.toolsParent}>
            <b className={styles.tools} >Join the Ranks of Our Satisfied Clients</b>
            {/* <div className={styles.weAreProficient}>We are proficient in</div> */}
          </div>

          <div className="container">
            <div className="row d-block align-items-center">
              <Slider {...settings}>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/1.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/2.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/3.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/4.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/5.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/6.png"
                  />
                </div>
              </Slider>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ClientsSlider;
