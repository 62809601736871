import styles from "./InterestedDevelopment10.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Social Media Marketing Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`Technological Infrastructure We Employ. `}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+814.svg"
                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Social Media Engagement</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Utilizing social media to its fullest capacity in order<br/> to engage the audience, and build partnerships.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Increased Social Media Engagement by `}</span>
                    <b className={styles.eCommercePortals}>
                      21%
                    </b>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+819.svg"
                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Brand Management
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Create strong brand engagement and meaningful connections with competent social media marketing.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  <b className={styles.eCommercePortals}>
                  12k+
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` unique elements created`}</span>
                  
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+816.svg"
                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  Influencer Marketing

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Amplifying your brand's impact through authentic collaborations and influential voices.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Increase in Website traffic from `}</span>
                      <b className={styles.websites}>13% </b>
                      <span className={styles.weHaveDeveloped}>to</span>
                      <b className={styles.websites}> 21%</b>

                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+819.svg"
                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Lead Generation
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Getting a steady stream of qualified prospects and growth opportunities.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Increased leads by `}</span>
                      <b className={styles.websites}>
                      27%
                      </b>
                    </p>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
