import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth’s Social Media Marketing Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Engaging social media campaigns, tailored to boost your brand `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Comprehensive Marketing Expertise</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Our team of seasoned social media strategists and content writers has years of expertise in the field and vast knowledge of it. They keep ahead of the most recent social media trends, ensuring that your campaigns are designed with skill and accuracy. Their collaborative approach ensures that every aspect of your social media presence aligns seamlessly and enhances brand consistency</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Tailored Digital Marketing Strategies`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>We believe in the power of personalization. Our team works on our unique digital marketing strategy framework 'BISCCBOR' to understand your business goals, target audience, and industry nuances. This in-depth understanding of your business BISCCBOR allows us to create tailored social media strategies.</p>
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Engaging and Shareable Content</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Our creative team is exceptional at creating captivating content that engages your target audience. We make sure that every piece of material is thoughtfully selected, from appealing images to compelling descriptions, to complement your brand identity and promote organic sharing and conversation.</p>
              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Real-Time Performance Reporting</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>For us, transparency is fundamental. We offer thorough, real-time performance reports that list the most significant stats and results of your social media efforts. These reports serve as a vital tool for decision-making and ongoing improvement because they not only highlight the progress made but also provide insightful information about how well our strategies are working.</p>
      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Return on Investment Focused`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>We are results-driven and work constantly to provide a significant return on your investment. Our strategies are expertly developed to maximise your social media presence, enhance website traffic and conversions, and eventually raise your revenue. We carefully track important performance factors and adapt based on data to maximise your ROI.</p>
      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Building Brand Authority</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>We are aware of how crucial it is to position your brand as the leading voice in your sector. Through strategically executed campaigns, we provide significant that highlights your knowledge, offers helpful feedback, and establishes your brand as a respected authority in the industry. We strengthen connections with your audience and set you apart from competition by fostering brand authority.</p>
    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth’s Social Media Marketing Services</h4>
      <p className={styles.businessAnalystics3}>Engaging social media campaigns, tailored to boost your brand </p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Comprehensive Marketing Expertise</h4>
      <p className={styles.itServices12345}>Our team of seasoned social media strategists and content writers has years of expertise in the field and vast knowledge of it. They keep ahead of the most recent social media trends, ensuring that your campaigns are designed with skill and accuracy. Their collaborative approach ensures that every aspect of your social media presence aligns seamlessly and enhances brand consistency</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Tailored Digital Marketing Strategies</h4>
      <p className={styles.itServices12345}>We believe in the power of personalization. Our team works on our unique digital marketing strategy framework 'BISCCBOR' to understand your business goals, target audience, and industry nuances. This in-depth understanding of your business BISCCBOR allows us to create tailored social media strategies.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Engaging and Shareable Content</h4>
      <p className={styles.itServices12345}>Our creative team is exceptional at creating captivating content that engages your target audience. We make sure that every piece of material is thoughtfully selected, from appealing images to compelling descriptions, to complement your brand identity and promote organic sharing and conversation.</p>

     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Real-Time Performance Reporting</h4>
      <p className={styles.itServices12345}>For us, transparency is fundamental. We offer thorough, real-time performance reports that list the most significant stats and results of your social media efforts. These reports serve as a vital tool for decision-making and ongoing improvement because they not only highlight the progress made but also provide insightful information about how well our strategies are working.</p>
      
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Return on Investment Focused</h4>
      <p className={styles.itServices12345}>We are results-driven and work constantly to provide a significant return on your investment. Our strategies are expertly developed to maximise your social media presence, enhance website traffic and conversions, and eventually raise your revenue. We carefully track important performance factors and adapt based on data to maximise your ROI.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Building Brand Authority</h4>
      <p className={styles.itServices12345}>We are aware of how crucial it is to position your brand as the leading voice in your sector. Through strategically executed campaigns, we provide significant that highlights your knowledge, offers helpful feedback, and establishes your brand as a respected authority in the industry. We strengthen connections with your audience and set you apart from competition by fostering brand authority.</p>
 


</div>


    
</div>
</>
  );
};

export default Services;
