import React from "react";
import styles from "../Stories3.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div4}>
<div>
  <img alt="" className={styles.div444}   src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth-ZM/izb-team.jpeg"/>
</div>
<div className={styles.div4444} >
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/logo-1%402x.png" />
      <div className={styles.weHaveBeenUsingTechrowthFParent}>
        <div className={styles.weHaveBeenContainer}>
          <p
            className={styles.revolutionizingTraditionalBa}
          >{`"We have been using Techrowth for our Digital Marketing & Digital Assets development needs; it has been completely satisfied with their product capabilities, services, and support.We are happy to recommend Techrowth Private Limited, formerly Proxy Digital Solutions and services."`}</p>
        </div>
        <img className={styles.groupIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group2.svg" />
      </div>
      <div className={styles.rectangleDiv} />
      </div>
    </div>
  );
};

export default TSlide1;
