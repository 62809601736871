import React, { useEffect, useState } from "react";
import ItMain from "../components/ItMain";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactUsForm from "../components/ContactUsForm";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import Testimonial1 from "../components/Testimonial1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar123";
import OneStopSolution1 from "../components/OneStopSolution1";
import styles from "./NewHomepage.module.css";
import { Link } from "react-router-dom";
import Testimonial from "../components/Testimonial";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import SatisfiedClients from "../components/SatisfiedClients";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth1";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";

const ITMain = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/newitMainbg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M13.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
    ]);
  }, []);
  const HeroTitel =
    "IT Services";
  const RecentText = "Ready to boost your business revenue with the best IT services in Bangalore? Book a free consultation Now.";

  const HeroContent =
    "IT services can help your ideas come to life, enhance user interaction, and help you achieve your business goals. With our exclusive IT services in Bangalore, we can help your ideas reach fruition, enhance user interaction, and bring you closer to your business goals.";
  return (
    <>
       <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/it-services" />
<title>Techrowth provides the best IT services in Bangalore</title>
<meta name="description" content="Techrowth, a leading IT service provider in Bangalore, delivers reliable and efficient solutions from development to quality testing. Get in touch with us now." />
</Helmet>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
           <div className={style.test1}>
      <OneStopSolution1 text={RecentText} btnText="Book a Consultation!" />
    <SatisfiedClients/>
    <Testimonial/>

    <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>

      <ContactUsBar content={RecentText} btnTxt="Contact Us" />
    </Link>
   
    <AccelerateYourGrowth />
      </div>
      <ItMain />

      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="We cover it all. Book a free consultation!"
        btnTxt="Contact Us"
      />
      </Link>
    
       <div className={styles.newHomepage}>
      <ContactForm1/>
      <ExploreOurCaseStudies />
</div>
      <TrustedByBrands/>
    </>
  );
};

export default ITMain;
