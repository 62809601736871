import styles from "./OneStopSolution1.module.css";
import NumberCounter from "number-counter";
import styles1 from "./ItMain.module.css";

const OneStopSolution1 = ({ text, text2, btnText }) => {
  return (
    <>

    <div className={styles1.itMain}>
    <div className={styles1.frameParent}>
        <div className={styles1.oneStopSolutionParent}>
          <b className={styles1.oneStopSolution} style={{fontWeight:"600"}}>One Stop Solution</b>
          <div
            className={styles1.resultDrivenIt}
          >{`Result driven IT & Digital Marketing Company in Bangalore`}</div>
        </div>
        <div className={styles1.frameGroup}>
          <div className={styles1.parent}>
            <div className={styles1.div}>7+</div>
            <div className={styles1.yearsOfAverageContainer}>
              <p className={styles1.yearsOfAverage}>Years of Average</p>
              <p className={styles1.yearsOfAverage}>Team Experience</p>
            </div>
          </div>

          <div className={styles1.parent}>
            <div className={styles1.div}>100+</div>
            <div className={styles1.yearsOfAverageContainer}>
            Clients across<br/> 5 countries
            </div>
          </div>
          <div className={styles1.parent}>
            <div className={styles1.technology}>500+</div>
            <div className={styles1.clientTestimonials}>Projects</div>
          </div>
          <div className={styles1.parent}>
            <div className={styles1.div}>100%</div>
            <div className={styles1.yearsOfAverageContainer}>
              Proven Track <br></br>Record
            </div>
          </div>
        </div>
      </div>
   </div>

    
    <div className={styles.onestopsolution1}>
    <h4 className={styles.businessAnalystics2}>One Stop Solution</h4>
      <p className={styles.businessAnalystics3}>Result driven IT & Digital Marketing Company in Bangalore</p>
      <div className={styles.frameGroup}>
          <div className={styles.parent}>
      

          <div className={styles.div}>7+</div>
            <div className={styles.yearsOfAverageContainer}>
              <p className={styles.yearsOfAverage}>
                Years of Average <br /> Team Experience
              </p>
            </div>
          </div>
          <div className={styles.parent}>
         

          <div className={styles.div1}>100+</div>
            <div className={styles.yearsOfAverageContainer1}>
              <p className={styles.yearsOfAverage}>
              Clients across<br />
5 countries
       
              </p>
            </div>
          </div>
        
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.parent}>
            {/* <NumberCounter
              end={7}
              delay={1}
              className={styles.div}
              postFix="+"
            /> */}

          <div className={styles.div2}>500+</div>
            <div className={styles.yearsOfAverageContainer2}>
              <p className={styles.yearsOfAverage}>
                 Projects
              </p>
            </div>
          </div>
          <div className={styles.parent}>
            {/* <NumberCounter
              end={7}
              delay={1}
              className={styles.div}
              postFix="+"
            /> */}

          <div className={styles.div3}>100%</div>
            <div className={styles.yearsOfAverageContainer3}>
              <p className={styles.yearsOfAverage}>
                Proven Track <br /> Record
              </p>
            </div>
          </div>
        
        </div>
    </div>
    </>
  );
};

export default OneStopSolution1;
