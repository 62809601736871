import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's Social Media Optimization Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Drive targeted traffic and increase conversions `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Targeted Social Media Strategies</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth develops highly targeted SMO strategies tailored to your business's objectives and target audience. Whether you want to increase brand awareness, drive website traffic, generate leads, or improve customer engagement, their team will create a customized plan to achieve your specific goals.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Social Media Platform Expertise `}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Social media platforms each have their unique characteristics and user demographics. Techrowth's SMO service includes expertise in major platforms such as Facebook, Instagram, Twitter, LinkedIn, Pinterest, and more. They know how to leverage the strengths of each platform to maximize your brand's visibility and engagement with the right audience.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Engaging Content Creation</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Compelling and shareable content is crucial for success on social media. Techrowth's team of content creators understands what resonates with social media users and crafts engaging content, including eye-catching visuals, attention-grabbing videos, and captivating copy. This helps to increase interactions, reach, and overall brand exposure.</p>

              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Social Media Community Building</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Building a strong and loyal community of followers is essential for long-term success on social media. Techrowth employs community-building strategies to foster meaningful connections with your audience. By nurturing relationships and actively engaging with your followers, they create a sense of brand loyalty and advocacy.</p>

      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Social Media Reputation Management`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Monitoring social media conversations about your brand is vital for understanding customer sentiment and addressing potential issues promptly. Techrowth's SMO service includes social listening and reputation management, allowing them to respond to feedback, handle customer inquiries, and manage your brand's online reputation effectively.</p>

      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Social Media
Data-Driven Analytics</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth relies on data-driven insights to continuously refine their SMO strategies. They track key metrics such as engagement rates, reach, click-through rates, and conversions. By analyzing this data, they can identify what works best for your brand and make data-backed decisions to optimize future campaigns.</p>

    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's Social Media Optimization Services</h4>
      <p className={styles.businessAnalystics3}>Drive targeted traffic and increase conversions</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Targeted Social Media Strategies</h4>
      <p className={styles.itServices12345}>Techrowth develops highly targeted SMO strategies tailored to your business's objectives and target audience. Whether you want to increase brand awareness, drive website traffic, generate leads, or improve customer engagement, their team will create a customized plan to achieve your specific goals.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Social Media Platform Expertise </h4>
      <p className={styles.itServices12345}>Social media platforms each have their unique characteristics and user demographics. Techrowth's SMO service includes expertise in major platforms such as Facebook, Instagram, Twitter, LinkedIn, Pinterest, and more. They know how to leverage the strengths of each platform to maximize your brand's visibility and engagement with the right audience.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Engaging Content Creation</h4>
      <p className={styles.itServices12345}>Compelling and shareable content is crucial for success on social media. Techrowth's team of content creators understands what resonates with social media users and crafts engaging content, including eye-catching visuals, attention-grabbing videos, and captivating copy. This helps to increase interactions, reach, and overall brand exposure.</p>


     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Social Media Community Building</h4>
      <p className={styles.itServices12345}>Building a strong and loyal community of followers is essential for long-term success on social media. Techrowth employs community-building strategies to foster meaningful connections with your audience. By nurturing relationships and actively engaging with your followers, they create a sense of brand loyalty and advocacy.</p>

      
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Social Media Reputation Management</h4>
      <p className={styles.itServices12345}>Monitoring social media conversations about your brand is vital for understanding customer sentiment and addressing potential issues promptly. Techrowth's SMO service includes social listening and reputation management, allowing them to respond to feedback, handle customer inquiries, and manage your brand's online reputation effectively.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Social Media
Data-Driven Analytics</h4>
      <p className={styles.itServices12345}>Techrowth relies on data-driven insights to continuously refine their SMO strategies. They track key metrics such as engagement rates, reach, click-through rates, and conversions. By analyzing this data, they can identify what works best for your brand and make data-backed decisions to optimize future campaigns.</p>

 


</div>


    
</div>
</>
  );
};

export default Services;
