import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>IT Management Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Grow With the best IT Management Services in Bangalore
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc18.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Consulting</b>
                        <div className={styles.consistentInGetting}>
                        Navigating your IT challenges with expert guidance
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Unlock the full potential of your business with our cutting-edge solutions. Experience a seamless transformation as you witness increased efficiency, streamlined business processes, and empowered decision-making. Our innovative solutions not only enhance productivity but also help you reduce costs significantly, leading to a remarkable Cost-To-Company reduction of 34%. Embrace the future of business optimization and stay ahead of the competition.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc19.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Infrastructure</b>
                        <div className={styles.consistentInGetting}>
                        Building the backbone of your digital success
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Experience the power of our enhanced technology infrastructure, delivering improved performance, scalability, and reliability. Our dedicated efforts have led to a significant reduction in overhead costs, saving a remarkable $144 million. With our upgraded systems, you can trust us to provide a seamless and efficient experience.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc20.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Tech Support</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Reliable solutions, just a call away`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Experience increased productivity and minimized downtime with our reliable technical issue resolution services. Our dedicated team ensures that your systems run smoothly by promptly addressing and resolving any technical issues that may arise. With our expertise, we maintain an impressive uptime rate of 95%, allowing you to focus on your core business activities without disruptions. Trust us to keep your systems optimized and your productivity soaring.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc21.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Software as a Service</b>
                        <div className={styles.consistentInGetting}>
                        Elevate productivity with seamless software solutions
                        </div>
                      </div>
                    </div>
                     
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
          Experience streamlined operations and increased productivity with our personalized software solutions. Our tailored software solutions are designed to optimize your business processes, resulting in enhanced efficiency and improved outcomes. By leveraging our expertise in developing customized software, we help businesses achieve remarkable results. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
   
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc22.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Cloud Services</b>
                        <div className={styles.consistentInGetting}>
                        Embrace the limitless possibilities of the cloud
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    By leveraging cloud-based resources, businesses can enjoy enhanced flexibility and scalability. This empowers organizations to dynamically adjust their computing resources according to their needs, resulting in improved operational efficiency and cost optimization. Studies have shown that companies utilizing cloud technology have experienced a significant reduction in scaling costs, with a reported decrease of 13%.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc23.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Data Center Services</b>
                        <div className={styles.consistentInGetting}>
                        Your data, our priority – safe and secure
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Experience enhanced data management and protection with our secure data center operations. We have successfully avoided over 1000 data breaches, ensuring the safety and confidentiality of your valuable information. Our efficient and secure data center operations ensure that your data is safeguarded, giving you peace of mind knowing that your sensitive data is in reliable hands.
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>IT Management Services We Serve</b>
          <p className={styles.ourOrigin123}>Grow With the best IT Management Services in Bangalore
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc18.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Consulting</b>
                        <div className={styles.consistentInGetting}>
                        Navigating your IT challenges with expert guidance
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Unlock the full potential of your business with our cutting-edge solutions. Experience a seamless transformation as you witness increased efficiency, streamlined business processes, and empowered decision-making. Our innovative solutions not only enhance productivity but also help you reduce costs significantly, leading to a remarkable Cost-To-Company reduction of 34%. Embrace the future of business optimization and stay ahead of the competition.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc19.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Infrastructure</b>
                        <div className={styles.consistentInGetting}>
                        Building the backbone of your digital success
                        </div>
                      </div>
                    </div>
              
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Experience the power of our enhanced technology infrastructure, delivering improved performance, scalability, and reliability. Our dedicated efforts have led to a significant reduction in overhead costs, saving a remarkable $144 million. With our upgraded systems, you can trust us to provide a seamless and efficient experience.

                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc20.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Tech Support</b>
                        <div className={styles.consistentInGetting}>
                        Reliable solutions, just a call away
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Experience increased productivity and minimized downtime with our reliable technical issue resolution services. Our dedicated team ensures that your systems run smoothly by promptly addressing and resolving any technical issues that may arise. With our expertise, we maintain an impressive uptime rate of 95%, allowing you to focus on your core business activities without disruptions. Trust us to keep your systems optimized and your productivity soaring.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc21.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Software as a Service</b>
                        <div className={styles.consistentInGetting}>
                        Elevate productivity with seamless software solutions
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Experience streamlined operations and increased productivity with our personalized software solutions. Our tailored software solutions are designed to optimize your business processes, resulting in enhanced efficiency and improved outcomes. By leveraging our expertise in developing customized software, we help businesses achieve remarkable results. 
                    </div>
              </div>
           
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc22.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Cloud Services</b>
                        <div className={styles.consistentInGetting}>
                        Embrace the limitless possibilities of the cloud
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    By leveraging cloud-based resources, businesses can enjoy enhanced flexibility and scalability. This empowers organizations to dynamically adjust their computing resources according to their needs, resulting in improved operational efficiency and cost optimization. Studies have shown that companies utilizing cloud technology have experienced a significant reduction in scaling costs, with a reported decrease of 13%.
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc23.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Data Center Services</b>
                        <div className={styles.consistentInGetting}>
                        Your data, our priority – safe and secure
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Experience enhanced data management and protection with our secure data center operations. We have successfully avoided over 1000 data breaches, ensuring the safety and confidentiality of your valuable information. Our efficient and secure data center operations ensure that your data is safeguarded, giving you peace of mind knowing that your sensitive data is in reliable hands.
                    </div>
              </div>
            
            </div>
           
          </div>
         
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
