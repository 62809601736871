import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth1 from "../components/InterestedInTechrowth1";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider1 from "../components/ClientsSlider1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions7 from "../components/SocialMediaMarketingSolutions7";
import Services7 from "../components/Services7";
import ProblemsWeSolved1 from "../components/ProblemsWeSolved1";
import OtherServices2 from "../components/OtherServices2";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial8 from "../components/NewTestimonial8";
import { Link } from "react-router-dom";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthsub221";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthsub201";
import style from "./NewHomepage1.module.css";
const ContentManagementSystem = () => {
  const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/banner1.png";
  const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M16.png";


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT", path: "/it-services" },
      { name: "Content Management System", path: "/it-services/it-development/cms-development-service" },

    ]);
  }, []);

  const HeroTitel = "CMS Development Service";
  const HeroContent =
    "Discover the best CMS Development Service in Bangalore. With our cutting-edge CMS system, embrace flexibility, streamline your online presence, and unleash your creativity.";
  const subject = "CMS Development Service ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with our digital marketing solutions!";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/cms-development-service" />
<title>Efficient and User-Friendly CMS Development Services in Bangalore | Techrowth</title>
<meta name="description" content="Techrowth provides expert CMS development services in Bangalore, tailored to meet your unique business requirements. Contact us now for personalized solutions." />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider1/>
      <NewTestimonial8/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your website's potential with our custom CMS development service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions7 />
      <Services7/>
      <ProblemsWeSolved1/>
      <OtherServices2/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default ContentManagementSystem;
