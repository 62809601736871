import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumb }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid" style={{ backgroundColor: "#0076A2" }}>
      <div className="row p-3 ms-1">
        <div className="text-white">
          {breadcrumb.map((item, index) => (
            <span key={item.path}>
              <Link
                to={item.path}
                style={{ textDecoration: "none", color: "white" }}
              >
                {item.name}
              </Link>
              {index < breadcrumb.length - 1 && " > "}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
