import styles from "./InterestedDevelopment8.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Search Engine Optimization Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`Technological Infrastructure We Employ. `}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/g2.svg"
                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Organic Traffic</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Organic search engine visitors reflect the success<br/> of SEO in attracting relevant users to a website.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Organic traffic increased by `}</span>
                    <b className={styles.eCommercePortals}>
                      117%
                    </b>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page22.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                <div className={styles.mobileApplications}>
                  SEO Audits

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    An SEO audit involves a comprehensive analysis of a website's performance, keyword ranking, and search engine visibility.

                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
             
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Conducted `}</span>
            

                      <b className={styles.websites}>987 </b>
                      <span className={styles.weHaveDeveloped}>SEO Audits</span>
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
            <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/page24.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Organic Conversion
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Unlocking the power of organic growth and engagement for your business.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Increased organic conversion by `}</span>
                      <b className={styles.websites}>
                      43%
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/g3.svg"

                />
                <div className={styles.corporateWebsiteParent}>
               
                  <div className={styles.mobileApplications}>
                  Customer Engagement
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Active involvement and interaction between a business and its customers, promote a meaningful.


                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                    <b className={styles.eCommercePortals}>
                  21%
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` increase in customer engagement`}</span>
                      {/* <b className={styles.websites}> 21%</b> */}

                    </p>
                  </div>
                </div>
              </div>
            
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
