import styles from "./BusinessAnalystics1.module.css";
import NumberCounter from 'number-counter';

import { Link, useLocation } from "react-router-dom";
const BusinessAnalystics = ({ show, onDisplay, titleDiplay }) => {
  return (
    <>
    <div className={styles.businessAnalystics}>
      <div className={styles.frameParent}>
  
          <div className={styles.businessAnalyticsParent}>
            <div className={styles.businessAnalytics}>IT Services</div>
            <div className={styles.unlockThePowerContainer}>
              <p className={styles.unlockThePower}>
              Let our expert IT development services in Bangalore crafts innovative solutions to meet your Business Goals
              </p>
        
            </div>
          </div>
<div className="d-flex">

<div className={styles.new21}>
        <div>
        <Link to="/it-services/it-development" style={{ textDecoration:"none" }}>
         <h1 style={{textAlign:"center", color:"#0076A2" ,fontSize: "2rem"}}> <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group1.svg"  alt="" /> Development</h1>
        </Link>
          <div className={styles.businessAnalystics4}>
      <div className={styles.groupParent1}>
          <Link to="/it-services/it-development/cms-development-service" style={{ textDecoration:"none" }}>
               
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group2.svg"
                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower1}>CMS Development</p>
                  
                </div>
                </Link>
              </div>
           
           
              <div className={styles.groupParent1}>
              <Link to="/it-services/it-development/website-development-service" style={{ textDecoration:"none" }}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group3.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower1}>Website<br/>
                  Development</p>
               
                </div>
                </Link>
              </div>
            
          </div>  
          <div className={styles.businessAnalystics4}>
      <div className={styles.groupParent1}>
      <Link to="/it-services/it-development/web-application-development-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group4.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower1}>Web Application<br/>Development</p>
                  
                </div>
              </Link>

              </div>
              <div className={styles.groupParent1}>
              <Link to="/it-services/it-development/ecommerce-development-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group5.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower1}>E-commerce <br/>
                  Development</p>
               
                </div>
                </Link>
              </div>
            
          </div> 
          <div className={styles.businessAnalystics4}>
      <div className={styles.groupParent1}>
      <Link to="/it-services/it-development/website-accessibility-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group6.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower1}>Website Accessibility</p>
                  
                </div>
                </Link>
              </div>
              
              <div className={styles.groupParent1}>
              <Link to="/it-services/it-development/mobile-application-development-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group7.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower1}>Mobile Application<br/>
                  Development</p>
               
                </div>
                </Link>
              </div>
           
          </div> 
        </div>
      </div>

<div className="w-100" style={{margin:"3rem"}}>
<div className={styles.businessAnalystics4} style={{    width: "43rem",
    height: "22rem"}}>
      <div className={styles.groupParentt1}>
      <Link to="/it-services/uiux-services" style={{ textDecoration:"none" }}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group8.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <h3 className="mt-3" style={{color:"var(--blue)"}}>UI/UX Interactive</h3>

                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Website Design</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Website Redesign</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>User Experience Testing</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Landing Page</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Conversion Rate</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Rapid Web Design</p>


                  
                </div>
                </Link>
              </div>


              <div className={styles.groupParentt1}>
              <Link to="/it-services/it-management-service" style={{ textDecoration:"none" }}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group9.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                <h3 className="mt-3" style={{color:"var(--blue)"}}>IT Management</h3>

                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Project Management</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Consultation Service</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>SAAS</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>DevOps Service</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Cloud Service</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Digital Transformation</p>
               
                </div>
                </Link>
              </div>
          </div>  
          <div className={styles.businessAnalystics4} style={{    width: "43rem",
    height: "22rem"}}>
      <div className={styles.groupParentt1}>
      <Link to="/it-services/integration-services" style={{ textDecoration:"none" }}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group10.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <h3 className="mt-3" style={{color:"var(--blue)"}}>Integration</h3>

                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Salesforce</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Adobe</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Power BI</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Zoho</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Dynamic 365</p>
                  <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Tableau</p>


                  
                </div>
                </Link>
              </div>
              <div className={styles.groupParentt1}>
              <Link to="/it-services/quality-testing-service" style={{ textDecoration:"none" }}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group11.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                <h3 className="mt-3" style={{color:"var(--blue)"}}>Quality Testing</h3>

                <p style={{ textAlign:"left", fontSize:"1rem" , margin: "0px"}}>Database Testing</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Platform Testing</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Functional Testing</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Security Testing</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Usability Testing</p>
                <p style={{ textAlign:"left", fontSize:"1rem", margin: "0px"}}>Performance Testing</p>
               
                </div>
                </Link>
              </div>
          </div>  
</div>
</div>
      
      </div>


    </div>














    <div className={styles.businessAnalystics1}>
    <h4 className={styles.businessAnalystics2}>IT Services</h4>
      {/* <p className={styles.businessAnalystics3}>Let our expert IT services team craft innovative solutions to meet your Business with Analytics</p> */}
      <div className={styles.new1}>
        <div>
        <Link to="/it-services/it-development" style={{ textDecoration:"none" }}>
         <h1 style={{textAlign:"center", color:"#0076A2", fontSize:"22px"}}> <img style={{width:"3rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group1.svg"
  alt="" /> Development</h1>
        </Link>
          <div className={styles.businessAnalystics4}  style={{width:"21.5rem"}}>
      <div className={styles.groupParent1}>
          <Link to="/it-services/it-development/cms-development-service" style={{ textDecoration:"none" }}>
               
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group2.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>CMS Development</p>
                  
                </div>
                </Link>
              </div>
           
           
              <div className={styles.groupParent1}>
              <Link to="/it-services/it-development/website-development-service" style={{ textDecoration:"none" }}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group3.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Website<br/>
                  Development</p>
               
                </div>
                </Link>
              </div>
            
          </div>  
          <div className={styles.businessAnalystics4}  style={{width:"21.5rem"}}>
      <div className={styles.groupParent1}>
      <Link to="/it-services/it-development/web-application-development-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group4.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Web Application<br/>Development</p>
                  
                </div>
              </Link>

              </div>
              <div className={styles.groupParent1}>
              <Link to="/it-services/it-development/ecommerce-development-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group5.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>E-commerce <br/>
                  Development</p>
               
                </div>
                </Link>
              </div>
            
          </div> 
          <div className={styles.businessAnalystics4}  style={{width:"21.5rem"}}>
      <div className={styles.groupParent1}>
      <Link to="/it-services/it-development/website-accessibility-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group6.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Website<br/> Accessibility</p>
                  
                </div>
                </Link>
              </div>
              
              <div className={styles.groupParent1}>
              <Link to="/it-services/it-development/mobile-application-development-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group7.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Mobile Application<br/>
                  Development</p>
               
                </div>
                </Link>
              </div>
           
          </div> 
        </div>
      </div>
      <div className={styles.businessAnalystics4}  style={{width:"23.5rem"}}>
      <div className={styles.groupParentt1}>
      <Link to="it-services/uiux-services" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group8.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>UI/UX<br/>Interactive</p>
                  
                </div>
                </Link>
              </div>
              
              <div className={styles.groupParentt1}>
              <Link to="/it-services/it-management-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group9.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>IT<br/>
                  Management</p>
               
                </div>
                </Link>
              </div>
           
          </div> 
          <div className={styles.businessAnalystics4}  style={{width:"23.5rem"}}>
      <div className={styles.groupParentt1}>
      <Link to="it-services/integration-services" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group10.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Integration</p>
                  
                </div>
                </Link>
              </div>
              
              <div className={styles.groupParentt1}>
              <Link to="/it-services/quality-testing-service" style={{ textDecoration:"none" }}>

                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/Group11.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Quality<br/>
                  Testing</p>
               
                </div>
                </Link>
              </div>
           
          </div> 
        


      
</div>

</>
  );
};

export default BusinessAnalystics;
