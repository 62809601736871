import styles from "./InterestedDevelopment3.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Website Accessibility Development Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`We offer the following Development Solutions `}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w61.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Accessibility Compliance</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our team is committed to ensuring that your website meets the highest accessibility standards, enabling equal access for all your target users. 
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Achieved compliance for over `}</span>
                    <b className={styles.eCommercePortals}>
                      40
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` websites.`}</span>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w64.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Positive User Feedback
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our commitment to website accessibility has received overwhelming positive feedback from users. 
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                    <b className={styles.websites}>
                     90%
                      </b>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` expressing appreciation for the ease of use.`}</span>
                     
                    </p>
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
            <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w62.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Inclusive Design
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our experts specialize in implementing inclusive design principles to create websites that are accessible. 
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  <b className={styles.eCommercePortals}>
                  50%
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` increase in user interactions.`}</span>
                  
                  </div>
                </div>
              </div>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w63.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  Enhanced User Experience

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    By optimizing websites for accessibility, we have seen a remarkable improvement in user experience

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Increase of `}</span>
                      <b className={styles.websites}>45% </b>
                      <span className={styles.weHaveDeveloped}>in user satisfaction and engagement.</span>
                   

                    </p>
                  </div>
                </div>
              </div>
             
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
