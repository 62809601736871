import React, { useState } from "react";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const DropdownMenu = ({ sendDataToParent }) => {
  const [setHover, setSetHover] = useState(false);
  const handleClick = () => {
    const data = false;
    sendDataToParent(data);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
      className="container-fluid position-absolute margin-top-20 dropdown-menu-top"
      style={{ backgroundColor: "#E9EEF4", zIndex: "1", top: "15%" }}
      // style={{
      //   zIndex: "1",
      //   top: "20%",
      //   backgroundColor: "#E9EEF4",

      //   '@media (max-width: 768px)': {
      //     top: "10%"
      //   },
      // }}
      
    >
      <div className="row py-4 px-4" style={{ gap: "2rem" }}>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3" style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">
                  Intent We Serve
  
                </h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} style={{textDecoration:"none"}} className="text-white">
                Enquiry/Lead Generation
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Ecommerce
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Branding
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                App Downloads
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Remarketing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/intent-we-serve" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Cross Selling/Up selling
              </Link>
            </li>
 
          </ul>
        </div>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3"  style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/digital-marketing" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">Our Services</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/social-media-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Social Media Marketing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/social-media-optimization" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Social Media Optimization
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/search-engine-optimization" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Search Engine Optimization
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/search-engine-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Search Engine Marketing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/pay-per-click" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Pay Per Click
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/email-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Email Marketing
              </Link>
            </li>
       
     
      
          </ul>
        </div>
        {/* <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3"  style={{listStyle:"none"}}>
            <li className="mb-3"> 
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">Graphic Design</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Logo Design
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Brochure
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Merchandise Design
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Company Profiles
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Catalog Design
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/graphic-design" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Product Packaging Design 
              </Link>
            </li>
      
          </ul>
        </div> */}
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3"  style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">E-Commerce</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Explainer videos
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Product Listings
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Ecommerce SEO
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Ecommerce PPC
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Social Media Shops
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/ecommerce-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Google Shop
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3"  style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">Content</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Website
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Copywriting
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Proofreading
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Blogs/Articles
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                White Paper
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/digital-marketing/content-marketing" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                PR Articles
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default DropdownMenu;
