import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t5.png" />

      <div className={styles.bus}>
      <h5 >Education Institution</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>50%</h6>
            {/* <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" /> */}
          </div>
          <p className={styles.bus5}>Engagement</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>35%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Online Sales</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      The website accessibility service provided by Techrowth team has transformed the way our educational platform reaches and supports students. By ensuring our website is fully accessible to individuals with disabilities, we've seen an increase in the number of students who can access our content and engage with our courses. Their dedication to making education inclusive for all is commendable, and we're grateful for their expertise in website accessibility."
     </p>
    </div>
  );
};

export default TSlide1;
