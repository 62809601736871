import styles from "./Hero.module.css";
import Breadcrumbs from "./Breadcrumbs";
import RecentResult from "./RecentResult";

const Hero = ({ title, content, MyBackgroundImage ,MyBackgroundImage1, sendDataToParent}) => {
  const handleClick = () => {
    const data = false;
    sendDataToParent(data);
  };
  const handleWhatsAppRedirect = () => {
    // Replace the phone number with the desired WhatsApp number
  
    const phoneNumber = "+918073162943";
    const url = `https://wa.me/${phoneNumber}`;
  
    // Open the WhatsApp chat in a new window/tab
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={styles.hero}>
        <div
          className={styles.empoweringYourOnlinePresencParent}
          style={{ backgroundImage: `url(${MyBackgroundImage})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed",  backgroundPosition: "center" }}
        >
          <div className={styles.empoweringYourOnlineContainer}>
            <div className="col-8">
              
              <p className={styles.empoweringYour + "my-4"} ><b style={{fontWeight:"600"}}>{title}</b></p>
              <div className={styles.joinTheTechrowthContainer}>
                <p className={styles.empoweringYour + "my-3"}>
                  <span>{content}</span>
                </p>
              </div>
              <button className={styles.cta} onClick={handleWhatsAppRedirect}>
                <div className={styles.talkToOur}>Talk to our strategist</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.herooo}  style={{ backgroundImage: `url(${MyBackgroundImage1})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",  backgroundPosition: "center" }}>
     
      <h5 className={styles.herooo1}>{title}</h5>
     
      <p className={styles.herooo11}>{content}</p>
<button className={styles.letsGetStartedWrapper + "w-auto mb-3"} onClick={handleWhatsAppRedirect} style={{borderRadius:"30px",padding:".5rem 1rem", color:"#0076A2",background:"white", border:"none",textAlign:"center"}}>
                <strong><div className={styles.letsGetStarted}>Talk to our strategist</div></strong>
              </button>
</div>
      
    </>
  );
};

export default Hero;
