import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthh21";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthh221";
import { Helmet } from "react-helmet";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients from "../components/SatisfiedClients";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues2 from "../components/OurCoreValues2";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain2 from "../components/OtherServicesmain2";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1"
import styles from "./NewHomepage.module.css";
import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";


const IntentPrototype = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/intentbgimg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M1.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Intent We Serve", path: "/digital-marketing/intent-we-serve" },
    ]);
  }, []);
  const subject = "Intents We Serve" 
  const HeroTitel = "Intent We Serve";
  const HeroContent =
    "Discover our purpose-driven solutions with Intent We Serve. From personalized strategies to exceptional experiences, we cater to your unique needs. Embrace our passion for success, build lasting partnerships, and achieve your goals together. Experience dedication like never before!";
  const RecentText =
    "Become a class apart with our Cutting-Edge Marketing Tactics. Contact ou experts today!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/intent-we-serve" />
<title>Understanding Your Goals: The Intents We Serve at Techrowth</title>
<meta name="description" content="Techrowth serves following marketing intents, Enquiry, Branding, Engagement, Downloading, Ecommerce, Upselling, Cross selling, Remarketing, Redownloading and Reactivation." />
</Helmet>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
 
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals!
        Maximize the potential of your online reach with our digital marketing solutions!
        "
        btnTxt="Contact Us"
      /></Link>

           <AccelerateYourGrowth />
           <OurCoreValues2/>
           <OtherServicesmain2/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default IntentPrototype;
