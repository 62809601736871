import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowthh22";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients from "../components/SatisfiedClients";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues3 from "../components/OurCoreValues3";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain3 from "../components/OtherServicesmain4";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1"
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowthh222";
import { Helmet } from "react-helmet";
import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const GraphicDesign = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/DigitalMarketingBg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M11.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Graphic Design", path: "/digital-marketing/graphic-design" },
    ]);
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const subject = "Graphic Designing ?" 
  const HeroTitel = "Graphic Design";
  const HeroContent =
    "Unleash the essence of your individuality through our aid, and let the world bask in the brilliance of your unique brand style. Partner with the best Graphic Design company in Bangalore.";

  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://techrowth.com/digital-marketing/graphic-design" />
<meta charSet="utf-8" />
<title>Transforming Ideas into Art: The best Graphic Designing company in Bangalore | Techrowth</title>
<meta name="description" content="Techrowth, the best graphic design company in Bangalore, offers innovative and creative solutions for all your design needs. Enhance your brand's visual identity with our expert team." />
</Helmet>
      <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the creative potential with the graphic design company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
           <AccelerateYourGrowth />
           <OurCoreValues3/>
           <OtherServicesmain3/>
      <ContactForm1/>
      <ExploreOurCaseStudies />
      </div>
      <TrustedByBrands />
    </>
  );
};

export default GraphicDesign;
