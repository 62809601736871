import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import InterestedInTechrowth from "../components/InterestedInTechrowth";
import Breadcrumbs from "../components/Breadcrumbs";
import ClientsSlider from "../components/ClientsSlider";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import ContactUsBar from "../components/ContactUsBar";
import TrustedByBrands from "../components/TrustedByBrands";
import SocialMediaMarketingSolutions1 from "../components/SocialMediaMarketingSolutions1";
import Services1 from "../components/Services1";
import ProblemsWeSolved from "../components/ProblemsWeSolved";
import OtherServices02 from "../components/OtherServices02";
import ContactFrom1 from "../components/ContactForm1";
import NewTestimonial3 from "../components/NewTestimonial3";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TechnologiesWeUseAndServiSocia3 from "../components/TechnologiesWeUseAndServiSocia3";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth113";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth13";
import style from "./NewHomepage1.module.css";
const SearchEngineOptimization = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Hero+Section-1.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M5.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" },
      { name: "Search Engine Optimization ", path: "/digital-marketing/search-engine-optimization" },

    ]);
  }, []);

  const HeroTitel = "Search Engine Optimization";
  const HeroContent =
    "The best SEO service in Bangalore to boost your website's ranking, and outperform competitors with result-driven strategies.";
  const subject = "Search Engine Optimization ?" 
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with the best SEO company in Bangalore";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/digital-marketing/search-engine-optimization" />
<title>SEO Company in Bangalore- Leading SEO Services in Bangalore
</title>
<meta name="description" content="Result-driven SEO company in Bangalore Karnataka, we helps to improve your search engine visibility, potential leads, sales and revenue with our SEO expert’s SEO strategies. 
" />
<meta name="keywords" content="SEO Company in Bangalore, SEO Services in Bangalore, SEO Agency in Bangalore, Best SEO Companies in Bangalore, SEO Services Agencies in Bangalore, Best SEO company in Bangalore, SEO services in Bangalore, SEO agency in Bangalore, SEO experts, SEO services
" />
</Helmet>
        <div className={styles.newHomepage}>
      <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     

      </div>
      <ClientsSlider/>
      <NewTestimonial3/>
      <div className={style.contact}> 
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize the potential of your online reach with the best SEO company in Bangalore"
        btnTxt="Contact Us"
      /></Link>
      </div>
      <SocialMediaMarketingSolutions1 />
      <Services1/>
      <ProblemsWeSolved/>
      <TechnologiesWeUseAndServiSocia3/>

      <OtherServices02/>
      <ContactFrom1 />

      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default SearchEngineOptimization;
