import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's Mobile Application Development Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Create high-quality and user-friendly mobile applications. `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Expertise in Mobile App Development: </div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth's team of skilled developers possesses extensive expertise in mobile app development across various platforms, including iOS and Android. Their in-depth knowledge allows them to create innovative, feature-rich, and high-performance mobile applications.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Customized Mobile Solutions`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}> Techrowth understands that every mobile app project is unique. They offer customized mobile app development services tailored to your specific requirements and business objectives. Whether you need a native app, hybrid app, or cross-platform app, they can create a solution that meets your needs precisely.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>User-Centric Approach</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>User experience is a top priority for Techrowth's mobile app development process. They focus on creating intuitive interfaces, smooth navigation, and engaging interactions, providing an excellent user experience that keeps users coming back to your app.</p>
              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Mobile Optimization</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>With the majority of users accessing apps on mobile devices, Techrowth ensures that the mobile applications they develop are fully optimized for different screen sizes and operating systems. This guarantees a seamless and enjoyable experience for users across various mobile devices.</p>
      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Rigorous Testing and Quality Assurance`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth follows a rigorous testing and quality assurance process to identify and rectify any potential issues in the mobile app before launch. Their thorough testing ensures that the app is stable, secure, and performs flawlessly across various scenarios.</p>
      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Timely Delivery and Budget Management</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth is committed to delivering mobile app projects on time and within budget. Their project management expertise and clear communication ensure that you stay informed about the development progress and any potential changes in scope or timeline.</p>
    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's Mobile Application Development Services</h4>
      <p className={styles.businessAnalystics3}>Create high-quality and user-friendly mobile applications. </p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Expertise in Mobile App Development: </h4>
      <p className={styles.cms1}>Techrowth's team of skilled developers possesses extensive expertise in mobile app development across various platforms, including iOS and Android. Their in-depth knowledge allows them to create innovative, feature-rich, and high-performance mobile applications.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized Mobile Solutions</h4>
      <p className={styles.cms1}> Techrowth understands that every mobile app project is unique. They offer customized mobile app development services tailored to your specific requirements and business objectives. Whether you need a native app, hybrid app, or cross-platform app, they can create a solution that meets your needs precisely.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>User-Centric Approach</h4>

      <p className={styles.cms1}>User experience is a top priority for Techrowth's mobile app development process. They focus on creating intuitive interfaces, smooth navigation, and engaging interactions, providing an excellent user experience that keeps users coming back to your app.</p>
     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Mobile Optimization</h4>
      
      <p className={styles.cms1}>With the majority of users accessing apps on mobile devices, Techrowth ensures that the mobile applications they develop are fully optimized for different screen sizes and operating systems. This guarantees a seamless and enjoyable experience for users across various mobile devices.</p>
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Rigorous Testing and Quality Assurance</h4>
      <p className={styles.cms1}>Techrowth follows a rigorous testing and quality assurance process to identify and rectify any potential issues in the mobile app before launch. Their thorough testing ensures that the app is stable, secure, and performs flawlessly across various scenarios.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Timely Delivery and Budget Management</h4>
 
      <p className={styles.cms1}>Techrowth is committed to delivering mobile app projects on time and within budget. Their project management expertise and clear communication ensure that you stay informed about the development progress and any potential changes in scope or timeline.</p>


</div>


    
</div>
</>
  );
};

export default Services;
