import ContactUsBar from "./ContactUsBar";
import styles from "./ItMain.module.css";
import OneStopSolution1 from "../components/OneStopSolution1";
import Testimonial from "../components/Testimonial";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import FrameComponent from "../components/FrameComponent";
import IndustriesWorkedWith from "../components/IndustiresWorkedWIth";
import AccelerateYourGrowth from "./AccelerateYourGrowth1";
import styless from "./WhoWeAre.module.css";
import SatisfiedClients from "../components/SatisfiedClients";
import { Link } from "react-router-dom";
import Testimonial1 from "../components/Testimonial1";
import SatisfiedClients1 from "../components/SatisfiedClients";
// import style from "../pages/NewHomepage1.module.css";

const ItMain = () => {

  const RecentText = "Ready to boost your business revenue with the best IT services in Bangalore? Book a free consultation Now.";
  return (
    <>
    <div className={styles.itMain}>
      <div className={styles.frameParent}>
        <div className={styles.oneStopSolutionParent}>
          <b className={styles.oneStopSolution} style={{fontWeight:"600"}}>One Stop Solution</b>
          <div
            className={styles.resultDrivenIt}
          >{`Result-Driven IT Development Company in Bangalore`}</div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.parent}>
            <div className={styles.div}>7+</div>
            <div className={styles.yearsOfAverageContainer}>
              <p className={styles.yearsOfAverage}>Years of Average</p>
              <p className={styles.yearsOfAverage}>Team Experience</p>
            </div>
          </div>

          <div className={styles.parent}>
            <div className={styles.div}>200+</div>
            <div className={styles.yearsOfAverageContainer}>
              Subject Matter Experts
            </div>
          </div>
          <div className={styles.parent}>
            <div className={styles.technology}>114+</div>
            <div className={styles.clientTestimonials}>Client Testimonials</div>
          </div>
          <div className={styles.parent}>
            <div className={styles.div}>100%</div>
            <div className={styles.yearsOfAverageContainer}>
              Proven Track <br></br>Record
            </div>
          </div>
        </div>
      </div>
      <SatisfiedClients1 />
   
      <Testimonial1/>
     


      
      <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>
      <ContactUsBar content={RecentText} btnTxt="Contact Us" />
  </Link>
     
  <AccelerateYourGrowth />

      <div className={styles.digite}>
        <div className={styles.frameParent10}>
          <div className={styles.frameParent11}>
            <div className={styles.logo1Parent}>
              <b className={styles.b} style={{fontWeight:"600", fontSize:"2rem"}}>4.8K+</b>
              <div className={styles.marketingBudgetsSpent}>
              Websites<br/> Built
              </div>
            </div>
  
            <div className={styles.logo1Parent}>
              <b className={styles.b2} style={{fontWeight:"600", fontSize:"2rem"}}>1000+</b>
              <div className={styles.revenueGenerated}>Third-Party<br/> Integrations</div>
            </div>
            <div className={styles.logo1Parent}>
              <b className={styles.b} style={{fontWeight:"600", fontSize:"2rem"}}>100+</b>
              <div className={styles.marketingBudgetsSpent}>
              Satisfied<br/> Clients
              </div>
            </div>
        
          </div>
          <div className={styles.frameParent12}>
    
            <div className={styles.logo1Parent}>
              <b className={styles.b1} style={{fontWeight:"600", fontSize:"2rem"}}>50+</b>
              <div className={styles.mobileApplicationsDeveloped}>
              Stand Alone
                <br/> Softwares
              </div>
            </div>
            <div className={styles.logo1Parent}>
              <b className={styles.b1} style={{fontWeight:"600", fontSize:"2rem"}}>125+</b>
              <div className={styles.mobileApplicationsDeveloped}>
                 Applications
                <br/> Developed
              </div>
            </div>
          </div>
          
        </div>
      </div>



  

      <div className={styles.frameParent16}>
        <div className={styles.transformYourIdeasIntoRealParent}>
          <b className={styles.transformYourIdeas} style={{fontWeight:"600"}}>
          IT Services We Serve
          </b>
          <div className={styles.letUsGuide}>
            Let us guide you using our Expertly Crafted IT Services
          </div>
        </div>
        <div className={styles.groupParent9}>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.frameParent17}>
              <div className={styles.frameParent18}>
                <img
                  className={styles.frameChild19}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-725%402x.png"
                />
                <div className={styles.development}>Development</div>
              </div>
              <div className={styles.realizeTheFull}>
              Delivering IT solutions for your CMS requirements, websites, website accessibility, applications software, e-commerce, and mobile apps.
              </div>
              <div className={styles.lineParent}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-50.svg" />
                
                <Link to="/it-services/it-development" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild4} />
            <div className={styles.uiuxInteractiveParent}>
              <div className={styles.uiuxInteractive}>UI/UX Interactive</div>
              <div className={styles.aTeamOf}>
              Leading to an interaction interface for which your users will thank you. We've got the skills to launch your website and help it stay in the spotlight. 
              </div>
              <div className={styles.lineGroup}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-501.svg" />
                <Link to="/it-services/uiux-services" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
              <img
                className={styles.groupChild5}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-726%402x.png"
              />
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild4} />
            <div className={styles.itManagementParent}>
              <div className={styles.itManagement}>IT Management</div>
              <div className={styles.letUsHelp}>
              These services are secure, confidential, and will assist you in reaching your business goals while boosting your ability to succeed.
              </div>
              <div className={styles.lineContainer}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-501.svg" />
                <Link to="/it-services/it-management-service" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
              <img
                className={styles.groupChild7}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-734%402x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.groupParent13}>
          <div className={styles.rectangleParent3}>
            <div className={styles.rectangleFrame}>
              <div className={styles.groupChild3} />
            </div>
            <div className={styles.qualityTestingParent}>
              <div className={styles.qualityTesting}>Quality Testing</div>
              <div className={styles.ourExpertsDeliver}>
              Our team is ready to help you integrate chatbots, online CMS, and other tools of your choosing. Don’t miss out on any opportunities.
              </div>
              <div className={styles.lineParent1}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-501.svg" />
                <Link to="/it-services/quality-testing-service" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
              <img
                className={styles.groupChild5}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-728%402x.png"
              />
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.integrationsParent}>
              <div className={styles.qualityTesting}>Integrations</div>
              <div className={styles.letPowerOf}>
              With our excellent quality testing team, your program will have its foundations tested and come out strong, with a user-friendly interface.
              </div>
              <div className={styles.lineParent2}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-501.svg" />
                <Link to="/it-services/integration-services" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
              <img
                className={styles.groupChild5}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-730%402x.png"
              />
            </div>
          </div>
        </div>
      </div>




   <div className={styles.new}>
   <b className={styles.transformYourIdeas1} style={{color:"#0076A2", fontWeight:"600"}}>
          We Assist You in Resolving
 
          </b>
    <div className="d-flex mt-4" style={{gap:"10rem"}}>
        <div className={styles.new1}>
        <div className={styles.transformYourIdeasIntoRealParent1}>
          {/* <b className={styles.transformYourIdeas1} style={{color:"#0076A2", fontWeight:"600"}}>
          We Assist You in Resolving
 
          </b> */}
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  A need for complex functionality in your software</p>
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Improving user engagement and accessibility</p>
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Security Requirement Based on Threat Vectors</p>
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Avoid breaches and downtime in the organization's infrastructure</p>



 

        </div>
        </div>
        <div className={styles.new1}>
        <div className={styles.transformYourIdeasIntoRealParent1}>
   

     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Improving Network Security Performance</p>
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Chat-bot and third-party software integration</p>
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Incorporation of cloud-hosted databases</p>
     <p style={{color:"#000000", fontSize:"18px"}}> <img className={styles.new4} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  The risk of software failure due to software errors and bugs</p>


 

        </div>
        </div>
        </div>
    </div>


      <div className={styles.frameParent23}>
        <div className={styles.kracParent}>
          <div className={styles.exploreOtherServices}>KRAC</div>
          <div className={styles.aSoftwareDelivery}>
          A Project Management Process, cracking the toughest codes with copacetic approach of dissecting them down
          </div>
          <div className={styles.frameParent} style={{marginBottom:"2rem"}}>

        <div className={styles.frameGroup}>
          <div className={styles.parent}>
            <div className={styles.div}>90%</div>
            <div className={styles.yearsOfAverageContainer}>
              <p className={styles.yearsOfAverage}>On time Project Completion</p>
              {/* <p className={styles.yearsOfAverage}>Team Experience</p> */}
            </div>
          </div>

          <div className={styles.parent}>
            <div className={styles.div}>100%</div>
            <div className={styles.yearsOfAverageContainer}>
            Project Closure
            </div>
          </div>
   
          <div className={styles.parent}>
            <div className={styles.div}>95%</div>
            <div className={styles.yearsOfAverageContainer}>
            Expectation <br/>Accuracy
            </div>
          </div>
        </div>
      </div>
        </div>
        <div className={styles.iconSymbole1Parent}>
          <img className={styles.iconSymbole1} style={{marginBottom:"2rem"}}alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/KRACimg.png" />
        </div>

        <div className={styles.experienceASoftwareDevelopmParent}>
          <div className={styles.experienceASoftwareContainer}>
            <span>{`Experience a project management process that really kicks it up a notch with `}</span>
            <span className={styles.span}>KRAC</span>
            <span>{`. Our process has cracked the code to efficient software delivery, boasting a `}</span>
            <span className={styles.span}>30%</span>
            <span> increased efficiency.</span>
          </div>
          <div className={styles.weKickIt}>
          We kick it off with discussions about your software requirements and business intentions, which help us create a highly accurate roadmap for the development phase. From there, precise actions are taken towards the fulfillment of a thoroughly tested final product. Our process is transparent for the client and easy to monitor, making the closure phase of the final product handoff simple and stress-free for any business.
          </div>
        </div>

        <FrameComponent />
      </div>

      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.exploreOtherServices}>
        Problems We Solved with KRAC
        </div>
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-739.svg"
              />
              <div className={styles.aNeedFor}>
              Software delivery workflows
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-740.svg"
              />
              <div className={styles.improvingUserEngagement}>
              Standardization in development processes
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-742.svg"
              />
              <div className={styles.avoidBreachesAnd}>
              Managing and tracking project progress
              </div>
            </div>
            <div className={styles.groupParent6}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-741.svg"
              />
              <div className={styles.securityRequirementBased}>
              Software development cycles Optimization
              </div>
            </div>
          
          </div>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-743.svg"
              />
              <div className={styles.improvingNetworkSecurity}>
              Quality assurance and testing procedures
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-744.svg"
              />
              <div className={styles.chatBotAndThirdParty}>
              Scalability and adaptability of IT services
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-745.svg"
              />
              <div className={styles.incorporationOfCloudHosted}>
              Risk assessment and mitigation strategies
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-746.svg"
              />
              <div className={styles.theRiskOf} style={{textAlign:"left"}}>
              Managing resources and project dependencies
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.frameParent27}>
        <div className={styles.howToPickYourItFirmParent}>
          <div className={styles.intent}>How to pick your IT firm? </div>
          <div className={styles.topFactorsInvolved}>
            Top factors involved in cracking the code of a successful software
            delivery.
          </div>
        </div>
        <div className={styles.frameParent28}>
          <div className={styles.frameParent29}>
            <div className={styles.frameParent30}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-774%402x.png"
              />
              <div className={styles.expertiseAndExperience}>
                Expertise and experience
              </div>
            </div>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-775%402x.png"
              />
              <div className={styles.flexibleAndCustomizableContainer}>
                <p className={styles.yearsOfAverage}>{`Flexible and `}</p>
                <p className={styles.yearsOfAverage}>customizable solutions</p>
              </div>
            </div>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-777%402x.png"
              />
              <div className={styles.securityAndCompliance}>
                Security and compliance
              </div>
            </div>
          </div>
          <div className={styles.frameParent33}>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-773%402x.png"
              />
              <div className={styles.customerCentricApproach}>
                Customer-centric approach
              </div>
            </div>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-776%402x.png"
              />
              <div className={styles.timelySupportAndContainer}>
                <p className={styles.yearsOfAverage}>Timely support and</p>
                <p className={styles.yearsOfAverage}>maintenance</p>
              </div>
            </div>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-778%402x.png"
              />
              <div className={styles.competitivePricing}>
                Competitive pricing
              </div>
            </div>
          </div>
        </div>
      </div>
  
 
  

    </div>























    <div className={styles.itMainn}>
 
      <div className={styles.digite}>
        <div className={styles.frameParent10}>
          <div className={styles.frameParent11}>
            <div className={styles.logo1Parent}>
              <b className={styles.b123} style={{marginTop:"0rem"}}>4.8K+</b>
              <div className={styles.marketingBudgetsSpent}>
              Websites Built
              </div>
            </div>
            <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>
            <div className={styles.logo1Parent}>
              <b className={styles.b123}>125+</b>
              <div className={styles.marketingBudgetsSpent}>
                Mobile Applications
                <br /> Developed
              </div>
            </div>
            <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>


            <div className={styles.logo1Parent}>
              <b className={styles.b123}>1000+</b>
              <div className={styles.marketingBudgetsSpent}>Third-Party Integrations</div>
          
            </div>
            <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>


            <div className={styles.logo1Parent}>
              <b className={styles.b123}>50+</b>
              <div className={styles.marketingBudgetsSpent}>
              Stand Alone Softwares
              </div>
            </div>
            <hr style={{height:"0.5vw", margin:"3vw", fontWeight:"700"}}/>


            <div className={styles.logo1Parent}>
              <b className={styles.b123}>100+</b>
              <div className={styles.marketingBudgetsSpent}>Satisfied Clients</div>
            </div>
          </div>
        </div>
      </div>

    







   


     
   

  

      <div className={styles.frameParent16}>
        <div className={styles.transformYourIdeasIntoRealParent}>
          <b className={styles.transformYourIdeas} style={{fontWeight:"600"}}>
          IT Services We Serve
          </b>
          <div className={styles.letUsGuide} style={{fontWeight:"500"}}>
            Let us guide you using our Expertly Crafted IT Services
          </div>
        </div>
        <div className={styles.groupParent9}>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.frameParent17}>
              <div className={styles.frameParent18}>
                <img
                  className={styles.frameChild19}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-725%402x.png"
                />
                <div className={styles.development}>Development</div>
              </div>
              <div className={styles.realizeTheFull}>
              Delivering IT solutions for your CMS requirements, websites, website accessibility, applications software, e-commerce, and mobile apps.
              </div>
              <div className={styles.lineParent}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-50.svg" />
                <Link to="/it-services/it-development" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.frameParent17}>
              <div className={styles.frameParent18}>
                <img
                  className={styles.frameChild19}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-726%402x.png"
                />
                <div className={styles.development}>UI/UX Interactive</div>
              </div>
              <div className={styles.realizeTheFull}>
              Leading to an interaction interface for which your users will thank you. We've got the skills to launch your website and help it stay in the spotlight. 
              </div>
              <div className={styles.lineParent}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-50.svg" />
                <Link to="/it-services/uiux-services" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.frameParent17}>
              <div className={styles.frameParent18}>
                <img
                  className={styles.frameChild19}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-734%402x.png"
                />
                <div className={styles.development}>IT Management</div>
              </div>
              <div className={styles.realizeTheFull}>
              These services are secure, confidential, and will assist you in reaching your business goals while boosting your ability to succeed.
              </div>
              <div className={styles.lineParent}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-50.svg" />
                <Link to="/it-services/it-management-service" style={{ textDecoration:"none" }}>
                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.frameParent17}>
              <div className={styles.frameParent18}>
                <img
                  className={styles.frameChild19}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-728%402x.png"
                />
                <div className={styles.development}>Quality Testing</div>
              </div>
              <div className={styles.realizeTheFull}>
              Our team is ready to help you integrate chatbots, online CMS, and other tools of your choosing. Don’t miss out on any opportunities.
              </div>
              <div className={styles.lineParent}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-50.svg" />
                <Link to="/it-services/quality-testing-service" style={{ textDecoration:"none" }}>

                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.groupChild3} />
            <div className={styles.frameParent17}>
              <div className={styles.frameParent18}>
                <img
                  className={styles.frameChild19}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-725%402x.png"
                />
                <div className={styles.development}>Integrations</div>
              </div>
              <div className={styles.realizeTheFull}>
              With our excellent quality testing team, your program will have its foundations tested and come out strong, with a user-friendly interface.
              </div>
              <div className={styles.lineParent}>
                <img className={styles.lineIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/line-50.svg" />
                <Link to="/it-services/integration-services" style={{ textDecoration:"none" }}>

                <div className={styles.exploreMore}>Explore More.....</div>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>

        <div className={styles.transformYourIdeasIntoRealParent1} style={{textAlign:"center", border:"1px solid #E9EEF4", backgroundColor:"#E9EEF4"}}> 
          <b className={styles.transformYourIdeas111} style={{color:"#0076A2", fontWeight:"600", fontSize:"1.5rem"}}>
          We Assist You in Resolving
 
          </b>
          <div>
          <img
                className={styles.new2}
                style={{width:"100%"}}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3100.png"
              />
              </div>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  A need for complex functionality in your software</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Improving user engagement and accessibility</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Security Requirement Based on Threat Vectors</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Avoid breaches and downtime in the organization's infrastructure</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Improving Network Security Performance</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Chat-bot and third-party software integration</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  Incorporation of cloud-hosted databases</p>
     <p style={{color:"#000000", fontSize:"3.5vw", marginLeft:"3vw", textAlign:"left", fontWeight:"500"}}> <img className={styles.neww4} alt="" style={{width:"4vw"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+3101.svg"/>  The risk of software failure due to software errors and bugs</p>


 

        </div>
   
      <div className={styles.frameParent23}>
        {/* <div className={styles.kracParent}>
          <div className={styles.exploreOtherServices}>KRAC</div>
          <div className={styles.aSoftwareDelivery}>
            A Software Delivery Method cracking the toughest codes with
            copacetic approach of dissecting them down 
          </div>
        </div> */}
        <h1 style={{color:"black", textAlign:"center", fontWeight:"600", margin:"0 1rem"}}>KRAC</h1>
        <p style={{color:"#0076A2", textAlign:"center", fontWeight:"500", margin:"0 1rem"}}>A systematic structured project management process</p>
        <div className={styles.iconSymbole1Parent} style={{margin:"0 2rem"}}>
          <img className={styles.iconSymbole1} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/krac123.png" />
        </div>


<div className={styles.textings} >
  <p style={{fontSize:"15px", fontWeight:"500", lineHeight:"1.5rem"}}>Experience a project management process that really kicks it up a notch with KRAC. Our process has cracked the code to efficient software delivery, boasting a 30% increased efficiency.</p>
<p style={{fontSize:"15px", fontWeight:"500", lineHeight:"1.5rem"}}>We kick it off with discussions about your software requirements and business intentions, which help us create a highly accurate roadmap for the development phase. From there, precise actions are taken towards the fulfillment of a thoroughly tested final product. Our process is transparent for the client and easy to monitor, making the closure phase of the final product handoff simple and stress-free for any business.</p>
</div>
        <FrameComponent />
      </div>
      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.exploreOtherServicess}>
          Problems We have Solved
        </div>
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-739.svg"
              />
              <div className={styles.aNeedFor}>
                A need for complex functionality in your software
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-740.svg"
              />
              <div className={styles.aNeedFor}>
                Improving user engagement and accessibility
              </div>
            </div>
            <div className={styles.groupParent6}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-741.svg"
              />
              <div className={styles.aNeedFor}>
                Security Requirement Based on Threat Vectors
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-742.svg"
              />
              <div className={styles.aNeedFor}>
                Avoid breaches and downtime in the organization's
                infrastructure.
              </div>
            </div>
        
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-743.svg"
              />
              <div className={styles.aNeedFor}>
                Improving Network Security Performance
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-744.svg"
              />
              <div className={styles.aNeedFor}>
                Chat-bot and third-party software integration
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-745.svg"
              />
              <div className={styles.aNeedFor}>
                Incorporation of cloud-hosted databases
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-746.svg"
              />
              <div className={styles.aNeedFor}>
                The risk of software failure due to software errors and bugs
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameParent27}>
        <div className={styles.howToPickYourItFirmParent}>
          <div className={styles.intent}>How to pick your IT firm? </div>
          <div className={styles.topFactorsInvolved}>
            Top factors involved in cracking the code of a successful software
            delivery. 
          </div>
        </div>
        <div className={styles.frameParent28}>
          <div className={styles.frameParent29} >
            <div className={styles.frameParent30}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-774%402x.png"
              />
              <div className={styles.expertiseAndExperience}>
              <p className={styles.yearsOfAverage}>Expertise<br/> and experience</p>
              </div>
            </div>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-775%402x.png"
              />
              <div className={styles.flexibleAndCustomizableContainer}>
                {/* <p className={styles.yearsOfAverage}>{`Flexible and `}</p> */}
                <p className={styles.yearsOfAverage}>Flexible<br/> solutions</p>
              </div>
            </div>
            </div>
            <div className={styles.frameParent29} style={{marginTop:"2rem"}}>
            <div className={styles.frameParent30}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-777%402x.png"
              />
           
              <div className={styles.expertiseAndExperience}>
              <p className={styles.yearsOfAverage}>Security<br/> and compliance</p>
              </div>
            </div>
          
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-773%402x.png"
              />
           
              <div className={styles.flexibleAndCustomizableContainer}>
                {/* <p className={styles.yearsOfAverage}>{`Flexible and `}</p> */}
                <p className={styles.yearsOfAverage}>Customer-centric <br/>approach</p>
              </div>
            </div>
            </div>
            <div className={styles.frameParent29} style={{marginTop:"2rem"}}>


            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-776%402x.png"
              />
           
              <div className={styles.expertiseAndExperience}>
              <p className={styles.yearsOfAverage}>Timely support <br/>and maintenance</p>
              </div>
            </div>
            <div className={styles.frameParent31}>
              <img
                className={styles.frameChild26}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-778%402x.png"
              />
        
              <div className={styles.flexibleAndCustomizableContainer}>
                {/* <p className={styles.yearsOfAverage}>{`Flexible and `}</p> */}
                <p className={styles.yearsOfAverage}>Competitive <br/>pricing</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ItMain;
