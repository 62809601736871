import React from "react";
import styles from "../Stories.module.css";


const TSlide4 = () => {
  return (
    <div class={styles.testimonial}>
                           
        <div class="clients-testo my-auto ">
                                <iframe width="100%" height="280px"
                                    src="https://www.youtube.com/embed/TH9bxibtwls?si=lBcyIg6ufV7L9O8x" loading="lazy"
                                    title="Restaurant Owner Shares His Success Story With Techrowth  #shorts #digitalmarketing"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>




</div>
  );
};

export default TSlide4;
