
import styles from "./ItMain1.module.css";

import { Link } from "react-router-dom";
const ProblemsWeSolved = () => {

  const RecentText = "Ready to take your business to new heights? Book a free consultation today and know more";
  return (
    <>
    <div className={styles.itMain}>


      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.exploreOtherServices}>
        Problems We Solved with <strong>KRAC</strong>
        </div>
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo1.svg"
              />
              <div className={styles.aNeedFor}>
              Software delivery workflows
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo2.svg"

              />
              <div className={styles.improvingUserEngagement}>
              Standardization in development processes
              </div>
            </div>
            <div className={styles.groupParent6}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo3.svg"


              />
              <div className={styles.securityRequirementBased}>
              Software development cycles Optimization

              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo4.svg"


              />
              <div className={styles.avoidBreachesAnd}>
              Managing and tracking project progress
              </div>
            </div>
          </div>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo5.svg"


              />
              <div className={styles.improvingNetworkSecurity}>
              Quality assurance and testing procedures
              </div>
            </div>
            <div className={styles.groupParent9}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo6.svg"


              />
              <div className={styles.chatBotAndThirdParty}>
              Scalability and adaptability of IT services
              </div>
            </div>
            <div className={styles.groupParent9}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo7.svg"


              />
              <div className={styles.incorporationOfCloudHosted}>
              Risk assessment and mitigation strategies
              </div>
            </div>
            <div className={styles.groupParent9}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo8.svg"


              />
              <div className={styles.theRiskOf}>
              Managing resources and project dependencies
              </div>
            </div>
          </div>
        </div>
      </div>
   

    </div>
    <div className={styles.itMainn}>

   


      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.exploreOtherServicess}>
        Problems We have Solved with <strong>BISCCBOR</strong>

        </div>
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo1.svg"


              />
              <div className={styles.aNeedFor}>
              Software delivery workflows
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo2.svg"


              />
              <div className={styles.aNeedFor}>
              Standardization in development processes
              </div>
            </div>
            <div className={styles.groupParent6}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo3.svg"


              />
              <div className={styles.aNeedFor}>
              Software development cycles Optimization

              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo4.svg"


              />
              <div className={styles.aNeedFor}>
              Managing and tracking project progress
              </div>
            </div>
        
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo5.svg"


              />
              <div className={styles.aNeedFor}>
              Quality assurance and testing procedures
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo6.svg"


              />
              <div className={styles.aNeedFor}>
              Scalability and adaptability of IT services
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo7.svg"


              />
              <div className={styles.aNeedFor}>
              Risk assessment and mitigation strategies
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/logo8.svg"


              />
              <div className={styles.aNeedFor}>
              Managing resources and project dependencies
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    </>
  );
};

export default ProblemsWeSolved;
