import styles from "./InterestedDevelopment2.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            E-Commerce Development Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`We offer the following E-Commerce Development Solutions`}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w31.svg"

                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>E-commerce</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our team works closely with you to understand your business needs and deliver customized e-commerce solutions.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`We have developed more than `}</span>
                    <b className={styles.eCommercePortals}>
                      31
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` e-commerce portals.`}</span>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w32.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Mobile Commerce
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                     Mobile commerce has experienced substantial growth in recent years.  This highlights the importance of mobile-friendly e-commerce websites.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
                  <span
                      className={styles.weHaveDeveloped}
                    >{`mobile devices account for `}</span>
                  <b className={styles.eCommercePortals}>
                  60%
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` of total e-commerce transactions`}</span>
                  
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w33.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  E-commerce Growth

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                     Techrowth has witnessed a significant growth in e-commerce. Reflecting the growing demand for online shopping platforms.

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` projects undertaken has increased by `}</span>
                      <b className={styles.websites}>75% </b>
               
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w34.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Average Order Value (AOV) 
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Through effective e-commerce development, this is a result of successful upselling and cross-selling techniques, leading to higher-value purchases by customers.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`achieved an average increase in the `}</span>
                      <b className={styles.websites}>
                      15%
                      </b>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` AOV`}</span>
                    </p>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
