import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import ImageRow from "../components/ImageRow";
import styles from "./NewHomepage.module.css";
import { Helmet } from "react-helmet";

const Portfolio = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/newitMainbg.jpg";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
    ]);
  }, []);

  const HeroTitel =
    "Empowering you for a Better Tomorrow";
  const HeroContent =
    "With our exclusive IT services, we can help your ideas reach fruition, enhance user interaction, and bring you closer to your business goals. Let us assist you in reeling in an abundance of growth opportunities.";
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/techrowth-portfolio" />
<title>Exploring Our Work: Techrowth Portfolio - IT & Marketing Success Stories</title>
<meta name="description" content="Explore Techrowth's portfolio page showcasing our innovative tech solutions and services. We provide seamless IT and digital marketing services to our 100+ clients." />
</Helmet>
      <ImageRow/>
        <div className={styles.newHomepage}>


 
      <ContactForm1/>

      </div>
      <TrustedByBrands/>
    </>
  );
};

export default Portfolio;
