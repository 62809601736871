import React,{useState} from 'react';
import { motion } from "framer-motion";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

const PopupComponent = ({ onClose }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuClick = (submenuName) => {
    if (openSubMenu === submenuName) {
      setOpenSubMenu(null); // Close the clicked submenu
    } else {
      setOpenSubMenu(submenuName); // Open the clicked submenu
    }
  };
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        overflow:"hidden",
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999999,
      }}
    >

      <div style={{ color: 'black', textAlign: 'center' }}>
      <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
      className="container-fluid position-absolute"
      style={{  zIndex: "1", top: "8%", left:"0%" }}
      
    >
      <div className="row py-4 px-4" style={{ gap: "2rem" }}>
      <div className="mobile-nav__wrapper">
       
   
       
       <div className="mobile-nav__content">

    

         <div className="mb-3 mx-0">
           <Sidebar style={{width:"100%", textAlign:"left", border:"1px solid white"}} >
             <Menu>
               <MenuItem component={<Link to="/" />} onClick={onClose}  style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", fontSize:"20px",height:"5rem"}}>Home</MenuItem>

               <SubMenu label="Digital Marketing" onClick={() => handleSubMenuClick("digital-marketing")} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"5rem",fontSize:"20px" }}>
               <MenuItem component={<Link to="/digital-marketing" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }} >
               Digital Marketing
                 </MenuItem>
                 <MenuItem component={<Link to="/digital-marketing/intent-we-serve" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }} >
                 Intent We Serve
                 </MenuItem>

                 <SubMenu label="Our Services"  style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}>
                 <MenuItem component={<Link to="/digital-marketing/social-media-marketing" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem"  }}>
                   Social Media Marketing
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/social-media-optimization" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem"  }}>
                   Social Media Optimization
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/search-engine-optimization" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem"  }}>
                   Search Engine Optimization
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/search-engine-marketing" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem"  }}>
                   Search Engine Marketing
                   </MenuItem>

                   <MenuItem component={<Link to="/digital-marketing/pay-per-click" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem"  }}>
                   Pay Per Click
                   </MenuItem>
                   <MenuItem component={<Link to="/digital-marketing/email-marketing" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem"  }}>
                   Email Marketing
                   </MenuItem>
                 </SubMenu>

                 {/* <MenuItem component={<Link to="/digital-marketing/graphic-design" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem"  }}>
                 Graphic Design 
                 </MenuItem> */}

                 <MenuItem component={<Link to="/digital-marketing/ecommerce-marketing" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem"  }}>
                 E-commerce 
                 </MenuItem>
                 <MenuItem component={<Link to="/digital-marketing/content-marketing" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem"  }}>Content</MenuItem>
               </SubMenu>
               <SubMenu label="IT Services" onClick={() => handleSubMenuClick("IT Services")} style={{ backgroundColor: "white",height:"5rem", fontSize:"20px", border: "1px white",  color:"black" , fontWeight:"500" }}
               >
                        <MenuItem
                   component={<Link to="/it-services" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}
                 >
                  IT Services
                 </MenuItem>
                 <SubMenu label="Development" style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}>
                   <MenuItem component={<Link to="/it-services/it-development" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   Development
                   </MenuItem>
                   <MenuItem component={<Link to="/it-services/it-development/cms-development-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   Content Management System
                   </MenuItem>
                   <MenuItem component={<Link to="/it-services/it-development/website-development-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   Website
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/ecommerce-development-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   E-Commerce
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/web-application-development-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   Web Application
                   </MenuItem>

                   <MenuItem component={<Link to="/it-services/it-development/mobile-application-development-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   Mobile Application
                   </MenuItem>
                   <MenuItem component={<Link to="/it-services/it-development/website-accessibility-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"14px", marginBottom:"1rem" }}>
                   Website Accessibility
                   </MenuItem>
                 </SubMenu>
             

                 <MenuItem
                   component={<Link to="/it-services/uiux-services" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}
                 >
                   UI/UX  Interactive
                 </MenuItem>

                 <MenuItem component={<Link to="/it-services/it-management-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}>
                 IT management 
                 </MenuItem>
                 <MenuItem component={<Link to="/it-services/integration-services" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}>
                 Integration
                 </MenuItem>
                 <MenuItem component={<Link to="/it-services/quality-testing-service" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}>
                 Quality Testing 
                 </MenuItem>
               </SubMenu>
          
               <SubMenu label="Who We Are"  onClick={() => handleSubMenuClick("Who We Are")} style={{ backgroundColor: "white", fontSize:"20px",height:"5rem", border: "1px white",  color:"black" , fontWeight:"500" }}>
                 <MenuItem component={<Link to="/about-us" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", height:"1rem", fontSize:"18px", marginBottom:"1rem" }}>
                 About Us 
                 </MenuItem>
                 <MenuItem component={<Link to="/contact-us" />} onClick={onClose} style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500" , height:"1rem", fontSize:"18px", marginBottom:"1rem"}}>
                 Contact Us
                 </MenuItem>
                
               </SubMenu>
       
               <MenuItem component={<Link to="/techrowth-knowledge" />} onClick={onClose}  style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", fontSize:"20px",height:"5rem"}}>Techrowth Knowledge</MenuItem>
               <MenuItem component={<Link to="/blogs" />} onClick={onClose}  style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", fontSize:"20px",height:"5rem"}}>Blogs</MenuItem>
               <MenuItem component={<Link to="/techrowth-portfolio" />} onClick={onClose}  style={{ backgroundColor: "white", border: "1px white",  color:"black" , fontWeight:"500", fontSize:"20px",height:"5rem"}}>Portfolio</MenuItem>


             </Menu>
           </Sidebar>
         </div>

     
       </div>
     </div>
    
      </div>
    </motion.div>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '2rem',
            cursor: 'pointer',
            color:"black"
          }}
        >
            <img  alt="" style={{width:"2rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/clear.svg" />

          
        </button>
      </div>

    </div>
  );
};

export default PopupComponent;
