import React, { useEffect, useState } from "react";
import Map from "../components/Map";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./NewHomepage.module.css";
import { Helmet } from "react-helmet";
const WhoWeArePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "About Us", path: "/about-us" },
    ]);
  }, []);
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/contact-us" />
<title>Get in Touch with Techrowth: Contact Us for Expert IT & Marketing Solutions</title>
<meta name="description" content="Contact Techrowth for the best IT and digital marketing services in Bangalore. Our team is dedicated to providing exceptional service and support. Contact us today for expert assistance." />
</Helmet>
        <div className={styles.newHomepage}>
<Map/></div>
      <TrustedByBrands />
    </>
  );
};

export default WhoWeArePage;
