import { useEffect } from "react";
import TrustedByBrands from "../components/TrustedByBrands";
import styles from "./case1.module.css";
import styless from "../components/ContactUsBar.module.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import Test from "../components/test10"
import Test1 from "../components/testph10"
const CaseStudy1 = () => {
  const bgImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40292%402x.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [value, setValue] = useState();

  function handleDownload() {
    const link = document.createElement('a');
    link.href = 'https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Case+Study+1.pdf';
    link.target = '_blank'; // Open the link in a new tab or window
    link.download = 'Case_Study_1.pdf';
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }
  return (
    <>
        <div className={styles1.newHomepage}>
          <div className={styles1.ph}>
          <Test />

          </div>
          <div className={styles1.ph1}>
          <Test1 />

</div>

      <ContactForm1/></div>
      <TrustedByBrands />
    </>
  );
};

export default CaseStudy1;
