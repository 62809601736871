import React, { useState, useEffect } from "react";
import FullScreenButton from "./example9";
import TrustedByBrands from "../components/TrustedByBrands";
import "./PhoneDetector.css";
import 'react-phone-number-input/style.css'
import styles from '../components/ImageRow.module.css';
import ContactForm1 from "../components/ContactForm1";
import styles1 from "./NewHomepage.module.css";
import { Link } from "react-router-dom";




const PhoneDetector = () => {
  return (
    <>
    <div className={styles1.newHomepage}>
    <div className="portfolio-headings pt-4 pb-2" style={{width:"100%" }}>
      <div className="container">
        <div className="product-heading-display">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="product-header">
                <div className="row">
                  <div className="col-lg-1 col-2" style={{margin:"auto 0"}}>
                    <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/23.png" alt="" className="img-fluid" style={{width:"5rem"}}/>
                  </div>
                  <div className="col-lg-10 col-10">
                    <h2 className="mb-0" style={{ color: "#0076a2" }}>Dr. Native</h2>
                    <h5 style={{ color: "#0076a2" }}>#Ecommerce Sector <br/>
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}>Hire Us</span>
                    </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    
    </div>
1
    <div className="App">
      <FullScreenButton />
    </div>
    <div className="App1 container">
    <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture48.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
              <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture49.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture50.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture51.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
                      <img className="mt-3"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/Picture52.png" // Replace with the URL of your image
          alt="Description of your image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />


    </div>
        <div className="container my-5">
    <div className="row" style={{marginTop:"10rem"}}>
        <div className="col-md-8 col-12">
        <h1 style={{ color: "#0076a2" }}>Dr. Native</h1>
      <h5>Discover our pet care product websites. We've designed user-friendly platforms to make pet care a breeze. Explore our portfolio to see how we've helped pet care brands connect with pet lovers. Our websites are designed to simplify shopping for your furry friends, offering a seamless and delightful experience for both pets and their owners.</h5>
 <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Sector : </span>Ecommerce Sector</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Problem Statement : </span>Dr.Native, a prominent e-commerce entity, wanted a comprehensive website. Their vision was to transform their digital presence into a user-friendly, seamless e-commerce platform equipped with a secure payment gateway.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Solution : </span>We carefully redesigned and revamped their website adding their petcare products. Alongside, we integrated Instamojo, providing a secure and efficient payment gateway. The result was a polished and user-centric e-commerce platform that not only met but exceeded Dr.Native's expectations.

</h5>
    <h5  className="my-3"><span style={{ color: "#0076a2",fontWeight:"600" }}>Tools and Techniques : </span>WordPress, Elementor, Woocommerce, Instamojo
</h5>





      <h5 style={{ color: "#0076a2",fontWeight:"600" }}>Need a Website ? 
                    <Link to="/contact-us" style={{textDecoration:"none" }}>
                    <span style={{ marginTop:"1rem", fontWeight: 500, color: "#FB0404", cursor: "pointer"}}> Hire Us</span>
                    </Link>
                    </h5>
        </div>
        <div className="col-md-4 col-12" style={{height:"33rem", overflowY:"scroll",border:"2px solid #e9eef4", padding:"10px"}}>
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Fullscreen/10.jpg" alt="Image 2" className="img-fluid"/>

        </div>
      </div>

                    <hr className="my-5" style={{ color: "#0076a2" }} />
                    <h4>You might also like</h4>
                    <div className={styles.imageContainer}>
                    <Link to="/techrowth-portfolio/sri-krishna-group" style={{textDecoration:"none", color:"black"}}>
  
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/10.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/9.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Sri Krishna Group</p>
          </div>
        </div>
   
     </Link>
     <Link to="/techrowth-portfolio/znbs-property" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/11.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/8.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>ZNBS Property</p>
          </div>
        </div>

      </Link>
      <Link to="/techrowth-portfolio/neha-prakash-hospital" style={{textDecoration:"none", color:"black"}}>

        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/12.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/19.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Neha Prakash Hospital</p>
          </div>
        </div>
   
      </Link>
      </div>
    </div>
    <div className={styles1.newHomepage}>

    <ContactForm1/></div>
      <TrustedByBrands />
      </>
  );

};





export default PhoneDetector;
