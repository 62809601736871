import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth203";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues7 from "../components/OurCoreValues7";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain7 from "../components/OtherServicesmain7";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth223";
import SatisfiedClients from "../components/SatisfiedClients";

import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const ITManagement = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/Itmanagementbgimg.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M22.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
      { name: "IT Management", path: "/it-services/it-management-service" },
    ]);
  }, []);
  const subject = "IT Management Service ?" 

  const HeroTitel =
    "IT Management Service";
    
  const HeroContent =
    "Optimize efficiency with our IT Management Service in Bangalore. From strategy to execution, we streamline your IT infrastructure. Improve productivity, secure your systems, and stay ahead of the competition with our cutting-edge solutions.";
  return (
    <>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your IT potential with our IT Management Service in Bangalore"
        btnTxt="Contact Us"
      /></Link>
           <AccelerateYourGrowth />
           <OurCoreValues7/>
           <OtherServicesmain7/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default ITManagement;
