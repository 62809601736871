import React from "react";
import styles1 from "../Stories6.module.css";
import styles from "../FounderNote1.module.css";


const TSlide2 = () => {
  return (
    <div className={styles1.div4}>
   
      <div className={styles1.weHaveBeenUsingTechrowthFParent}>
      <div className={styles.founderNote}>
          <div className={styles.founderNoteChild} />
           <div className={styles.founderNoteInner}>
            <img className={styles.frameChild} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-355.svg" />
          </div>
          <img
            className={styles.asifSirF12Icon1111}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/rawathsir.png"
          />
        <div className={styles.founderNoteItem} />
            <div className={styles.frameParent}>
            <div className={styles.coFounderNoteParent}>
              <div className={styles.coFounderNote}>Co-Founder Note</div>
              <div className={styles.managingDirectorParent}>
                <div className={styles.managingDirector123}>Chairman & Co-Founder</div>
                <div className={styles.asifR1}>- Gangadhar Kamath</div>
              </div>
            </div>
            <div className={styles.weAreDriven}>
            We at Techrowth are focused on empowering businesses and individuals to excel in the digital realm. The IT solutions we develop are tailored to streamline software applications and processes, which enhance efficiency using our patented frameworks and the latest cutting-edge technologies. From web and mobile app development to cloud computing and much-needed quality testing, we have come a long way towards helping our fellow businesses. Our specialisation in digital marketing, through a unique digital marketing strategy framework, creates custom campaigns that target your ideal audience, optimise your online presence, and maximise ROI. We boost brand visibility on all frontiers with our Digital Marketing Solutions and build and enhance customer engagement through strong IT solutions. Trust Techrowth for a strong online presence and transformative IT.
            </div>
          </div>
        </div>
      </div>
   
    </div>
  );
};

export default TSlide2;
