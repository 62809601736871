import styles from "./InterestedDevelopment2.module.css";
const SocialMediaMarketingSolutions = () => {
  return (
    <div className="container-fluid " style={{padding:"0rem  2rem 0rem 2rem"}}>
      <div className="row">
        <div className={styles.frameParent}>
          <div className={styles.interestedInSoftwareDevelopParent}>
            <b className={styles.interestedInSoftware}>
            Website Development Solutions
            </b>
            <div
              className={styles.weOfferThe}
            >{`We offer the following Website Development Solutions`}</div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w11.svg"
                />
                <div className={styles.eCommerceParent}>
                  <div className={styles.eCommerce}>Corporate Website</div>
                  <div className={styles.ourTeamWorksContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our team is dedicated to delivering user-friendly and<br/> efficient applications that help you achieve your business goals.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <span
                      className={styles.weHaveDeveloped}
                    >{`Our team has reworked and enhanced over `}</span>
                    <b className={styles.eCommercePortals}>
                      34
                    </b>
                    <span
                      className={styles.weHaveDeveloped}
                    >{` websites.`}</span>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w12.svg"

                />
                <div className={styles.mobileApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Website Accessibility
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    Our team specializes in optimizing your website for all users, including those with disabilities, to ensure that your website is inclusive and accessible to everyone.
                    </p>
                  </div>
                  <div className={styles.optimizedAndDevelopedContainer}>
             
                    <span
                      className={styles.weHaveDeveloped}
                    >{`We have increased user engagement by `}</span>
                       <b className={styles.eCommercePortals}>
                  34%
                    </b>
                  </div>
                </div>
              </div>
           
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w13.svg"

                />
                <div className={styles.corporateWebsiteParent}>
                  <div className={styles.mobileApplications}>
                  Responsive Design

                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    There has been a significant increase in the adoption of responsive design by Techrowth. 

                    </p>
                  </div>
                  <div className={styles.ourTeamHasContainer}>
                    <p className={styles.ourTeamWorks}>
              
                      <b className={styles.websites}>95%</b>
                      <span className={styles.weHaveDeveloped}> of the websites developed  are responsive.</span>
     

                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.groupContainer}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/w14.svg"

                />
                <div className={styles.webApplicationsParent}>
                  <div className={styles.mobileApplications}>
                  Mobile Traffic
                  </div>
                  <div className={styles.weSpecializeInContainer}>
                    <p className={styles.ourTeamWorks}>
                    With the rise of mobile usage, Techrowth has observed a substantial increase in mobile traffic to the websites they have developed Mobile.
                    </p>
                  </div>
                  <div className={styles.weHaveDevelopedContainer}>
                    <p className={styles.ourTeamWorks}>
                      <span
                        className={styles.weHaveDeveloped}
                      >{`Traffic now accounts for `}</span>
                      <b className={styles.websites}>
                      65%
                      </b>
                      <span
                        className={styles.weHaveDeveloped}
                      >{` of total website visits`}</span>
                    </p>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaMarketingSolutions;
