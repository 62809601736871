import styles from "./AdvantageOfTechrowth.module.css";
import NumberCounter from 'number-counter';

const InterestedInTechrowth1 = ({ title}) => {
  return (
    <div className={styles.secondPageIntent}>
      <div className="container py-5">
      <b className={styles.advantageOfTechrowth} style={{fontWeight:"600"}}>{title}</b>
      <div className={styles.weExploreDifferentContainer +" my-4"}>
        <p className={styles.weExploreDifferent}>
        Strategic Digital Marketing Solutions for a Streamlined Journey
        </p>
      </div>
      <div className={styles.secondPageIntentInner +" my-4"}>
        <div className={styles.frameParent }>
          <div className={styles.parent}>
          {/* <NumberCounter end={34} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>11 Mn +</div>
            <div className={styles.incrementInGross}>
            Leads Generated
            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={21} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>35 MN+</div>
            <div className={styles.incrementInSocial}>
            Downloads
            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={9} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>20%</div>
            <div className={styles.incrementInSocial}>
            Increase in Conversion 

            </div>
          </div>
          <div className={styles.parent}>
          {/* <NumberCounter end={36} delay={1} className={styles.div} postFix="%"/> */}
            <div className={styles.div}>36%</div>
            <div className={styles.incrementInSocial}>
            Increased Customer Retention

            </div>
          </div>
        </div>
      </div>
      <div className={styles.inTheRealmContainer}>
        <p className={styles.weExploreDifferent}>
        Here's a glimpse of our outstanding achievements and the impact we've made with our digital marketing services in Bangalore

        </p>
      </div>
    </div>
    </div>
  );
};

export default InterestedInTechrowth1;
