import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactPage.css';
import styles from "../pages/NewHomepage.module.css";

import ContactForm2 from "../components/ContactForm1"
function ContactPage() {
  const [activeMap, setActiveMap] = useState('india-map');

  const showMap = (mapId) => {
    setActiveMap(mapId);
  };

  return (
    <div>
        <h1 className="contact">Contact Us</h1>
      <section className="journey-sec">
        <div className="container">
          <div className="journey-desktop mt-4">
            <img
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/journey.png"
              className="img-fluid"
              alt="..."
            />
          </div>

          <div className="journey-mobile mt-4">
            <img
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/journey.png"
              className="img-fluid"
              alt="..."
            />
          </div>
        </div>
      </section>

      <section className="journey-details">
        <div className="container">
          <div className="journey-details-open">
            <div className="row">
              <div className="col-sm">
                <div className="team-box">
                  <div className="image-con pb-3">
                    <img
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/team-size.png"
                      className="team-size"
                      alt="Team Size"
                    />
                  </div>
                  <h2>
                    Team size <span>126 </span> and Increasing
                  </h2>
                  <p>
                    We are an organisation that promotes equal opportunities for everyone and gender equality in our organisation
                  </p>
                </div>
              </div>
              <div className="col-sm divider">
                <div className="exp-box">
                  <div className="image-con pb-3">
                    <img
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/contact/exp.png"
                      className="experence"
                      alt="Experience"
                    />
                  </div>
                  <h2>
                    Average Experience <span>7+</span> yrs
                  </h2>
                  <p>
                    We are an organisation that promotes equal opportunities for everyone and gender equality in our organisation
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.newHomepage} style={{marginTop:"2rem"}}>
<ContactForm2/>
</div>
      <section className="maps my-5">
        <div className="container">
          <div className="switch-section-buttons">
            <div id="buttons">
              <button onClick={() => showMap('india-map')}>India</button>
              <button onClick={() => showMap('zambia-map')}>Zambia</button>
              <button onClick={() => showMap('australia-map')}>Australia</button>
            </div>
          </div>

          <div id="map-container">
            <div id="india-map" className={`map ${activeMap === 'india-map' ? 'active' : ''}`}>
              {/* India map content goes here */}
              <div className="addess-loc">
              No.373, Garudadri Arcade, 2nd floor, 2nd Phase, WOC Road, Stage 2, Rajajinagar Bengaluru, Karnataka 560086
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.4626928491607!2d77.5459812734172!3d13.006180814122512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3de7b3c65d0b%3A0x819ce263361998e7!2sTechrowth%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1689242002000!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div id="zambia-map" className={`map ${activeMap === 'zambia-map' ? 'active' : ''}`}>
              {/* Zambia map content goes here */}
              <div className="addess-loc">
                2nd Floor Mukuba, Mukuba Pension House, C/O iZyane InovSolutions Ltd, Dedan Kimathi Rd, Lusaka 10100
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.187227045155!2d28.288793599999995!3d-15.420443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f3d3759768df%3A0x4d2f7c4b1cf69e95!2sTechrowth%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1689242032312!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div id="australia-map" className={`map ${activeMap === 'australia-map' ? 'active' : ''}`}>
              {/* Australia map content goes here */}
              <div className="addess-loc">10 Willesden Ave, Kingsley WA 6026, Australia</div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3390.288291945444!2d115.7862575!3d-31.8171412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32ac46a0799a83%3A0xf75262b58222e442!2s10%20Willesden%20Ave%2C%20Kingsley%20WA%206026%2C%20Australia!5e0!3m2!1sen!2sin!4v1689242053072!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactPage;
