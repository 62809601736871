import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's E-commerce Development Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Deliver a top-notch shopping experience for customers`}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Customized Ecommerce Solutions</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth provides tailored Ecommerce development solutions that cater to your specific business needs and objectives. They create a unique online store that aligns with your brand identity, product offerings, and target audience, ensuring a seamless shopping experience for your customers.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`User-Friendly Interface`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>A user-friendly Ecommerce website is crucial for driving conversions and customer satisfaction. Techrowth prioritizes user experience (UX) in their development process, designing intuitive navigation, easy-to-use shopping carts, and clear calls-to-action that encourage visitors to complete their purchase journey.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Mobile-Optimized Design</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>With the increasing number of mobile shoppers, having a mobile-responsive Ecommerce website is essential. Techrowth ensures that your online store is optimized for mobile devices, providing a consistent and enjoyable shopping experience across smartphones and tablets.</p>
              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Payment and Security Integration</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth integrates secure payment gateways into your Ecommerce website, enabling smooth and safe transactions for your customers. They prioritize security measures to protect sensitive customer information, instilling trust and confidence in your online store.</p>
      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`E-Commerce Development and Scalability`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth develops Ecommerce websites that are scalable and flexible, accommodating your business's growth and evolving needs. Whether you want to add new products, expand your product categories, or introduce new features, their Ecommerce development service can easily adapt to your requirements.</p>
      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Analytics and Reporting</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth integrates analytics tools into your Ecommerce website to track key performance metrics, such as sales, conversion rates, and customer behavior. With comprehensive data insights, you can make informed decisions to optimize your online store and marketing strategies.</p>
    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's E-commerce Development Services</h4>
      <p className={styles.businessAnalystics3}>Deliver a top-notch shopping experience for customers</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized Ecommerce Solutions</h4>
      <p className={styles.cms1}>Techrowth provides tailored Ecommerce development solutions that cater to your specific business needs and objectives. They create a unique online store that aligns with your brand identity, product offerings, and target audience, ensuring a seamless shopping experience for your customers.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>User-Friendly Interface</h4>
      <p className={styles.cms1}>A user-friendly Ecommerce website is crucial for driving conversions and customer satisfaction. Techrowth prioritizes user experience (UX) in their development process, designing intuitive navigation, easy-to-use shopping carts, and clear calls-to-action that encourage visitors to complete their purchase journey.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Mobile-Optimized Design</h4>

      <p className={styles.cms1}>With the increasing number of mobile shoppers, having a mobile-responsive Ecommerce website is essential. Techrowth ensures that your online store is optimized for mobile devices, providing a consistent and enjoyable shopping experience across smartphones and tablets.</p>
     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Payment and Security Integration</h4>
      
      <p className={styles.cms1}>Techrowth integrates secure payment gateways into your Ecommerce website, enabling smooth and safe transactions for your customers. They prioritize security measures to protect sensitive customer information, instilling trust and confidence in your online store.</p>
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>E-Commerce Development and Scalability</h4>
      <p className={styles.cms1}>Techrowth develops Ecommerce websites that are scalable and flexible, accommodating your business's growth and evolving needs. Whether you want to add new products, expand your product categories, or introduce new features, their Ecommerce development service can easily adapt to your requirements.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Analytics and Reporting</h4>
 
      <p className={styles.cms1}>Techrowth integrates analytics tools into your Ecommerce website to track key performance metrics, such as sales, conversion rates, and customer behavior. With comprehensive data insights, you can make informed decisions to optimize your online store and marketing strategies.</p>


</div>


    
</div>
</>
  );
};

export default Services;
