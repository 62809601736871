import React from "react";
import styles from "../Storiesfound.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div4}>
      <h4 style={{color:"var(--blue)", marginTop:"1rem", fontWeight:"600"}}>Co-Founder Note</h4>

      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/Rectangle+4083.png" />
      <div className={styles.weHaveBeenUsingTechrowthFParent}>
        <div className={styles.weHaveBeenContainer}>
          <p
            className={styles.revolutionizingTraditionalBa}
          >{`"Techrowth empowers businesses and individuals with tailored IT solutions. From app development to digital marketing, we enhance efficiency using cutting-edge technology. Trust us for a strong online presence and IT transformation."`}</p>
        </div>

        {/* <img className={styles.groupIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group2.svg" /> */}
      </div>
      <div style={{textAlign:"right", width:"15rem"}}>
      <h5 style={{color:"var(--blue)", marginBottom:"0"}}>- Gangadhar Kamath</h5>
        <p style={{color:"black", fontWeight:"500"}}>Chairman & Co-Founder</p>
        </div>
      <div className={styles.rectangleDiv} />
    </div>
  );
};

export default TSlide1;
