import styles from "./SatisfiedClients.module.css";

const SatisfiedClients = ({show}) => {
  return (
    <>
    <div className={styles.satisfiedClients}>
      <div className={styles.frameParent}>
     
          <div className={styles.joinTheRanksOfOurSatisfieParent}>
            <div className={styles.joinTheRanks}>
              Join the Ranks of Our Satisfied Clients
            </div>
            <b className={styles.andCounting} style={{fontWeight:"600"}}>100+ and Counting</b>
          </div>
  
        <div className={styles.frameWrapper}>
     

          <div className={styles.frameContainer}>
            <div className={styles.frameGroup}>
              <div className={styles.frameGroup}>
                <div className={styles.n1Parent}>
        
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/14.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/15.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/2.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/18.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/20.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/21.png" />

                </div><br/>
                <div className={styles.ggLogo1Parent}>
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/19.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/16.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/3.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/4.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/17.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/7.png" />
                </div><br/>
                <div className={styles.ggLogo1Parent}>
                <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/8.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/1.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/5.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/6.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/9.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/13.png" />
                </div><br/>
              </div>
              <div className={styles.whatsappImage20230131At1Group} style={{gap:"4rem"}}>
              <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/10.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/12.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/22.png" />
                  <img className={styles.izb1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/11.png" />
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.satisfiedClients1}>
      <h6 className={styles.satisfiedClients2}>Join the Ranks of Our Satisfied Clients</h6>
      <h3 className={styles.satisfiedClients3} >100+ and Counting</h3>
    <div className={styles.satisfiedClients4} style={{marginBottom:"1rem"}}>

      <img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem", width:"8rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/IZB.png"/>
      
      <img className={styles.n1Icon1} alt="" style={{width:"7rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/c2.png"/>
      </div>
    <div className={styles.satisfiedClients4} style={{marginBottom:"1rem"}}>
     


      <img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem",width:"3.5rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/c3.png"/>
 
      <img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/09.png"/>
      <img className={styles.n1Icon1} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/10.png"/>
</div>

<div className={styles.satisfiedClients4} >
     

     <img className={styles.n1Icon1} alt="" style={{width:"6rem", marginRight:"1rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/2.png"/>
     <img className={styles.n1Icon1} alt="" style={{width:"6rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/16.png"/>


</div>
<div className={styles.satisfiedClients4} style={{marginBottom:"1rem"}}>

<img className={styles.n1Icon1} alt="" style={{marginRight:"1.5rem", width:"8rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/14.png"/>

<img className={styles.n1Icon1} alt="" style={{width:"7rem"}} src="https://techrowth.s3.eu-north-1.amazonaws.com/clients+logo/15.png"/>
</div>



    </div>
    </>
  );
};

export default SatisfiedClients;
