import styles from "./Services.module.css";

import { Link } from "react-router-dom";

const ITServices1 = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth’s Website Development Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Captivating custom websites tailored to your business needs`}</p>
            
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Responsive Design</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Engage users on all platforms with a fully adaptable website that adjusts to all screen widths, offering an adaptive and user-friendly experience on desktops, tablets, and smartphones. Our team of talented engineers makes sure that the design, functionality, and content of your website automatically adapt to various devices, keeping visitors interested and boosting conversion rates.</p>

                  </div>
                </div>
              </div>
             
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Intuitive User Experience`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Increase customer satisfaction with our professionally designed user-friendly interfaces, simple call-to-action buttons, and straightforward navigation. We ensure that users can navigate your website with ease, discover the information they need, and perform the activities you want them to, eventually increasing engagement and conversion rates.</p>

                  </div>
                </div>
              </div>
            
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>SEO Optimization</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>With our skillfully executed built-in SEO optimisation strategies, you may raise your website's visibility and search engine ranks. In order to make it simpler for potential clients to find your company online, our team of talented developers and SEO specialists collaborate to ensure that your website is optimised for search engines. </p>

                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Scalability and Performance</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>With the help of our scalable architecture and effective coding practises, you can make sure that your website works smoothly even under heavy traffic. Our skilled developers use cutting-edge tools and finest coding techniques to create scalable, reliable websites that can manage large amounts of traffic without slowing down. </p>

                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Content Management System (CMS)</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>With our simple-to-use CMS, you can easily manage and change the content of your website while maintaining control over your online presence. Without any technical knowledge, you can easily update, publish, and organise your website's content using our proprietary content management system (CMS). </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
    
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth’s Website Development Services</h4>
      <p className={styles.businessAnalystics3}>Captivating custom websites tailored to your business needs</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Responsive Design</h4>
      <p className={styles.cms1}>Engage users on all platforms with a fully adaptable website that adjusts to all screen widths, offering an adaptive and user-friendly experience on desktops, tablets, and smartphones. Our team of talented engineers makes sure that the design, functionality, and content of your website automatically adapt to various devices, keeping visitors interested and boosting conversion rates.</p>


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Intuitive User Experience</h4>
      <p className={styles.cms1}>Increase customer satisfaction with our professionally designed user-friendly interfaces, simple call-to-action buttons, and straightforward navigation. We ensure that users can navigate your website with ease, discover the information they need, and perform the activities you want them to, eventually increasing engagement and conversion rates.</p>


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>SEO Optimization</h4>
      <p className={styles.cms1}>With our skillfully executed built-in SEO optimisation strategies, you may raise your website's visibility and search engine ranks. In order to make it simpler for potential clients to find your company online, our team of talented developers and SEO specialists collaborate to ensure that your website is optimised for search engines. </p>
     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Scalability and Performance</h4>
      <p className={styles.cms1}>With the help of our scalable architecture and effective coding practises, you can make sure that your website works smoothly even under heavy traffic. Our skilled developers use cutting-edge tools and finest coding techniques to create scalable, reliable websites that can manage large amounts of traffic without slowing down. </p>
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Content Management System (CMS)</h4>
      <p className={styles.cms1}>With our simple-to-use CMS, you can easily manage and change the content of your website while maintaining control over your online presence. Without any technical knowledge, you can easily update, publish, and organise your website's content using our proprietary content management system (CMS). </p>


</div>




</div>
</>
  );
};

export default ITServices1;
