import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import { useEffect } from "react";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(

  
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


reportWebVitals();
