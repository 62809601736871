import styles from "./AccelerateYourGrowth.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { useState } from "react";

const AccelerateYourGrowth = () => {
  const LeftArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-358.svg";
const RightArrow = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/group-359.svg";
  const [marketingShow, setMarketingShow] = useState(true);
  const [technologyShow, setTechonologyShow] = useState(false);
  const [analyticsShow, setAnalyticsShow] = useState(false);
  const [activeButton, setActiveButton] = useState("marketing"); 
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        onClick={onClick}
        alt=""
        style={{
          ...style,
          display: "block",
          width: "40px",
          height: "40px",
          left: "-50px",
        }}
        src={LeftArrow}
      />
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={RightArrow}
        className={className}
        style={{
          ...style,
          display: "block",
          width: "40px",
          height: "50px",
          right: "-50px",
        }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
        },
      },
    ],
  };
  const MarketingTools = () => {
    setActiveButton("marketing"); // Set activeButton to "marketing"
    setTechonologyShow(false);
    setMarketingShow(true);
    setAnalyticsShow(false);
  };
  
  const TechnologyTools = () => {
    setActiveButton("technology"); // Set activeButton to "technology"
    setMarketingShow(false);
    setTechonologyShow(true);
    setAnalyticsShow(false);
  };
  
  const AnalyticsTools = () => {
    setActiveButton("analytics"); // Set activeButton to "analytics"
    setMarketingShow(false);
    setTechonologyShow(false);
    setAnalyticsShow(true);
  };
  return (
    <div className={styles.accelerateYourGrowth}>
      <div className={styles.frameParent}>
        <div className={styles.frameParent}>
          <div className={styles.accelerateYourGrowthHeading}>
            <b className={styles.accelerateYourGrowth1} style={{fontWeight:"600"}}>
              Accelerate Your Growth
            </b>
            <div className={styles.withOurVersatile}>
              with Our Versatile Technology Proficiency
            </div>
          </div>
          <div className={styles.frameGroup}>



<button className={`${styles.groupContainer} ${activeButton === "marketing" ? styles.active : ""}`} onClick={MarketingTools}>
  <div className={styles.rectangleParent}>
    <div className={styles.marketing}>Marketing</div>
  </div>
</button>
<button className={`${styles.groupContainer} ${activeButton === "technology" ? styles.active : ""}`} onClick={TechnologyTools}>
  <div className={styles.rectangleParent}>
    <div className={styles.marketing}>Technology</div>
  </div>
</button>
<button className={`${styles.groupContainer} ${activeButton === "analytics" ? styles.active : ""}`} onClick={AnalyticsTools}>
  <div className={styles.rectangleParent}>
    <div className={styles.marketing}>Analytics</div>
  </div>
</button>
          </div>
        </div>
      
        {marketingShow && (
          <div className="container">
            <div className="row d-block align-items-center">
              <Slider {...settings}>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/marketing1.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/marketing2.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/marketing3.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/marketing4.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/marketing5.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/marketing6.png"

                  />
                </div>
              </Slider>

            </div>
          </div>
        )}
        {technologyShow && (
          <div className="container">
            <div className="row d-block align-items-center">
              <Slider {...settings}>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/technology1.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/technology2.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/technology3.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/technology4.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/technology5.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/technology6.png"

                  />
                </div>
              </Slider>
            </div>
          </div>
        )}
                {analyticsShow && (
          <div className="container">
            <div className="row d-block align-items-center">
              <Slider {...settings}>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/analytics1.png"
                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/analytics2.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/analytics3.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/analytics4.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/analytics5.png"

                  />
                </div>
                <div className="col">
                  <img
                    className={styles.images1Icon}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/analytics6.png"

                  />
                </div>
              </Slider>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccelerateYourGrowth;
