import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth’s Search Engine Optimization  Services</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Improve your online presence and achieve business goals `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Proven SEO Track Record</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth has a solid track record of successfully implementing SEO strategies for various clients across different industries. Their expertise in SEO ensures that they can effectively optimize your website to achieve higher rankings in search engine results pages (SERPs), leading to increased organic traffic and potential customers.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Customized SEO Strategies`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth understands that every business is unique, and there is no one-size-fits-all approach to SEO. They take the time to understand your business goals, target audience, and industry competition to create a tailored SEO strategy that aligns with your specific needs. This personalized approach maximizes the impact of their efforts and delivers better results.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>On-Page and Off-Page Optimization</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Their SEO service covers both on-page and off-page optimization. On-page optimization involves optimizing elements on your website, such as meta tags, keywords, content, and site structure, to make it more search engine-friendly. Off-page optimization focuses on building high-quality backlinks and establishing your website's authority across the web.</p>

              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Delivering An Enhanced User Experience</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>In addition to optimizing for search engines, Techrowth also emphasizes enhancing the overall user experience on your website. A user-friendly and well-structured website leads to higher engagement, reduced bounce rates, and increased conversion rates. Search engines value positive user experiences, and this can positively impact your search rankings.</p>

      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Stay Updated with Algorithm Changes`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Search engines frequently update their algorithms, impacting how websites rank in search results. Techrowth's SEO team stays up-to-date with these changes and adjusts their strategies accordingly. This ensures that your website remains compliant with the latest SEO guidelines and maintains its visibility in search results.</p>

      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Transparent Reporting and Analytics</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth provides regular and transparent reports on the progress of their SEO efforts. These reports include key performance indicators (KPIs) such as keyword rankings, organic traffic growth, conversion rates, and more. By keeping you informed of the results, you can see the tangible impact of their services on your business's online performance.</p>

    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth’s Search Engine Optimization  Services</h4>
      <p className={styles.businessAnalystics3}>Improve your online presence and achieve business goals</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Proven SEO Track Record</h4>
      <p className={styles.itServices12345}>Techrowth has a solid track record of successfully implementing SEO strategies for various clients across different industries. Their expertise in SEO ensures that they can effectively optimize your website to achieve higher rankings in search engine results pages (SERPs), leading to increased organic traffic and potential customers.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized SEO Strategies</h4>
      <p className={styles.itServices12345}>Techrowth understands that every business is unique, and there is no one-size-fits-all approach to SEO. They take the time to understand your business goals, target audience, and industry competition to create a tailored SEO strategy that aligns with your specific needs. This personalized approach maximizes the impact of their efforts and delivers better results.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>On-Page and Off-Page Optimization</h4>
      <p className={styles.itServices12345}>Their SEO service covers both on-page and off-page optimization. On-page optimization involves optimizing elements on your website, such as meta tags, keywords, content, and site structure, to make it more search engine-friendly. Off-page optimization focuses on building high-quality backlinks and establishing your website's authority across the web.</p>


     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Delivering An Enhanced User Experience</h4>
      <p className={styles.itServices12345}>In addition to optimizing for search engines, Techrowth also emphasizes enhancing the overall user experience on your website. A user-friendly and well-structured website leads to higher engagement, reduced bounce rates, and increased conversion rates. Search engines value positive user experiences, and this can positively impact your search rankings.</p>

      
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Stay Updated with Algorithm Changes</h4>
      <p className={styles.itServices12345}>Search engines frequently update their algorithms, impacting how websites rank in search results. Techrowth's SEO team stays up-to-date with these changes and adjusts their strategies accordingly. This ensures that your website remains compliant with the latest SEO guidelines and maintains its visibility in search results.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Transparent Reporting and Analytics</h4>
      <p className={styles.itServices12345}>Techrowth provides regular and transparent reports on the progress of their SEO efforts. These reports include key performance indicators (KPIs) such as keyword rankings, organic traffic growth, conversion rates, and more. By keeping you informed of the results, you can see the tangible impact of their services on your business's online performance.</p>

 


</div>


    
</div>
</>
  );
};

export default Services;
