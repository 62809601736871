
import styles from "./ItMain1.module.css";

import { Link } from "react-router-dom";
const ProblemsWeSolved = () => {

  const RecentText = "Ready to take your business to new heights? Book a free consultation today and know more";
  return (
    <>
    <div className={styles.itMain}>


      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.exploreOtherServices}>
        Problems We have Solved with <strong>BISCCBOR</strong>
        </div>
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+480.svg"
              />
              <div className={styles.aNeedFor}>
              Business Enquiries and Branding Revamp
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+481.svg"
              />
              <div className={styles.improvingUserEngagement}>
              Cross-Sell or Up-Sell Strategies
              </div>
            </div>
            <div className={styles.groupParent6}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+482.svg"

              />
              <div className={styles.securityRequirementBased}>
              Reactivation of Dormant Accounts
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+483.svg"

              />
              <div className={styles.avoidBreachesAnd}>
              Compelling Content Creation
              </div>
            </div>
          </div>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+484.svg"

              />
              <div className={styles.improvingNetworkSecurity}>
              Boosting Downloads and E-commerce Sales
              </div>
            </div>
            <div className={styles.groupParent9}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+485.svg"

              />
              <div className={styles.chatBotAndThirdParty}>
              Strategic Remarketing Campaigns
              </div>
            </div>
            <div className={styles.groupParent9}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+486.svg"

              />
              <div className={styles.incorporationOfCloudHosted}>
              Establishing a Distinct Business Voice
              </div>
            </div>
            <div className={styles.groupParent9}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+487.svg"

              />
              <div className={styles.theRiskOf}>
              Expanding International Market Presence
              </div>
            </div>
          </div>
        </div>
      </div>
   

    </div>
    <div className={styles.itMainn}>

   


      <div className={styles.problemsWeHaveSolvedParent}>
        <div className={styles.exploreOtherServicess}>
        Problems We have Solved with <strong>BISCCBOR</strong>

        </div>
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+480.svg"

              />
              <div className={styles.aNeedFor}>
              Business Enquiries and Branding Revamp
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+481.svg"

              />
              <div className={styles.aNeedFor}>
              Cross-Sell or Up-Sell Strategies
              </div>
            </div>
            <div className={styles.groupParent6}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+482.svg"

              />
              <div className={styles.aNeedFor}>
              Reactivation of Dormant Accounts
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+483.svg"

              />
              <div className={styles.aNeedFor}>
              Compelling Content Creation
              </div>
            </div>
        
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+484.svg"

              />
              <div className={styles.aNeedFor}>
              Boosting Downloads and E-commerce Sales
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+485.svg"

              />
              <div className={styles.aNeedFor}>
              Strategic Remarketing Campaigns
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+486.svg"

              />
              <div className={styles.aNeedFor}>
              Establishing a Distinct Business Voice
              </div>
            </div>
            <div className={styles.groupParent8}>
              <img
                className={styles.frameChild11}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Group+487.svg"

              />
              <div className={styles.aNeedFor}>
              Expanding International Market Presence
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    </>
  );
};

export default ProblemsWeSolved;
