import styles from "./WhoWeAre1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {
const WhoWeAreImg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/who+we+are+img.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>

      <div className={styles.exploreOtherServicesParent}>
        <div className={styles.exploreOtherServices}>
          Explore Our Services
        </div>
        <div className={styles.groupParent14}>
    
          <div className={styles.groupParent15}>
            <div className={styles.groupChild10} />
            <div className={styles.unlockThePotentialOfYourBParent}>
     
              <div className={styles.frameWrapper8}>
                <div className={styles.frameParent7}>
                  <Link to="/digital-marketing/intent-we-serve" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + "ps-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg1.svg"
                        width={35}
                      />
                       Intent We Serve{" "}
                    </button>
                  </Link>
                  <Link to="/digital-marketing" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg2.svg"
                        width={35}
                      />
                      Services{" "}
                    </button>
                  </Link>
                  <Link to="/digital-marketing/ecommerce-marketing" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg3.svg"
                        width={35}
                      />
                       E-commerce
                    </button>
                  </Link>
                  <Link to="#" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg4.svg"
                        width={35}
                      />
                      Graphic Design{" "}
                    </button>
                  </Link>
                  <Link to="/digital-marketing/content-marketing" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg5.svg"
                        width={35}
                      />
                      Content{" "}
                    </button>
                  </Link>

                 
                </div>
              </div>
              <Link to="/digital-marketing" style={{ width: "100%" }}>
              <div className={styles.groupParent17}>
                <div className={styles.rectangleWrapper}>
                  <div className={styles.groupChild11} />
                </div>
                <div className={styles.itServicesParent}>
                  <div className={styles.itServices}>Digital Marketing</div>
                  <img
                    className={styles.arrowRightIcon1}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
                  />
                  <img
                    className={styles.groupChild15}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+443712.png"
                  />
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div className={styles.groupParent15}>
            <div className={styles.groupChild10} />
            <div className={styles.unlockThePotentialOfYourBParent}>
     
              <div className={styles.frameWrapper8}>
                <div className={styles.frameParent7}>
                  <Link to="/it-services/it-development" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + "ps-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg1.svg"
                        width={35}
                      />
                      Development{" "}
                    </button>
                  </Link>
                  <Link to="/it-services/uiux-services" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg2.svg"
                        width={35}
                      />
                      UI/UX Interface{" "}
                    </button>
                  </Link>
                  <Link to="/it-services/it-management-service" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg3.svg"
                        width={35}
                      />
                      IT Management
                    </button>
                  </Link>
                  <Link to="/it-services/integration-services" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg4.svg"
                        width={35}
                      />
                      Integrations{" "}
                    </button>
                  </Link>
                  <Link to="/it-services/quality-testing-service" style={{ width: "100%" }}>
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg5.svg"
                        width={35}
                      />
                      Quality Testing{" "}
                    </button>
                  </Link>

                 
                </div>
              </div>
              <Link to="/it-services" style={{ width: "100%" }}>
              <div className={styles.groupParent17}>
                <div className={styles.rectangleWrapper}>
                  <div className={styles.groupChild11} />
                </div>
                <div className={styles.itServicesParent}>
                  <div className={styles.itServices}>IT Services</div>
                  <img
                    className={styles.arrowRightIcon1}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
                  />
                  <img
                    className={styles.groupChild15}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1093%402x.png"
                  />
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div className={styles.groupParent15}>
            <div className={styles.groupChild10} />
            <div className={styles.unlockThePotentialOfYourBParent}>
     
              <div className={styles.frameWrapper8}>
                <div className={styles.frameParent7}>
   
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + "ps-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg1.svg"
                        width={35}
                      />
                      Data Analytics{" "}
                    </button>
           
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg2.svg"
                        width={35}
                      />
                      Data Visualization{" "}
                    </button>
             
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg3.svg"
                        width={35}
                      />
                      Business Analytics
                    </button>
        
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg4.svg"
                        width={35}
                      />
                      Data Mining{" "}
                    </button>
             
                    <button
                      className="btn btn-light w-100"
                      style={{
                        fontSize: "18px",
                        width: "18.3rem",
                        height: "3.75rem",
                        fontWeight: "600",
                        color: "#0076A2",
                        textAlign: "start",
                      }}
                    >
                      <img
                        className={styles.frameItem + " mr-1"}
                        alt=""
                        src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg5.svg"
                        width={35}
                      />
                      Predictive Analytics{" "}
                    </button>
         

                 
                </div>
              </div>
        

              <div className={styles.groupParent17}>
                <div className={styles.rectangleWrapper}>
                  <div className={styles.groupChild11} />
                </div>
                <div className={styles.itServicesParent}>
                  <div className={styles.itServices}>Analytics</div>
                  <img
                    className={styles.arrowRightIcon1}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
                  />
                  <img
                    className={styles.groupChild15}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1094%402x.png"
                  />
                </div>
              </div>
        
            </div>
          </div>
    
        </div>
      </div>
    
    </div>



    <div className={styles.whoWeAree}>


      <div className={styles.exploreOtherServicesParent}>
      <div className={styles.exploreOtherServices} style={{fontSize:"28.21px"}}>
          Explore Our Services
        </div>
        <div className={styles.groupParent14}>
        <div className={styles.groupParent15}>
    <Link to="/digital-marketing" style={{ width: "100%" }}>
      <div className={styles.rectangleParent5}>
        <div className={styles.groupChild10} />
        <div className={styles.rectangleWrapper}>
          <div className={styles.rectangleWrapper}>
            <div className={styles.groupChild11} />
          </div>
          <img
            className={styles.groupChild1212}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Frame+443712.png"
          />
          <div
            className={styles.digitalMarketing}
          >{`Digital Marketing `}</div>
          <img
            className={styles.arrowRightIcon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright.svg"
          />
        </div>
      </div>
  </Link>
      <div className={styles.frameWrapper8}>
        <div className={styles.frameParent7}>
          <Link to="/digital-marketing/intent-we-serve" style={{ width: "100%" }}>
            <button
              className="btn btn-light w-100"
              style={{
                fontSize: "15px",
                width: "18.3rem",
                height: "2rem",

                fontWeight: "600",
                color: "black",
                textAlign: "left",
                padding: "0vw"
              }}
            >
              <img
                className={styles.frameItem + "ps-1"}
                alt=""  style={{width:"6vw", marginLeft:"1rem"}}
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg1.svg"
                width={35}
              />
              Intent We Serve{" "}
            </button>
          </Link>
          <Link to="/digital-marketing" style={{ width: "100%" }}>
            <button
              className="btn btn-light w-100"
              style={{
                fontSize: "15px",
                width: "18.3rem",
                height: "2rem",

                fontWeight: "600",
                color: "black",
                textAlign: "left",
                padding: "0vw"
              }}
            >
              <img
                className={styles.frameItem + " mr-1"}
                style={{width:"5vw" , marginLeft:"1rem"}}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg2.svg"
                width={35}
              />
              Services{" "}
            </button>
          </Link>
          <Link to="/digital-marketing/ecommerce-marketing" style={{ width: "100%" }}>
            <button
              className="btn btn-light w-100"
              style={{
                fontSize: "15px",
                width: "18.3rem",
                height: "2rem",

                fontWeight: "600",
                color: "black",
                textAlign: "left",
                padding: "0vw"
              }}
            >
              <img
                className={styles.frameItem + " mr-1"}
                alt="" style={{width:"6vw" , marginLeft:"1rem"}}
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg3.svg"
                width={35}
              />
              E-commerce
            </button>
          </Link>
          <Link to="#" style={{ width: "100%" }}>
            <button
              className="btn btn-light w-100"
              style={{
                fontSize: "15px",
                width: "18.3rem",
                height: "2rem",

                fontWeight: "600",
                color: "black",
                textAlign: "left",
                padding: "0vw"
              }}
            >
              <img
                className={styles.frameItem + " mr-1"}
                alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg4.svg"
                width={35}
              />
              Graphic Design{" "}
            </button>
          </Link>
          <Link to="/digital-marketing/content-marketing" style={{ width: "100%" }}>
            <button
              className="btn btn-light w-100"
              style={{
                fontSize: "15px",
                width: "18.3rem",
                height: "2rem",

                fontWeight: "600",
                color: "black",
                textAlign: "left",
                padding: "0vw"
              }}
            >
              <img
                className={styles.frameItem + " mr-1"}
                alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/DMimg5.svg"
                width={35}
              />
              Content{" "}
            </button>
          </Link>
        
        </div>
      </div>
    </div>
        
        </div>

        <div className={styles.groupParent14}>

        <div className={styles.groupParent15}>
      <div className={styles.groupChild10} />
      <div className={styles.unlockThePotentialOfYourBParent}>

        <div className={styles.frameWrapper8}>
          <div className={styles.frameParent7}>
            <Link to="/it-services/it-development" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + "ps-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem", marginRight:"4px"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg1.svg"
                  width={35}
                />
                Development{" "}
              </button>
            </Link>
            <Link to="/it-services/uiux-services" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg2.svg"
                  width={35}
                />
                UI/UX Interface{" "}
              </button>
            </Link>
            <Link to="/it-services/it-management-service" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg3.svg"
                  width={35}
                />
                IT Management
              </button>
            </Link>
            <Link to="/it-services/integration-services" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg4.svg"
                  width={35}
                />
                Integrations{" "}
              </button>
            </Link>
            <Link to="/it-services/quality-testing-service" style={{ width: "100%" }}>
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/ITimg5.svg"
                  width={35}
                />
                Quality Testing{" "}
              </button>
            </Link>

           
          </div>
        </div>
        
        <Link to="/it-services" style={{ width: "100%" }}>
        <div className={styles.groupParent17}>
          <div className={styles.rectangleWrapper}>
            <div className={styles.groupChild11} />
          </div>
          <div className={styles.itServicesParent}>
            <div className={styles.itServices}>IT Services</div>
            <img
              className={styles.arrowRightIcon1}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
            />
            <img
              className={styles.groupChild15}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1093%402x.png"
            />
          </div>
        </div>
        </Link>
      </div>
    </div>
       
        </div>


        <div className={styles.groupParent14}>


        <div className={styles.groupParent15}>
      <div className={styles.groupChild10} />
      <div className={styles.unlockThePotentialOfYourBParent}>

        <div className={styles.frameWrapper8}>
          <div className={styles.frameParent7}>
         
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + "mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem", marginRight:"4px"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg1.svg"
                  width={35}
                />
                Data Analytics{" "}
              </button>
  
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg2.svg"
                  width={35}
                />
                Data Visualization{" "}
              </button>
      
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg3.svg"
                  width={35}
                />
                Business Analytics
              </button>
           
              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg4.svg"
                  width={35}
                />
                Data Mining{" "}
              </button>

              <button
                className="btn btn-light w-100"
                style={{
                  fontSize: "15px",
                  width: "18.3rem",
                  height: "2rem",
  
                  fontWeight: "600",
                  color: "black",
                  textAlign: "left",
                  padding: "0vw"
                }}
              >
                <img
                  className={styles.frameItem + " mr-1"}
                  alt="" style={{width:"5vw" , marginLeft:"1rem"}}
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Sliderimg/Analyticsimg5.svg"
                  width={35}
                />
                Predictive Analytics{" "}
              </button>
      

           
          </div>
        </div>
    

        <div className={styles.groupParent17}>
          <div className={styles.rectangleWrapper}>
            <div className={styles.groupChild11} />
          </div>
          <div className={styles.itServicesParent}>
            <div className={styles.itServices}>Analytics</div>
            <img
              className={styles.arrowRightIcon1}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/arrowright1.svg"
            />
            <img
              className={styles.groupChild15}
              alt=""
              src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-1094%402x.png"
            />
          </div>
        </div>
      
      </div>
    </div>
</div>
        
      </div>
     

    </div>
    </>
  );
};

export default WhoWeAre;
