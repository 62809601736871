import styles from "./TrustedByBrands.module.css";
import { Link, useLocation } from "react-router-dom";
import ContactUsBar from "../components/ContactUsBar";

import { useEffect } from "react";
const TrustedByBrands = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const RecentText =
  "How can we help transform your business vision?";
  return (
    
    <>
    <div className={styles.trustedByBrands}>
    <h4 style={{fontSize:"2.5rem", fontWeight:"600", textAlign:"center", marginBottom:"3rem"}}>Trusted by the Best</h4>

      <div className="d-flex" style={{width:"55rem", margin:"0 auto"}}>

      <div className={styles.div}>
              <img
                className={styles.googleLogo1Icon}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/googlelogo-1%402x.png"
              />
              <img className={styles.frameIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame.svg" />
            </div>
            <div className={styles.div1}>
              <img
                className={styles.clutchLogo2d745b01f0SeeklogIcon}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/clutchlogo2d745b01f0seeklogo-1%402x.png"
              />
              <img className={styles.frameIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame.svg" />
            </div>
            <div className={styles.div2}>
              <img
                className={styles.pngimg1Icon}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/pngimg-1%402x.png"
              />
              <img className={styles.frameIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame.svg" />
            </div>
            <div className={styles.div3}>
              <img
                className={styles.glassdoorLogo1Icon}
                alt=""
                src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/glassdoorlogo-1%402x.png"
              />
              <img className={styles.frameIcon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame.svg" />
            </div>
      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
    <ContactUsBar content={RecentText} btnTxt="Contact Us" /> 
    </Link>
    <h4 style={{fontSize:"2.5rem", fontWeight:"600", textAlign:"center", marginBottom:"4rem"}}>Partnered With</h4>

    <div style={{display:"flex",gap:"3rem", textAlign:"center", justifyContent:"center"}}>
 
    <img
            className={styles.googlePartner11Icon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/googlepartner1-1%402x.png"
          />
          <img
            className={styles.b45dc8a806b0a419d8ddcUntitledIcon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/631b45dc8a806b0a419d8ddc-untitled2-1%402x.png"
          />
          <img
            className={styles.solutionPartner1Icon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/solutionpartner-1%402x.png"
          />
          <img
            className={styles.amazonAdsVerifiedPartner1Icon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/amazonadsverifiedpartner-1%402x.png"
          />
          <img
            className={styles.maskGroupIcon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/mask-group%402x.png"
          />
          <img
            className={styles.shopifyPartners1Icon}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/shopifypartners-1%402x.png"
          />
    </div>

    </div>


    <div className={styles.trustedByBrands1}>
    <h4 className={styles.trustedByBrands2}>Trusted by the Best</h4>
    <div className={styles.logos1}>
          <img
            className={styles.logos12}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1872.png"
          />
          <img
            className={styles.logos122}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1873.png"

          />
          <img
            className={styles.logos12}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1874.png"

          />
        
        </div>
    <h4 className={styles.trustedByBrands2}>Partnered With</h4>
    <div className={styles.logos1}>
          <img
            className={styles.logos12}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1225.png"
          />
          <img
            className={styles.logos12}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1226.png"

          />
          <img
            className={styles.logos12}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1228.png"

          />
            <img
            className={styles.logos12}
            alt=""
            src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/images/Frame+1230.png"

          />
        
        </div>
</div>
    </>
  );
};

export default TrustedByBrands;
