import styles from "./DigitalMarketingServices1.module.css";

const DigitalMarketingServices1 = () => {
  return (
    <>
    <div className={styles.digitalMarketingServices}>
      <div className={styles.digitalMarketingServicesInner}>
        <div className={styles.frameParent}>
          <div className={styles.digitalMarketingServicesParent}>
            <b className={styles.digitalMarketingServices1}>
              Digital Marketing Services
            </b>
            <div className={styles.intentWeServe}>Intent We serve</div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.parent}>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-34.svg" />
                  <div className={styles.enquiry}>{`Enquiry `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p
                      className={styles.unleashYourBrands}
                    >{`Unleash your brand's `}</p>
                    <p
                      className={styles.unleashYourBrands}
                    >{`digital potential: `}</p>
                    <p
                      className={styles.unleashYourBrands}
                    >{`Discover, Convert, `}</p>
                    <p className={styles.unleashYourBrands}>Succeed</p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-35.svg" />
                  <div className={styles.enquiry}>{`Branding `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Creating distinctive brands that leave a lasting
                      impression{" "}
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-36.svg" />
                  <div className={styles.enquiry}>{`Engagement `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Elevating brand engagement to new heights, one click at a
                      time.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-37.svg" />
                  <div className={styles.enquiry}>Downloading</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Leveraging the Power of Downloads to Achieve Your Goals
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-38.svg" />
                  <div className={styles.enquiry}>E-Commerce</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Streamlining your online business through our expert
                      e-commerce solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.parent}>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-39.svg" />
                  <div className={styles.enquiry}>{`Upselling `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Experience premium up-selling services for faster growth.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-40.svg" />
                  <div className={styles.enquiry}>Cross-selling</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Enhancing your marketing efforts with targeted
                      cross-selling.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-41.svg" />
                  <div className={styles.enquiry}>{`Remarketing `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Maximizing your ROI with our powerful remarketing
                      services.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-42.svg" />
                  <div className={styles.enquiry}>Redownloading</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Reviving your customer base, Re-Download and reignite!
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-43.svg" />
                  <div className={styles.enquiry}>Reactivation</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Bringing your dormant accounts back to life and Accelerate
                      your banking success
                    </p>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
     
        </div>
      </div>
    </div>



    <div className={styles.digitalMarketingServices1}>
    <h4 className={styles.businessAnalystics2}>Digital Marketing Services</h4>
      <p className={styles.businessAnalystics3}>Intent We serve</p>
              <div className={styles.parent}>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-34.svg" />
                  <div className={styles.enquiry}>{`Enquiry `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p
                      className={styles.unleashYourBrands}
                    >{`Unleash your brand's `}</p>
                    <p
                      className={styles.unleashYourBrands}
                    >{`digital potential: `}</p>
                    <p
                      className={styles.unleashYourBrands}
                    >{`Discover, Convert, `}</p>
                    <p className={styles.unleashYourBrands}>Succeed</p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-35.svg" />
                  <div className={styles.enquiry}>{`Branding `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Creating distinctive brands that leave a lasting
                      impression{" "}
                    </p>
                 
                  </div>
                </div>
              
               
              </div>
              <div className={styles.parent}>
 
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-36.svg" />
                  <div className={styles.enquiry}>{`Engagement `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Elevating brand engagement to new heights, one click at a
                      time.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-37.svg" />
                  <div className={styles.enquiry}>Downloading</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Leveraging the Power of Downloads to Achieve Your Goals
                    </p>
                  </div>
                </div>
             
              </div>
              <div className={styles.parent}>

                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-38.svg" />
                  <div className={styles.enquiry}>E-Commerce</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Streamlining your online business through our expert
                      e-commerce solutions.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-39.svg" />
                  <div className={styles.enquiry}>{`Upselling `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Experience premium up-selling services for faster growth.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.parent}>
      
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-40.svg" />
                  <div className={styles.enquiry}>Cross-selling</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Enhancing your marketing efforts with targeted
                      cross-selling.
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-41.svg" />
                  <div className={styles.enquiry}>{`Remarketing `}</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Maximizing your ROI with our powerful remarketing
                      services.
                    </p>
                  </div>
                </div>
         
              </div>
              <div className={styles.parent}>
     
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-42.svg" />
                  <div className={styles.enquiry}>Redownloading</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Reviving your customer base, Re-Download and reignite!
                    </p>
                  </div>
                </div>
                <div className={styles.div}>
                  <img className={styles.child} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/frame-43.svg" />
                  <div className={styles.enquiry}>Reactivation</div>
                  <div className={styles.unleashYourBrandsContainer}>
                    <p className={styles.unleashYourBrands}>
                      Bringing your dormant accounts back to life and Accelerate
                      your banking success
                    </p>
                  </div>
                </div>
              </div>
         
</div>
</>
  );
};

export default DigitalMarketingServices1;
