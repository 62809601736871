import styles from "./OurCoreValues1.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourCoreValues}>Quality Testing Services We Serve</b>
          <p className={styles.ourCoreValuessss}>Grow With the best Quality Testing Services in Bangalore
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc30.svg"
                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Database Testing</b>
                        <div className={styles.consistentInGetting}>
                        Unify and empower your business with Salesforce integration
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Database testing ensures the accuracy, integrity, and reliability of the underlying data storage systems. It involves verifying data consistency, validating data relationships, and assessing database performance.<br/>
                    Key Features: Data integrity checks, Data validation and verification, Performance optimization, Database schema validation, Query performance tuning and
Statistical analysis of data discrepancies

                    </div>
                  
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc31.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Platform Testing</b>
                        <div className={styles.consistentInGetting}>
                        Seamless connections with Adobe integration for creative excellence
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Platform testing focuses on verifying software compatibility and functionality across different platforms, such as operating systems, devices, and browsers. It ensures seamless user experiences and broad market reach.<br/>
                    Key Features: Cross-platform compatibility testing, Device and browser compatibility testing, UI/UX consistency checks, Accessibility testing, Error handling and recovery testing and Statistical analysis of platform-specific issues
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc32.svg"

                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Functional Testing</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Data-driven decisions with Power BI integration – the power of insights`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Functional testing validates the functional requirements of software applications. It involves testing individual functions, features, and interactions to ensure they meet the specified business requirements.<br/>
Key Features: Test case creation and execution, Boundary value analysis, User scenario testing, Regression testing, Error handling and exception testing and Statistical analysis of functional defects

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc33.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Security Testing</b>
                        <div className={styles.consistentInGetting}>
                        Streamline your workflows with Zoho integration – efficiency redefined 
                        </div>
                      </div>
                    </div>
                     
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
          Security testing assesses the robustness and resilience of software against potential security vulnerabilities. It identifies and mitigates risks, protecting sensitive data and maintaining system integrity.<br/>
          Key Features: Vulnerability assessment, Penetration testing, Authentication and authorization testing, Data encryption testing, Security policy compliance testing and Statistical analysis of identified vulnerabilities
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc34.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Usability Testing</b>
                        <div className={styles.consistentInGetting}>
                        Connect, adapt, and grow with Dynamics 365 integration
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Usability testing evaluates the ease of use, intuitiveness, and overall user experience of software applications. It ensures that users can navigate and interact with the software effectively and efficiently.<br/>
                    Key Features: User-centric testing scenarios, User interface (UI) testing, Navigation and workflow analysis, User feedback and surveys, Usability heuristic evaluation
 and Statistical analysis of usability metrics



                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc35.svg"

                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Performance Testing</b>
                        <div className={styles.consistentInGetting}>
                        Visualize success through Tableau integration – data brought to life
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Performance testing evaluates software performance under different conditions, such as high load, stress, and peak usage. It assesses response times, scalability, and stability to ensure optimal performance.<br/>
                    Key Features: Load testing, Stress testing, Scalability testing, Endurance testing, Performance profiling
 and Statistical analysis of response times





                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>

    </div>
    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
          <b className={styles.ourOrigin}>Quality Testing Services We Serve</b>
          <p className={styles.ourOrigin123}>Grow With the best Quality Testing Services in Bangalore
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc30.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Database Testing</b>
                        <div className={styles.consistentInGetting}>
                        Unify and empower your business with Salesforce integration

                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Database testing ensures the accuracy, integrity, and reliability of the underlying data storage systems. It involves verifying data consistency, validating data relationships, and assessing database performance.<br/>
                    Key Features: Data integrity checks, Data validation and verification, Performance optimization, Database schema validation, Query performance tuning and
Statistical analysis of data discrepancies
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc31.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Platform Testing</b>
                        <div className={styles.consistentInGetting}>
                        Seamless connections with Adobe integration for creative excellence

                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Platform testing focuses on verifying software compatibility and functionality across different platforms, such as operating systems, devices, and browsers. It ensures seamless user experiences and broad market reach.<br/>
                    Key Features: Cross-platform compatibility testing, Device and browser compatibility testing, UI/UX consistency checks, Accessibility testing, Error handling and recovery testing and Statistical analysis of platform-specific issues
                    </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc32.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Functional Testing</b>
                        <div className={styles.consistentInGetting}>
                        Data-driven decisions with Power BI integration – the power of insights
                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Functional testing validates the functional requirements of software applications. It involves testing individual functions, features, and interactions to ensure they meet the specified business requirements.<br/>
Key Features: Test case creation and execution, Boundary value analysis, User scenario testing, Regression testing, Error handling and exception testing and Statistical analysis of functional defects
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc33.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Security Testing</b>
                        <div className={styles.consistentInGetting}>
                        Streamline your workflows with Zoho integration – efficiency redefined 

                        </div>
                      </div>
                    </div>
               
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Security testing assesses the robustness and resilience of software against potential security vulnerabilities. It identifies and mitigates risks, protecting sensitive data and maintaining system integrity.<br/>
          Key Features: Vulnerability assessment, Penetration testing, Authentication and authorization testing, Data encryption testing, Security policy compliance testing and Statistical analysis of identified vulnerabilities
                    </div>
              </div>
           
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc34.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Usability Testing</b>
                        <div className={styles.consistentInGetting}>
                        Connect, adapt, and grow with Dynamics 365 integration

                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Usability testing evaluates the ease of use, intuitiveness, and overall user experience of software applications. It ensures that users can navigate and interact with the software effectively and efficiently.<br/>
                    Key Features: User-centric testing scenarios, User interface (UI) testing, Navigation and workflow analysis, User feedback and surveys, Usability heuristic evaluation
 and Statistical analysis of usability metrics
                    </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc35.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Performance Testing</b>
                        <div className={styles.consistentInGetting}>
                        Visualize success through Tableau integration – data brought to life

                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className={styles.ourCommitmentTo}>
                    Performance testing evaluates software performance under different conditions, such as high load, stress, and peak usage. It assesses response times, scalability, and stability to ensure optimal performance.<br/>
                    Key Features: Load testing, Stress testing, Scalability testing, Endurance testing, Performance profiling
 and Statistical analysis of response times


                    </div>
              </div>
            
            </div>
           
          </div>
         
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
