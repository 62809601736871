import React from "react";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

const DropdownMenuIT = ({ sendDataToParent }) => {
  const handleClick = () => {
    const data = false;
    sendDataToParent(data);
  };
  const handleMouseLeave = () => {
    const data = false;
    sendDataToParent(data);
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
      className="container-fluid position-absolute margin-top-20"
      style={{ backgroundColor: "#E9EEF4", zIndex: "1", top: "15%" }}

      
    >
      <div className="row py-4 px-4" style={{ gap: "2rem"}}>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3"  style={{listStyle:"none"}}>



            <li className="mb-3">
              <Link to="/it-services/it-development" onClick={handleClick} style={{textDecoration:"none"}}>

              
                <h5 className="text-white">Development</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-development/cms-development-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Content Management System
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-development/website-development-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Website
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-development/ecommerce-development-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                E-Commerce
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-development/web-application-development-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Web Application
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-development/mobile-application-development-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Mobile Application
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-development/website-accessibility-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Website Accessibility
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3" style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">UI/UX Interactive</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                UI/UX Website Design
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                UI/UX Website Redesign
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                User Experience Testing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Landing Pages & Funnels
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Conversion Rate Optimization
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/uiux-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Rapid Web Design
              </Link>
            </li>
            {/* <li className= "mb-3">
              <Link to="/contact-us" onClick={handleClick} className="text-white">
                Redownloading
              </Link>
            </li>
            <li className= "mb-3">
              <Link to="/contact-us" onClick={handleClick} className="text-white">
                downloading
              </Link>
            </li>
            <li className= "mb-3">
              <Link to="/contact-us" onClick={handleClick} className="text-white">
                Ecommerce Service
              </Link>
            </li> */}
          </ul>
        </div>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3" style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">IT Management</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Project Management
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Consultation Service
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                SAAS
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                DevOps Service
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Cloud Service
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/it-management-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Digital Transformation
              </Link>
            </li>
       
            {/* <li className= "mb-3">
              <Link to="/contact-us" onClick={handleClick} className="text-white">
                downloading
              </Link>
            </li>
            <li className= "mb-3">
              <Link to="/contact-us" onClick={handleClick} className="text-white">
                Ecommerce Service
              </Link>
            </li> */}
          </ul>
        </div>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3" style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">Integration</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Salesforce
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Adobe
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Power BI
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Zoho
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Dynamic 365
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/integration-services" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Tableau
              </Link>
            </li>
   
          </ul>
        </div>
        <div
          className="col text-white"
          style={{ backgroundColor: "#0076A2", borderRadius: "18px" }}
        >
          <ul className="text-center px-0 py-3" style={{listStyle:"none"}}>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} style={{textDecoration:"none"}}>
                <h5 className="text-white">Quality Testing</h5>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Database Testing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Platform Testing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Functional Testing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Security Testing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
              Usability Testing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/it-services/quality-testing-service" onClick={handleClick} className="text-white" style={{textDecoration:"none"}}>
                Performance Testing
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default DropdownMenuIT;
