import React from "react";
import styles from "../Stories4.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div4}>
        <h3 className={styles.divup}>Harish Shetty</h3>
        <p className={styles.divup1}>Shanghai Court - Managing Director </p>
        <video controls controlsList="nodownload" disablePictureInPicture  className={styles.customVideoControls} >
  <source
    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/ShangaiCourt_13-07-2023_Video04_ShangaiCourtVideo_v1_1.mp4" width={"100%"}
    type="video/mp4"
  />
  Your browser does not support the video tag.
</video>
      <div className={styles.weHaveBeenUsingTechrowthFParent}>
        <div className={styles.weHaveBeenContainer}>
          <h6
            className={styles.revolutionizingTraditionalBa}
          >{`“Techrowth is excellent at delivering designs and content according to our needs. The team is kind, super helpful, and prepares digital content , including social media and websites. We wholeheartedly recommend Techrowth to restaurant owners and anyone seeking digital services.”.`}</h6>
        </div>
     
      </div>
 
    </div>
  );
};

export default TSlide1;
