import styles from "./Otherservices1.module.css";
import { Link } from "react-router-dom";

const OtherServices = () => {
  return (
    <>
    <div className={styles.digitalMarketingServices}>
      <div className={styles.digitalMarketingServicesInner}>
        <div className={styles.frameParent}>
          <div className={styles.digitalMarketingServicesParent}>
         
            <div className={styles.intentWeServe}>Explore Other Services</div>
          </div>
        
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>
         
            <Link to="/it-services/it-development" style={{textDecoration:"none", color:"white"}}>
            <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc5.svg"
                />
                <div className={styles.socialMediaServicesContainer}>
                Development
                </div>
              </div>
              </Link>
            <Link to="/it-services/uiux-services" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc1.svg"



                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>UI/UX Interactive</p>
                </div>
              </div>
              </Link>
            <Link to="/it-services/it-management-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc2.svg"




                />
                <div className={styles.socialMediaServicesContainer}>
                IT Management
                </div>
              </div>
              </Link>
            <Link to="/it-services/quality-testing-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc4.svg"




                />
                <div className={styles.socialMediaServicesContainer}>
                Quality Testing
                </div>
              </div>
              </Link>
           
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className={styles.digitalMarketingServices1}>

      <p className={styles.businessAnalystics3}>Explore Other Services</p>
    
              <div className={styles.wonder}>
            
          <div className="d-flex">
            <Link to="/it-services/it-development" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc5.svg"



                />
                     <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Development</p>
                </div>
            
              </div>
              </Link>
            <Link to="/it-services/uiux-services" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc1.svg"



                />
                <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>UI/UX Interactive</p>
                </div>
              </div>
              </Link>
              </div>
          <div className="d-flex">
            <Link to="/it-services/it-management-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc2.svg"



                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>IT Management</p>
                </div>
              </div>
              </Link>
            <Link to="/it-services/quality-testing-service" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.frameParent1}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/cc4.svg"



                />
                           <div className={styles.socialMediaServicesContainer}>
                  {/* <p className={styles.unleashYourBrands}>{`Graphic &`}</p> */}
                  <p className={styles.unleashYourBrands}>Quality Testing</p>
                </div>
              </div>
              </Link>
              </div>
        
</div>
</div>
</>
  );
};

export default OtherServices;
