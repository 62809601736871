import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t2.png" />

      <div className={styles.bus}>
      <h5 >Interiors Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>45%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Brand Awareness</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>25%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+3152.svg" />
          </div>
          <p className={styles.bus5}>Conversions</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      Thanks to Techrowth’s website accessibility service, our e-commerce business has taken a significant step towards creating an inclusive shopping experience for all customers. Their team implemented accessibility features that make it easier for individuals with disabilities to navigate our online store and complete purchases independently. The positive feedback we've received from our customers shows that website accessibility is not only the right thing to do but also beneficial for our business.
     </p>
    </div>
  );
};

export default TSlide1;
