import React from "react";
import styles from "../Stories2.module.css";
import { Link } from "react-router-dom";


const TSlide1 = () => {
  return (
    <div className={styles.div}>
      <img className={styles.logo1Icon} alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/t1.png" />

      <div className={styles.bus}>
      <h5 >E-Commerce Business</h5>
      </div>

      <div className="d-flex">
      <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6>40%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+315212.svg" />
          </div>
          <p className={styles.bus5}>Launch Time</p>
        </div>
        <div className={styles.bus3}>
          <div className={styles.bus4}>
            <h6 style={{margin:"0 0 0px"}}>50%</h6>
            <img alt="" src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/our_services/Frame+315212.svg" />
          </div>
          <p className={styles.bus5}>Bounce Rate</p>
        </div>

      </div>
      <h6 className={styles.bus1}>From Our Client</h6>
      <p className={styles.bus2}>
      The CMS service provided by Techrowth’s team has been a game-changer for our e-commerce business! With their easy-to-use content management system, we can now update product listings, create engaging content, and manage promotions effortlessly. It has saved us valuable time and resources, allowing us to focus on growing our business. Their support and training have been exceptional, and we're thrilled with the results.
     </p>
    </div>
  );
};

export default TSlide1;
