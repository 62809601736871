import React, { useState } from 'react';
import styles from './ImageRow.module.css'; // Create this CSS file for styling
import { Link } from 'react-router-dom';

const ImageRow = () => {
  const [selectedValue, setSelectedValue] = useState('All');
  const [searchInput, setSearchInput] = useState('');

  // Handle changes in the dropdown selection
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
         <div className={styles.portfolio}>
<h1 className='text-white'>Hey There, How Can We Make A Difference For You?</h1>
<h6 className='text-white'>Reach Out to Learn More About How We Created Impact For 100+ Businesses.</h6>
<form className="search-form">
<input
  type="text"
  className={styles.serach}
  placeholder="Search Portfolio..."
  value={searchInput}
  onChange={(e) => setSearchInput(e.target.value)}
/>
    {/* <button className={styles.portfolio1} type="submit">Search</button> */}
  </form>
        </div>
<div className='container'>
        <div className={styles.dropdownContainer}>
     
        <select
            id="dropdown"
            className={styles.dropdown}
            value={selectedValue} // Set the selected value
            onChange={handleDropdownChange} // Handle selection change
          >
          <option value="All">All</option>
          <option value="Banking">Banking Sector</option>
          <option value="CRM">CRM Sector</option>
          <option value="Ecommerce">Ecommerce Sector</option>
          <option value="Education">Education Sector</option>
          <option value="Hospital">Hospital Sector</option>
          <option value="Hospitality">Hospitality Sector</option>
          <option value="Interiors">Interiors Sector</option>
          <option value="Listing">Listing Sector</option>
          <option value="RealEstate">Real Estate Sector</option>
          <option value="Restaurant">Restaurant Sector</option>
          <option value="Service">Service Sector</option>
    
        
        </select>
      </div>
   
      <div className={styles.imageContainer}>
        <Link to="/techrowth-portfolio/spaze-ratiio" style={{textDecoration:"none", color:"black"}}>
      {(
    (selectedValue === 'All' || selectedValue === 'Interiors') &&
    (searchInput === '' || 'Spaze Ratiio'.toLowerCase().includes(searchInput.toLowerCase()))
  ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/1.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/22.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Spaze Ratiio</p>
           
          </div>
        
        </div>
      )}
      </Link>
      <Link to="/techrowth-portfolio/shegan-gastro-pub" style={{textDecoration:"none", color:"black"}}>

      {(
        (selectedValue === 'All' || selectedValue === 'Restaurant') &&
        (searchInput === '' || 'Shegan Gastro Pub'.toLowerCase().includes(searchInput.toLowerCase()))
      ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/2.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/1.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Shegan Gastro Pub</p>
          </div>
        </div>
      )}
      </Link>
      <Link to="/techrowth-portfolio/blossoms-banquets" style={{textDecoration:"none", color:"black"}}>
      {(
        (selectedValue === 'All' || selectedValue === 'Hospitality') &&
        (searchInput === '' || 'Blossoms Banquets'.toLowerCase().includes(searchInput.toLowerCase()))
      ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/3.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/2.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Blossoms Banquets</p>
          </div>
        </div>
  )}
      </Link>
      <Link to="/techrowth-portfolio/tasmai-exports" style={{textDecoration:"none", color:"black"}}>
  {(
    (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
    (searchInput === '' || 'Tasmai Exports'.toLowerCase().includes(searchInput.toLowerCase()))
  ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/4.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/3.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Tasmai Exports</p>
          </div>
        </div>
  )}
      </Link>
      <Link to="/techrowth-portfolio/abhayahasta-hospital" style={{textDecoration:"none", color:"black"}}>
  {(
    (selectedValue === 'All' || selectedValue === 'Hospital') &&
    (searchInput === '' || 'Abhayahasta Hospital'.toLowerCase().includes(searchInput.toLowerCase()))
  ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/5.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/4.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Abhayahasta Hospital</p>
          </div>
        </div>
     )}
      </Link>
      <Link to="/techrowth-portfolio/atomic-academy" style={{textDecoration:"none", color:"black"}}>
     {(
       (selectedValue === 'All' || selectedValue === 'Education') &&
       (searchInput === '' || 'Atomic Academy'.toLowerCase().includes(searchInput.toLowerCase()))
     ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/6.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/5.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Atomic Academy</p>
          </div>
        </div>
         )}
         </Link>
         <Link to="/techrowth-portfolio/kalpatharu-constructions" style={{textDecoration:"none", color:"black"}}>
         {(
           (selectedValue === 'All' || selectedValue === 'RealEstate') &&
           (searchInput === '' || 'Kalpatharu Constructions'.toLowerCase().includes(searchInput.toLowerCase()))
         ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/7.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/6.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Kalpatharu Constructions</p>
          </div>
        </div>
    )}
      </Link>
      <Link to="/techrowth-portfolio/znbs-corporate" style={{textDecoration:"none", color:"black"}}>
    {(
      (selectedValue === 'All' || selectedValue === 'Banking') &&
      (searchInput === '' || 'ZNBS Corporate'.toLowerCase().includes(searchInput.toLowerCase()))
    ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/8.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/8.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>ZNBS Corporate</p>
          </div>
        </div>
           )}
           </Link>
           <Link to="/techrowth-portfolio/dr-native" style={{textDecoration:"none", color:"black"}}>
           {(
             (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
             (searchInput === '' || 'Dr. Native'.toLowerCase().includes(searchInput.toLowerCase()))
           ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/9.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/23.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Dr. Native</p>
          </div>
        </div>
   )}
      </Link>
      <Link to="/techrowth-portfolio/sri-krishna-group" style={{textDecoration:"none", color:"black"}}>
        {(
     (selectedValue === 'All' || selectedValue === 'Education') &&
     (searchInput === '' || 'Sri Krishna Group'.toLowerCase().includes(searchInput.toLowerCase()))
   ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/10.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/9.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Sri Krishna Group</p>
          </div>
        </div>
     )}
     </Link>
     <Link to="/techrowth-portfolio/znbs-property" style={{textDecoration:"none", color:"black"}}>
     {(
       (selectedValue === 'All' || selectedValue === 'RealEstate') &&
       (searchInput === '' || 'ZNBS Property'.toLowerCase().includes(searchInput.toLowerCase()))
     ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/11.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/8.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>ZNBS Property</p>
          </div>
        </div>
   )}
      </Link>
      <Link to="/techrowth-portfolio/neha-prakash-hospital" style={{textDecoration:"none", color:"black"}}>
   {(
     (selectedValue === 'All' || selectedValue === 'Hospital') &&
     (searchInput === '' || 'Neha Prakash Hospital'.toLowerCase().includes(searchInput.toLowerCase()))
   ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/12.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/19.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Neha Prakash Hospital</p>
          </div>
        </div>
      )}
      </Link>
      <Link to="/techrowth-portfolio/hatchlong" style={{textDecoration:"none", color:"black"}}>
      {(
        (selectedValue === 'All' || selectedValue === 'Service') &&
        (searchInput === '' || 'Hatchlong'.toLowerCase().includes(searchInput.toLowerCase()))
      ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/13.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/10.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Hatchlong</p>
          </div>
        </div>
          )}
          </Link>
          <Link to="/techrowth-portfolio/validus-thailand" style={{textDecoration:"none", color:"black"}}>
          {(
            (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
            (searchInput === '' || 'Validus Thailand'.toLowerCase().includes(searchInput.toLowerCase()))
          ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/14.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/24.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Validus Thailand</p>
          </div>
        </div>
      )}
      </Link>
      {/* <Link to="/techrowth-portfolio/pearl-vally-resort" style={{textDecoration:"none", color:"black"}}>
      {(
        (selectedValue === 'All' || selectedValue === 'Hospitality') &&
        (searchInput === '' || 'Pearl Vally Resort'.toLowerCase().includes(searchInput.toLowerCase()))
      ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/15.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/11.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Pearl Vally Resort</p>
          </div>
        </div>
      )}
      </Link> */}
      <Link to="/techrowth-portfolio/izb" style={{textDecoration:"none", color:"black"}}>
      {(
        (selectedValue === 'All' || selectedValue === 'Banking') &&
        (searchInput === '' || 'IZB'.toLowerCase().includes(searchInput.toLowerCase()))
      ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/16.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/20.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>IZB</p>
          </div>
        </div>
      )}
      </Link>
      <Link to="/techrowth-portfolio/grandiose-art" style={{textDecoration:"none", color:"black"}}>
      {(
        (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
        (searchInput === '' || 'Grandiose Art'.toLowerCase().includes(searchInput.toLowerCase()))
      ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/17.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/12.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Grandiose Art</p>
          </div>
        </div>
    )}
    </Link>
    <Link to="/techrowth-portfolio/venus-kart" style={{textDecoration:"none", color:"black"}}>
    {(
      (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
      (searchInput === '' || 'Venus Kart'.toLowerCase().includes(searchInput.toLowerCase()))
    ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/18.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/13.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Venus Kart</p>
          </div>
        </div>
         )}
      </Link>
      <Link to="/techrowth-portfolio/techrowth" style={{textDecoration:"none", color:"black"}}>
         {(
           (selectedValue === 'All' || selectedValue === 'Service') &&
           (searchInput === '' || 'Techrowth'.toLowerCase().includes(searchInput.toLowerCase()))
         ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/19.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/15.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Techrowth</p>
          </div>
        </div>
        )}
        </Link>
        <Link to="/techrowth-portfolio/prolearn-box" style={{textDecoration:"none", color:"black"}}>
        {(
          (selectedValue === 'All' || selectedValue === 'Education') &&
          (searchInput === '' || 'Prolearn Box'.toLowerCase().includes(searchInput.toLowerCase()))
        ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/20.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/14.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Prolearn Box</p>
          </div>
        </div>
            )}
      </Link>
      <Link to="/techrowth-portfolio/citizen-car" style={{textDecoration:"none", color:"black"}}>
            {(
              (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
              (searchInput === '' || 'Citizen Car'.toLowerCase().includes(searchInput.toLowerCase()))
            ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/21.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/16.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Citizen Car</p>
          </div>
        </div>
  )}
  </Link>
  <Link to="/techrowth-portfolio/business-listing" style={{textDecoration:"none", color:"black"}}>
  {(
    (selectedValue === 'All' || selectedValue === 'Listing') &&
    (searchInput === '' || 'Business Listing'.toLowerCase().includes(searchInput.toLowerCase()))
  ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/22.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/25.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Business Listing</p>
          </div>
        </div>
  )}
      </Link>
      <Link to="/techrowth-portfolio/arvaa-academy" style={{textDecoration:"none", color:"black"}}>
  {(
    (selectedValue === 'All' || selectedValue === 'Education') &&
    (searchInput === '' || 'Arvaa Academy'.toLowerCase().includes(searchInput.toLowerCase()))
  ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/23.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/17.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Arvaa Academy
</p>
          </div>
        </div>
    )}
    </Link>
    <Link to="/techrowth-portfolio/zambia-medical-assoc" style={{textDecoration:"none", color:"black"}}>
    {(
      (selectedValue === 'All' || selectedValue === 'Hospital') &&
      (searchInput === '' || 'Zambia Medical Association'.toLowerCase().includes(searchInput.toLowerCase()))
    ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/24.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/21.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Zambia Medical Assoc</p>
          </div>
        </div>
         )}
      </Link>
      <Link to="/techrowth-portfolio/vendor-management" style={{textDecoration:"none", color:"black"}}>
         {(
           (selectedValue === 'All' || selectedValue === 'Ecommerce') &&
           (searchInput === '' || 'Vendor Management'.toLowerCase().includes(searchInput.toLowerCase()))
         ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/25.png" alt="Image 1" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/26.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Vendor Management</p>
          </div>
        </div>
         )}
         </Link>
         <Link to="/techrowth-portfolio/izb-crm" style={{textDecoration:"none", color:"black"}}>
         {(
           (selectedValue === 'All' || selectedValue === 'CRM') &&
           (searchInput === '' || 'IZB CRM'.toLowerCase().includes(searchInput.toLowerCase()))
         ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/26.png" alt="Image 2" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/20.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>IZB CRM</p>
          </div>
        </div>
        )}
      </Link>
      <Link to="/techrowth-portfolio/green-view-medical" style={{textDecoration:"none", color:"black"}}>
        {(
          (selectedValue === 'All' || selectedValue === 'Hospital') &&
          (searchInput === '' || 'Green View Medical'.toLowerCase().includes(searchInput.toLowerCase()))
        ) && (
        <div className={styles.imageColumn}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/portfolio/27.png" alt="Image 3" className={styles.image} />
          <div className={styles.image1}>
          <img src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/logo/7.png" alt="Image 1" className={styles.image2} />
            <p className={styles.image3}>Green View Medical</p>
          </div>
        </div>
             )}
             </Link>
     
      </div>
  
      </div>
</>
  );
}

export default ImageRow;
