import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's Pay Per Click Services
</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Drive targeted traffic and increase conversions `}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Expertise in PPC Advertising</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth's PPC team consists of experienced professionals who are well-versed in the intricacies of PPC advertising. They stay updated with the latest trends and best practices in the industry to deliver effective and efficient PPC campaigns that yield tangible results.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Customized PPC Strategies`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}> Every business has unique goals and target audiences. Techrowth develops customized PPC strategies tailored to your specific needs. They take the time to understand your business objectives, industry landscape, and target audience, ensuring that their PPC campaigns align perfectly with your overall marketing strategy.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Keyword Research and Optimization</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Effective keyword research is the foundation of successful PPC campaigns. Techrowth conducts thorough keyword research to identify the most relevant and high-converting keywords for your business. They optimize your campaigns to target these keywords, increasing the chances of reaching your ideal customers.</p>

              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Ad Copy and Creative Design</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Compelling ad copy and visually engaging designs are crucial for driving clicks and conversions. Techrowth's team of skilled copywriters and designers create persuasive ad content and eye-catching visuals that resonate with your audience and encourage action.</p>

      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Continuous Monitoring and Optimization`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>PPC campaigns require ongoing monitoring and optimization to maximize their performance. Techrowth continuously tracks the performance of your PPC ads, analyzing data and making real-time adjustments to improve ad relevance, click-through rates, and conversion rates. This proactive approach ensures that your PPC budget is spent efficiently and that you achieve the best possible ROI.</p>

      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>A/B Testing and Experimentation</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth employs A/B testing and experimentation to refine your PPC campaigns further. By testing different ad copies, designs, and targeting options, they can identify the most effective combinations and optimize your campaigns for better results.</p>

    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's Pay Per Click Services
</h4>
      <p className={styles.businessAnalystics3}>Drive targeted traffic and increase conversions</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Expertise in PPC Advertising</h4>
      <p className={styles.itServices12345}>Techrowth's PPC team consists of experienced professionals who are well-versed in the intricacies of PPC advertising. They stay updated with the latest trends and best practices in the industry to deliver effective and efficient PPC campaigns that yield tangible results.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized PPC Strategies</h4>
      <p className={styles.itServices12345}>Every business has unique goals and target audiences. Techrowth develops customized PPC strategies tailored to your specific needs. They take the time to understand your business objectives, industry landscape, and target audience, ensuring that their PPC campaigns align perfectly with your overall marketing strategy.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Keyword Research and Optimization</h4>
      <p className={styles.itServices12345}>Effective keyword research is the foundation of successful PPC campaigns. Techrowth conducts thorough keyword research to identify the most relevant and high-converting keywords for your business. They optimize your campaigns to target these keywords, increasing the chances of reaching your ideal customers.</p>


     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Ad Copy and Creative Design</h4>
      <p className={styles.itServices12345}>Compelling ad copy and visually engaging designs are crucial for driving clicks and conversions. Techrowth's team of skilled copywriters and designers create persuasive ad content and eye-catching visuals that resonate with your audience and encourage action.</p>

      
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Continuous Monitoring and Optimization</h4>
      <p className={styles.itServices12345}>PPC campaigns require ongoing monitoring and optimization to maximize their performance. Techrowth continuously tracks the performance of your PPC ads, analyzing data and making real-time adjustments to improve ad relevance, click-through rates, and conversion rates. This proactive approach ensures that your PPC budget is spent efficiently and that you achieve the best possible ROI.</p>




</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>A/B Testing and Experimentation</h4>
      <p className={styles.itServices12345}>Techrowth employs A/B testing and experimentation to refine your PPC campaigns further. By testing different ad copies, designs, and targeting options, they can identify the most effective combinations and optimize your campaigns for better results.</p>

 


</div>


    
</div>
</>
  );
};

export default Services;
