import styles from "./BusinessAnalystics.module.css";
import NumberCounter from 'number-counter';

import { Link, useLocation } from "react-router-dom";
const BusinessAnalystics = ({ show, onDisplay, titleDiplay }) => {
  return (
    <>
    <div className={styles.businessAnalystics}>
      <div className={styles.frameParent}>
        {titleDiplay && (
          <div className={styles.businessAnalyticsParent}>
            <div className={styles.businessAnalytics}>Analytic Services</div>
            <div className={styles.unlockThePowerContainer}>
              <p className={styles.unlockThePower}>
              Data-Driven Decisions, Thriving Businesses – Empowering Your
              </p>
              <p className={styles.unlockThePower}>
                {" "}
                Business with Analytics
              </p>
            </div>
          </div>
        )}
        {onDisplay && (
          <div className={styles.frameGroup}>
            <div className={styles.frameWrapper}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-572.svg"
                />
                <div className={styles.analyticsInsightsContainer}>
                  <p className={styles.unlockThePower}>Data</p>
                  <p className={styles.unlockThePower}>{`Analytics`}</p>
                </div>
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-573.svg"
                />
                <div className={styles.analyticsInsightsContainer}>
                  <p className={styles.unlockThePower}>Data</p>
                  <p className={styles.unlockThePower}>Visualization</p>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/333.svg"
                />
                <div className={styles.analyticsInsightsContainer}>
                  <p className={styles.unlockThePower}>Business</p>
                  <p className={styles.unlockThePower}>Analytics</p>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper1}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/444.svg"
                />
                <div className={styles.analyticsInsightsContainer}>
                  <p className={styles.unlockThePower}>Predictive</p>
                  <p className={styles.unlockThePower}>Analytics </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.businessAnalytics} style={{textAlign:"center"}}>Powering Success: IT & Digital Marketing Achievements</div>

      {show && (
        <div className="">
          <div className={styles.businessAnalysticsInner}>
            <div className={styles.frameParent1}>
              <div className={styles.parent}>
                <div className={styles.div2}>
                {/* <NumberCounter end={24} delay={1} className={styles.div1} postFix="%"/> */}

                  <div className={styles.div1}>24%</div>
                  <div className={styles.revenueIncrement}>
                    <p className={styles.unlockThePower}>Revenue</p>
                    <p className={styles.unlockThePower}>Increment</p>
                  </div>
                </div>
                <div className={styles.div2}>
                {/* <NumberCounter end={987} delay={1} className={styles.div1} /> */}
                  <div className={styles.div1}>987</div>
                  <div className={styles.seoAudits}>SEO Audits</div>
                </div>
                <div className={styles.div2}>
                {/* <NumberCounter end={22} delay={1} className={styles.div1} postFix="%"/> */}
                  <div className={styles.div1}>22%</div>
                  <div className={styles.seoAudits}>Conversion Rate</div>
                </div>
                <div className={styles.div2}>
                {/* <NumberCounter end={172} delay={1} className={styles.div1}/> */}
                  <div className={styles.div1}>172</div>
                  <div className={styles.seoAudits}>Mobile Apps</div>
                </div>
              </div>
              <div className={styles.group}>
                <div className={styles.div8}>
                {/* <NumberCounter end={415} delay={1} className={styles.div9}/> */}
                  <div className={styles.div9}>415</div>
                  <div className={styles.websitesMade}>Websites Made</div>
                </div>
                <div className={styles.div10}>
                {/* <NumberCounter end={36} delay={1} className={styles.div3} style={{left:"40px"}} postFix="%"/> */}
                  <div className={styles.div11}>36%</div>
                  <div className={styles.customerRetention}>
                    Customer Retention
                  </div>
                </div>
                <div className={styles.div12}>
                {/* <NumberCounter end={11} delay={1} className={styles.div4} postFix="%"/> */}
                  <div className={styles.div13}>11%</div>
                  <div className={styles.socialMediaInteractionContainer}>
                    <p className={styles.unlockThePower}>
                      Social Media Interaction
                    </p>
                    <p className={styles.unlockThePower}>Increment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.businessAnalysticsChild}>
            <div className={styles.instanceParent}>
              <div className={styles.rectangleWrapper}>
                <img
                  className={styles.instanceChild}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/rectangle-40291%402x.png"
                />
              </div>

              <div className={styles.wonderingIfYouCouldBeAPaParent}>
                <div className={styles.wonderingIfYouContainer}>
                  <p className={styles.unlockThePower}>
                    Wondering if you could be a part of a success story?
                  </p>
                  <p className={styles.unlockThePower}>
                    Book a free consultation today and learn more!
                  </p>
                </div>
                <Link to="/contact-us" style={{ color: "#ffffff",textDecoration:"none" }}>

                <button className={styles.contactUsWrapper} autoFocus>
                  <div className={styles.contactUs}>Contact Us</div>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>














    <div className={styles.businessAnalystics1}>
    <h4 className={styles.businessAnalystics222222} >Analytic Services</h4>
      <p className={styles.businessAnalystics3}>Data-Driven Decisions, Thriving Businesses – Empowering Your Business with Analytics </p>
      <div className={styles.businessAnalystics4}>
      <div className={styles.groupParent1}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-572.svg"
                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Data<br/> Analytics</p>
                  
                </div>
              </div>
              <div className={styles.groupParent1}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/group-573.svg"
                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Data<br/>
                  Visualization</p>
               
                </div>
              </div>
</div>
<div className={styles.businessAnalystics4}>
      <div className={styles.groupParent1}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/333.svg"

                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Business<br/>
Analytics</p>
                
                </div>
              </div>
              <div className={styles.groupParent1}>
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/444.svg"
                />
                <div className={styles.analyticsInsightsContainer1}>
                  <p className={styles.unlockThePower}>Predictive<br/>
Analytics </p>
               
                </div>
              </div>
</div>

<h4 className={styles.businessAnalystics2}>IT & Digital Marketing Achievements</h4>
<div className={styles.revenue}>
<div className={styles.revenue1}>
<div className={styles.revenue2}>
<strong><p className={styles.revenue222}>24%</p></strong>
  <p style={{color:"black", fontSize:"12px", fontWeight:"600"}}>Revenue
 Increment</p>

  </div>
  <div className={styles.revenue2}>
  <strong><p className={styles.revenue222}>22%</p></strong>
  <p style={{color:"black", fontSize:"12px", fontWeight:"600"}}>Conversion Rate</p>
  </div>
  <div className={styles.revenue2}>
  <strong><p className={styles.revenue222}>172</p></strong>
  <p style={{color:"black", fontSize:"12px", fontWeight:"600"}}>Mobile Apps</p>
  </div>
  </div>
  <div className={styles.revenue1}>
  <div className={styles.revenue2}>
<strong><p className={styles.revenue222}>415</p></strong>
  <p style={{color:"black", fontSize:"12px", fontWeight:"600", marginBottom:"0"}}>Websites Built</p>

  </div>
  <div className={styles.revenue2}>
  <strong><p className={styles.revenue222}>36%</p></strong>
  <p style={{color:"black", fontSize:"12px", fontWeight:"600" , marginBottom:"0"}}>Customer Retention</p>
  </div>
  <div className={styles.revenue2}>
  <strong><p className={styles.revenue222}>11%</p></strong>
  <p style={{color:"black", fontSize:"12px", fontWeight:"600", marginBottom:"0"}}>Social Media Interaction </p>
  </div>
</div>
</div>

</div>

</>
  );
};

export default BusinessAnalystics;
