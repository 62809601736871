import React from "react";
import { useEffect } from "react";
import ContactUsForm from "../components/ContactUsForm";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styles from "./Thankyou.module.css";

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
     <Helmet>
        <script>{`
          gtag('event', 'conversion', {'send_to': 'AW-11108316604/ypHOCLiM4KsYELzr7bAp'});
        `}</script>
      </Helmet>
      {/* <div className="my-5 mx-5 text-center"> */}
      <div className={styles.thankyou}>
      <Link to="/" className="text-white">
      <img
               className={styles.thankyou1}
                  alt=""
                  src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/popfull.png"
                />
                </Link>
      </div>
    </>
  );
};

export default Thankyou;
