import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import InterestedInTechrowth2 from "../components/InterestedInTechrowth2";
import TrustedByBrands from "../components/TrustedByBrands";
import ContactForm1 from "../components/ContactForm1";
import RecentResult from "../components/RecentResult";
import Breadcrumbs from "../components/Breadcrumbs";
import SatisfiedClients1 from "../components/SatisfiedClients1";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth1";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import OurCoreValues5 from "../components/OurCoreValues5";
import ContactUsBar from "../components/ContactUsBar";
import OtherServicesmain5 from "../components/OtherServicesmain5";
import ContactUsForm from "../components/ContactUsForm"
import Testimonial1 from "../components/Testimonial1";
import styles from "./NewHomepage.module.css";
import InterestedInTechrowth22 from "../components/InterestedInTechrowth22";
import SatisfiedClients from "../components/SatisfiedClients";
import { Helmet } from "react-helmet";

import Testimonial from "../components/Testimonial";
import style from "./NewHomepage1.module.css";
const DevelopmentPage = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/assets/it-development.jpg";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M14.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "IT Main", path: "/it-services" },
      { name: "Development", path: "/it-services/it-development" },
    ]);
  }, []);
  const subject = "IT Development Service?" 
  const HeroTitel = "IT Development Service" 
  const HeroContent = "From concept to execution, we craft bespoke solutions for your unique needs. Unlock innovation with the best IT Development Service in Bangalore." 

  const RecentText =
    "Let us help your Potential Flourish Access the power of our Result-Driven Development Cycle for Your Business's Growth!";
    const handleWhatsAppRedirect = () => {
      const phoneNumber = "+918073162943";
      const url = `tel:${phoneNumber}`;
    
      // Initiate the phone call
      window.location.href = url;
    };
  return (
    <>
    <Helmet>
<meta charSet="utf-8" />
<link rel="canonical" href="https://techrowth.com/it-development" />
<title>Tailored IT Development Services for Your Business Success | Techrowth</title>
<meta name="description" content="Looking for IT development services ? Techrowth provides the best IT development services in Bangalore. Contact us now for expert solutions to your technology requirements." />
</Helmet>
        <div className={styles.newHomepage}>
  <Hero
        title={HeroTitel}
        content={HeroContent}
        MyBackgroundImage={heroBg}
        MyBackgroundImage1={heroBg1}
      />
      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb}/>
      <div className={style.Testimonial}>
      <InterestedInTechrowth2 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial1/>

      </div >
      <div className={style.Testimonial1}>
      <InterestedInTechrowth22 title={subject}/>
     
     <SatisfiedClients />
     <Testimonial/>

      </div>
      <Link to="/contact-us" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar
        content="Take guidance from Expert Professionals! Maximize your business's potential with the best IT development services in Bangalore"
        btnTxt="Contact Us"
      />
      </Link>
           <AccelerateYourGrowth />
           <OurCoreValues5/>
           <OtherServicesmain5/>
      <ContactForm1/>
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default DevelopmentPage;
