import React, { useEffect, useState } from "react";

import Career from "../components/TermsAndCondition"
import 'bootstrap/dist/css/bootstrap.min.css';


const Career1 = () => {



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
  return (
    <>


   <Career/>

    </>
  );
};

export default Career1;
