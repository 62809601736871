import React, { useEffect } from "react";
import "./Test.module.css";
import { Link } from "react-router-dom";


const Test = () => {
  
    useEffect(() => {
        // Define an array of stylesheet URLs
        const stylesheetUrls = [
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/css(1)",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(2).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(3).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(4).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(5).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(6).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(7).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(8).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(9).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(10).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(11).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(12).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(13).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(14).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(15).css",
          "./DevSecOps Implemention _ Enhancing Security for an Energy Services Firm - Veritis Group Inc_files/huq25(16).css",
        ];
    
        // Dynamically create <link> elements for each stylesheet URL
        stylesheetUrls.forEach((url) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = url;
          linkElement.type = "text/css";
          linkElement.media = "all";
    
          // Append the <link> element to the <head>
          document.head.appendChild(linkElement);
        });
      }, []);
  return (
    <>
<div id="content" className="site-content" style={{marginTop:"1rem"}}>

<section id="content">
<section id="inner_page" className="padding resource_page">
<div className="container">

<div className="blog_content">
<h3 style={{color:"#0076a2"}}>
Implemented Digital Marketing Strategies for Increasing Walk-ins in a Jewelry Store

</h3>
<div className="meta">


<div className="row" style={{borderTop:"1px solid #e9eef4", borderBottom:"1px solid #e9eef4", padding:"0.5rem", margin:"1rem auto"}} >
<div className="col-sm-6">
<p style={{marginBottom:"0rem",fontWeight:"500"}}>By
Techrowth

</p>


</div>
<div className="col-sm-6">
<div className="social_media sm1"> <div className="social_media">
</div> </div>
</div>
</div>



</div>
</div>
<p><img decoding="async" fetchpriority="high" className="alignnone wp-image-16683 size-full" title="DevSecOps Implemention" src="https://techrowth.s3.eu-north-1.amazonaws.com/Prolearn/study9.png" alt="D" width="100%"/></p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business : </span>
A Jewelry business store.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Business Statement : </span>The jewelry store wanted to increase their walk-Ins through <Link to="https://techrowth.com/digital-marketing">digital marketing</Link>.</p>
<p><span style={{fontSize:"1rem",color:"#0076a2", fontWeight:"600" }}>Impact : </span>



144% Increase in Store Walk-Ins.</p>







<h5 style={{color:"#0076a2", fontWeight:"600" }}>Execution Strategy :</h5>

<p><strong>1) Emotion-Based Marketing:</strong></p>
<ul>
<li>Utilized emotion-based marketing to tap into the emotional connection between customers and their friends, encouraging them to spread the word about the brand.</li>

</ul>
<p><strong>2) Utilizing Multiple Channels for Ads:</strong></p>
<ul>
<li>The company used Google, Facebook, and Display ads to boost both website sales and in- store visits, with the goal of increasing brand awareness and overcoming misconceptions about La b Grown Diamonds.</li>

</ul>
<p><strong>3) Google Local Ads:</strong></p>
<ul>
<li>Utilized Google Local Ads with targeted diamond-related keywords to direct potential customers to the nearest store location, while educating them about the store's offerings and making them feel welcomed. In addition, targeted display ads using in-market and affinity audiences to increase brand awareness and drive store visits.</li>


</ul>
<p><strong>4) Visual Content Creation:</strong></p>
<ul>
<li>The company created visually stunning content that showcased their products and culture, resonating with their target audience, using high-quality images, videos, and behind-the-scenes stories.</li>


</ul>
<p><strong>5) Incentivization Tactics:</strong></p>
<ul>
<li>Implemented a successful referral program and offered exclusive discounts to social media followers for increased engagement.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Result :</h5>

<ul>
<li>Since the start of our campaigns, we have seen an increase of 144% in the number of walk-ins to stores.</li>
<li>This result was achieved within a time frame of two months.</li>
<li>A steady increase of 34% for their month-over-month walk-ins.</li>


</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Tools & Techniques :</h5>

<ul>
<li>We used <Link to="https://techrowth.com/digital-marketing/social-media-marketing">social media marketing</Link> to increase brand awareness and engage with customers on various platforms.</li>
<li><Link to="https://techrowth.com/digital-marketing/pay-per-click">PPC campaigns</Link> were utilized to target specific audiences and drive traffic to our website.</li>
<li>Branding efforts were focused on creating a strong brand identity through consistent messaging and visual design.</li>

</ul>

<h5 style={{color:"#0076a2", fontWeight:"600" }}>Budget :</h5>

<ul>
<li>A $33k budget exclusive contract with us resulted in up to 34% customer engagement through various marketing channels.</li>

</ul>

</div>


</section>
</section>
</div>


    </>
  );
};

export default Test;
