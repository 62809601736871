import styles from "./OurCoreValues2.module.css";
import Testimonial from "./Testimonial";

import { Link } from "react-router-dom";
import { useEffect } from "react";
const WhoWeAre = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className={styles.whoWeAre}>
  
      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
         
          <p className={styles.ourCoreValuessss} style={{color:"black"}}>We provide comprehensive digital marketing solutions that are customised to your specific demands driven by a team of experienced marketers and advanced strategies.
</p>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
            <Link to="/digital-marketing/social-media-marketing" >

<div className={styles.frameDiv}>
  <div className={styles.groupParent}>
    <img
      className={styles.frameInner}
      alt=""
      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr5.svg"

    />
    <div className={styles.groupContainer}>
      <div className={styles.groupDiv}>
        <div className={styles.consistencyParent}>
          <b className={styles.consistency}>Social Media Marketing</b>
          <div className={styles.consistentInGetting}>
          
          Boost your brand's presence with our expertise
          </div>
        </div>
      </div>
      <div className={styles.ourCommitmentTo}>
      Social Media Marketing: Discover the power of social media platforms to engage, connect, and build relationships with your target audience. Our creative campaigns are designed to increase brand awareness, drive traffic, and ultimately boost your conversions.

      </div>

    </div>
  </div>
</div>
</Link>
<Link to="/digital-marketing/social-media-optimization" >
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr6.svg"


                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Social Media Optimization</b>
                        <div className={styles.consistentInGetting}>
                        Unlock your brand's potential on social media
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Optimize your social media presence to the fullest with our expert guidance. From profile setup and content creation to community management, we ensure your brand shines across all major social networks.
                    </div>
                
                  </div>
                </div>
              </div>
              </Link>
            </div>

            <div className={styles.frameContainer}>
            <Link to="/digital-marketing/search-engine-optimization" >

<div className={styles.frameDiv}>
  <div className={styles.groupParent}>
    <img
      className={styles.frameInner}
      alt=""
      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr7.svg"

    />
    <div className={styles.groupContainer}>
      <div className={styles.groupDiv}>
        <div className={styles.consistencyParent}>
          <b className={styles.consistency}>Search Engine Optimization</b>
          <div className={styles.consistentInGetting}>
          
          Climb the search rankings and be seen by the world
          </div>
        </div>
      </div>
      <div className={styles.ourCommitmentTo}>
      Climb to the top of search engine results with our result-oriented SEO strategies. We'll analyze your website, conduct in-depth keyword research, and implement on-page and off-page SEO techniques to enhance your visibility and organic traffic.

      </div>

    </div>
  </div>
</div>
</Link>
<Link to="/digital-marketing/search-engine-marketing" >
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr8.svg"


                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Search Engine Marketing</b>
                        <div className={styles.consistentInGetting}>
                        Drive results with targeted search engine campaigns
                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    When it comes to rapid and targeted results, our SEM services have got you covered. Through targeted ads and efficient bid management, we drive qualified traffic to your website, maximizing your ROI.

                    </div>
                
                  </div>
                </div>
              </div>
              </Link>
            </div>
          
            <div className={styles.frameContainer}>
            <Link to="/digital-marketing/pay-per-click" >

<div className={styles.frameDiv}>
  <div className={styles.groupParent}>
    <img
      className={styles.frameInner}
      alt=""
      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr9.svg"

    />
    <div className={styles.groupContainer}>
      <div className={styles.groupDiv}>
        <div className={styles.consistencyParent}>
          <b className={styles.consistency}>Pay Per Click</b>
          <div className={styles.consistentInGetting}>
          Maximize your ROI with precision-driven PPC
          </div>
        </div>
      </div>
      <div className={styles.ourCommitmentTo}>
      Achieve instant visibility and control your budget with our PPC advertising expertise. Our certified professionals create compelling ad campaigns that attract high-intent prospects, ensuring a steady stream of qualified leads.

      </div>

    </div>
  </div>
</div>
</Link>
<Link to="/digital-marketing/email-marketing" >
              <div className={styles.frameWrapper1}>
                <div className={styles.groupParent1}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr10.svg"


                  />
                  <div className={styles.groupParent2}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.originality}>Email Marketing</b>
                        <div className={styles.consistentInGetting}>
                        Connect, engage, and convert with every email sent

                        </div>
                      </div>
                    </div>
                    <div className={styles.originalityIsOne}>
                    Nurture and retain your customers with our personalized and engaging email marketing campaigns. We craft compelling content and optimize delivery times to achieve higher open rates and conversions.

                    </div>
                
                  </div>
                </div>
              </div>
              </Link>
            </div>
      
       
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr11.svg"


                  />
                  <div className={styles.groupParent4}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Influencer Marketing</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Let influencers spread the word about your brand`}</div>
                      </div>
                    </div>
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Harness the power of influential voices to amplify your brand's message. Our extensive network of influencers and data-driven approach ensure your product reaches the right audience through authentic recommendations.
                      </p>
                    </div>
              
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr12.svg"


                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Performance Marketing</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Guaranteed results: pay only for what works `}</div>
                      </div>
                    </div>
           
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
With our performance marketing strategies, you pay for results, not just efforts. We track, analyze, and optimize campaigns in real-time to achieve maximum efficiency and drive measurable growth.
                        </p>
                      </div>
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupParent3}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr13.svg"


                  />
                  <div className={styles.groupParent4}>
                  <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>App Store Optimization</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Get your app discovered and downloaded by millions`}</div>
                      </div>
                    </div>
                  
                    <div className={styles.reliabilityIsOneContainer}>
                      <p
                        className={
                          styles.searchingForRelevant21stCe +
                          " position-absolute"
                        }
                        style={{ top: "30px" }}
                      >
Ensure your app gets the visibility it deserves on various app stores. Our ASO experts optimize your app's title, description, keywords, and visuals to boost downloads and user engagement.
                      </p>
                    </div>
                 
                  </div>
                </div>
              </div>
              <div className={styles.groupWrapper}>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <img
                      className={styles.frameInner}
                      alt=""
                      src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr14.svg"

                    />
                    <div className={styles.groupParent6}>
                    <div className={styles.frameWrapper4}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Programmatic Marketing</b>
                        <div
                          className={styles.consistentInGetting}
                        >{`Automate success with data-driven strategies`}</div>
                      </div>
                    </div>
            
                      <div className={styles.oneOfOurContainer}>
                        <p
                          className={
                            styles.searchingForRelevant21stCe +
                            " position-absolute"
                          }
                          style={{ top: "30px" }}
                        >
Stay ahead of the competition with our programmatic marketing solutions. Through automated ad buying and real-time data analysis, we deliver hyper-targeted messages that resonate with your audience.
                        </p>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>











    <div className={styles.whoWeAree}>

      <div className={styles.whoWeAreChild}>
        <div className={styles.ourCoreValuesParent}>
       
          <p className={styles.ourOrigin123} style={{color:"black"}}>Custom digital marketing by experienced experts with advanced strategies
</p>

          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>

            <Link to="/digital-marketing/social-media-marketing" >

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr5.svg"

                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Social Media Marketing</b>
                        <div className={styles.consistentInGetting}>
                        
                        Boost your brand's presence with our expertise
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Social Media Marketing: Discover the power of social media platforms to engage, connect, and build relationships with your target audience. Our creative campaigns are designed to increase brand awareness, drive traffic, and ultimately boost your conversions.

                    </div>
          
                  </div>
                </div>
              </div>
              </Link>

              <Link to="/digital-marketing/social-media-optimization" >

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr6.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Social Media Optimization</b>
                        <div className={styles.consistentInGetting}>
                        
                        Unlock your brand's potential on social media
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    
                    Optimize your social media presence to the fullest with our expert guidance. From profile setup and content creation to community management, we ensure your brand shines across all major social networks.
             
                    </div>
       
                  </div>
                </div>
              </div>
</Link>


              <Link to="/digital-marketing/search-engine-optimization" >

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr7.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Search Engine Optimization</b>
                        <div className={styles.consistentInGetting}>
                        Climb the search rankings 
                       
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    
                    Climb to the top of search engine results with our result-oriented SEO strategies. We'll analyze your website, conduct in-depth keyword research, and implement on-page and off-page SEO techniques to enhance your visibility and organic traffic.
                  
                    </div>

                  </div>
                </div>
              </div>
</Link>


              <Link to="/digital-marketing/search-engine-marketing" >

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr8.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Search Engine Marketing</b>
                        <div className={styles.consistentInGetting}>
                        Drive results with targeted campaigns
                       
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    When it comes to rapid and targeted results, our SEM services have got you covered. Through targeted ads and efficient bid management, we drive qualified traffic to your website, maximizing your ROI.
             

                    </div>
  
                  </div>
                </div>
              </div>
</Link>
           
              <Link to="/digital-marketing/pay-per-click" >

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr9.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Pay Per Click</b>
                        <div className={styles.consistentInGetting}>
                        Maximize your ROI with precision-driven PPC
                        
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    
                    Achieve instant visibility and control your budget with our PPC advertising expertise. Our certified professionals create compelling ad campaigns that attract high-intent prospects, ensuring a steady stream of qualified leads.
               
                    </div>
     
                  </div>
                </div>
              </div>
              </Link>



              <Link to="/digital-marketing/email-marketing" >

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr10.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Email Marketing</b>
                        <div className={styles.consistentInGetting}>
                       
                        Connect, engage, and convert with every email
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    
                    Nurture and retain your customers with our personalized and engaging email marketing campaigns. We craft compelling content and optimize delivery times to achieve higher open rates and conversions.
            
                    </div>
            
                  </div>
                </div>
              </div>
              </Link>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr11.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Influencer Marketing</b>
                        <div className={styles.consistentInGetting}>
                       
                        Let influencers spread the word about brand
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Harness the power of influential voices to amplify your brand's message. Our extensive network of influencers and data-driven approach ensure your product reaches the right audience through authentic recommendations.

                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr12.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Performance Marketing</b>
                        <div className={styles.consistentInGetting}>
                       
                        Guaranteed results: pay only for what works
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    
                    With our performance marketing strategies, you pay for results, not just efforts. We track, analyze, and optimize campaigns in real-time to achieve maximum efficiency and drive measurable growth.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr13.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>App Store Optimization</b>
                        <div className={styles.consistentInGetting}>
                        
                        Get your app downloaded by millions
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    Ensure your app gets the visibility it deserves on various app stores. Our ASO experts optimize your app's title, description, keywords, and visuals to boost downloads and user engagement.

                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.frameInner}
                    alt=""
                    src="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/gr14.svg"


                  />
                  <div className={styles.groupContainer}>
                    <div className={styles.groupDiv}>
                      <div className={styles.consistencyParent}>
                        <b className={styles.consistency}>Programmatic Marketing</b>
                        <div className={styles.consistentInGetting}>
                        Automate success with data-driven strategies
                        
                        </div>
                      </div>
                    </div>
                    <div className={styles.ourCommitmentTo}>
                    
                    Stay ahead of the competition with our programmatic marketing solutions. Through automated ad buying and real-time data analysis, we deliver hyper-targeted messages that resonate with your audience.
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

  

    </div>
    </>
  );
};

export default WhoWeAre;
