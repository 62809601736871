import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Ppcbanner from "../components/ppcbanner1";

import RecentResult from "../components/RecentResult";
import OneStopSolution1 from "../components/OneStopSolution1";
import SatisfiedClients from "../components/SatisfiedClients";
import AccelerateYourGrowth from "../components/AccelerateYourGrowth";
import DigitalMarektingComponent1 from "../components/DigitalMarektingComponent1";
import DigitalMarektingComponent2 from "../components/DigitalMarektingComponent2";
import styles from "./NewHomepage.module.css";
import style from "./NewHomepage1.module.css";
import { Helmet } from "react-helmet";
import DigitalMarketingServices1 from "../components/DigitalMarketingServices1";
import Testimonial from "../components/Testimonial";
import BISCCBOR from "../components/BISCCBOR";
import ExploreOurCaseStudies from "../components/ExploreOurCaseStudies";
import RevenueGenerated from "../components/RevenueGenerated";
import ITServices1 from "../components/ITServices1";
import BusinessAnalystics from "../components/BusinessAnalystics";
import ContactFrom1 from "../components/ContactForm1";
import TrustedByBrands from "../components/TrustedByBrands";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactUsForm from "../components/ContactUsForm";
import ContactUsBar from "../components/ContactUsBar";
import { Link } from "react-router-dom";
import Testimonial1 from "../components/Testimonial1";
import NumberCounterSocial from "../components/NumberCounterSocial";
import IndustriesWorkedWith from "../components/IndustiresWorkedWIth";
import TechnologiesWeUseAndServiSocia from "../components/TechnologiesWeUseAndServiSocia";
import OurCoreValuess from "../components/OurCoreValuess";
const DigitalMarketing = () => {
const heroBg = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/2123123.png";
const heroBg1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth+Website/Banners/M2.png";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [breadcrumb, setBreadcrumb] = useState([]);
  useEffect(() => {
    setBreadcrumb([
      { name: "Home", path: "/" },
      { name: "Digital Marketing", path: "/digital-marketing" }
    ]);
  }, []);

  const HeroTitel = "Digital Marketing Services";
  const HeroContent =
    "Unlock the potential of your brand and seize future growth opportunities with our result-driven digital marketing services in Bangalore to reach a wider audience, boost brand visibility, and drive growth.";
  const RecentText =
    "Take guidance from Expert Professionals! Maximize the potential of your online reach with the best digital marketing services in Bangalore";
  const cotactBarContent =
    "Wondering if you could be a part of a success story? Book a free consultation today and learn more!";
  return (
    <>
        <Helmet>
        <link rel="canonical" href="https://techrowth.com/digital-marketing" />
                <meta charSet="utf-8" />
                <title>Digital Marketing Services in Bangalore - Best Digital Marketing Agency</title>
                <meta name="description" content="Looking for the best Digital Marketing Agency? Techrowth provide result-driven digital marketing services in Bangalore. Choose Techrowth as your marketing partner" />

                <meta name="keywords" content="Digital Marketing Company in Bangalore, Digital Marketing Agency in Bangalore, Online Marketing Company in Bangalore, Internet Marketing Company in Bangalore, Digital Marketing Companies in Bangalore, Digital Marketing Agency, Digital Marketing Company, Online Marketing Company, Internet Marketing Company Bangalore" />

            </Helmet>
            <Ppcbanner id="ppcBanner" />
        <div className={styles.newHomepage}>

      <RecentResult />
      <Breadcrumbs breadcrumb={breadcrumb} />
      <OneStopSolution1 />
     
      <SatisfiedClients show={false} />
      <div className={style.Testimonial}>
      <Testimonial1/>
     

      </div >
      <div className={style.Testimonial1}>
      <Testimonial/>

      </div>
      <Link to="/digital-marketing#ppcBanner" style={{ color: "#ffffff", textDecoration:"none" }}>
      <ContactUsBar content={RecentText} btnTxt="Contact Us"/>
     </Link>
      <AccelerateYourGrowth />
      <DigitalMarektingComponent1 />
      <TechnologiesWeUseAndServiSocia />
      <OurCoreValuess/>
      <BISCCBOR />
   
      <DigitalMarektingComponent2 />







      <IndustriesWorkedWith />
      <ContactFrom1 />
      <ExploreOurCaseStudies /></div>
      <TrustedByBrands />
    </>
  );
};

export default DigitalMarketing;
