import styles from "./Services.module.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
    <div className="container">
      <div className={styles.itServices}>
        <div className={styles.frameParent}>
          <div className={styles.itServicesParent}>
            <div className={styles.development}>Why Choose Techrowth's CMS Development Service?</div>
            <div className={styles.letOurExpertContainer}>
              <p
                className={styles.letOurExpert}
              >{`Seek an efficient, user-friendly, and scalable solution to manage your website content`}</p>
           
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Easy Website Content Updates</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                    <p className={styles.cms1}>Techrowth's CMS service provides a user-friendly interface that allows you to update and manage your website content with ease, even without technical expertise. You can add new pages, edit existing content, upload images, and publish blog posts without the need for complex coding knowledge.</p>
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Customized CMS Solutions`}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth understands that every business has unique content management needs. They offer customized CMS solutions tailored to your specific requirements, ensuring that the CMS aligns perfectly with your website's design, functionality, and business goals.</p>

                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Seamless Website Maintenance</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>With a reliable CMS in place, website maintenance becomes more efficient. Techrowth's CMS service allows you to quickly address security updates, manage plugins or extensions, and implement changes, saving time and effort in website maintenance.</p>
              
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Content Versioning and Collaboration</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>Techrowth's CMS provides content versioning and collaboration features, allowing multiple users to work on content simultaneously without overwriting each other's changes. This ensures better content management in team settings and helps maintain content integrity.</p>
      
                  </div>
                </div>
              </div>

              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div
                    className={styles.development}
                  >{`Search Engine Optimization (SEO) Friendly: `}</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}>An SEO-friendly CMS can significantly impact your website's search engine rankings. Techrowth ensures that their CMS solutions are designed to be SEO-friendly, making it easier to implement best practices and optimize your content for search engines.</p>
      
                  </div>
                </div>
              </div>
 
              <div className={styles.frameDiv}>
                <div className={styles.uiuxInteractiveParent}>
                  <div className={styles.development}>Content Management and Scalability</div>
                  <div className={styles.cmsDevelopmentsupportWebsitContainer}>
                  <p className={styles.cms1}> As your business grows, so do your content management needs. Techrowth's CMS service is scalable and flexible, accommodating your website's future expansion and increasing traffic without sacrificing performance or user experience.</p>
    
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
      <div className={styles.itServices1}>
   
      <h4 className={styles.businessAnalystics2}>Why Choose Techrowth's CMS Development Service?
</h4>
      <p className={styles.businessAnalystics3}>Seek an efficient, user-friendly, and scalable solution to manage your website content</p>
      <div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Easy Website Content Updates</h4>
      <p className={styles.cms1}>Techrowth's CMS service provides a user-friendly interface that allows you to update and manage your website content with ease, even without technical expertise. You can add new pages, edit existing content, upload images, and publish blog posts without the need for complex coding knowledge.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Customized CMS Solutions</h4>
      <p className={styles.cms1}>Techrowth understands that every business has unique content management needs. They offer customized CMS solutions tailored to your specific requirements, ensuring that the CMS aligns perfectly with your website's design, functionality, and business goals.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Seamless Website Maintenance</h4>

      <p className={styles.cms1}>With a reliable CMS in place, website maintenance becomes more efficient. Techrowth's CMS service allows you to quickly address security updates, manage plugins or extensions, and implement changes, saving time and effort in website maintenance.</p>
     


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Content Versioning and Collaboration</h4>
      
      <p className={styles.cms1}>Techrowth's CMS provides content versioning and collaboration features, allowing multiple users to work on content simultaneously without overwriting each other's changes. This ensures better content management in team settings and helps maintain content integrity.</p>
    


</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Search Engine Optimization (SEO) Friendly: </h4>
      <p className={styles.cms1}>An SEO-friendly CMS can significantly impact your website's search engine rankings. Techrowth ensures that their CMS solutions are designed to be SEO-friendly, making it easier to implement best practices and optimize your content for search engines.</p>



</div>
<div className={styles.itServices123}>
      <h4 className={styles.itServices1234}>Content Management and Scalability</h4>
 
      <p className={styles.cms1}> As your business grows, so do your content management needs. Techrowth's CMS service is scalable and flexible, accommodating your website's future expansion and increasing traffic without sacrificing performance or user experience.</p>


</div>


    
</div>
</>
  );
};

export default Services;
